import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

interface LazyTextField {
    value: string;
    label: string;
    multiline?: boolean;
    multilineLevels?: number;
    onChange: (val: string) => void;
    helperText?: (string) => string;
    validator?: (string) => boolean;
    focusStatusUpdated?: (boolean) => void;
}
const LazyTextField: React.FC<LazyTextField> = ({ value, label, focusStatusUpdated, multiline, multilineLevels, onChange, helperText, validator }) => {
    const [temp, setTemp] = useState(value ?? "")

    useEffect(() => {
        setTemp(value ?? "");
    }, [value])
    return (
        <TextField
            label={label}
            value={temp}
            onFocus={() => {if(focusStatusUpdated != null) focusStatusUpdated(true);}}
            onChange={(e) => setTemp(e.target.value)}
            onBlur={(e) => {onChange(e.target.value);
            if(focusStatusUpdated != null) focusStatusUpdated(false);}}
            fullWidth
            color={validator != null ? validator(temp) ? "success" : "error" : "success"}
            multiline={multiline}
            rows={multilineLevels}
            helperText={helperText != null ? helperText(temp) : ""}
        />
    )
}

export default LazyTextField;