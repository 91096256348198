import {Card, CardContent, Divider, Skeleton, Typography} from '@mui/material'
import DataField from 'components/data/DataField'
import React from 'react'
import HoverPaper from "../../../components/HoverPaper";

const DeviceDebugCard = ({device, isLoading}) => {
    if (isLoading) {
        return (
            <HoverPaper elevation={4}>
                <Skeleton height="20vh"/>
            </HoverPaper>
        )
    }
    return (
        <HoverPaper elevation={4}>
            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Device Debug
                Information</Typography>
            <Divider sx={{mb: '1rem'}}/>

            <DataField compact width={"100%"} title="Platform Version" data={device.platformVersion}/>
            <DataField compact width={"100%"} title="Firmware Version" data={device.firmwareVersion}/>
            <DataField compact width={"100%"} title="KIND" data={device.kind}/>
            <DataField compact width={"100%"} title="ETag" data={device.etag}/>
            <DataField compact width={"100%"} title="Boot Mode" data={device.bootMode}/>
            <DataField compact width={"100%"} title="OS Version" data={device.osVersion}/>

        </HoverPaper>
    )
}

export default DeviceDebugCard