import { Add, Delete } from '@mui/icons-material';
import { Avatar, Box, Button, Card, CardContent, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, IconButton, LinearProgress, List, ListItemButton, ListItemText, MenuItem, Skeleton, Stack, TextField, Typography, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { current } from '@reduxjs/toolkit';
import DataEditable from 'components/data/DataEditable';
import PermissionCheckbox from 'components/data/PermissonCheckbox';
import TwoSide from 'components/data/TwoSide';
import React, { useEffect, useState } from 'react'
import { ConvertScopeIntToString, useGetGroupMembersQuery, useGetGroupsQuery } from 'state/api';
import AddUserToGroup from './AddUserToGroup';
import OrganizationMembersTable from './OrganizationMembersTable';
import OrganizationGroup from './OrganizationGroup';

const groups = ["Admin", "Student"]

const scopes = {
    'read': 
    [
        {
            value: "read",
            label: "Read All",
            children: 
            [
                {
                    value: "read:ticket",
                    label: "Read Ticket",
                    children: 
                    [
                        {
                            value: "read:ticket:overview",
                            label: "Read Ticket Overview"
                        },
                        {
                            value: "read:ticket:self",
                            label: "Read Personal Tickets"
                        },
                        {
                            value: "read:ticket:messages",
                            label: "Read Ticket Messages"
                        },
                        {
                            value: "read:ticket:images",
                            label: "Read Ticket Images"
                        },
                        {
                            value: "read:ticket:Audits",
                            label: "Read Ticket Audits"
                        },
                    ]
                    
                },
                {
                    value: "read:user",
                    label: "Read User",
                    children: []
                },
                {
                    value: "read:student",
                    label: "Read Student",
                    children: []
                },
                {
                    value: "read:device",
                    label: "Read Device",
                    children: []
                },
                {
                    value: "read:organization",
                    label: "Read Organization",
                    children: [
                        {
                            label: "Read Organization Settings",
                            value: "read:organization:settings"
                        }
                    ]
                }
            ]
        }
    ],
    'write': 
    [
        {
            value: "write",
            label: "Write All",
            children: 
            [
                {
                    value: "write:ticket",
                    label: "Write Ticket",
                    children: 
                    [
                        {
                            value: "write:ticket:overview",
                            label: "Write Ticket Overview"
                        },
                        {
                            value: "write:ticket:self",
                            label: "Write Personal Tickets"
                        },
                        {
                            value: "write:ticket:messages",
                            label: "Write Ticket Messages"
                        },
                        {
                            value: "write:ticket:images",
                            label: "Write Ticket Images"
                        },
                    ]
                    
                },
                {
                    value: "write:user",
                    label: "Write User",
                    children: []
                },
                {
                    value: "write:organization",
                    label: "Write Organization",
                    children: [
                        {
                            label: "Write Organization Settings",
                            value: "write:organization:settings"
                        }
                    ]
                }
            ]
        }
    ]
}

const ticketScopes = [
    {
        label: "Full Ticket access", 
        value: "write:ticket"
    },
    {
        label: "Read Only", 
        value: "read:ticket"
    },
    {
        label: "Read Only Overview", 
        value: "read:ticket:overview"
    },
    {
        label: "Read Only Personal Tickets", 
        value: "read:ticket:self"
    }, 
    {
        label: "None",
        value: "none:ticket"
    }
]

const userScopes = [
    {
        label: "Full Access",
        value: "write:user"
    },
    {
        label: "Read Only Users", 
        value: "read:user"
    },
    {
        label: "None",
        value: "none:user"
    }
]

const studentScopes = [
    {
        label: "View Students", 
        value: "read:student"
    },
    {
        label: "None",
        value: "none:student"
    }
]

const deviceScopes = [
    {
        label: "Read Devices", 
        value: "read:device"
    },
    {
        label: "None",
        value: "none:device"
    }
]


const organizationScopes = [
    {
        label: "Read Organization Settings", 
        value: "read:organization"
    },
    {
        label: "Update Organization Settings",
        value: "write:organization"
    },
    {
        label: "None",
        value: "none:organization"
    }
]


function OrganizationRolesSettings() {
    const [currentPermissions, setCurrentPermissions] = useState([])
    const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
    const [groups, setGroups] = useState([]);
    const [currentGroup, setCurrentGroup] = useState("")
    const {data, isLoading, error, refetch} = useGetGroupsQuery("");
    const [isAddingUser, setIsAddingUser] = useState(false);
    useEffect(() => {
        setGroups(data || []);
        if(data && data.length == 1) {
            setCurrentGroup(data[0].id)
        }
    }, [data])

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        if(groups.length <= 0) return;
        setCurrentGroup(groups[currentGroupIndex].id)
    }, [currentGroupIndex])




    const addGroup = () => {
        fetch(process.env.REACT_APP_BASE_URL + '/org/groups?name=Untitled%20Group', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            }).then(async (v) => {
                await refetch()
            })
    }
    

    const deleteGroup = (group) => {
        fetch(process.env.REACT_APP_BASE_URL + `/org/groups/${group}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            }).then(async (v) => {
                await refetch()
            })
    }

    const theme = useTheme();

  return (
    <Box sx={{mt: '1rem'}}>
        <Typography variant="h3">Organization Groups</Typography>
        <Typography variant='subtitle2'>Edit your Organizational Groups</Typography>
        <Divider sx={{m: isLoading ? '1rem 0 0rem 0' : '1rem 0 2rem 0'}}/>
        {isLoading && (
            <Box sx={{
                mb: '1rem'
            }}>
                <LinearProgress color="secondary" />
            </Box>
        )}
        <Box 
        >
            <Grid2 container spacing={1}> 
                <Grid2 md={2}>
                    <Card sx={{mt: '1rem'}}>
                        <CardContent>

                            <Box>
                                <Typography variant="h4">Groups</Typography>
                                <Divider/>
                                {isLoading && (
                                    <>
                                        <Skeleton/>
                                        <Skeleton/>
                                        <Skeleton/>
                                    </>
                                )}
                                <List>
                                    {groups.map((g, i) => 
                                        <ListItemButton key={g.id} onClick={() => {
                                            setCurrentGroupIndex(i);
                                            setCurrentGroup(String(g.id));
                                        }}
                                        sx={{
                                            //@ts-ignore
                                            backgroundColor: currentGroupIndex === i ? theme.palette.secondary[300] : "transparent",
                    
                                            //@ts-ignore
                                            color: currentGroupIndex === i ? theme.palette.primary[600] : theme.palette.secondary[100]
                                        }}>
                                            <ListItemText primary={g.name}/>
                                            <IconButton onClick={() => deleteGroup(g.id)} size="small">
                                                <Delete/>
                                            </IconButton>
                                        </ListItemButton>
                                    )}
                                    
                                </List>
                                <Button onClick={() => addGroup()} sx={{width: '100%'}} variant="contained" color="secondary" endIcon={<Add/>}>Add Group</Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid2>
                <Grid2 md={10}>

                    {currentGroup != null && (
                        <OrganizationGroup id={currentGroup} onChange={() => refetch()}/>
                    )}
                </Grid2>
                <Box>
                </Box>
            </Grid2>
            
        </Box>
    </Box>
  )
}

export default OrganizationRolesSettings