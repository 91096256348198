import { useTheme } from '@emotion/react'
import { Backdrop, Box, CircularProgress, LinearProgress, Typography } from '@mui/material'
import React from 'react'

interface Loading {
  line?: boolean;
  backdrop?: boolean;
  message?: string;
}
const Loading:React.FC<Loading> = ({line = false, backdrop = false, message ="Loading...."}) => {
  const theme = useTheme();


  if(backdrop) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '100%', height: '100%', flexDirection: "column", alignItems:'center'}}>
          <CircularProgress size={100} color="secondary" />
          <Typography variant="h4" sx={{marginTop: '.5rem'}}>{message}</Typography>
        </Box>
      </Backdrop>
    )
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '100%', height: '100%', flexDirection: "column"}}>
    <div style={{margin: 'auto'}}>
      {line ? (
        <LinearProgress  sx={{margin: 'auto'}} color="secondary" />
      ): (
        <>
          <CircularProgress sx={{margin: 'auto'}} color="secondary" size={60} />
          <Typography sx={{marginTop: '.5rem'}}>{message}</Typography>
        </>
      )}
    </div>
    </Box>
  )
}

export default Loading