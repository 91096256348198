import {Avatar, Box, Divider, Grow, Skeleton, Typography, useTheme} from '@mui/material'
import {red} from '@mui/material/colors'
import {DataGrid, GridColDef, GridToolbar} from '@mui/x-data-grid'
import React from 'react'
import {LazyNavigation, useGetRecentUsersQuery} from 'state/api'
import HoverPaper from "../../../components/HoverPaper";

const RecentUsersCard = ({id}) => {
    const {data, isLoading, error} = useGetRecentUsersQuery(id)
    const theme = useTheme();
    const recentUsersColumns: GridColDef[] = [
        {
            field: "icon",
            headerName: "Icon",
            renderCell: (params) => {
                return <Grow in={true}>
                    <Avatar alt={params.row.name} sx={{bgcolor: red[200]}} src={params.row.thumbnailPhotoUrl}/>
                </Grow>
            }

        },
        {field: "name", headerName: 'Name', flex: 1},
        {field: "primaryEmail", headerName: 'Primary Email', flex: 1},
    ]

    if (isLoading) {
        return (
            <HoverPaper elevation={4}>
                <Skeleton height="40vh"/>
            </HoverPaper>
        )

    }


    return (
        <HoverPaper elevation={4} sx={{height: '100%'}}>
            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Recent Users</Typography>
            <Divider sx={{mb: '1rem'}}/>

            <Box
                height="50vh"

                sx={{

                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        cursor: 'grab',
                        borderBottom: "none",
                    },

                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    slots={{toolbar: GridToolbar}}
                    loading={isLoading}
                    getRowId={(row) => row.id}
                    rows={data || []}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    columns={recentUsersColumns}
                    onRowClick={(params) => {
                        LazyNavigation("/org/users/" + params.row.id)
                    }}
                />
            </Box>
        </HoverPaper>

    )
}

export default RecentUsersCard