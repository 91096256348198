import { Box, Button, Card, CardActions, CardContent, Divider, Stack, Typography, useTheme } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Header from 'components/Header'
import React, { useState } from 'react'
import CreateConfiguration from './CreateConfiguration';
import { useParams } from 'react-router-dom';
import CreateBatch from './CreateBatch';
import { LazyNavigation, useGetStudentBatchesQuery } from 'state/api';
import DataField from 'components/data/DataField';
import QuickCreate from "./QuickCreate";

interface BatchCard {
  batch: any;
  onDelete: () => void;
}

const BatchCard = ({batch, onDelete}) => {
  
  const deleteBatch = async () => {
    var response = await fetch(process.env.REACT_APP_BASE_URL + '/org/students/batch/configuration/' + batch.id, {
      method: "DELETE",
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if(response.status == 200) {
      onDelete();
    }
  }


  return (
    <Card sx={{height: '100%'}}>
      <CardContent>
        <Typography variant="h3">Batch #{batch.id.slice(-5)}</Typography>
        <Divider />
        <Typography sx={{m: '.5rem 0'}} variant="h6">Configuration: </Typography>
        <Grid2 container spacing={1}>
          <Grid2 md={6}>
            <DataField title={"Student Ou"} compact data={batch.studentOu  == "" ? "Do Not Change" : batch.studentOu} width="fit-content"/>
          </Grid2>
          <Grid2 md={6}>
            <DataField title={"Device Ou"} compact data={batch.deviceOu == "" ? "Do Not Change" : batch.deviceOu} width="fit-content"/>
          </Grid2>
        </Grid2>
      </CardContent>
      <CardActions>
        <Grid2 sx={{width: '100%'}} container spacing={2}>
          <Grid2 md={6}>
            <Button fullWidth color="error" variant="outlined" onClick={() => deleteBatch()}>Delete</Button>
          </Grid2>
          <Grid2 md={6}>
            <Button fullWidth color="success" variant="outlined" onClick={() => LazyNavigation("/org/students/create?batch=" + batch.id)}>Continue</Button>
          </Grid2>
        </Grid2>
      </CardActions>
    </Card>
  )
}

function CreateStudent() {
  const [createdStudents, setCreatedStudents] = useState([]);
  const [isCreatingBatch, setIsCreatingBatch] = useState(false);
  const [isCreatingConfiguration, setIsCreatingConfiguration] = useState(false);
  const {data, isLoading, error, refetch} = useGetStudentBatchesQuery("")
  const params = new URLSearchParams(window.location.search)
  const [quickCreate, setQuickCreate] = useState(false);

  const theme = useTheme();


  if(!isCreatingBatch && params.has('batch')) {
    setIsCreatingBatch(true)
  }
  return (
    <Box m="1.5rem 2.5rem">
        <Header title={"Create Students"} subtitle={"Create Students in batches and assign devices to users"}/> 
        <Box mt="1rem" 
        
        sx={{
          //@ts-ignore
          backgroundColor:theme.palette.background.alt,
          borderRadius:"0.55rem",
          padding: '1rem',
          mb: '1rem'
        }}
      
        >
            <Typography variant="h5">Here you can create students by assigning/creating users and assigning them to devices.</Typography>
            <CreateConfiguration open={isCreatingConfiguration} stateHandler={setIsCreatingConfiguration}/>

            {isCreatingBatch ? (
              <CreateBatch id={params.get("batch")} />
              ) : (
                <Box>
                  <Button sx={{m: '1rem 0'}} variant="contained" color="secondary" onClick={() => setIsCreatingConfiguration(true)}>Create New Batch of Students</Button>
                  <Button sx={{m: '1rem 1rem'}} variant="contained" color="secondary" onClick={() => setQuickCreate(true)}>Create Single Student</Button>
                  <QuickCreate open={quickCreate} setOpen={setQuickCreate} />
                  <Divider sx={{m: '1rem 0'}} />

                  {(data != null && data.length > 0) && (
                    <Box>
                    
                      <Typography variant="h2">Current Batches</Typography>
                      <Divider sx={{m: '1rem 0'}}/>
                      <Grid2 container spacing={2}>
                        {data.map(c => (
                          <Grid2 xs={12} md={4}>
                            <BatchCard key={c.id} batch={c} onDelete={() =>refetch()} />
                          </Grid2>
                        ))}
                      </Grid2>
                    </Box>
                  )}
                </Box>
            )}
        </Box>
    

    </Box>
  )
}

export default CreateStudent