import ForceLightMode from 'components/ForceLightMode';
import React, { useEffect } from 'react'

const TermsOfService = () => {
    useEffect(() => {
        //window.location.href = "https://app.termly.io/document/terms-of-service/cd64d962-ad6b-4c7f-bb88-f33a3b0d205f";        
      }, []);
    
      return (
        <>
          <ForceLightMode/>
          <iframe
              src="https://app.termly.io/document/terms-of-service/cd64d962-ad6b-4c7f-bb88-f33a3b0d205f" // Replace with your URL
              title="Terms and Conditions"
              width="100%"
              height="100%" // Adjust the height as needed
              frameBorder="0"
          />
        </>
      );
}

export default TermsOfService