import { ResponsiveBar } from '@nivo/bar'
import React, { useEffect, useState } from 'react'
import { useGetWeekStatsQuery } from 'state/api';

interface WeekStats {
    start: Date,
    end: Date
}
const WeekStats:React.FC<WeekStats> = ({start, end}) => {
    const [data, setData] = useState([]);
    const [keys, setKeys] = useState([]);
    const {data: weekStats, isLoading} = useGetWeekStatsQuery({start: start, end:end});

    useEffect(() => {
        if(!isLoading) {

            var dates = []
            // Get the current date
            const currentDate = new Date();

            // Set the current date to the previous Sunday
            currentDate.setDate(currentDate.getDate() - currentDate.getDay());
            console.log(currentDate)

            for(var i = 0; i < 7; i++) {
                console.log(currentDate)
                var tickets = weekStats.tickets.filter((t) => new Date(t.key).getDay() == currentDate.getDay())
                var completed = weekStats.completed.filter((t) => new Date(t.key).getDay() == currentDate.getDay())
                
                currentDate.setDate(currentDate.getDate() + 1);

                var ticketCount = 0;
                var completedCount = 0;

                if(tickets.length > 0) {
                    ticketCount = calculateAverage(tickets.map((t) => t.value))
                }

                if(completed.length > 0) {
                    completedCount = calculateAverage(completed.map((t) => t.value))
                }

                dates.push({
                    "date": getDayOfWeek(currentDate.getDay()),
                    "Completed": completedCount,
                    "Tickets Created": ticketCount
                })

            }
            setData(dates)
        }
    }, [weekStats])

    function getDayOfWeek(dayNumber) {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      
        // Validate the dayNumber
        if (dayNumber < 0 || dayNumber > 6) {
          return 'Invalid day number';
        }
      
        return daysOfWeek[dayNumber];
    }

    function fillArray(array, start: Date, end: Date) {
        var arr = [];
        var currentDate = start;
        while(currentDate < end) {
            var filteredArr = array.filter((t) => new Date(t.key).getDate() == currentDate.getDate() && new Date(t.key).getDay() == currentDate.getDay() && new Date(t.key).getMonth() == currentDate.getMonth())
            if(filteredArr.length > 0) { 
                arr.push(filteredArr[0])
            } else {
                arr.push({key: currentDate.toISOString(), value: 0})
            }

            currentDate.setDate(currentDate.getDate() + 1 );
        }
        console.log(arr)

        return arr;
    }

    function calculateAverage(numbers) {
        if (numbers.length === 0) {
          return 0; // Return 0 if the array is empty to avoid division by zero
        }
      
        const sum = numbers.reduce((accumulator, currentValue) => accumulator + currentValue);
        const average = sum / numbers.length;
        return average;
    }

    return (
      <ResponsiveBar 
          data={data}   
          keys={["Completed","Tickets Created"]}
          indexBy={"date"}
          groupMode='grouped'
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          colors={{ scheme: 'nivo' }}
          legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }} 
      />
    )
}

export default WeekStats