import { Backdrop, CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import { LazyNavigation } from 'state/api'

const QrLogin = () => {
    const [loading, setLoading] = useState(true)

    React.useEffect(() => {

        const params = new URLSearchParams(window.location.search)

        if(!params.has("code")) {
            LazyNavigation("/login")
        }

        fetch(process.env.REACT_APP_BASE_URL + '/auth/code/login?code=' + params.get("code"), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        }).then(async (res) => {
            try {

                if(res.status == 200) {
                    var response = await res.json()

                    LazyNavigation(response.route.substring(1) || "/org/dashboard")
                } else {
                    LazyNavigation("/login")
    
                }
            }catch(e) {
                LazyNavigation("/org/dashboard")
            }
        }).catch(e => {
            LazyNavigation("/login")
        }) 
    
    }, [])



    return (
    <>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
  )
}

export default QrLogin