import PieChartCheckin from 'components/PieChartCheckin';
import React, { useEffect, useState } from 'react'
import { useGetWeekStatsQuery } from 'state/api';

interface Dates {
    start?: Date,
    end?: Date,
}
const UserCreatedTicketsPie:React.FC<Dates> = ({start, end}) => {
    const {data, isLoading} = useGetWeekStatsQuery({start: start, end:end});
    const [input, setInput] = useState([]);

    useEffect(() => {
        if(!isLoading) {
            var completedByUser = data.createdByUser;
            if(completedByUser == null) return;
            var i = completedByUser.map((d) => {
                return {
                    id: d.key,
                    label: d.key,
                    value: d.value,
                    
                }
            })
            setInput(i);
        }   
    }, [data])
    return (
      <PieChartCheckin input={input} isDashboard/>
    )
}

export default UserCreatedTicketsPie