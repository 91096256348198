import {FileDownload, Group, History, QrCodeScanner, ScreenSearchDesktop} from '@mui/icons-material';
import {
    Box,
    Checkbox,
    Divider,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import Header from 'components/Header'
import React, {useEffect, useRef, useState} from 'react'
import {HasMinimumScopeWithArray} from 'state/api';
import {GridColDef} from '@mui/x-data-grid';
import Loading from 'components/Loading';
import {useSelector} from 'react-redux';
import ServerSideTable from 'components/data/ServerSideTable';
import DeviceAutocomplete from 'components/Autocomplete/DeviceAutocomplete';
import Grid2 from '@mui/material/Unstable_Grid2';
import CheckDeviceIn from 'scenes/data/device/CheckDeviceIn';
import HandheldQRScanner from "../../components/HandheldQRScanner";
import YearSelector from "../../components/YearSelector";
import StudentGroupCheckin from "./StudentGroupCheckin";
import ExportCheckinData from "./ExportCheckinData";


/**
 * Appends queries to a url
 * @param url
 * @param queries
 */
export const appendQuery = (url: string, queries) => {
    let starter = url.includes("?")
    let query = "";
    if (!starter) {
        query += "?"
    }
    Object.keys(queries).forEach((key, index) => {
        //Check to see if the query already exists in the url
        if (url.includes(key)) {
            //Remove the old query by replacing it with the new one
            url = url.replace(new RegExp(`${key}=[^&]*`, "g"), `${key}=${queries[key]}`)
        } else {
            // If it is not the first query, add an &
            if (index !== 0)
                query += "&"
            query += `${key}=${queries[key]}`
        }
    });
    return url + query;
}


export const getCurrentYear = () => {
    let now = new Date();

    if (now.getMonth() >= 8) {
        return `${now.getFullYear()}-${now.getFullYear() + 1}`;
    } else {
        return `${now.getFullYear() - 1}-${now.getFullYear()}`;
    }
}

const ScanDevicesInComponent = ({}) => {

    const getQueriesFromUrl = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const queries = Object.fromEntries(urlParams.entries());
        return queries;
    }
    const [defaultDevice, setDefaultDevice] = useState("")
    const [device, setDevice] = useState(null);
    const [year, setYear] = useState(getCurrentYear())

    useEffect(() => {
        var queries = getQueriesFromUrl();

        if (queries?.device) {
            setDevice(queries?.device)
        }

        if (queries?.year) {
            setYear(queries.year)
        }
    }, [window.location.search])


    return (
        <Box>
            <Typography variant="h3">Search for Devices to check them in</Typography>
            <Divider sx={{m: '1rem 0'}}/>

            <DeviceAutocomplete onValueChange={(v) => {
                setDevice(v)
            }} label={'Scan Device'} value={device?.label ?? defaultDevice ?? ""}/>

            {device != null && (
                <CheckDeviceIn displayAdditionalInfo device={device} year={year}/>
            )}

        </Box>
    )
}


interface LazyUpdateCheckbox {
    defaultValue: boolean;
    type: "device" | "case" | "charger";
    id: string;
}

const LazyUpdateCheckbox: React.FC<LazyUpdateCheckbox> = ({defaultValue, type, id}) => {
    const initialChecked = useRef(defaultValue);
    const [checked, setChecked] = useState(initialChecked.current)
    const updateDevice = async (value) => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + `/org/checkin/device/update?deviceId=${id}&type=${type}&value=${value}`, {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        if (response.status == 200) {

            setChecked(value);
        }

    }
    return (
        <Checkbox
            checked={checked}
            onChange={(e, c) => updateDevice(c)}
            color='secondary'
            disabled //Temp until fix
        />
    )
}

const TotalViewComponent = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false)

    const updateDevice = async (deviceId, type, value) => {
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_BASE_URL + `/org/checkin/device/update?deviceId=${deviceId}&type=${type}&value=${value}`, {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
        })

        if (response.status == 200) {
            setLoading(false);

        }
    }


    const columns: GridColDef[] = [
        {
            field: 'device',
            headerName: 'Serial Number',
            flex: 2
        }, {
            field: "studentName",
            headerName: "Student",
            flex: 1.4
        },
        {
            field: 'returnedDevice',
            headerName: 'Returned Device',
            flex: .5,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value}
                    onChange={(e, c) => {
                    }}
                    color='secondary'
                    disabled //Temp until fix
                />
            ),
        },

        {
            field: 'returnedCase',
            headerName: 'Returned Case',
            type: 'string',
            flex: .5,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value}
                    onChange={(e, c) => {
                    }}
                    color='secondary'
                    disabled //Temp until fix
                />
            ),
        },
        {
            field: 'returnedCharger',
            headerName: 'Returned Charger',
            type: 'string',
            flex: .5,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value}
                    onChange={(e, c) => {
                    }}
                    color='secondary'
                    disabled //Temp until fix
                />
            ),
        },
        {
            field: 'returner',
            headerName: 'Returned By',
            type: 'string',
            flex: 1,
        },
    ];

    const createQuery = (searchField: string, searchValue: string, sort: string, sortDirection: string, displayCount: number, page: number) => {
        var query = ""
        if (searchField != "") {

            //Depending on the field the operator might be different
            if (searchField == "device" || searchField == "returner") {
                query += "query=" + encodeURI(searchField + ":" + searchValue);
            } else {
                query += "query=" + encodeURI(searchField + "=" + searchValue);
            }
        } else {
            query = "query=all"
        }

        query += `&sort=${sort == "" ? "device" : sort}&sortDirection=${sortDirection}&page=${page}&limit=${displayCount}&useCurrentYear=true&includeNonExistentDevices=true`

        return query;
    }

    return (
        <Box>
            <ServerSideTable
                dataUrl={process.env.REACT_APP_BASE_URL + `/org/checkin/query`}
                columns={columns}
                createQuery={createQuery}
                isLoading={loading}
            />
        </Box>
    )
}

const QuickScanDevices = () => {
    const [msg, setMsg] = useState("");
    const [device, setDevice] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    return (
        <Box>
            <Typography variant={"h3"}>Checkin Devices using a QR/Barcode Scanner</Typography>
            <Typography variant={"body1"}>Scan your device using a QR Scanner</Typography>
            <Divider sx={{mb: '1rem'}}/>

            <HandheldQRScanner onLoading={() => {
                setLoading(true);
                setMsg("Loading...");
                setDevice(null);
            }} onValidInput={(device) => {
                setLoading(false);
                setDevice(device);
                setMsg("")
            }} onInvalidInput={(msg) => {
                setLoading(false);
                setMsg(msg);
                setDevice(null);
            }} disabled={disabled}/>

            {loading && (
                <Loading backdrop/>
            )}

            {msg != "" && (
                <Typography>{msg}</Typography>
            )}
            {device != null && (
                <CheckDeviceIn focusedStatusUpdated={(d) => setDisabled(d)} displayAdditionalInfo device={device.device} year={getCurrentYear()}/>
            )}

        </Box>
    )
}


const options = [
    {
        title: "Group View",
        minimumScope: "read:student:groups:members",
        icon: <Group/>,
        view: <StudentGroupCheckin/>
    },
    {
        title: "Search Devices",
        minimumScope: "read:device",
        icon: <ScreenSearchDesktop/>,
        view: <ScanDevicesInComponent/>
    },
    {
        title: "Quick Scan Devices",
        minimumScope: "read:device:checkin",
        icon: <QrCodeScanner/>,
        view: <QuickScanDevices/>
    },
    {
        title: "Export Data",
        minimumScope: "read:device:checkin",
        icon: <FileDownload/>,
        view: <ExportCheckinData/>
    }
    // {
    //     title: "View History",
    //     minimumScope: "read:device:checkin",
    //     icon: <History/>,
    //     view: <TotalViewComponent/>
    // }
]

interface CheckedInDevicesOverview {

}

const CheckedInDevicesOverview: React.FC<CheckedInDevicesOverview> = () => {
    const [view, setView] = useState(0);
    const [viewOptions, setViewOptions] = useState([]);

    //@ts-ignore
    const state = useSelector(g => g.global);

    const getQueriesFromUrl = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const queries = Object.fromEntries(urlParams.entries());
        return queries;
    }

    useEffect(() => {
        if (state.scopes != null) {
            var v = []
            options.forEach(opt => {
                if (HasMinimumScopeWithArray(state.scopes, opt.minimumScope)) {
                    v.push(opt)
                }
            });

            setViewOptions([...v])
        }
    }, [state])

    useEffect(() => {
        var queries = getQueriesFromUrl();

        if (queries?.type) {

            //Make sure it is a valid type
            if (Number.isInteger(Number(queries.type))) {
                setView(Number(queries.type));
            }
        }
    }, [viewOptions])


    return (
        <Box>
            <ToggleButtonGroup onChange={(e, v) => {
                if (v == null) return; // Dont allow unselecting

                setView(v);


                // Append type to url without reloading page
                window.history.pushState({}, "", appendQuery("", {type: v.toString()}));

            }} value={view} exclusive sx={{m: '1rem 0'}}>
                {viewOptions.map((o, i) => (
                    <ToggleButton value={i} title={o.title}>
                        {o.icon}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>

            {view + 1 <= viewOptions.length && (
                <>
                    {viewOptions[view].view}
                </>
            )}
        </Box>
    )
}


function DeviceCheckinOverview() {
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const theme = useTheme();
    const [year, setYear] = useState(getCurrentYear())

    // Check the year in the url using useEffect
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const queries = Object.fromEntries(urlParams.entries());
        if (queries?.year) {
            setYear(queries.year)
        }
    }, [window.location.search])

    return (
        <Box m="1.5rem 2.5rem">
            <Grid2 container>
                <Grid2 xs={12} md={6}>
                    <Header title={"Device Check In"} subtitle={"Check in devices and keep track of them"}></Header>
                </Grid2>
                <Grid2 xs={12} md={6}>
                    {/** Year Selector */}
                    <YearSelector onChange={(e) => {
                        setYear(e);
                        // Append year to url
                        window.history.pushState({}, "", appendQuery(window.location.search, {year: e}))
                    }
                    } year={year}/>
                </Grid2>
            </Grid2>

            <Divider/>
            <CheckedInDevicesOverview/>

        </Box>
    )
}

export default DeviceCheckinOverview