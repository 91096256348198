import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useGetOrgUnitsPathsQuery } from 'state/api';

interface OUPathAutocomplete {
  label: string;
  onValueChange: (v) => void;
  freeSolo?: boolean;
  initialValue?: string;
  fullWidth?: boolean;
}
const OUPathAutocomplete:React.FC<OUPathAutocomplete> = ({label, onValueChange, initialValue, freeSolo, fullWidth}) => {
  const {data, isLoading, error} = useGetOrgUnitsPathsQuery("")
  const [open, setOpen] = useState(false);
  const [paths, setPaths] = useState([]);
  const [search, setSearch] = useState("")
  useEffect(() => {
    if(!isLoading) {
      if(data != null) {
        setPaths([...data])
      } else {
        setPaths([])
      }
    }
  }, [data])
  
  
  return (
    <Autocomplete 
    sx={{width:"100%"}}
    id="async-autocomplete"
    freeSolo={freeSolo}
    open={open}
    onOpen={() => setOpen(true)}
    onClose={() => setOpen(false)}
    fullWidth={fullWidth}
    value={initialValue}
    getOptionLabel={(opt: any) => {
      return opt;
    }}
    onChange={(e,v) => {
      onValueChange(v)
    }}
    options={paths}
    loading={isLoading}
    renderInput={(params) => (
        <TextField
        {...params}
        label={label}
        fullWidth={fullWidth}
        variant="standard"
        color="secondary"
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    )}
    
  
  />

  )
}

export default OUPathAutocomplete