import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useGetLocationQuery, useGetLocationsDevicesQuery, useGetLocationTransactionsQuery} from "../../../state/api";
import {Box, Button, ButtonGroup, Divider, Skeleton, Stack, Typography, useTheme} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {DataGrid, GridColDef, GridToolbar} from "@mui/x-data-grid";
import HoverPaper from "../../../components/HoverPaper";
import CustomNoRowsOverlay from "../../../components/CustomNoRowsOverlay";
import LocationSankeyChart from "../../../components/Charts/LocationSankeyChart";
import MoveDeviceDialog from "../devices/MoveDeviceDialog";
import {BatchCheckinDevicesDialogWrapper} from "./BatchCheckinDevicesDialog";
import SnackbarHandler from "../../../components/SnackbarHandler";
import DeviceCheckoutDialogWrapper from "../devices/DeviceCheckoutDialogWrapper";
import {BatchMoveDevicesDialogWrapper} from "./BatchMoveDevicesDialog";

const InventoryDevices = () => {
    const [devices, setDevices] = React.useState(null)
    const {id} = useParams();
    const [device, setDevice] = React.useState(null)
    const [openMoveDialog, setOpenMoveDialog] = React.useState(false);
    const {data, isLoading, isError, refetch} = useGetLocationsDevicesQuery(id);
    const [snackbarData, setSnackbarData] = React.useState({open: false, message: "", severity: "success"})
    const navigate = useNavigate()
    const theme = useTheme();
    useEffect(() => {
        setDevices(null);
        refetch();
    }, [id])

    useEffect(() => {
        if (data) {
            setDevices([...data]);
        }
    }, [data])

    const colDefs: GridColDef[] = [
        {
            field: 'sku',
            headerName: 'Device',
            flex: 1,
            editable: false,
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: .5,
            editable: false,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: params => {
                return (
                    <>
                        <ButtonGroup>
                            <Button variant={"outlined"} color={"secondary"}
                                    onClick={() => navigate('/org/inventory/devices/' + params.row.sku)}
                            >View</Button>
                            <DeviceCheckoutDialogWrapper onChange={() => {
                            }} device={params.row.sku}/>
                            <Button variant={"outlined"} color={"warning"} onClick={() => {
                                setDevice(params.row);
                                setOpenMoveDialog(true);
                            }}>Move</Button>
                            <Button variant={"outlined"} color={"error"}
                                    onClick={() => DeleteDevice(params.row.sku)}>Delete</Button>
                        </ButtonGroup>
                    </>
                )
            }

        }
    ]

    const DeleteDevice = async (sku) => {
        var response = await fetch(process.env.REACT_APP_BASE_URL + `/org/inventory/devices/${sku}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        });

        // If the request was successful, add the device to the list
        if (response.status === 200) {
            //Display success
            setSnackbarData({open: true, message: sku + " removed successfully", severity: "success"})
            refetch();
        } else {
            // Display error
            setSnackbarData({open: true, message: "Error removing " + sku, severity: "error"})
        }

    }

    if (isLoading || devices == null) {
        return (
            <Box>
                <Skeleton variant={"rectangular"} height={"100%"} width={"100%"}/>
            </Box>
        )
    }

    return (
        <HoverPaper sx={{height: '100%', minHeight: "400px"}} elevation={4}>
            <Typography variant={"h4"}>Devices in this Location</Typography>
            <MoveDeviceDialog open={openMoveDialog} setOpen={setOpenMoveDialog} device={device}/>
            <SnackbarHandler open={snackbarData.open} onClose={() =>
                setSnackbarData({...snackbarData, open: false})
            } message={snackbarData.message} severity={snackbarData.severity}/>

            <Divider/>
            {devices ? (
                <DataGrid
                    sx={{
                        height: '90%', mt: '1rem',
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${theme.palette.secondary[200]} !important`,
                        },
                    }}
                    columns={colDefs}
                    rows={devices ?? []}
                    getRowId={(row) => row.id}
                    slots={{noRowsOverlay: CustomNoRowsOverlay, toolbar: GridToolbar}}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                />
            ) : (
                <Skeleton variant={"rectangular"} height={"90%"}/>
            )}

        </HoverPaper>
    )
}

const InventoryTransactions = () => {
    const theme = useTheme();
    const {id} = useParams();
    const [transactions, setTransactions] = React.useState(null);
    const {data, isLoading, isError, refetch} = useGetLocationTransactionsQuery(id);

    useEffect(() => {
        if (data) {
            setTransactions([...data]);
        }
    }, [data]);

    useEffect(() => {
        setTransactions(null);
        refetch();
    }, [id]);


    const colDefs: GridColDef[] = [
        {
            field: 'sku',
            headerName: 'Device',
            flex: 1,
            editable: false,
        },
        {
            field: 'sourceName',
            headerName: 'Source',
            flex: 1,
            editable: false,
        },
        {
            field: 'destinationName',
            headerName: 'Destination',
            flex: 1,
            editable: false,
        },
        {
            field: 'author',
            headerName: 'Author',
            flex: 1,
            editable: false,
        },
        {
            field: 'time',
            headerName: 'Time',
            flex: 1,
            editable: false,
            type: "dateTime",
            valueGetter: (params) => {
                const timestamp = params.row.time;

                if (isNaN(timestamp) || timestamp === null || timestamp === undefined) {
                    return new Date();
                }

                const date = new Date(timestamp);
                return date;
            }
        },
    ]

    if (isLoading || transactions == null) {
        return (
            <Box>
                <Skeleton variant={"rectangular"} height={"100%"} width={"100%"}/>
            </Box>
        )
    }


    return (
        <HoverPaper sx={{height: '100%', minHeight: "400px"}} elevation={4}>
            <Typography variant={"h4"}>Inventory Transactions</Typography>
            <Divider/>
            {transactions ? (
                <DataGrid
                    sx={{
                        height: '90%', mt: '1rem',
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${theme.palette.secondary[200]} !important`,
                        },
                    }}
                    columns={colDefs}
                    rows={transactions ?? []}
                    getRowId={(row) => row.id}

                    //Default sort by time
                    sortModel={[
                        {
                            field: 'time',
                            sort: 'desc',
                        },
                    ]}

                    slots={{noRowsOverlay: CustomNoRowsOverlay, toolbar: GridToolbar}}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                />
            ) : (
                <Skeleton variant={"rectangular"} height={"90%"}/>
            )}

        </HoverPaper>
    )

}


const InventoryLocation = () => {
    const {id} = useParams();
    const [location, setLocation] = React.useState(null)
    const {data, isLoading, isError, refetch} = useGetLocationQuery(id);

    useEffect(() => {
        setLocation(null);
        refetch();
    }, [id])

    useEffect(() => {
        if (data) {
            setLocation(data);
        }
    }, [data])

    if (isLoading || location == null) {
        return (
            <Box>
                <Skeleton variant={"rectangular"} height={"100%"} width={"100%"}/>
            </Box>
        )
    }

    return (
        <Box padding={"1rem"}>
            <Typography variant={"h3"}>
                {location.name}
            </Typography>

            <Typography variant={"h6"}>
                {location.description}
            </Typography>
            <Divider/>
            <Grid2 spacing={1} container sx={{mt: '1rem'}}>
                <Grid2 xs={12} md={8}>
                    {/* Devices */}
                    <InventoryDevices/>
                </Grid2>

                <Grid2 xs={12} md={4}>
                    <HoverPaper sx={{height: '100%'}} elevation={4}>
                        <Typography variant={"h4"}>Actions</Typography>
                        <Divider/>
                        <Stack sx={{mt: '1rem'}} spacing={1}>
                            <BatchCheckinDevicesDialogWrapper onClose={refetch}/>
                            <BatchMoveDevicesDialogWrapper onClose={refetch}/>
                        </Stack>
                    </HoverPaper>
                </Grid2>


                <Grid2 xs={12} md={12}>
                    {/* Transactions */}
                    <InventoryTransactions/>
                </Grid2>

                <Grid2 xs={12} md={12}>
                    <LocationSankeyChart location={location}/>
                </Grid2>
            </Grid2>


        </Box>
    );
};

export default InventoryLocation;