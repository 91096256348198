import { PersonOff } from '@mui/icons-material';
import { Avatar, Button, Card, CardActions, CardContent, Divider, Skeleton, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import DataField from 'components/data/DataField';
import React from 'react'
import { LazyNavigation, useGetDataProviderUserQuery, useGetDeviceQuery } from 'state/api';
import HoverPaper from "../../../components/HoverPaper";

const StudentUserAccountInfoCard = ({id}) => {
    const {data, isLoading, error} = useGetDataProviderUserQuery(id);

    if(isLoading) {
        return (
            <HoverPaper elevation={4}>
                <Skeleton height="20vh" />
                <Skeleton height="5vh" />
                <Skeleton height="5vh" />
            </HoverPaper>
        )
    }

    if(data == null) {
        return (
            <HoverPaper elevation={4}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Account Information</Typography>
                <Divider sx={{mb: '1rem'}} />
                <PersonOff fontSize="large" />
            </HoverPaper>
        )
    }
    return (
        <HoverPaper elevation={4} sx={{height: '100%'}}>
            <Grid2 container padding={0}>
                <Grid2 xs={2}>
                    <Avatar alt={data.name} src={data.thumbnailPhotoUrl}/>
                </Grid2>
                <Grid2 xs={10}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Account Information</Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>{data.primaryEmail}</Typography>
                </Grid2>
            </Grid2>
            <Divider sx={{mb: '1rem'}}/>

            <DataField title="Primary Email" data={data.primaryEmail} compact width={"100%"}/>
            <DataField title="Name" data={data.name} compact width={"100%"}/>
            <Grid2 container spacing={1} padding={0}>
                <Grid2 xs={6}>
                    <DataField title="Given Name" data={data.givenName} compact width={"100%"}/>
                </Grid2>
                <Grid2 xs={6}>
                    <DataField title="Family Name" data={data.familyName} compact width={"100%"}/>
                </Grid2>
            </Grid2>
            <DataField title="Org Unit" data={data.orgUnitPath} compact width={"100%"} />

            <Button onClick={() => LazyNavigation("/org/users/" + data.id)} color="secondary" variant="outlined">View More</Button>

        </HoverPaper>
    )
}

export default StudentUserAccountInfoCard