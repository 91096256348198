import React from 'react';
import {styled} from "@mui/system";
import {Paper} from "@mui/material";

const HoverPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.neutral[900],
    transition: 'box-shadow 0.2s, transform 0.4s ease-out', // Add transform to the transition
    '&:hover': {
        boxShadow: theme.shadows[24],
        transform: 'translateY(-3px)',
    },
}));


export default HoverPaper;