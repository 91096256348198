import ForceLightMode from 'components/ForceLightMode'
import React, { useEffect } from 'react'

const PrivacyPolicy = () => {
    return (
        <>
            <ForceLightMode/>
            <iframe
            src="https://app.termly.io/document/privacy-policy/f58b065a-d673-4da1-a616-b9dc11204862" // Replace with your URL
            title="Terms and Conditions"
            width="100%"
            height="100%" // Adjust the height as needed
            frameBorder="0"
            />
        </>
    
    )
}

export default PrivacyPolicy