import {Send} from '@mui/icons-material';
import {
    Divider,
    Grow,
    IconButton,
    Paper,
    Skeleton,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material'
import {GridColDef} from '@mui/x-data-grid';
import Loading from 'components/Loading';
import DataList from 'components/data/DataList';
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {CompareScopes, useGetMessagesFromTicketQuery} from 'state/api';

function TicketMessages() {
    const theme = useTheme();
    const [messageCount, setMessageCount] = useState([])
    const [message, setMessage] = useState("")
    const isSmallScreen = useMediaQuery('(max-width: 600px)');

    //@ts-ignore
    const scopes = useSelector(s => s.global.scopes);
    const [canEdit, setCanEdit] = useState(false);

    const {id} = useParams();
    const [loading, setIsLoading] = useState(false);
    const {data: messages, isLoading, error, refetch} = useGetMessagesFromTicketQuery(id);
    const [data, setData] = useState(messages || [])

    useEffect(() => {
        if (!isLoading) {
            if (messages != null) {
                for (var i = 0; i < messages.length; i++) {
                    messageCount.push(<Skeleton height="50px"/>)
                }
                setMessageCount([...messageCount])
            }
            setData(messages || []);
        }
    }, [messages])

    useEffect(() => {
        for (var i = 0; i < scopes.length; i++) {
            if (CompareScopes(scopes[i], "write:ticket:messages")) {
                setCanEdit(true);
                return
            }
        }
        setCanEdit(false)
    }, [scopes])

    if (isLoading || data == null) return <Loading/>

    const columns: GridColDef[] = [
        {field: 'user', headerName: 'Author', flex: 1},
        {field: 'message', headerName: 'Message', flex: 1},
        {field: 'date', headerName: 'Date', flex: 1, type: 'date'},
    ];

    const handleFormSubmit = async () => {
        setIsLoading(true);
        fetch(process.env.REACT_APP_BASE_URL + `/org/tickets/${id}/messages?message=${message}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include"
        })
            .then(async function (response) {
                // Refresh the messages data by refetching from the API
                await refetch();
                setMessage("");
                setIsLoading(false);
            })
            .catch((reason) => {
                console.log(reason);
            })
    }

    return (
        <Grow in={true}>
            <Paper elevation={16} sx={{
                m: '1rem auto',

                padding: '1rem',
                width: isSmallScreen ? "100%" : '80%',
                paddingBottom: isSmallScreen ? '100%' : "auto"
            }}>
                <Typography variant="h3">Messages</Typography>
                <Typography variant='subtitle2'>Ticket Messages</Typography>
                <Divider sx={{mt: '1rem'}}/>
                <Stack width={"100%"} divider={<Divider orientation='horizontal' flexItem/>}>
                    {loading ? (
                        <>
                            {messageCount}
                        </>
                    ) : (<DataList GridColDef={columns} data={data}/>)}
                    <Divider/>
                    {canEdit && (

                        <Stack sx={{mt: '1rem'}} direction={"row"}>
                            <TextField
                                label="Message"
                                multiline
                                color='secondary'
                                fullWidth
                                value={message}
                                onChange={(v) => setMessage(v.target.value)}
                                sx={{maxWidth: '50%'}}
                            />
                            <IconButton disabled={message.length < 5} onClick={handleFormSubmit} color="success">
                                <Send/>
                            </IconButton>

                        </Stack>
                    )}
                </Stack>
            </Paper>
        </Grow>

    )
}

export default TicketMessages