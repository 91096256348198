import React from 'react';
import {useParams} from "react-router-dom";
import {
    Button,
    Dialog,
    DialogContent,
    Divider,
    IconButton,
    MenuItem,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {Delete, QrCodeScanner, SearchRounded} from "@mui/icons-material";
import DeviceAutocomplete from "../../../components/Autocomplete/DeviceAutocomplete";
import HandheldQRScanner from "../../../components/HandheldQRScanner";
import {DataGrid} from "@mui/x-data-grid";
import SnackbarHandler from "../../../components/SnackbarHandler";
import CustomNoRowsOverlay from "../../../components/CustomNoRowsOverlay";


interface CheckinProps {
    onAdded: (device) => void;
}

const CheckinSearch: React.FC<CheckinProps> = ({onAdded}) => {
    const handleAdd = (device) => {
        console.log(device)
        if (device.isFreeSolo) {
            onAdded(device.inputValue)
        } else {
            onAdded(device.serialNumber)
        }
    }
    return (
        <>
            <Typography variant={"h5"}>Search for your Device</Typography>
            <Typography variant={"subtitle1"}>
                Note: Device does not have to exist to add it. Press enter to add the device.
            </Typography>

            <DeviceAutocomplete onValueChange={(d) => handleAdd(d)}
                                label={"Add Device"}
                                value={""}
                                freeSolo/>
        </>
    )
}

const CheckinScan: React.FC<CheckinProps> = ({onAdded}) => {
    return (
        <>
            <Typography variant={"h5"}>Scan</Typography>
            <Typography variant={"subtitle1"}>
                Scan the barcode on the device to add it.
                Make sure it is the serial number
            </Typography>

            <HandheldQRScanner onValidInput={(d) => onAdded(d?.serialNumber ?? d)} onLoading={() => {
            }} onInvalidInput={() => {
            }} freeSolo displayInfo/>
        </>
    )

}


const checkinModes = [
    {
        name: "Search",
        description: "Search for devices by SKU",
        icon: <SearchRounded/>,
    },
    {
        name: "Scan",
        description: "Scan devices using a barcode scanner",
        icon: <QrCodeScanner/>,
    }
]


interface BatchCheckinDevicesDialogProps {
    open: boolean;
    setOpen: (boolean) => void;
    onClose: () => void;
}

const BatchCheckinDevicesDialog: React.FC<BatchCheckinDevicesDialogProps> = ({open, setOpen, onClose}) => {
    const {id} = useParams();
    const [snackbarData, setSnackbarData] = React.useState({open: false, message: "", severity: "success"})
    const [addedDevices, setAddedDevices] = React.useState([]);
    const [mode, setMode] = React.useState(checkinModes[0])
    const [type, setType] = React.useState("Device")
    // Table columns
    const colDefs = [
        {
            field: 'sku',
            headerName: 'SKU',
            flex: 1,
            editable: false,
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 1,
            editable: false,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: params => {
                return (
                    <>
                        <IconButton onClick={() => DeleteDevice(params.row.sku)}>
                            <Delete/>
                        </IconButton>
                    </>
                )
            }
        }
    ]

    const DeleteDevice = async (sku) => {
        var response = await fetch(process.env.REACT_APP_BASE_URL + `/org/inventory/devices/${sku}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        });

        // If the request was successful, add the device to the list
        if (response.status === 200) {
            // Remove the device from the list
            setAddedDevices(addedDevices.filter((d) => d.sku !== sku))
            //Display success
            setSnackbarData({open: true, message: "Device removed successfully", severity: "success"})
        } else {
            // Display error
            setSnackbarData({open: true, message: "Error removing " + sku, severity: "error"})
        }

    }

    const onDeviceAdded = async (device) => {
        // Make sure the device is not already in the list
        if (addedDevices.includes(device)) {
            return;
        }

        // CHeck if device is a string

        // Make request to add device to the list
        var response = await fetch(process.env.REACT_APP_BASE_URL + `/org/inventory/locations/${id}/devices/checkin?sku=${device}&type=${type}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        });

        // If the request was successful, add the device to the list
        if (response.status === 200) {
            //Display success
            setSnackbarData({open: true, message: device + " added successfully", severity: "success"})
            setAddedDevices([...addedDevices, {sku: device, type: type}])
        } else {
            // Display error
            setSnackbarData({open: true, message: "Error adding " + device, severity: "error"})
        }

    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false)
                onClose()
            }}
            fullWidth
            maxWidth={"md"}
        >
            <DialogContent>
                <SnackbarHandler open={snackbarData.open} onClose={() =>
                    setSnackbarData({...snackbarData, open: false})
                } message={snackbarData.message} severity={snackbarData.severity}/>

                <Typography variant={"h4"}>Batch Checkin</Typography>
                <Typography variant={"subtitle1"}>
                    Note: Devices do not have to exist in the inventory to be checked in.
                    If a device does not exist in the inventory, it will be created.
                </Typography>
                <Divider/>
                <TextField
                    label={"Type"}
                    helperText={"The type of the device to checkin (if the device is new)"}
                    select
                    color={"secondary"}
                    variant={"outlined"}
                    fullWidth
                    sx={{m: '1rem 0 .5rem 0'}}
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                >
                    <MenuItem value={"Device"}>Device</MenuItem>
                    <MenuItem value={"Barrel Charger"}>Barrel Charger</MenuItem>
                    <MenuItem value={"Type-C Charger"}>Type-C Charger</MenuItem>
                    <MenuItem value={"Accessory"}>Accessory</MenuItem>
                </TextField>

                <ToggleButtonGroup
                    value={mode}
                    exclusive
                    onChange={(e, value) => setMode(value)}
                    sx={{m: '1rem 0'}}
                >
                    {checkinModes.map((mode) => (
                        <ToggleButton value={mode}>
                            {mode.icon}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>

                {mode?.name === "Search" && (
                    <CheckinSearch onAdded={onDeviceAdded}/>
                )}

                {mode?.name === "Scan" && (
                    <CheckinScan onAdded={onDeviceAdded}/>
                )}


                <Divider sx={{mt: '1rem'}}/>

                <Typography>
                    Added Devices
                </Typography>

                <DataGrid
                    sx={{minHeight: '300px', mt: '1rem'}}
                    getRowId={(row) => row.sku}
                    columns={colDefs}
                    rows={addedDevices}
                    slots={{noRowsOverlay: CustomNoRowsOverlay}}
                />

            </DialogContent>
        </Dialog>
    );
};

interface BatchCheckinDevicesDialogWrapperProps {
    onClose: () => void;
}

export const BatchCheckinDevicesDialogWrapper: React.FC<BatchCheckinDevicesDialogWrapperProps> = ({onClose}) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Button variant={"outlined"} color={"success"} onClick={() => setOpen(true)}>Batch Checkin</Button>
            {open && (
                <BatchCheckinDevicesDialog onClose={onClose} open={open} setOpen={setOpen}/>
            )}
        </>
    )
}

export default BatchCheckinDevicesDialog;