//@ts-nocheck
import React, { useState } from 'react'
import {TokenResponse, useGoogleLogin} from "@react-oauth/google"
import {
    Box, 
    Button,
    TextField,
    useMediaQuery,
    Typography,
    useTheme,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Backdrop,
    CircularProgress,
    IconButton,
    Snackbar,
    Alert,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Switch,
    FormControlLabel,
    Checkbox,
    Stack
} from "@mui/material";

import {Google,WindowRounded, Close , ExpandMore} from '@mui/icons-material';
import {Formik} from "formik";
import * as yup from "yup";
import {setDomain} from "state"
import {useNavigate} from "react-router-dom";
import { useDispatch } from 'react-redux';
import StripeCheckout from 'components/stripe/StripeCheckout';
import { useLocation } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { LazyNavigation } from 'state/api';


const registerSchema = yup.object().shape({
    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    companyName: yup.string().required("required"),
    domain: yup.string().required("required").matches(".us|.com|.net|/gim", "must be a valid domain."),



    //Optional
    mongoDBServer: yup.string(),
    isMockData: yup.boolean(),

    privacyPolicy: yup.boolean().required(),
    termsOfService: yup.boolean().required()
})

const initialValues = {
    firstName: "",
    lastName: "",
    companyName: "",
    domain: "",
    mongoDBServer: "mongodb+srv://*****:*****@testcluster.78axial.mongodb.net",
    isMockData: true,
    privacyPolicy: false,
    termsOfService: false
}

function Form() {

    const [pageType, setPageType] = useState("login")
    const [tokenValid, setTokenValid] = useState({valid: false, tokenResponse: {}});
    const [error, setError] = useState("");
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [allowedDomain, setAllowedDomain] = useState("gmail.com")
    const {palette} = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { from } = location.state || { from: '/org/dashboard' }; 
    console.log(from)
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const isLogin = pageType === "login"
    const isRegister = pageType === "register"

    //TODO: Get scopes from server
    const scopes = [
        "https://www.googleapis.com/auth/admin.directory.device.chromeos.readonly",
        "https://www.googleapis.com/auth/admin.directory.domain.readonly",
        "https://www.googleapis.com/auth/admin.directory.user.readonly",
        "https://www.googleapis.com/auth/admin.directory.orgunit.readonly"
    ]


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = (allowed) => {
      setOpen(false);

    };

    const handleBackdopOpen = () => {
        setBackdropOpen(true);
    }


    const handleBackdropClose = () => {
        setBackdropOpen(!backdropOpen);
    }



        //Snackbar
        const [snackbarOpen, setSnackbarOpen] = React.useState(false);

        const handleSnackbarClick = () => {
            setSnackbarOpen(true);
        };
      
        const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
          if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
        };
      
        
    const lazyNavigation = () => {
        const host = document.location.origin;

        document.location.href = host + from
    }   

    const lazyNavigationPayment = (route) => {
        const host = document.location.origin;
        document.location.href = host + "/login/" + route
    }   

    const tryForFree = async () => {
        LazyNavigation("/test-suite?to=" + from)
        return;
        try{
            setBackdropOpen(true);
            var res = await fetch(process.env.REACT_APP_BASE_URL + '/auth/sample', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                })
            
            if(res.status == 200) {
                setBackdropOpen(false);
                LazyNavigation("/org/dashboard")
            } else {
                setBackdropOpen(false);
                setError("Failed to make request. Please try again in a few minutes")
                setSnackbarOpen(true);
            }
        }catch(e) {
            setBackdropOpen(false);
            setError("Failed to make request. Please try again in a few minutes")
            setSnackbarOpen(true);
        }

    }

    const handleLogin = (codeResponse: TokenResponse) => {
        try{
            setBackdropOpen(true);
            fetch(process.env.REACT_APP_BASE_URL + '/auth/Login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({code: codeResponse.access_token, scope: codeResponse.scope, redirectUri: document.location.href})
                }).then(async (response) => {
                    try{
                        //TODO: Set domain and user perms
                        var json = await response.json();
                        setBackdropOpen(false);
                        if(response.status != 200) {
                            setError(json.error);
                            setSnackbarOpen(true);
                        } else {
                            lazyNavigation();
                            navigate('/org/dashboard')
                        }
                    }catch(e) {
                        console.log(e)
                    }
    
                    
    
                    }).catch((reason) => {
                        setBackdropOpen(false);
                        setError("Failed to make request. Please try again in a few minutes")
                        setSnackbarOpen(true);
                    })
        }catch(e) {
            setBackdropOpen(false);
            setError("Failed to make request. Please try again in a few minutes")
            setSnackbarOpen(true);
        }

    }

    


    const handleFormSubmit = async (values:any, onSubmitProps: any) => {
        setBackdropOpen(true);
        values.accessToken = tokenValid.tokenResponse.access_token;
        values.redirectURI = document.location.origin
        values.scope = tokenValid.tokenResponse.scope;

        fetch(process.env.REACT_APP_BASE_URL + '/org/create', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include"
            }).then(async function(response) {
                //TODO: Set domain and user perms
                    setBackdropOpen(false);
                    if(response.status != 200) {
                        setError("There was a problem creating the organization")
                        setSnackbarOpen(true);
                    } else {
                        //sessionStorage.setItem('user', "test");
                        window.setTimeout(() => {}, 200);
                        var json = await response.json()
                        lazyNavigationPayment("payment?session=" + json.sessionId);
                    }


                }).catch((reason) => {
                    setBackdropOpen(false);
                    setError("Failed to make request. Please try again in a few minutes")
                    setSnackbarOpen(true);
                })
    }


     const googleLoginRegister = useGoogleLogin({
        prompt: 'consent',
        hosted_domain: allowedDomain,
        onSuccess: (codeResponse) => setTokenValid({valid: true, tokenResponse: codeResponse}),
        onError: (error) => console.log('Login Failed:', error)
      });



    const login = useGoogleLogin({
        onSuccess: (codeResponse) => handleLogin(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });







  return (
    <Formik 
        onSubmit={handleFormSubmit}
        initialValues={isLogin ? initialValues : {}}
        validationSchema={isLogin ? null : registerSchema}
        isInitialValid={false}
    >
        {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
            isValid,
            
        }) => (
            <form onSubmit={handleSubmit}>
                <Box 
                    display="grid"
                    gap="30px"
                    gridTemplateColums="repeat(4, 1fr)"
                    sx={{
                        "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                        padding: "1rem"
                    }}
                    margin="1rem"
                >
                    {isRegister && (
                        <>
                            <TextField 
                                label="First Name"
                                color="secondary"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.firstName}
                                name="firstName"
                                error={Boolean(touched.firstName) && Boolean(errors.firstName)}
                                helperText={touched.firstName && errors.firstName}
                                sx={{gridColumn: "span 4"}}
                            />

                            <TextField 
                                label="Last Name"
                                color="secondary"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.lastName}
                                name="lastName"
                                error={Boolean(touched.lastName) && Boolean(errors.lastName)}
                                helperText={touched.lastName && errors.lastName}
                                sx={{gridColumn: "span 4"}}
                            />

                            <TextField 
                                label="Company Name"
                                color="secondary"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.companyName}
                                name="companyName"
                                error={Boolean(touched.companyName) && Boolean(errors.companyName)}
                                helperText={touched.companyName && errors.companyName}
                                sx={{gridColumn: "span 4"}}
                            />

                            <TextField 
                                label="Domain"
                                color="secondary"
                                onBlur={handleBlur}
                                onChange={(e) => {setAllowedDomain(e.target.value); handleChange(e)}}
                                value={values.domain}
                                name="domain"
                                error={Boolean(touched.domain) && Boolean(errors.domain)}
                                helperText={touched.domain && errors.domain}
                                sx={{gridColumn: "span 4"}}
                            />




                            <div style={{gridColumn: "span 4", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "center", alignContent: "center"}}>
                            <Divider sx={{margin: "1rem 0"}}/>
                            
                            <Stack>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="termsOfService"
                                            color="secondary"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            checked={values.termsOfService}
                                        />
                                    }
                                    label={<Typography variant="body2">I agree to the <a href="/terms-of-service" target="_blank">Terms of Service</a></Typography>}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="privacyPolicy"
                                            color="secondary"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            checked={values.privacyPolicy}
                                        />
                                    }
                                    label={<Typography variant="body2">I agree to the <a href="/privacy-policy" target="_blank">Privacy Policy</a></Typography>}
                                />
                                


                            </Stack>

                            <Divider sx={{margin: "1rem 0"}}/>


                            <Typography fontWeight="300" textAlign="center" variant="body1">
                            Register with either
                            </Typography>
                            <Button disabled={!isValid || (!values.privacyPolicy || !values.termsOfService)} sx={{ margin: "  1rem 0 !important", width: "100%", gridColumn: "span 4"}}variant="contained" onClick={() => googleLoginRegister()}>Google Admin<div style={{marginLeft: ".2rem"}}></div><Google/> </Button>


                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                {"Allow CTickets to have read-only access to Google Admin?"}
                                </DialogTitle>
                                <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Please note, for CTickets to be able to create the link between a Google User and a device to manage
                                    tickets, CTickets needs read-only access to Google Admin.
                                    <br/>
                                    We will only ask for scopes related to users and chromebooks. If you wish to read more about API access, you can 
                                    do so <a href="https://developers.google.com/identity/protocols/oauth2/scopes#admin-directory" style={{color: palette.primary.contrastText}}>here</a>
                                    <br/>
                                    <br/>
                                    Note: you can revoke CTickets access anytime you wish by following <a href="https://myaccount.google.com/u/0/permissions" style={{color: palette.primary.contrastText}}>this link</a> and removing CTickets
                                </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={handleClose} variant="contained">Disagree</Button>
                                <Button onClick={() => {handleClose(); googleLoginRegister()}} autoFocus variant="contained">
                                    Agree
                                </Button>
                                </DialogActions>
                            </Dialog>

                            <Typography fontWeight="300" textAlign="center" variant="body1">
                            Or
                            </Typography>
                            
                            <Button disabled sx={{margin: " 1rem 0 !important", width: "100%", gridColumn: "span 4"}}variant="contained" onClick={() => login()}>Microsoft Azure AD <div style={{marginLeft: ".2rem"}}></div><WindowRounded/> </Button>
                            <Typography variant="overline">Note: Microsoft Azure AD is not supported at this time</Typography>
                            <Divider/>
                            </div>


                            <Button disabled={tokenValid.valid == false} variant="contained" type="submit" sx={{gridColumn: "span 4"}}>Register</Button>

                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={backdropOpen}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>

                            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                                    Error registering: {error}
                                </Alert>
                            </Snackbar>
                        </>
                                   
                    )}

                {isLogin && (
                    <div>
                            <div style={{gridColumn: "span 4", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "center", alignContent: "center"}}>
                            <Divider sx={{margin: "1rem 0"}}/>
                            
                            <Typography fontWeight="300" textAlign="center" variant="body1">
                            Login with either
                            </Typography>
                            <Button sx={{ margin: "  1rem 0 !important", width: "100%", gridColumn: "span 4"}}variant="contained" onClick={() => login()}>Google<div style={{marginLeft: ".2rem"}}></div><Google/> </Button>

                            <Typography fontWeight="300" textAlign="center" variant="body1">
                            Or
                            </Typography>
                            
                            <Button disabled sx={{margin: " 1rem 0 !important", width: "100%", gridColumn: "span 4"}}variant="contained">Microsoft <div style={{marginLeft: ".2rem"}}></div><WindowRounded/> </Button>
                            <Typography variant="overline">Note: Microsoft Azure AD is not supported at this time</Typography>


                            </div>


                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={backdropOpen}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>

                            <Snackbar open={snackbarOpen} autoHideDuration={10000} onClose={handleSnackbarClose}>
                                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                                    Error logging in: {error}
                                </Alert>
                            </Snackbar>
                    </div>
                )}


                <Grid2 container>
                    <Grid2 xs={12} md={6}>
                        <Typography
                            onClick={() => {
                                setPageType(isLogin ? "register" : "login");
                                resetForm();
                            }}
                            sx={{
                                textDecoration: "underline",
                                margin: "0 0 0.5rem 0.5rem",
                                width: "100%",
                                color: palette.primary.contrastText,
                                "&:hover": {
                                    cursor: "pointer",
                                    color: palette.primary.light
                                }
                            }}
                            >
                                {isLogin ? "Don't have a orginaztion set up? Register here." : "Already have an orginaztion registered? Login here."}

                            </Typography>
                    </Grid2>
                    <Grid2 xs={12} md={6}>
                        <Typography
                            onClick={() => {
                                tryForFree();
                            }}
                            sx={{
                                textDecoration: "underline",
                                margin: "0 0 0.5rem 0.5rem",
                                width: "100%",
                                color: palette.primary.contrastText,
                                "&:hover": {
                                    cursor: "pointer",
                                    color: palette.primary.light
                                }
                            }}
                            >
                                Want to test it out first? Click this to try it out for free.
                            </Typography>
                        </Grid2>
                </Grid2>

                </Box>
            </form>
        )}
    </Formik>
  )
}

export default Form