import { Grow, useMediaQuery, useTheme } from '@mui/material'
import { Box, Typography } from '@mui/material'
import React from 'react'

function DataOutlined({data}) {
    const theme = useTheme()
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)")
  return (
    <Grow in={true}>
      <Box
          width={isNonMediumScreens ? "20vw" : "auto"}
          sx={{
              //@ts-ignore
              backgroundColor: theme.palette.mode == "light" ? theme.palette.neutral[700] : theme.palette.primary[400],
              borderRadius: "5px",
              margin: '5px 0',
              padding: '.5rem 2rem .5rem 2rem',
              background: 'rgba(0,0,0,.09)',
              
          }}
      >
          <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} textAlign={"center"} variant="body1">{data}</Typography>
      </Box>
    </Grow>
  )
}


export default DataOutlined