import { Delete } from '@mui/icons-material';
import { Card, Skeleton, CardContent, Typography, CardMedia, CardActions, IconButton } from '@mui/material';
import RequiresScope from 'components/RequiresScope';
import React, { useEffect, useState } from 'react'
import { objectIdToString, useGetFullImageQuery, useGetImageDocumentQuery, useGetImageFromTicketQuery } from 'state/api';
interface LazyLoadImage {
    id: any;
    deleteScope: string;
    onDelete: (id) => {};
}
const LazyLoadImage = ({ id, deleteScope, onDelete }) => {


    const [imageDoc, setImageDoc] = useState(null);
    const [canDelete, setCanDelete] = useState(false);
    const { data, isLoading, error } = useGetImageDocumentQuery(id)


    useEffect(() => {
        setImageDoc(data)
    }, [data])


    if (!imageDoc) {
        return (
            <Card>
                <Skeleton height={400} variant="rectangular" />
            </Card>
        )
    }
    return (
        <Card>
            <CardMedia
                component="img"
                alt="image"
                width="100%"
                height="auto"
                image={process.env.REACT_APP_BASE_URL + `/org/images/${id}/image`}
            />
            <CardContent>
                <Typography gutterBottom variant="h3">
                    {imageDoc.title}
                </Typography>

                <Typography variant="body2" color="text.secondary">
                    {imageDoc.description}
                </Typography>

                <Typography variant="subtitle1">
                    Author: {imageDoc.author}
                </Typography>
            </CardContent>
            <RequiresScope scope={deleteScope}>
                <CardActions>
                    <IconButton onClick={() => { onDelete(id) }}>
                        <Delete />
                    </IconButton>
                </CardActions>
            </RequiresScope>

        </Card>

    )
}

export default LazyLoadImage;