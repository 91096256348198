import React, {useEffect} from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    Dialog,
    DialogContent,
    Divider,
    Skeleton,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import Header from "../../../components/Header";
import HoverPaper from "../../../components/HoverPaper";
import Grid2 from "@mui/material/Unstable_Grid2";
import {useGetLocationsDevicesQuery, useGetLocationsQuery} from "../../../state/api";
import {Add, LaptopMac} from "@mui/icons-material";
import * as yup from "yup";
import {Formik} from "formik";
import SnackbarHandler from "../../../components/SnackbarHandler";
import {useNavigate} from "react-router-dom";


interface UpdateInventoryLocationDialogProps {
    open: boolean;
    setOpen: (boolean) => void;
    onUpdate: () => void;
    location: any;
}

const EditInventoryLocationDialog: React.FC<UpdateInventoryLocationDialogProps> = ({
                                                                                       location,
                                                                                       open,
                                                                                       setOpen,
                                                                                       onUpdate
                                                                                   }) => {

    const [snackbarData, setSnackbarData] = React.useState({open: false, message: "", severity: "success"});

    const validationSchema = yup.object({
        name: yup.string().required("Name is required"),
        description: yup.string().required("Description is required"),
        capacity: yup.number().required("Capacity is required"),
    })

    const initialValues = {
        name: location.name,
        description: location.description,
        capacity: location.capacity ?? 0,
    }

    const onSubmit = async (values) => {
        // convert capacity to number
        values.capacity = parseInt(values.capacity);

        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/inventory/locations/' + location.id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(values)
        })

        if (response.status === 200) {
            setOpen(false);
            onUpdate();
        } else {
            setSnackbarData({open: true, message: "Error updating location", severity: "error"})
        }

    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth={"md"}
        >
            <DialogContent>
                <SnackbarHandler open={snackbarData.open}
                                 onClose={() => setSnackbarData({...snackbarData, open: false})}
                                 message={snackbarData.message}
                                 severity={snackbarData.severity}/>
                <Typography variant={"h3"}>Create Location</Typography>
                <Divider/>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, isSubmitting}) => (
                        <form onSubmit={handleSubmit}>
                            <Stack sx={{mt: '1rem'}} spacing={4}>
                                <TextField
                                    fullWidth
                                    color={"secondary"}
                                    label={"Name"}
                                    name={"name"}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    //@ts-ignore
                                    error={!!errors.name && touched.name}
                                    //@ts-ignore
                                    helperText={touched.name && errors.name}
                                    variant={"filled"}
                                />

                                <TextField
                                    fullWidth
                                    label={"Description"}
                                    color={"secondary"}
                                    name={"description"}
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    //@ts-ignore
                                    error={!!errors.description && touched.description}
                                    //@ts-ignore
                                    helperText={touched.description && errors.description}
                                    variant={"filled"}
                                />

                                <TextField
                                    fullWidth
                                    label={"Capacity"}
                                    color={"secondary"}
                                    name={"capacity"}
                                    value={values.capacity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    //@ts-ignore
                                    error={!!errors.capacity && touched.capacity}
                                    //@ts-ignore
                                    helperText={touched.capacity && errors.capacity}
                                    variant={"filled"}
                                />
                            </Stack>


                            <ButtonGroup fullWidth sx={{mt: '1rem'}}>
                                <Button variant={"outlined"} color={"error"}
                                        onClick={() => setOpen(false)}>Cancel</Button>
                                <Button variant={"outlined"} color={"success"} type={"submit"}
                                        disabled={!isValid}>Update</Button>
                            </ButtonGroup>

                        </form>
                    )}

                </Formik>
            </DialogContent>
        </Dialog>
    )
}

interface CreateInventoryLocationDialogProps {
    open: boolean;
    setOpen: (boolean) => void;
    onUpdate: () => void;
}

const CreateInventoryLocationDialog: React.FC<CreateInventoryLocationDialogProps> = ({open, setOpen, onUpdate}) => {

    const [snackbarData, setSnackbarData] = React.useState({open: false, message: "", severity: "success"});

    const validationSchema = yup.object({
        name: yup.string().required("Name is required"),
        description: yup.string().required("Description is required"),
        capacity: yup.number().required("Capacity is required"),
    })

    const initialValues = {
        name: "",
        description: "",
        capacity: 0,
    }

    const onSubmit = async (values) => {
        // convert capacity to number
        values.capacity = parseInt(values.capacity);

        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/inventory/locations', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(values)
        })

        if (response.status === 200) {
            setOpen(false);
            onUpdate();
        } else {
            setSnackbarData({open: true, message: "Error creating location", severity: "error"})
        }

    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth={"md"}
        >
            <DialogContent>
                <SnackbarHandler open={snackbarData.open}
                                 onClose={() => setSnackbarData({open: false, ...snackbarData})}
                                 message={snackbarData.message}
                                 severity={snackbarData.severity}/>
                <Typography variant={"h3"}>Create Location</Typography>
                <Divider/>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, isSubmitting}) => (
                        <form onSubmit={handleSubmit}>
                            <Stack sx={{mt: '1rem'}} spacing={4}>
                                <TextField
                                    fullWidth
                                    color={"secondary"}
                                    label={"Name"}
                                    name={"name"}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    //@ts-ignore
                                    error={!!errors.name && touched.name}
                                    //@ts-ignore
                                    helperText={touched.name && errors.name}
                                    variant={"filled"}
                                />

                                <TextField
                                    fullWidth
                                    label={"Description"}
                                    color={"secondary"}
                                    name={"description"}
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    //@ts-ignore
                                    error={!!errors.description && touched.description}
                                    //@ts-ignore
                                    helperText={touched.description && errors.description}
                                    variant={"filled"}
                                />

                                <TextField
                                    fullWidth
                                    label={"Capacity"}
                                    color={"secondary"}
                                    name={"capacity"}
                                    value={values.capacity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    //@ts-ignore
                                    error={!!errors.capacity && touched.capacity}
                                    //@ts-ignore
                                    helperText={touched.capacity && errors.capacity}
                                    variant={"filled"}
                                />
                            </Stack>


                            <ButtonGroup fullWidth sx={{mt: '1rem'}}>
                                <Button variant={"outlined"} color={"error"}
                                        onClick={() => setOpen(false)}>Cancel</Button>
                                <Button variant={"outlined"} color={"success"} type={"submit"}
                                        disabled={!isValid}>Create</Button>
                            </ButtonGroup>

                        </form>
                    )}

                </Formik>
            </DialogContent>
        </Dialog>
    )
}

interface InventoryLocationCardProps {
    location: any;
    refetch: () => void;
}

const InventoryLocationCard: React.FC<InventoryLocationCardProps> = ({location, refetch}) => {
    const [open, setOpen] = React.useState(false);
    const {data, isLoading, refetch: refetchLocationDevices} = useGetLocationsDevicesQuery(location.id);
    const navigate = useNavigate();

    useEffect(() => {
        refetchLocationDevices()
    }, [location])

    return (
        <HoverPaper elevation={4} sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <Grid2 container sx={{padding: 0, flex: 1}}>
                <Grid2 xs={12} md={6} sx={{textAlign: 'left'}}>
                    <Typography variant={'h4'}>{location.name}</Typography>
                </Grid2>
                <Grid2 xs={12} md={6}
                       sx={{textAlign: 'right', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <Typography variant={'body1'}>{isLoading ? "..." : data.length}/{location.capacity}</Typography>
                    <LaptopMac sx={{ml: '5px'}}/>
                </Grid2>
            </Grid2>
            <Typography variant={'subtitle1'}>{location.description}</Typography>

            <Divider sx={{mb: '1rem'}}/>

            <Box sx={{mt: 'auto'}}>
                <ButtonGroup fullWidth>
                    <Button variant={"outlined"} color={"success"} fullWidth
                            onClick={() => navigate('/org/inventory/locations/' + location.id)}>View</Button>
                    <Button variant={"outlined"} color={"warning"} fullWidth onClick={() => setOpen(true)}>Edit</Button>
                </ButtonGroup>
            </Box>

            <EditInventoryLocationDialog open={open} setOpen={setOpen} onUpdate={refetch} location={location}/>
        </HoverPaper>
    )
}

const InventoryLocationsOverview = () => {
    const [locations, setLocations] = React.useState([]);
    const {data, isLoading, error, refetch} = useGetLocationsQuery("");
    const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

    useEffect(() => {
        if (!isLoading && data) {
            setLocations(data)
        }
    }, [data, isLoading])

    return (
        <Box padding={"1rem"}>
            <Grid2 container>
                <Grid2 xs={12} md={6} sx={{textAlign: 'left'}}>
                    <Header title={"Inventory Locations"} subtitle={"A overview of all your inventory locations"}/>
                </Grid2>
                <Grid2 xs={12} md={6} sx={{textAlign: 'right'}}>
                    <Button endIcon={<Add/>} color={"success"} variant={"outlined"}
                            onClick={() => setCreateDialogOpen(true)}>Create Location</Button>

                    <CreateInventoryLocationDialog
                        open={createDialogOpen}
                        setOpen={setCreateDialogOpen}
                        onUpdate={refetch}
                    />

                </Grid2>
            </Grid2>
            <Divider/>
            <Grid2 spacing={2} mt={"1rem"} container>
                {isLoading && (
                    <>
                        <Grid2 xs={12} md={6} lg={4} xl={3}>
                            <Skeleton variant={"rectangular"} height={"100%"} width={"100%"}/>
                        </Grid2>
                        <Grid2 xs={12} md={6} lg={4} xl={3}>
                            <Skeleton variant={"rectangular"} height={"100%"} width={"100%"}/>
                        </Grid2>
                    </>
                )}
                {error && (
                    <>
                        <Grid2 xs={12}>
                            <Typography>Error loading inventory</Typography>
                        </Grid2>
                    </>
                )}
                {locations.map((location) => (
                    <Grid2 xs={12} md={6} lg={4} xl={3}>
                        <InventoryLocationCard location={location} refetch={refetch}/>
                    </Grid2>
                ))}
            </Grid2>

        </Box>
    );
};

export default InventoryLocationsOverview;