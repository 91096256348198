import {Backdrop, Button, Dialog, DialogActions, DialogTitle, Divider, Typography} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, {useState} from 'react'
import FieldChange from './FieldChange';
import {useGetLinkProviderSettingsQuery} from 'state/api';
import Loading from 'components/Loading';

interface CreateStudentDialog {
    user: any;
    device: any;
    configuration?: any;
    open: boolean;
    setOpen: (boolean) => void;
    onStudentCreate: () => void;
    isNonBatch?: boolean;
}

const CreateStudentDialog: React.FC<CreateStudentDialog> = ({
                                                                user,
                                                                device,
                                                                configuration,
                                                                open,
                                                                setOpen,
                                                                onStudentCreate,
                                                                isNonBatch
                                                            }) => {
    const {data, isLoading, error} = useGetLinkProviderSettingsQuery("");
    const [backdropOpen, setBackdropOpen] = useState(false);
    const createStudent = async () => {
        if(isNonBatch) {
            await createNonBatchStudent();
            return;
        }
        setBackdropOpen(true);
        var response = await fetch(process.env.REACT_APP_BASE_URL + '/org/students/batch/' + configuration.id + `/students/create?user=${user.primaryEmail}&device=${device.serialNumber}`, {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        setBackdropOpen(false)
        onStudentCreate()
    }

    const createNonBatchStudent = async () => {
        setBackdropOpen(true);
        var response = await fetch(process.env.REACT_APP_BASE_URL + '/org/students/create', {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({user: user, device: device})
        })

        setBackdropOpen(false)
        onStudentCreate()
    }
    const getField = (field, obj) => {
        var fieldToCamel = field.charAt(0).toLowerCase() + field.slice(1);

        return obj[fieldToCamel]
    }
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth="md"
        >
            <Backdrop open={backdropOpen}>
                <Loading/>
            </Backdrop>
            <DialogTitle>
                <Typography variant="h2">Create Student</Typography>
                <Typography sx={{m: '1rem 0'}}>You are about to
                    assign <b>{user.name}</b> to <b>{device.serialNumber}</b></Typography>
                <Typography>Please review the information below to confirm the changes that will be made according to
                    the configuration</Typography>

                <Grid2 container sx={{mt: '1rem'}} spacing={2}>
                    {configuration && (
                        <>
                            <Grid2 md={6} xs={12}>
                                {configuration?.studentOu != "" && (
                                    <FieldChange disabled label={"User Org Unit Path"} value={true} setValue={() => {
                                    }} baseValue={user.orgUnitPath} changedValue={configuration.studentOu}/>
                                )}
                            </Grid2>
                            <Grid2 md={6} xs={12}>
                                {configuration?.deviceOu != "" && (

                                    <FieldChange
                                        disabled
                                        label={"Device Org Unit Path"}
                                        value={true}
                                        setValue={() => {
                                        }}
                                        baseValue={device.orgUnitPath}
                                        changedValue={configuration.deviceOu}
                                    />
                                )}
                            </Grid2>
                        </>
                    )}

                    {isLoading && (
                        <Loading/>
                    )}
                    {data != null && (

                        <Grid2 md={12} xs={12}>
                            <Typography>The values below will be created for the linking process according to your link
                                provider. These values cannot be changed.</Typography>
                            <Grid2 container>
                                <Grid2 md={12} xs={12}>
                                    <Typography variant='h4'>Device Changes</Typography>
                                    <Divider sx={{m: '.5rem 0 1rem 0'}}/>
                                    <FieldChange
                                        disabled
                                        label={data.authorityField}
                                        value={true} setValue={() => {
                                    }}
                                        baseValue={getField(data.authorityField, device)}
                                        changedValue={getField(data.receiverField, user)}/>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    )}
                </Grid2>
            </DialogTitle>
            <DialogActions>
                <Button variant="contained" color="success" onClick={() => createStudent()}>Create Student</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateStudentDialog