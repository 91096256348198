//@ts-nocheck

import {useTheme} from '@mui/material'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux';
import {setForcedMode} from 'state';
import {loadStripe} from "@stripe/stripe-js";
import Grid2 from "@mui/material/Unstable_Grid2";
import HoverPaper from "../HoverPaper";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function StripeCheckout() {
    const theme = useTheme();
    const dispatch = useDispatch()

    const searchParams = new URLSearchParams(window.location.search);

    // Access the value of the "session" parameter
    const sessionId = searchParams.get('session');

    useEffect(() => {
        if (theme.palette.mode === "dark") {
            dispatch(setForcedMode("light"))
        }
    }, [])


    // Display the test mode pricing table if development mode is enabled
    if (process.env.NODE_ENV !== 'development') {
        return (
            <>
                <stripe-pricing-table
                    pricing-table-id="prctbl_1OZbApIZGJjdZW872o5zQm4I"
                    publishable-key="pk_live_51KajBIIZGJjdZW87ZnYLoQop8hK6nurqixAHa04wOFfwEcfHFGlCK1PHVxoMMIlao0pMdYtalBWIJEKIub5pdrP000XA91JEeA"
                    client-reference-id={sessionId}
                >
                </stripe-pricing-table>
            </>
        );
    } else {
        return (
            <>
                <stripe-pricing-table
                    pricing-table-id="prctbl_1NdCexIZGJjdZW879XvQtGj1"
                    publishable-key="pk_test_51KajBIIZGJjdZW87i9l6JcDI2YJ8E67p3RomyUsiW7NxTh3K6Tdr48r7jNRqMYqzZLOAIT5GOtXAbGS9xASoDq9E00BLPDdaPe"
                    client-reference-id={sessionId}
                >
                </stripe-pricing-table>
            </>
        )
    }
}



export default StripeCheckout
