import { OtherHouses, Search } from '@mui/icons-material';
import { Box, Button, Checkbox, Divider, FormControlLabel, IconButton, Stack, Switch, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2';
import { GridToolbar } from '@mui/x-data-grid';
import Header from 'components/Header'
import MultiSelect from 'components/MultiSelect';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { CompareScopes, useGetFormQuery } from 'state/api';
import DataContainer from './DataContainer';
import Loading from 'components/Loading';


const UserAccountFields = [
    "HEADER__USER",
    "Id",
    "Primary Email",
    "Name",
    "Given Name",
    "Family Name",
    "Emails",
    "Phones",
    "Org Unit Path",
    "Last Login Time"
]
const DeviceFields = [
    "HEADER__DEVICE",
    "Serial Number",
    "Status",
    "Annotated Asset Id",
    "Org Unit Path",
    "Notes",
    "Annotated User",
    "Annotated Location",
    "MAC Adderss",
    "Model"
]

const StudentFields = [
    "HEADER__STUDENT",
    "Serial Number",
    "Account Id",
    "Annotated Asset Id",
    "Device Id",
    "Name",
    "Email",
    "Loaner",
    "Loaner Asset Id",
]

function SearchOrganization() {
    const theme = useTheme();
    const [searchThrough, setSearchThrough] = useState([])
    const [searchFields, setSearchFields] = useState([])
    const [searchFor, setSearchFor] = useState("")
    const [avaiableSearchOptions, setAvaiableSearchOptions] = useState([]);
    const {data: ticketFieldsQuery, isLoading} = useGetFormQuery("");
    const [ticketFields, setTicketFields] = useState([])
    const [fields, setFields] = useState([]);
    const [fullSearch, setFullSearch] = useState(false)
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false);
    //@ts-ignore
    const state = useSelector(s => s.global);

    const searchOptions = [
        {
            title: "User Accounts",
            requireScope: "read:user"
        }, 
        {
            title: "Devices",
            requireScope: "read:device"
        },
        {
            title: "Students",
            requireScope: "read:student"
        },
        {
            title: "Tickets",
            requireScope: "read:ticket:overview"
        }
    ]

    useEffect(() => {
        if(state.scopes != null) {
        
            var options = [];
            if(state.scopes.includes('admin')) {
                setAvaiableSearchOptions(searchOptions.map((o) => o.title));
                return;
            }
            searchOptions.map((opt) => {
                if(hasRequiredScope(state.scopes, opt.requireScope)) {
                    options.push(opt.title)
                }
            })

            setAvaiableSearchOptions(options);
        }
    }, [state])

    useEffect(() => {
        if(ticketFieldsQuery) {

            var fields = ticketFieldsQuery.fields
    
            var fieldNames = fields.map((field) => {
                return field.label;
            })
    
            setTicketFields(fieldNames)
        }
    }, [ticketFieldsQuery])

    useEffect(() => {
        var options = [];

        if(searchThrough.includes("User Accounts")) {
            console.log(...UserAccountFields)
            options.push(...UserAccountFields)
        }

        if(searchThrough.includes("Devices")) {
            options.push(...DeviceFields)
        }

        if(searchThrough.includes("Students")) {
            options.push(...StudentFields)
        }

        if(searchThrough.includes("Tickets")) {
            options.push(...["HEADER__TICKETS", ...ticketFields])
        }

        setFields([...options])

    }, [searchThrough])

    const hasRequiredScope = (scopes: string[], scope: string) => {
        scopes.map((s) => {
            if(CompareScopes(s, scope)) return true;
        })

        return false;
    }


    const search = async () => {
        setData(null);
        setLoading(true)

        //Format query
        var formattedFields = [];

        if(fullSearch) {
            formattedFields.push([...DeviceFields, ...UserAccountFields, ...StudentFields, ...ticketFields])
        } else {
            formattedFields.push(searchFields.map((v:string) => v.replace(/\s/g, "")))
        }

        var res = await fetch(process.env.REACT_APP_BASE_URL + "/org/serch/query/rich" + `?query=${searchFor}&searchThrough=${searchThrough.join(",")}&fields=${formattedFields}&limit=100`, {
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include"
            })

        setLoading(false);
        if(res.status == 200) {
            setData(await res.json())
        }
    }

    return (
        <Box m="1.5rem 2.5rem">
            <Header title={"Search Organization"} subtitle={"Search for data in your organization"}/> 
            <Divider sx={{m:'1rem 0'}}/>
            <Box sx={{
                //@ts-ignore
                backgroundColor:theme.palette.background.alt,
                borderRadius:"0.55rem",
                padding: '1rem',
            }}>
                
                <Typography variant='h4' sx={{mb: '1rem'}}>
                    Filter our your search.
                </Typography>

                <Grid2 container spacing={2}>
                    <Grid2 xs={12} md={4}>
                        <MultiSelect data={searchThrough} options={avaiableSearchOptions} onChange={(d) => setSearchThrough([...d])} label={'Search Through...'}/>
                    </Grid2>
                    <Grid2 xs={12} md={4}>
                        <MultiSelect data={searchFields} options={fields} onChange={(d) => setSearchFields([...d])} label="Search Fields..." header="HEADER__"/>
                        <Typography sx={{textAlign:'center', m:'.5rem'}}>Or..</Typography>
                        <FormControlLabel control={<Checkbox value={fullSearch} onChange={(e,v) => setFullSearch(v)} color="secondary"/>} label="Search Through All Fields" />
                    </Grid2>
                    <Grid2 xs={12} md={4}>
                        <TextField
                            color="secondary" 
                            label="Query"
                            value={searchFor}
                            onChange={(e) => setSearchFor(e.target.value)}
                        
                        />
                    </Grid2>
                    <Grid2 xs={12} md={12}>
                        <Button fullWidth onClick={() => search()} sx={{m: '1rem 0'}} variant="contained" color="secondary">Search</Button>
                    </Grid2>
                </Grid2>
                {loading && (<Loading backdrop/>)}
            </Box>
            {(loading || data != null) && (
                <Box sx={{
                    mt: '2rem',
                    //@ts-ignore
                    backgroundColor:theme.palette.background.alt,
                    borderRadius:"0.55rem",
                    padding: '1rem',
                }}>
                    <Typography variant="h4">Results</Typography>
                    <Divider sx={{mb:'1rem'}}/>

                    <DataContainer loading={loading} data={data}/>
                </Box>
            )}
        </Box>
    )
}

export default SearchOrganization