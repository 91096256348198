import {
    Box,
    Card,
    Chip,
    Dialog,
    DialogContent,
    Divider,
    Fab,
    IconButton,
    Menu,
    MenuItem, Paper,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import TwoSide from './data/TwoSide';
import Grid2 from '@mui/material/Unstable_Grid2';
import DataEditable from './data/DataEditable';
import DataField from './data/DataField';
import DataEditableAutocomplete from './data/DataEditableAutocomplete';
import { CompareScopes, HasMinimumScopeWithArray, LazyNavigation, useGetAllQuery } from 'state/api';
import { Edit, Pending, PendingOutlined, Print, Visibility } from '@mui/icons-material';
import PrintTicket from 'scenes/ticket/PrintTicket';
import { useParams } from 'react-router-dom';
import { GridComparatorFn } from '@mui/x-data-grid';
import GithubAutocomplete from './data/GithubAutocomplete';
import Assignees from 'scenes/ticket/Assignees';
import { useSelector } from 'react-redux';
import DeviceAutocomplete from './Autocomplete/DeviceAutocomplete';
import UserAutocomplete from './Autocomplete/UserAutocomplete';
import { Console } from 'console';
import TicketStatusComponent from './data/TicketStatus';



interface UpdateStudentPopup {
    open: boolean;
    setOpen: (boolean) => void;
    onUpdate: (v: string) => void;
}
const UpdateStudentPopup: React.FC<UpdateStudentPopup> = ({ open, setOpen, onUpdate }) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");

    return (
        <Dialog
            open={open}
            maxWidth={isNonMobile ? "sm" : 'lg'}
            fullWidth
            onClose={() => setOpen(false)}
        >
            <DialogContent>
                <Typography variant="h3">Update Student</Typography>
                <Divider sx={{ mb: '2rem', mt: '.2rem' }} />

                <UserAutocomplete
                    label='Update Student'
                    onValueChange={(val) => {
                        setOpen(false)
                        onUpdate(val);
                    }}
                    value={''}

                />
            </DialogContent>
        </Dialog>
    )
}

interface StudentDeviceEditableDisplay {
    student: string;
    title: string;
    onUpdate: (v: string) => void;
    canViewStudent?: boolean;

}
const StudentDeviceEditableDisplay: React.FC<StudentDeviceEditableDisplay> = ({ student, title, onUpdate, canViewStudent }) => {
    const [editing, setEditing] = useState(false);
    const theme = useTheme();
    const [currentStudentLink, setCurrentStudentLink] = useState(null);

    useEffect(() => {
        const GetStudent = async () => {
            var response = await fetch(process.env.REACT_APP_BASE_URL + "/org/students/query/any?q=" + student, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if (response.status == 200) {
                var json = await response.json()
                setCurrentStudentLink("/org/students/" + json.email)
            }
        }

        GetStudent()
    }, [student])


    return (
        <Box>
            <Typography variant="h5">
                {title}
            </Typography>
            <Divider />
            <UpdateStudentPopup open={editing} setOpen={setEditing} onUpdate={(v) => onUpdate(v)} />
            <Box
                display='flex'
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"5px"}
                width="100%"
                sx={{
                    //@ts-ignore
                    backgroundColor: theme.palette.mode == "light" ? theme.palette.neutral[700] : theme.palette.primary[400],
                    borderRadius: "5px",
                    margin: '5px 0',
                    padding: '.5rem 2rem .5rem 2rem',
                    background: 'rgba(0,0,0,.09)'
                }}
            >
                <Typography
                    textAlign={"center"}
                    //@ts-ignore
                    variant={"body1"}
                    onClick={() => setEditing(true)}
                    width="100%"
                >
                    {student}
                </Typography>
                <IconButton onClick={(e) => setEditing(true)} size="small">
                    <Edit />
                </IconButton>

                {(canViewStudent && currentStudentLink != null) && (
                    <Tooltip title="View Student Information">
                        <IconButton onClick={() => LazyNavigation(currentStudentLink)}>
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                )}

            </Box>
        </Box>
    )
}

interface UpdateDevicePopup {
    open: boolean;
    setOpen: (boolean) => void;
    onUpdate: (v: string) => void;
}
const UpdateDevicePopup: React.FC<UpdateDevicePopup> = ({ open, setOpen, onUpdate }) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");

    return (
        <Dialog
            open={open}
            maxWidth={isNonMobile ? "sm" : 'lg'}
            fullWidth
            onClose={() => setOpen(false)}
        >
            <DialogContent>
                <Typography variant="h3">Update Device</Typography>
                <Divider sx={{ mb: '2rem', mt: '.2rem' }} />

                <DeviceAutocomplete
                    label='Update Device'
                    onValueChange={(val) => {
                        setOpen(false)
                        onUpdate(val);
                    }}
                    value={''}

                />
            </DialogContent>
        </Dialog>
    )
}

interface DeviceEditableDisplay {
    device: string;
    title: string;
    onUpdate: (v: string) => void;
    canViewDevice: boolean;
}

const DeviceEditableDisplay = ({ device, title, onUpdate, canViewDevice }) => {
    const [editing, setEditing] = useState(false);
    const theme = useTheme();

    const [currentDeviceLink, setCurrentDeviceLink] = useState(null);

    useEffect(() => {
        var splitDevice = device.split(".");

        if (splitDevice.length == 1) {
            setCurrentDeviceLink("/org/devices/" + splitDevice[0])
            return;
        }

        var serialSection = splitDevice[0].split(":")

        setCurrentDeviceLink("/org/devices/" + serialSection[1].replace(/\s/g, ""))
    }, [device])
    return (
        <Box>
            <Typography variant="h5">
                {title}
            </Typography>
            <Divider />
            <UpdateDevicePopup open={editing} setOpen={setEditing} onUpdate={(v) => onUpdate(v)} />
            <Box
                display='flex'
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"5px"}
                width="100%"
                sx={{
                    //@ts-ignore
                    backgroundColor: theme.palette.mode == "light" ? theme.palette.neutral[700] : theme.palette.primary[400],
                    borderRadius: "5px",
                    margin: '5px 0',
                    padding: '.5rem 2rem .5rem 2rem',
                    background: 'rgba(0,0,0,.09)'
                }}
            >
                <Typography
                    textAlign={"center"}
                    //@ts-ignore
                    variant={"body1"}
                    onClick={() => setEditing(true)}
                    width="100%"
                >
                    {device}
                </Typography>
                <IconButton onClick={(e) => setEditing(true)} size="small">
                    <Edit />
                </IconButton>

                {(canViewDevice && currentDeviceLink != null) && (
                    <Tooltip title="View Device">
                        <IconButton onClick={() => LazyNavigation(currentDeviceLink)}>
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                )}

            </Box>
        </Box>
    )
}



interface Layout {
    layout: GridItem[]
    ticketInformation: TicketInformation
    printMode?: boolean;
    onFieldUpdated?: (fields: any) => void;
    isNonMedium?: boolean;
    refetch: () => void;
    onStudentUpdate?: (student: any, fields: any) => void;
}

interface GridItem {
    name: string;
    value: string;
    index: number;
    type: "title" | 'divider' | 'field' | 'header'
    autocompleteOption?: 'student' | 'device'
    uid: number;
    editable?: boolean;
    xs?: number;
}

interface TicketInformation {
    id: string;
    author: string;
    status: string;
    createdAt: string;
    studentName: string;
}

interface AutocompleteOption {
    label: string;
    key: string;
}

const statusOptions = [
    "Waiting for Repair",
    "Repaired, Waiting for Return",
    "In Office",
    "Completed",
    "Other"
]
const CustomTicketLayout: React.FC<Layout> = ({ layout, ticketInformation, printMode, onFieldUpdated, refetch, onStudentUpdate }) => {
    const isSmallScreen = useMediaQuery('(max-width: 600px)');
    const [gridItems, setGridItems] = useState([])
    const [headerItems, setHeaderItems] = useState([])

    //@ts-ignore
    const scopes = useSelector(s => s.global.scopes)
    const [canEdit, setCanEdit] = useState(false);
    const [canViewStudent, setCanViewStudent] = useState(false);
    const [canViewDevice, setCanViewDevice] = useState(false);


    const { id } = useParams()
    const [status, setStatus] = useState(ticketInformation.status)
    const { data, isLoading } = useGetAllQuery("");
    const theme = useTheme()
    const [searchOptions, setSearchOptions] = useState([] as AutocompleteOption[])
    const [openPrintDialog, setOpenPrintDialog] = useState(false);
    const [studentOptions, setStudentOptions] = useState([])
    const [deviceOptions, setDeviceOptions] = useState([])
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = async (newStatus) => {
        setAnchorEl(null);
        //Update status
        var res = await fetch(process.env.REACT_APP_BASE_URL + `/org/tickets/${id}/status?status=${newStatus}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        })


        if (res.status == 200) {
            refetch();
        }

    };

    useEffect(() => {
        if (!isLoading && data != null) {
            const options: AutocompleteOption[] = []
            const users = data.users;
            const devices = data.devices;
            const tickets = data.tickets;


            //Loop over users if not null and convert to search option
            if (users != null) {
                users.forEach(user => {
                    //Add id
                    studentOptions.push({
                        label: "User: " + user.id,
                        key: user.id
                    })

                    //Add Email
                    studentOptions.push({
                        label: "User: " + user.id,
                        key: user.id
                    })

                    //Add Name
                    studentOptions.push({
                        label: "User: " + user.name,
                        key: user.id
                    })
                });
            }

            //Loop over devices if not null and convert each to search option
            if (devices != null) {
                devices.forEach(device => {
                    //Add serial number
                    deviceOptions.push({
                        label: "Device: " + device.serialNumber,
                        key: '/org/devices/' + device.serialNumber
                    })

                    //Add annotated user
                    deviceOptions.push({
                        label: "Device: " + device.annotatedUser,
                        key: '/org/devices/' + device.annotatedUser
                    })

                    //Add annotatedAssetId
                    deviceOptions.push({
                        label: "Device: " + device.annotatedAssetId,
                        key: '/org/devices/' + device.annotatedAssetId
                    })

                });
            }

            setDeviceOptions([...deviceOptions])
            setStudentOptions([...studentOptions])
            setSearchOptions(options);

        }
    }, [data])
    useEffect(() => {
        console.log(layout)
        var items = layout.map((i) => ({
            ...i,
            uid: Math.random()
        }));
        for (var i = 0; i < items.length; i++) {
            if (items[i].name == "Student Information") {
                items[i].value = ticketInformation.studentName;
            }
        }
        var headerItems = items.filter((i) => i.type == "header").map((i) => ({ ...i, uid: Math.random() }))
        setGridItems([...items])
        setHeaderItems([...headerItems])
    }, [layout])

    useEffect(() => {
        
        setCanViewDevice(HasMinimumScopeWithArray(scopes, "view:device"))
        setCanViewStudent(HasMinimumScopeWithArray(scopes, "view:student"))
        setCanEdit(HasMinimumScopeWithArray(scopes, "write:ticket:overview"))
    }, [scopes])



    const getTicketStatusToChip = (data) => {
        switch (data) {
            case 0:
                return <Chip size="small" label={"Waiting for Repair"} variant="outlined" color="error" />
            case 1:
                return <Chip size="small" label={"Repaired, Waiting for Return"} variant="outlined" color="warning" />
            case 2:
                return <Chip size="small" label={"In Office"} variant="outlined" color="warning" />
            case 3:
                return <Chip size="small" label={"Completed"} variant="outlined" color="success" />
            case 4:
                return <Chip size="small" label={"Other"} variant="outlined" color="warning" />
        }
    };

    const ticketStatusComparator: GridComparatorFn = (v1, v2) => {
        return 0;
    }

    const updateValue = (index, value) => {
        layout[index].value = value;
        //Map back to object
        const result = layout.reduce((obj, item) => {
            obj[item.name] = item.value;
            return obj;
        }, {});

        if (onFieldUpdated) {
            onFieldUpdated(result);
        }

    }

    const updateStudent = (index, student) => {
        layout[index].value = student.name;
        //Map back to object
        const result = layout.reduce((obj, item) => {
            obj[item.name] = item.value;
            return obj;
        }, {});

        if (onStudentUpdate) {
            onStudentUpdate(student, result);
        }
    }

    const getTicketStatusToString = (data) => {
        switch (data) {
            case 0:
                return "Waiting for Repair"
                break;
            case 1:
                return "Repaired, Waiting for Return"
                break;
            case 2:
                return "In Office"
                break;
            case 3:
                return "Completed"
                break;
            case 4:
                return "Other"
                break;

        }
    }

    const UpdateStatus = async (newStatus) => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + `/org/tickets/${id}/status?status=${newStatus}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json'
            },
            credentials: "include",
        })
    
        refetch();
    }

    return (
        <Paper elevation={16} sx={{
            m: '1rem auto',
            padding: '1rem',
            width: isSmallScreen ? "100%" : "80%",
            position: 'relative'
        }}>
            {!isSmallScreen && (
                <>
                    <Fab size="small" sx={{
                        position: 'absolute',
                        top: '-15px',
                        right: '-15px',
                        zIndex: '1000'
                    }}
                        color="secondary"
                        onClick={() => setOpenPrintDialog(true)}
                    >
                        <Print />
                    </Fab>
                    <PrintTicket open={openPrintDialog} handleClose={() => setOpenPrintDialog(false)} data={layout} ticketInformation={ticketInformation} />
                </>
            )}
            {isSmallScreen ? (
                <Stack spacing={1} sx={{ textAlign: "center" }}>
                    <Typography variant="h2">Ticket #{ticketInformation.id.slice(-7)}</Typography>
                    <Typography variant='subtitle2'>Author: {ticketInformation.author}</Typography>
                    <Typography>Created At: {new Date(ticketInformation.createdAt).toLocaleTimeString() + " " + new Date(ticketInformation.createdAt).toLocaleDateString()}</Typography>
                    <Divider />
                    <Stack sx={{
                        m: '.7rem 0'
                    }} direction="row" flexWrap={'wrap'} justifyContent={'center'}>
                        <Stack direction="row" alignItems={'center'}>
                            <Typography>Status: </Typography>
                            <Box>
                                <TicketStatusComponent status={ticketInformation.status} editable={canEdit} onChange={(c) => {
                                    UpdateStatus(c)

                                }} />
                            </Box>
                        </Stack>
                        <Assignees canEdit={canEdit} id={ticketInformation.id} onChange={() => refetch()} />
                        {headerItems.map((i, index) => (
                            <Typography key={i.uid}>{i.name}: {i.value}</Typography>
                        ))}
                    </Stack>
                </Stack>
            ) : (

                <TwoSide>
                    <Box>
                        <Stack direction={"row"} spacing={1.5} divider={<Divider flexItem orientation='vertical' />}>

                            <Box>
                                <img src={"https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=" + window.location.protocol + "//" + window.location.host + "/org/ticket/" + ticketInformation.id} alt="QR CODE" />
                            </Box>
                            <Box>
                                <Typography variant="h1">Ticket #{ticketInformation.id.slice(-7)}</Typography>
                                <Typography variant='subtitle2'>Author: {ticketInformation.author}</Typography>
                            </Box>
                        </Stack>
                    </Box>
                    <Stack direction="column" spacing={1}>
                        <Typography>Created At: {new Date(ticketInformation.createdAt).toLocaleTimeString() + " " + new Date(ticketInformation.createdAt).toLocaleDateString()}</Typography>
                        <Stack direction="row" alignItems={'center'}>
                            <Typography>Status: </Typography>
                            <Box>

                                <TicketStatusComponent status={ticketInformation.status} editable={canEdit} onChange={(c) => {
                                    UpdateStatus(c)
                                }} />
                            </Box>
                        </Stack>
                        <Assignees canEdit={canEdit} id={ticketInformation.id} />
                        {headerItems.map((i, index) => (
                            <Typography key={i.uid}>{i.name}: {i.value}</Typography>
                        ))}
                    </Stack>
                </TwoSide>
            )}
            <Divider />
            {printMode && (
                <Grid2 container spacing={1}>
                    {gridItems.map((item, index) => {
                        if (item.type == "title") {
                            return (
                                <Grid2 key={item.uid} xs={12}>
                                    <Typography variant='h2'>{item.name}</Typography>
                                </Grid2>
                            )
                        }
                        if (item.type == "divider") {
                            return (
                                <Grid2 key={item.uid} xs={12}>
                                    <Divider sx={{ margin: '1rem 0' }} />
                                </Grid2>
                            )
                        }
                        if (item.type == "field") {
                            return (
                                <Grid2 key={item.uid} xs={item.xs || 6}>
                                    <DataField data={item.value} title={item.name} width={"100%"} />
                                </Grid2>
                            )
                        }
                        return <></>
                    })}
                </Grid2>
            )}
            {!printMode && (

                <Grid2 container spacing={1}>
                    {gridItems.map((item, index) => {
                        if (item.type == "title") {
                            return (
                                <Grid2 key={item.uid} xs={12}>
                                    <Typography variant='h2'>{item.name}</Typography>
                                </Grid2>
                            )
                        }
                        if (item.type == "divider") {
                            return (
                                <Grid2 key={item.uid} xs={12}>
                                    <Divider sx={{ margin: '1rem 0' }} />
                                </Grid2>
                            )
                        }
                        if (item.type == "field") {
                            if (item.editable && item.getter == "devices") {
                                return (
                                    <Grid2 key={item.uid} xs={item.xs || 6}>
                                        <DeviceEditableDisplay title={item.name} device={item.value} onUpdate={(e) => {
                                            updateValue(index, `Serial Number: ${e.serialNumber}. Annotated Asset Id: ${e.annotatedAssetId}. Model: ${e.model}`)
                                        }} canViewDevice={canViewDevice} />
                                    </Grid2>
                                )
                            }

                            if (item.editable && item.getter == "students") {
                                return (
                                    <Grid2 key={item.uid} xs={item.xs || 6}>
                                        <StudentDeviceEditableDisplay title={item.name} student={item.value} onUpdate={(e) => {
                                            updateStudent(index, e);
                                        }} canViewStudent={canViewStudent} />
                                    </Grid2>
                                )
                            }
                            return (
                                <Grid2 key={item.uid} xs={item.xs || 6}>
                                    {(item.editable && (canEdit)) && (
                                        <DataEditable width={'100%'} title={item.name} data={item.value} callback={(val) => { updateValue(index, val) }} />
                                    )}
                                    {!item.editable && (
                                        <DataField padding='.5rem 2rem .5rem 1rem' data={item.value} title={item.name} width={"100%"} />
                                    )}
                                </Grid2>
                            )
                        }
                        return <></>
                    })}
                </Grid2>
            )}

        </Paper>
    )
}

export default CustomTicketLayout