import { Box, Typography, Divider, useTheme, Avatar, CardContent, Card, Skeleton } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Loading from 'components/Loading';
import ViewInProviderButton from 'components/ViewInProviderButton';
import DataField from 'components/data/DataField';
import DataOutlined from 'components/data/DataOutlined';
import TwoSide from 'components/data/TwoSide';
import React from 'react'
import { useParams } from 'react-router-dom';
import { useGetDataProviderUserQuery } from 'state/api';

function UserInformation({user, isLoading}) {
    const theme = useTheme();
    if(isLoading) {
        <Card>
            <CardContent>
                <Skeleton height="20vh"/>
            </CardContent>
        </Card>
    }

      
  return (
    <Card sx={{height: '100%'}}>
        <CardContent>
            <Grid2 container padding={0} spacing={0}>
                <Grid2 xs={2}>
                    <Avatar alt={user.name} src={user.thumbnailPhotoUrl}/>
                </Grid2>
                <Grid2 xs={10}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Account Information</Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>{user.primaryEmail}</Typography>
                </Grid2>
            </Grid2>
            <Divider sx={{mb: '1rem'}}/>

            <DataField compact width={"100%"} title="Name" data={user.name} />
            <DataField title="Primary Email" data={user.primaryEmail} compact width={"100%"}/>
            <DataField title="Name" data={user.name} compact width={"100%"}/>

            <Grid2 container spacing={1} padding={0}>
                <Grid2 xs={6}>
                    <DataField title="Given Name" data={user.givenName} compact width={"100%"}/>
                </Grid2>
                <Grid2 xs={6}>
                    <DataField title="Family Name" data={user.familyName} compact width={"100%"}/>
                </Grid2>
            </Grid2>
            <DataField title="Org Unit" data={user.orgUnitPath} compact width={"100%"} />
            <ViewInProviderButton type="user" id={user.id} />

        </CardContent>
    </Card>
  )
}

export default UserInformation