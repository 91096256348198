import { Slide } from '@mui/material';
import React from 'react'

//@ts-ignore
function Transition(props) {
  return (
    <Slide {...props} direction="right" />
  )
}

export default Transition