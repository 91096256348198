import { Box, Button, Dialog, DialogActions, DialogContent, Divider, Skeleton, Typography } from '@mui/material';
import DeviceAutocomplete from 'components/Autocomplete/DeviceAutocomplete';
import OUPathAutocomplete from 'components/Autocomplete/OUPathAutocomplete';
import SnackbarHandler from 'components/SnackbarHandler';
import React, { useEffect, useState } from 'react'
import FieldChange from 'scenes/CreateStudent/FieldChange';
import { useGetDataProviderUserQuery, useGetLinkProviderSettingsQuery, useGetUserQuery } from 'state/api';

interface DeprovisionStudent {
    open: boolean;
    setOpen: (boolean) => void;
    studentInformation: any;
}
const DeprovisionStudent:React.FC<DeprovisionStudent> = ({open, setOpen, studentInformation}) => {
    const {data, isLoading, error} = useGetLinkProviderSettingsQuery("");
    const {data: user, isLoading:userLoading, error: userError} =  useGetDataProviderUserQuery(studentInformation.accountId);
    const [newDevice, setNewDevice] = useState(null)
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [newOU, setNewOU] = useState("");
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: ""
    })
    const getField = (field, obj) => {
        var fieldToCamel = field.charAt(0).toLowerCase() + field.slice(1);

        return obj[fieldToCamel]
    }

    useEffect(() => {
        if(data != null) {
            setNewOU(data.decommissionOu)
        }
    }, [data])
    
    const deprovisionStudent = async (newDevice) => {
        setBackdropOpen(true)
        try {
            var mode = newDevice == null ? "deprovision" : "re-assign";
            var queryString = newDevice == null ? "" : "&device=" + newDevice;
            const response = await fetch(process.env.REACT_APP_BASE_URL + "/org/students" + studentInformation.id + "/update?mode=" + mode + queryString, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                method: "POST"
            })
    
            if(response.status == 200) {
                var snackbar = {
                    open: true,
                    message: "Success! The studnent has been updated",
                    severity: "success"
                }
    
                setSnackbar(snackbar)
            } else {
                var snackbar = {
                    open: true,
                    message: "Uh Oh! There was a problem updating the student",
                    severity: "error"
                }
    
                setSnackbar(snackbar)
            }
    
        }catch (e) {
            var snackbar = {
                open: true,
                message: "Uh Oh! There was a problem updating the student",
                severity: "error"
            }

            setSnackbar(snackbar)
        
        }

        setBackdropOpen(false)
    }

    return (
        <Box>
            {/**@ts-ignore */}
            <SnackbarHandler open={snackbar.open} message={snackbar.message} severity={snackbar.severity}/>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogContent>
                    <Typography variant="h3">Deprovision/Re-Assign Student</Typography>
                    <Divider sx={{mb:'1rem'}}/>
                    {userLoading || isLoading  ? (
                        <Skeleton/>
                    ) : (
                        <>       
                            <Box>
                                <DeviceAutocomplete value={newDevice} freeSolo onValueChange={(v) => setNewDevice(v)}  label='Assign new Device (leave blank if deprovisioning)'/>
                            </Box>
                            {newDevice != null ? (
                                <Box>
                                    <Divider sx={{m: '1rem 0 .5rem 0'}}/>
                                    <Typography>Assign <b>{studentInformation.name}</b> to <b>{newDevice.serialNumber}</b>?</Typography>
                                    <Divider/>
                                    <Typography sx={{m: '1rem 0 0 0'}} variant="h5">Configuration Changes</Typography>
                                    <Box>
                                        <FieldChange 
                                            disabled
                                            label={data.authorityField}  
                                            value={true} setValue={() => {}} 
                                            baseValue={getField(data.authorityField, newDevice)} 
                                            changedValue={getField(data.receiverField, user)}/>

                                        <FieldChange 
                                            disabled
                                            label={"Org Unit Path"}  
                                            value={true} setValue={() => {}} 
                                            baseValue={newDevice.orgUnitPath} 
                                            changedValue={newOU}/>
                                    </Box>
                                    <Divider sx={{mb: '1rem'}} />
                                    <Typography>
                                        Warning, you are about to assign <b>{studentInformation.name}</b> to <b>{newDevice.serialNumber}</b> This will change the {data.authorityField} field in the device.
                                        The old device with be moved to <b>{newOU}</b>
                                    </Typography>
                                </Box>
                            ) : (
                                <Box>
                                    <Divider sx={{m: '1rem 0 .5rem 0'}}/>
                                    <Typography>Deprovision <b>{studentInformation.name}?</b> Warning this will remove the data from the {data.authorityField} field in the device and move the device to <b>{newOU}</b></Typography>
                                </Box>
                            )}
                        </>
                    )}
                    <Box sx={{mt: '1rem'}}>

                        <OUPathAutocomplete 
                            label="Decomission OU"
                            onValueChange={(v) => setNewOU(v)}
                            initialValue={newOU}
                            fullWidth
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='error' onClick={() => setOpen(false)}>Cancel</Button>
                    <Button variant='outlined' color='warning'>{newDevice == null ? "Deprovision Student" : "Re-Assign Student"}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default DeprovisionStudent