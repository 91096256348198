import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    Skeleton,
    TextField,
    Typography
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import React, {useEffect, useState} from 'react'
import {useGetCheckinDocumentByDeviceQuery, useGetDeviceQuery} from 'state/api'
import DeviceInfoCard from './DeviceInfoCard'
import LazyTextField from 'components/data/LazyTextField'
import * as yup from "yup"
import {useFormik} from 'formik'
import {PublishOutlined, Save} from '@mui/icons-material'
import LazyLoadImage from 'components/data/LazyLoadImage'
import RequiresScope from 'components/RequiresScope'
import StudentGroupsCard from '../other/StudentGroupsCard'
import {getCurrentYear} from 'scenes/checkin/DeviceCheckinOverview'
import StudentInformationCard from '../student/StudentInformationCard'
import CustomNoRowsOverlay from "../../../components/CustomNoRowsOverlay";
import SnackbarHandler from "../../../components/SnackbarHandler";

interface ImagesForm {
    onChange: () => {}
    onAdded: (success: boolean) => void;
    id: string;
}

const ImagesForm: React.FC<ImagesForm> = ({onChange, id, onAdded}) => {
    const [file, setFile] = useState(null);
    const validationSchema = yup.object({
        title: yup.string().min(4, "Please enter at least 4 characters").required("Title is Required").max(50, "Max of 50 characters"),
        description: yup.string().max(200, "Max of 200 characters"),
    });

    const defaultValues = {
        title: "",
        description: ""
    }

    const submit = async (values) => {
        const base = await getBase64(file as File);


        //@ts-ignore
        const resizedBase = await resizeImage(base, 800, 800); // 4:3 aspect ratio

        values.image = resizedBase;

        const response = await fetch(process.env.REACT_APP_BASE_URL + `/org/checkin/${id}/images`, {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({title: values.title, description: values.description, image: values.image})
        })

        onAdded(response.status === 200);
        onChange();


    }

    const formik = useFormik({
        initialValues: defaultValues,
        validationSchema: validationSchema,
        isInitialValid: false,
        onSubmit: submit,
    });


    const getBase64 = (file: File) => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                //@ts-ignore
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const resizeImage = async (base64: string, targetWidth: number, targetHeight: number) => {
        return new Promise<string>((resolve) => {
            const img = new Image();
            img.src = base64;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d')!;

                canvas.width = targetWidth;
                canvas.height = targetHeight;

                ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

                const resizedBase64 = canvas.toDataURL('image/jpeg', 0.8); // Adjust quality as needed
                resolve(resizedBase64);
            };
        });
    };

    return (
        <Box>
            <form onSubmit={formik.handleSubmit}>
                <Grid2 container spacing={2} alignItems={"center"}>
                    <Grid2 xs={12} md={4}>
                        <TextField
                            fullWidth
                            id="title"
                            name="title"
                            label="Title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            color="secondary"
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            /**@ts-ignore */
                            helperText={formik.touched.title && formik.errors.title}
                        />
                    </Grid2>
                    <Grid2 xs={12} md={4}>
                        <TextField
                            fullWidth
                            id="description"
                            name="description"
                            label="Description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            color="secondary"
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            /**@ts-ignore */
                            helperText={formik.touched.description && formik.errors.description}
                        />
                    </Grid2>
                    <Grid2 xs={12} md={3}>
                        <Box sx={{width: '100%'}}>
                            <input
                                hidden
                                accept="image/*"
                                id="file-input"
                                type="file"
                                onChange={(e) => {
                                    //@ts-ignore
                                    setFile(e.currentTarget.files[0]);
                                }}/>
                            <label htmlFor="file-input">
                                <TextField
                                    fullWidth
                                    label="Image"
                                    color="secondary"
                                    disabled
                                    //@ts-ignore
                                    value={file?.name || ""}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton aria-label="upload" component="span">
                                                <PublishOutlined/>
                                            </IconButton>
                                        ),
                                    }}/>
                            </label>

                        </Box>
                    </Grid2>
                    <Grid2 xs={12} md={1}>
                        <Box sx={{display: 'flex', flexWrap: "wrap", justifyContent: 'center', alignItems: 'center'}}>
                            <Button fullWidth type="submit" variant="contained" color="success"
                                    disabled={!formik.isValid || file == null} endIcon={<Save/>}>
                                Save
                            </Button>
                        </Box>
                    </Grid2>
                </Grid2>
            </form>
        </Box>
    )
}

interface CheckinImages {
    data: any;
    refetch: any;
    onImageAdded?: (success: boolean) => void;
    onImageRemoved?: (success: boolean) => void;
}

export const CheckinImages: React.FC<CheckinImages> = ({data, refetch, onImageAdded, onImageRemoved}) => {

    const [snackbarData, setSnackbarData] = useState({
        open: false,
        severity: "success",
        message: "Image added Successfully!"
    })
    const onDelete = async (id) => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + `/org/checkin/${data.id}/images/${id}`, {
            method: "DELETE",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
        })

        if (onImageRemoved)
            onImageRemoved(response.status === 200)
        else
            refetch()
    }

    const imageAddedCallback = (success: boolean) => {
        if (success) {
            setSnackbarData({open: true, severity: "success", message: "Image added Successfully!"})
        } else {
            setSnackbarData({open: true, severity: "error", message: "Error adding image!"})
        }

        if(onImageAdded)
            onImageAdded(success)
        else
            refetch()
    }

    return (
        <Grid2 xs={12} md={12}>
            <Card>
                <CardContent>
                    <SnackbarHandler open={snackbarData.open} onClose={() => setSnackbarData({
                        open: false, severity: "success", message: "Image added Successfully!"
                    })} message={snackbarData.message}
                                     //@ts-ignore
                                     severity={snackbarData.severity}/>
                    <Typography variant={'h5'}>Images From Checkin</Typography>
                    <Divider/>

                    {/** Display */}
                    <Grid2 container>
                        {data.images.map((image, index) => (
                            <Grid2 key={image} md={6} xs={12}>
                                <LazyLoadImage onDelete={onDelete} id={image}
                                               deleteScope={"write:device:checkin:images"}/>
                            </Grid2>
                        ))}

                        {/** If no images, display custom overlay in the center */}
                        {data.images.length == 0 && (
                            <Grid2 xs={12} md={12} sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <CustomNoRowsOverlay header={"No images"}/>
                            </Grid2>
                        )}
                    </Grid2>

                    {/** Form */}
                    <RequiresScope scope="write:device:checkin:images">
                        <Box>
                            <Divider sx={{mt: '2rem'}}/>
                            <Typography variant={"h5"}>Add image</Typography>
                            <ImagesForm id={data.id} onChange={refetch} onAdded={imageAddedCallback}/>
                        </Box>
                    </RequiresScope>
                </CardContent>
            </Card>
        </Grid2>
    )
}

interface CheckDeviceIn {
    device: any;
    displayAdditionalInfo?: boolean;
    focusedStatusUpdated?: (status: boolean) => void;
    year?: string;
}

const CheckDeviceIn: React.FC<CheckDeviceIn> = ({device, displayAdditionalInfo = false, focusedStatusUpdated, year = getCurrentYear()}) => {

    const [checkinData, setCheckinData] = useState({
        device: false,
        charger: false,
        case: false,
        notes: "",
        images: [],
        id: device.serialNumber,
        student: ""
    })
    const {data, isLoading, error, refetch} = useGetCheckinDocumentByDeviceQuery({
        id: device?.serialNumber ?? "",
        year: year
    })
    const updateCheckin = async (type, value) => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + `/org/checkin/device/update?deviceId=${device.serialNumber}&type=${type}&value=${value}`, {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        if (response.status == 200) {
            refetch()
        }
    }

    const updateNotes = async (value) => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + `/org/checkin/${device.serialNumber}/notes?notes=${value}`, {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        if (response.status == 200) {
            refetch()
        }
    }

    useEffect(() => {
        if (data && data.length > 0) {
            var d = data[0]
            setCheckinData({
                device: d.returnedDevice,
                charger: d.returnedCharger,
                case: d.returnedCase,
                student: d.studentName,
                notes: d.notes,
                images: d.images,
                id: device.serialNumber
            })
        } else {
            setCheckinData({
                device: false,
                charger: false,
                case: false,
                notes: "",
                images: [],
                id: device.serialNumber,
                student: ""
            })
        }
    }, [data])

    useEffect(() => {
        refetch()
    }, [device, year])


    if (typeof device == "string") {
        return (
            <CheckDeviceInWithSerialNumber focusedStatusUpdated={focusedStatusUpdated} device={device} displayAdditionalInfo={displayAdditionalInfo} year={year}/>)
    }


    return (
        <Box sx={{mt: '1rem'}}>
            <Grid2 container spacing={1}>
                <Grid2 xs={12} md={12}>
                    <Card>
                        <CardContent>
                            <Typography variant={'h4'}>Check device In</Typography>
                            <Typography sx={{fontSize: 12}} color="text.secondary"
                                        gutterBottom>{device.serialNumber}</Typography>
                            <Divider/>
                            <Grid2 sx={{mt: '1rem'}} container>
                                <Grid2 xs={12} md={2}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={checkinData.device}
                                                                             onChange={(e, v) => updateCheckin("device", v)}
                                                                             color='secondary'/>}
                                                          label="Returned Device?"/>
                                        <FormControlLabel control={<Checkbox checked={checkinData.charger}
                                                                             onChange={(e, v) => updateCheckin("charger", v)}
                                                                             color='secondary'/>}
                                                          label="Returned Charger?"/>
                                        <FormControlLabel control={<Checkbox checked={checkinData.case}
                                                                             onChange={(e, v) => updateCheckin("case", v)}
                                                                             color='secondary'/>}
                                                          label="Returned Case?"/>
                                    </FormGroup>
                                </Grid2>
                                <Grid2 xs={12} md={10}>
                                    <LazyTextField
                                        value={checkinData.notes}
                                        label='Notes'
                                        multiline
                                        multilineLevels={4}
                                        focusStatusUpdated={focusedStatusUpdated}
                                        onChange={(v) => {
                                            updateNotes(v);
                                            focusedStatusUpdated(false)
                                        }}
                                    />
                                </Grid2>

                            </Grid2>
                        </CardContent>
                    </Card>
                </Grid2>
                {checkinData && (
                    <CheckinImages data={checkinData} refetch={refetch}/>
                )}
                {displayAdditionalInfo && (
                    <>
                        <Grid2 xs={12} md={6}>
                            <DeviceInfoCard device={device} isLoading={false}/>
                        </Grid2>
                        <Grid2 xs={12} md={6}>
                            <StudentInformationCard view id={checkinData.student || device.serialNumber}/>
                        </Grid2>
                        <StudentGroupsCard xs={12} md={12} id={device.serialNumber}/>
                    </>
                )}

            </Grid2>
        </Box>
    )
}


interface CheckDeviceIn {
    device: any;
    displayAdditionalInfo?: boolean;
}

const CheckDeviceInWithSerialNumber = ({device,focusedStatusUpdated, displayAdditionalInfo = false, year = getCurrentYear()}) => {
    //Load device
    const {data, isLoading, error, refetch} = useGetDeviceQuery(device);

    if (data != null) {
        console.log(data)
        return (<CheckDeviceIn focusedStatusUpdated={focusedStatusUpdated} device={{...data.device}} displayAdditionalInfo={displayAdditionalInfo} year={year}/>)
    }

    if (error) {
        return (<></>)
    }


    return (
        <>
            <Skeleton variant={"rectangular"} height={500}/>
        </>
    )
}

export default CheckDeviceIn