import React from 'react'
import {ResponsivePie} from "@nivo/pie";
import {Theme, useTheme} from "@mui/material";

function PieChart({input}) {
    const theme = useTheme()
    return (
        <ResponsivePie
            data={input}
            margin={
                {top: 40, right: 80, bottom: 100, left: 50}
            }
            sortByValue={true}
            innerRadius={0.45}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsTextColor={theme.palette.secondary[200]}
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{from: "color"}}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
            }}
            theme={convertMuiThemeToNivoTheme(theme)}
        />
    )
}


export const convertMuiThemeToNivoTheme = (muiTheme: Theme) => {
    return {
        textColor: muiTheme.palette.text.primary,
        fontFamily: muiTheme.typography.fontFamily,
        fontSize: muiTheme.typography.fontSize,
        axis: {
            domain: {
                line: {
                    stroke: muiTheme.palette.text.primary,
                    strokeWidth: 1,
                },
            },
            ticks: {
                line: {
                    stroke: muiTheme.palette.text.primary,
                    strokeWidth: 1,
                },
                text: {
                    fill: muiTheme.palette.text.primary,
                    fontSize: muiTheme.typography.fontSize,
                },
            },
        },
        grid: {
            line: {
                stroke: muiTheme.palette.action.hover,
                strokeWidth: 1,
            },
        },
        legends: {
            text: {
                fill: muiTheme.palette.text.primary,
                fontSize: muiTheme.typography.fontSize,
                fontWeight: muiTheme.typography.fontWeightRegular,
            },
        },
        tooltip: {
            container: {
                background: muiTheme.palette.background.paper,
                color: muiTheme.palette.text.primary,
                fontSize: muiTheme.typography.fontSize,
                borderRadius: '4px',
                boxShadow: `0 0 10px ${muiTheme.palette.divider}`,
            },
        },
    };
};





export default PieChart