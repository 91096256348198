import { Box, Button, Divider, Grow, IconButton, MenuItem, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import FieldEditable from './forms/FieldEditable';
import DataEditable from 'components/data/DataEditable';
import TwoSide from 'components/data/TwoSide';
import { ArrowDropDown, ArrowDropUp, DeleteForever } from '@mui/icons-material';
import { useGetFormQuery } from 'state/api';
import Loading from 'components/Loading';

export interface SelectOption {
  label: string;
  uid: number;
  offset: number;
}
export interface Field {
  id: number;
  step: number;
  label: string;
  selectOptions?: SelectOption[]
  type: string;
  index: number;
  additionalOptions: any[];
  specialType: string;
}
interface KeyValue {
  key: string;
  value: any;
}

export interface SavableField {
  step: number;
  label: string;
  type: string;
  required: boolean;
  autofill: string | "none" | "studentDevice" | 'studentName' | 'studentEmail'
  select: KeyValue[]
  selectOptions?: any;
  getter: 'none' | 'students' | 'devices' | 'loaners'
  specialType: string;
}


function TicketFormSettings() {
  const {data, isLoading, error} = useGetFormQuery("");

  const [currentId, setCurrentId] = useState(0);
  const [fields, setFields] = useState([] as Field[])
  const [steps, setSteps] = useState(["Step 1"] as string[])
  const [currentStep, setCurrentStep] = useState(steps[0])
  const [option, setOption] = useState("text");

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    updateFieldSteps(steps);

  }, [steps])

  useEffect(() => {
    load();
  }, [data])

  const addField = () => {
    const getSpecialType = () => {
      if(option == 'text' || option == 'select') return '';
      return option;
    }
    var field: Field = {
      id: currentId,
      step: steps.indexOf(currentStep),
      label: option == 'loaner' ? "Loaner" : "Untitled Question",
      //@ts-ignore
      type: option,
      additionalOptions: [{ id: 'steps', value: steps }, {id: 'required', value: true}],
      specialType: getSpecialType(),
      index: 0
    }
    if(option == "loaner") {
      field.additionalOptions.push({id: 'required', value: true});
    }
    setCurrentId(currentId + 1);
    setFields([...fields, field])
  }

  const addStep = () => {
    
    var newStep = "Step " + String(steps.length + 1)

    var count = steps.length + 2;
    while(steps.includes(newStep)) {
      newStep = "Step " + String(count);
      ++count;
    }


    
    
    setSteps([...steps, newStep])

  }

  const deleteStep = (step) => {
    //Remove all fields with step
    setFields(fields.filter((field) => field.step != step))
    
    
    setSteps(steps.filter((s) => s != step))


  }

  const updateFieldSteps = (steps: string[]) => {
    //Update fields steps value 
    for(var i = 0; i < fields.length; i++) {
      var index = fields[i].additionalOptions.findIndex(v => v.id == "steps");
      if(i < 0) {
        fields[i].additionalOptions.push({id: 'steps', value: steps})
      } else {

        fields[i].additionalOptions[index].value = steps;
      }
    }
    setFields([...fields])
  }

  const deleteField = (id) => {
    setFields(fields.filter((field) => field.id != id))
  }

  const updateField = (id: number, label: string, additionalOptions: any[], selectOption: SelectOption[]) => {
    const updatedFields = fields.map((field) => {
      if (field.id === id) {
        return {
          ...field,
          label: label,
          selectOptions: selectOption,
          additionalOptions: additionalOptions,
        };
      }
      return field;
    });
  
    setFields([...updatedFields]);
  };
  
  

  const moveStepDown = (step) => {
    setFields([...fields.map((f) => {
      if(f.step == steps.indexOf(step)) {
        ++f.step;
        
      } else if(f.step - 1 == steps.indexOf(step)) {
        --f.step;
      }
      return f;

    })])
    setSteps([...swapWithNext(steps, step)])
  }

  const moveStepUp = (step) => {
    console.log(steps.indexOf(step))
    setFields([...fields.map((f) => {
      if(f.step == steps.indexOf(step)) {
        --f.step;
        
      } else if(f.step + 1 == steps.indexOf(step)) {
        console.log(f.step)
        ++f.step;
      }

      return f;
    })])

    setSteps([...swapWithPrev(steps, step)])
  }

  const updateFieldPosition = (id: number, positionalChange: 1 | -1) => {
    const field = fields.find((f) => f.id === id);
    if (field) {
      const updatedFields =
        positionalChange === 1 ? swapWithPrev(fields, field, true) : swapWithNext(fields, field, true);
      setFields([...updatedFields]);
    } 
  };
  

  function swapWithNext(array, target, id=false) {
    for (let i = 0; i < array.length - 1; i++) {
      if(id) {
        if (array[i].id === target.id) {
          const temp = array[i];
          array[i] = array[i + 1];
          array[i + 1] = temp;
          break;
        }
      } else{

        if (array[i] === target) {
          const temp = array[i];
          array[i] = array[i + 1];
          array[i + 1] = temp;
          break;
        }
      }
    }

    return array;
  }
  
  function swapWithPrev(array, target, id=false) {
    for (let i = 1; i < array.length; i++) {
      if(id) {
        if (array[i].id === target.id) {
          const temp = array[i];
          array[i] = array[i - 1];
          array[i - 1] = temp;
          break;
        }
      } else {
        if (array[i] === target) {
          const temp = array[i];
          array[i] = array[i - 1];
          array[i - 1] = temp;
          break;
        }

      }
    }
    return array;
  }
  
  const load = () => {
    if(!isLoading && data != null) {
      setSteps(data.steps);

      var fields = data.fields;

      var fset: Field[] = []

      const ConvertField = (field: SavableField, id: number) => {
        const getAdditionalOptions = (): any[] => {
          var options = [];
          options.push({id: 'required', value: field.required})
          if(field.type == 'select') {
            options.push({ id: 'selectOption', value: [...field.selectOptions.map((opt) => {
              return {label: opt.label, offset: opt.offset, uid: Math.random()}
            })] });
          }
          
          if(field.autofill != '') {
            options.push({id: 'autofill', value: field.autofill})
          }
          options.push({id: 'steps', value: data.steps})
          return options;
        }

        const mapSelectOptions = () => {
          return field.selectOptions.map((opt) => {
            return {label: opt.label, offset: opt.offset, uid: Math.random()}
          })
        }
        return {
          id: id,
          step: field.step,
          label: field.label,
          selectOptions: [...mapSelectOptions()],
          type: field.specialType || field.type,
          index: currentId,
          additionalOptions: [...getAdditionalOptions()],
          specialType: field.specialType
        }
      }


      var id = 0;
      fields.forEach((field) => {
        console.log(field)
        console.log(ConvertField(field, id))
        fset.push(ConvertField(field, id))
        ++id;
      })

    
      //Remove first one because it is the student field
      fset.splice(0, 1)

      console.log(fset)

      setFields(fset)

      setIsDataLoaded(true)
    }
  }

  const save = () => {
    var fieldset: SavableField[] = [];

    fieldset.push({
      required: true,
      getter: 'students',
      autofill: 'none',
      step: 0,
      label: 'Student Information',
      type: 'autocomplete',
      select: [],
      specialType: 'student'
    })
    fields.forEach(field => {
      fieldset.push(convertFieldToJson(field))
    })

    var data = {
      steps: steps,
      fields: fieldset
    }

    console.log(data)
    upload(data)
  }

  const convertFieldToJson = (field: Field): SavableField => {

    const convertTypeToString = (type: string): string => {
      switch(type) {
        case "student":
        case "loaner":
        case "device":
          return "autocomplete"
        default:
          return type
      }
    }

    const getIsRequired = (options) => {
      var opt = options.find(v => v.id == "required")
      if(opt == undefined) return false;
      return opt.value;
    }

    const getSelectOptions = () => {
      if(field.type != 'select') return [];
      var opt = field.selectOptions;
      return opt.map(v => {return {label: v.label, offset: v.offset, value: v.label}});

    }
 
    const getGetter = () => {

      switch(field.type) {
        case "student":
          return 'students'
        case "loaner":
          return 'loaners'
        case "device":
          return 'devices'
        default:
          return 'none'
      }

    }
    const getAutofill = () => {
      if(field.additionalOptions == undefined) return ''
      var option = field.additionalOptions.find(v => v.id == 'autofill')
      if(option == undefined) return ''
      return option.value
    }

    return {
      step: field.step,
      label: field.label,
      type: convertTypeToString(field.type),
      required: getIsRequired(field.additionalOptions || []),
      autofill: getAutofill(),
      //@ts-ignore
      select: getSelectOptions(),
      getter: getGetter(),
      specialType: field.specialType
    }
  }


  const upload = (body) => {
    fetch(process.env.REACT_APP_BASE_URL + "/org/tickets/form", {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: {
          'Content-Type': 'application/json'
      },
      credentials: "include"
      }).then(async function(response) {
      }).catch( (reason) => {
      })
  }

  if(!isDataLoaded) return (<Loading/>)

  return (
    <Box sx={{mt: '1rem'}}>
      <Typography variant="h3">Ticket Settings</Typography>
      <Typography variant='subtitle2'>Customize your Ticket Form</Typography>
      <Divider sx={{mt: '1rem'}}/>

      <Box>
        {/**Display the current fields */}
        {steps.map((step, index) => (
          <Grow key={step} in={steps.includes(step)} timeout={1000}> 
            <Box
              sx={{
                mt: '1rem',
                //@ts-ignore
                backgroundColor:theme.palette.background.alt,
                borderRadius:"0.55rem",
                padding: '1rem',
                mb: '1rem'
              }}
            
              display="flex"
              flexDirection="column"
              flexWrap="nowrap"
              justifyContent="flex-start"
              flex='1'
            
            >
              <TwoSide key={index}>

                <DataEditable key={index} data={step} variant="h4" callback={(value) => {
                  setSteps(steps.map((s) => s == step ? value : s))
                }} />
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row'
                }}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap'
                  }}>
                    <IconButton disabled={index == 0} onClick={() => moveStepUp(step)} size="small">
                      <ArrowDropUp/>
                    </IconButton>
                    <IconButton disabled={index == steps.length - 1} onClick={() => moveStepDown(step)} size="small">
                      <ArrowDropDown/>
                    </IconButton>
                  </Box>

                  <IconButton color='error' onClick={() => {
                    deleteStep(step);
                  }}>
                    <DeleteForever/>
                  </IconButton>
                </Box>
              </TwoSide>
              <Divider/>

              {index == 0 && (
                <FieldEditable step={0} additionalOptions={[]} label={"Student Infromation"} type="student" id={-1} index={index} onPositionChange={() => {}} onUpdate={() => {}} onDelete={() => {}}/>
              )}
              
              {fields.map((field, i) => {
                if(field.step == index) {
                  return <FieldEditable selectOptions={field.selectOptions} step={index} key={field.id} label={field.label} type={field.type} id={field.id} index={i} onPositionChange={updateFieldPosition} additionalOptions={field.additionalOptions} onUpdate={updateField} onDelete={deleteField}/> 
                }
              })}
            </Box>
          </Grow>

        ))}

        {/**Add options */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          justifyContent: 'center'
          
        }}>
          <Box>
            <TextField
                label="Step to add To"
                select
                name="set-step"
                value={currentStep}
                onChange={(v) => {setCurrentStep(v.target.value)}}
                fullWidth
                color="secondary"
                sx={{m: '1rem 0'}}
              >
                {steps.map((step) => (<MenuItem value={step}>{step}</MenuItem>))}
              </TextField>
              <TextField
                label="Add Option"
                select
                name="add-option"
                defaultValue={option}
                onChange={(v) => {setOption(v.target.value)}}
                fullWidth
                sx={{m: '1rem 0'}}
                color="secondary"

              >
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="select">Select</MenuItem>
                <MenuItem value="loaner">Loaner Input</MenuItem>
                <MenuItem value="device">Device Input</MenuItem>
                <MenuItem value="studentInput">Student Input</MenuItem>
              </TextField>

          </Box>
          <Button sx={{mt: '1rem'}} disabled={steps.length == 0} variant="contained" onClick={addField}>Add Field</Button>
          <Button sx={{mt: '1rem'}}  variant="contained" onClick={addStep}>Add Step</Button>
          <Button sx={{mt: '2rem', mb: '2rem'}}  variant="contained" color="secondary" onClick={save}>Save</Button>
        </Box>
      </Box>
    </Box>

  )
}

export default TicketFormSettings