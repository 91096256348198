import React from 'react';
import {useMemo} from "react";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {themeSettings} from "theme";
import { useSelector } from 'react-redux';
import { BrowserRouter, useParams } from 'react-router-dom';
import { Navigate, Route, Routes } from 'react-router';
import Dashboard from "scenes/dashboard";
import Layout from "scenes/layout";
import Login from "scenes/login";
import Students from "scenes/students"

import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PrivateRoute } from 'components/Authenticator';
import Users from 'scenes/users';
import CreateTicket from 'scenes/createTicket';
import Tickets from 'scenes/tickets';


import NotFound from 'components/NotFound';

import RequiresRoleAuthenticator from 'components/RequiresRoleAuthenticator';
import AdminSettings from 'scenes/admin/AdminSettings';

import DeviceCheckinOverview from 'scenes/checkin/DeviceCheckinOverview';
import CheckIn from 'scenes/checkin/CheckIn';
import PaymentPage from 'scenes/login/Checkout/PaymentPage';
import AdminAudits from 'scenes/admin/AdminAudits';
import LinkProviderSettings from 'scenes/admin/LinkProviderSettings';
import Settings from 'scenes/admin/Settings';
import AccountSettings from 'scenes/admin/AccountSettings';
import OrganizationSettings from 'scenes/admin/OrganizationSettings/OrganizationSettings';
import BillingSettings from 'scenes/admin/BillingSettings';
import DataExport from 'scenes/admin/DataExport';
import Version from 'components/Version';
import Test from 'Test';
import TicketFormSettings from 'scenes/admin/TicketFormSettings';
import Loading from 'components/Loading';
import TicketLayoutSettings from 'scenes/admin/TicketLayout/TicketLayoutSettings';
import TicketSettings from 'scenes/admin/TicketLayout/TicketSettings';
import TicketTableSettings from 'scenes/admin/TicketLayout/TicketTableSettings';
import User from 'scenes/data/user/User';
import UserInformation from 'scenes/data/user/UserInformation';
import UserSettings from 'scenes/data/user/UserSettings';
import Device from 'scenes/data/device/Device';
import Student from 'scenes/data/student/Student';
import StudentTickets from 'scenes/data/student/StudentTickets';
import StudentInformation from 'scenes/data/student/StudentInformation';
import TicketOverview from 'scenes/ticket/TicketOverview';
import TicketMessages from 'scenes/ticket/TicketMessages';
import TicketImages from 'scenes/ticket/TicketImages';
import TicketAudits from 'scenes/ticket/TicketAudits';
import TicketActions from 'scenes/ticket/TicketActions';
import SettingsActions from 'scenes/admin/SettingsActions';
import OrganizationSettingsNav from 'scenes/admin/OrganizationSettings/OrganizationSettingsNav';
import OrganizationRolesSettings from 'scenes/admin/OrganizationSettings/OrganizationRolesSettings';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Stats from 'scenes/stats/Stats';
import ScopeAuthenticator from 'components/ScopeAuthenticator';
import ExperimentalSettings from 'scenes/admin/ExperimentalSettings';
import SetupProvider from 'scenes/admin/SetupProvider';
import Ticket from 'scenes/ticket';
import Loaners from 'scenes/loaners';
import CreateStudent from 'scenes/CreateStudent';
import Security from 'scenes/admin/OrganizationSettings/Security';
import SearchOrganization from 'scenes/search';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from 'scenes/login/Checkout/PaymentForm';
import QrLogin from 'scenes/login/QrLogin';
import HomeLayout from 'scenes/home/HomeLayout';
import SecurityPolices from 'scenes/admin/OrganizationSettings/SecurityPolices';
import TryItOut from 'scenes/login/TryItOut';
import FrontPage from 'scenes/home/FrontPage';
import TicketStatusSettings from 'scenes/admin/TicketLayout/TicketStatusSettings';
import EventSettings from 'scenes/admin/OrganizationSettings/Events/EventSettings';
import ActionsSettings from 'scenes/admin/OrganizationSettings/Events/ActionsSettings';
import PrivacyPolicy from 'scenes/home/Terms/PrivacyPolicy';
import TermsOfService from 'scenes/home/Terms/TermsOfService';
import StudentGroups from 'scenes/studentGroups/StudentGroups';
import StudentGroupOverview from 'scenes/studentGroups/StudentGroupOverview';
import InventoryOverview from "./scenes/inventory/InventoryOverview";
import InventoryLocationsOverview from "./scenes/inventory/locations/InventoryLocationsOverview";
import InventoryLocation from "./scenes/inventory/locations/InventoryLocation";
import DevicesOverview from "./scenes/inventory/devices/DevicesOverview";
import InventoryDevice from "./scenes/inventory/devices/InventoryDevice";
import DocsHome from "./docs/DocsHome";
import DocsNotFound from "./docs/DocsNotFound";
import DocsHomeInfo from "./docs/DocsHomeInfo";
import DisplayDocs from "./docs/DisplayDocs";
const queryClient = new QueryClient()

function App() {

  const mode = useSelector((state: any) => state.global.mode);

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const clientId = "164453924771-9gn1fsa4oea13gm3kkqlsl9764an8567.apps.googleusercontent.com";
  const {id} = useParams();
  return (
    <GoogleOAuthProvider clientId={clientId}>
    <QueryClientProvider client={queryClient}>

    <div className='app'>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="/home" element={<HomeLayout/>}>
                <Route path={"/home"} element={<FrontPage/>}/>
              </Route>
              <Route path={"/docs"} element={<DocsHome/>}>
                <Route path={"/docs"} element={<Navigate to={"/docs/home"}/>}/>
                <Route path={"/docs/*"} element={<DisplayDocs/>}/>
              </Route>
              <Route path="/org" element={<PrivateRoute> <Layout /> </PrivateRoute> }>
                
                <Route path="/org" element={<Navigate to="/dashboard" replace />} />
                <Route path="/org/dashboard" element={<Dashboard/>} />
                <Route path="/org/setup" element={<DenyIfTestSuite><SetupProvider/></DenyIfTestSuite>} />
                <Route path="/org/search" element={<SearchOrganization/>} />

                <Route path="/org/loaners" element={<Loaners/>}/>
                <Route path="/org/students" element={<Students/>} />
                <Route path="/org/users" element={<Users/>} />
                <Route path="/org/ticket" element={<Tickets/>}></Route>
                <Route path="/org/stats" element={<Stats/>} />
                <Route path="/org/devices/returned/" element={<DeviceCheckinOverview/>}/>
                <Route path="/org/devices/returned/checkin" element={<CheckIn/>}/>

                <Route path="/org/ticket/:id" element={<Ticket/>}>
                  <Route path="/org/ticket/:id" element={<TicketOverview/>}/>
                  <Route path="/org/ticket/:id/overview" element={<TicketOverview/>}/>
                  <Route path="/org/ticket/:id/messages" element={<TicketMessages/>}/>
                  <Route path="/org/ticket/:id/images" element={<TicketImages/>}/>
                  <Route path="/org/ticket/:id/audits" element={<TicketAudits/>}/>
                  <Route path="/org/ticket/:id/actions" element={<TicketActions/>}/>
                </Route>

                <Route path="/org/settings" element={<Settings/>}>
                  <Route path="/org/settings" element={<Navigate to="/org/settings/account"/>}/>
                  <Route path="/org/settings/account" element={<AccountSettings/>}></Route>
                  <Route path="/org/settings/provider" element={<LinkProviderSettings/>}></Route>
                  <Route path="/org/settings/billing" element={<BillingSettings/>}></Route>
                  <Route path="/org/settings/export" element={<DataExport/>}></Route>
                  <Route path="/org/settings/audits" element={<AdminAudits/>} errorElement={<Loading/>}></Route>
                  <Route path="/org/settings/ticket" element={<TicketSettings/>}>
                    <Route path="/org/settings/ticket/table" element={<TicketTableSettings/>}/>
                    <Route path="/org/settings/ticket" element={<Navigate to="/org/settings/ticket/form"/>}/>
                    <Route path="/org/settings/ticket/form" element={<TicketFormSettings/>}></Route>
                    <Route path="/org/settings/ticket/layout" element={<TicketLayoutSettings/>}></Route>
                    <Route path="/org/settings/ticket/status" element={<TicketStatusSettings/>}></Route>
                  </Route>
                  <Route path="/org/settings/organization" element={<OrganizationSettingsNav/>}>
                    <Route path="/org/settings/organization/overview" element={<OrganizationSettings/>}></Route>
                    <Route path="/org/settings/organization" element={<Navigate to="/org/settings/organization/overview"/>}/>
                    <Route path="/org/settings/organization/groups" element={<OrganizationRolesSettings/>}/>
                    <Route path="/org/settings/organization/security" element={<SecurityPolices/>}/>
                    <Route path="/org/settings/organization/events" element={<EventSettings/>}/>
                    <Route path="/org/settings/organization/actions" element={<ActionsSettings/>}/>
                  </Route>
                  <Route path="/org/settings/actions" element={<SettingsActions/>}></Route>
                  <Route path="/org/settings/experimental" element={<ExperimentalSettings/>} />

                </Route>

                <Route path="/org/users/:id" element={<User/>} />

                <Route path="/org/devices/:id" element={<Device/>}>

                </Route>

                <Route path="/org/students/groups" element={<StudentGroups/>}></Route>
                <Route path="/org/students/groups/:id" element={<StudentGroupOverview/>}/>


                <Route path="/org/students/:id" element={<Student/>}>
                  <Route path="/org/students/:id/information" element={<StudentInformation/>}/>
                  <Route path="/org/students/:id" element={<StudentInformation/>}/>
                  <Route path="/org/students/:id/tickets" element={<StudentTickets/>}/>
                </Route>
                <Route path="/org/students/create" element={<CreateStudent/>}/>

                <Route path="/org/ticket/create" element={<CreateTicket/>}></Route>



                <Route path={"/org/inventory"}>
                  <Route path={"/org/inventory"} element={<InventoryOverview/>}/>
                  <Route path={"/org/inventory/devices"} element={<DevicesOverview/>}/>
                  <Route path={"/org/inventory/devices/:id"} element={<InventoryDevice/>}/>
                  <Route path={"/org/inventory/locations"} element={<InventoryLocationsOverview/>}/>
                  <Route path={"/org/inventory/locations/:id"} element={<InventoryLocation/>}/>
                </Route>

                <Route path="/org/*" element={<NotFound/>} />


              </Route>
              <Route path="*" element={<NotFound/>} />
              <Route path="/test_suite" element={<TryItOut/>} />
              <Route path="/test-suite" element={<TryItOut/>} />
              <Route path="/login" element={<Login />} />
              <Route path="/login/qr" element={<QrLogin />} />
              <Route path="/login/payment" element={<PaymentPage />} />
              <Route path="/test" element={<TicketStatusSettings/>} />

              <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
              <Route path="/terms-of-service" element={<TermsOfService/>}/>
            </Routes>
          </ThemeProvider>
        </LocalizationProvider>
      </BrowserRouter>
      <Version/>
    </div>
    </QueryClientProvider>
    </GoogleOAuthProvider>

);
}


/**
 * Redirects the user to the dashboard component if the organization is a testing suite organization is a testing suite organization
 * which means that the users domain is a gmail.com domain.
 * @param props Children
 * @returns Either navigates to the dashboard or returns the children of this component
 */
const DenyIfTestSuite = (props: { children: React.ReactNode }): JSX.Element => {
  const { children } = props
  const domain = useSelector((state: any) => state.global.domain);

  if (domain === 'gmail.com') {
    return <Navigate to="/org/dashboard" />;
  }

  return (
    <>{children}</>
  )
}


export default App;