import { HorizontalRule } from '@mui/icons-material';
import { Box, Divider, Grow, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers';
import TicketsCalander from 'components/Charts/TicketsCalander';
import UserCompletedTicketsPie from 'components/Charts/UserCompletedTicketsPie';
import UserCreatedTicketsPie from 'components/Charts/UserCreatedTicketsPie';
import WeekStats from 'components/Charts/WeekStats';
import Header from 'components/Header';
import SkeletonLoading from 'components/SkeletonLoading';
import LineChart from 'components/data/LineChart';
import TwoSide from 'components/data/TwoSide';
import dayjs from 'dayjs';
import React, { useState } from 'react'
import { start } from 'repl';

function Stats() {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

    const [endTime, setEndTime] = useState(dayjs())
    const [startTime, setStartTime] = useState(dayjs().subtract(1, 'week'))
    const [isLoading, setIsLoading] = useState(false);
    return (
      <Box m="1.5rem 2.5rem">
        <TwoSide>
            <Box>
               <Header title={"Org Statistics"} subtitle={"View your organizations statistics"}/>
            </Box>
            <Box>
                <Stack direction="row" spacing={3} alignItems={"center"}>
                    <DatePicker 
                        value={startTime}
                        onChange={(newValue) => setStartTime(newValue)}
                        label="Start Date" 
                    />

                    <HorizontalRule sx={{mt: 'auto'}}/>

                    <DatePicker 
                        value={endTime}
                        onChange={(newValue) => setEndTime(newValue)}
                        label="End Date" 
                    />
                </Stack>
            </Box>
        </TwoSide>

        <Box height={"100vh"}>
            <Grow in={true}>

                <Box
                    mt="20px"
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="200px"
                    gap="20px"
                    sx={{
                    "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
                    }}
                >
                    <Box
                        sx={{
                        //@ts-ignore
                        backgroundColor: theme.palette.background.alt
                        }}
                        p="1rem"
                        borderRadius="0.55rem"
                        gridRow={"span 2"} 
                        gridColumn={"span 12"}
                    >
                        <LineChart isDashboard={false} isBasis start={startTime.toDate()} end={endTime.toDate()}/>
                    </Box>
                    <Box
                    
                        sx={{
                        //@ts-ignore
                        backgroundColor: theme.palette.background.alt
                        }}
                        p="1rem"
                        borderRadius="0.55rem"
                        gridRow={"span 2"} 
                        gridColumn={"span 12"}
                    >
                        {/**Week Stats (average tickets per day, average amount completed) */}
                        <Typography>Average Tickets Created/Completed by day</Typography>
                        <WeekStats start={startTime.toDate()} end={endTime.toDate()}/>
                    </Box> 
                    <Box
                        sx={{
                        //@ts-ignore
                        backgroundColor: theme.palette.background.alt
                        }}
                        p="1rem"
                        borderRadius="0.55rem"
                        gridRow={"span 2"} 
                        gridColumn={"span 6"}                
                    >
                        <Typography>Tickets Completed By User</Typography>
                        <UserCompletedTicketsPie start={startTime.toDate()} end={endTime.toDate()}/>
                    </Box>

                    <Box
                        sx={{
                        //@ts-ignore
                        backgroundColor: theme.palette.background.alt
                        }}
                        p="1rem"
                        borderRadius="0.55rem"
                        gridRow={"span 2"} 
                        gridColumn={"span 6"}                
                    >
                        <Typography>Tickets Created By User</Typography>
                        <UserCreatedTicketsPie start={startTime.toDate()} end={endTime.toDate()}/>
                    </Box>
                    <Box
                        sx={{
                        //@ts-ignore
                        backgroundColor: theme.palette.background.alt
                        }}
                        p="1rem"
                        borderRadius="0.55rem"
                        gridRow={"span 2"} 
                        gridColumn={"span 12"}
                    >
                        <Typography>Created Tickets</Typography>
                        <TicketsCalander />
                    </Box>

                </Box>
            </Grow>
            {
            /** Statistics 
             * 
             * Use these stats: Tickets created, Tickets completed, a graph of that data. A calander representation of created tickets,
             * pie chart of who completed tickets, a pie chart of who created tickets, and possibly let user add other options
            */
            }

            {isLoading && (
                <Box>
                    <SkeletonLoading/>
                </Box>
            )}


        </Box>
      </Box>
    )
}

export default Stats