import {Box, Button, ButtonGroup, Card, CardContent, Typography} from '@mui/material'
import React from 'react'
import {useNavigate} from 'react-router-dom';
const FrontPage = () => {
    const navigate = useNavigate();
    return (
        <Box>
            {/**Main image */}
            <Card sx={{margin: '3rem auto', width: '80%'}}>
                <CardContent sx={{textAlign: 'center'}}>
                    <Typography variant={"h3"}>Welcome to CTickets!</Typography>
                    <Typography>The main page is still under construction.</Typography>

                    <ButtonGroup>
                        <Button color={"secondary"} variant="contained" onClick={() => navigate('/test-suite')}>Try it for free!</Button>
                        <Button color={"secondary"} variant="contained" onClick={() => navigate('/login')}>Login/Register</Button>
                    </ButtonGroup>
                </CardContent>
            </Card>
        </Box>
    )
}

export default FrontPage