import { VapingRooms } from '@mui/icons-material';
import { Checkbox, FormControl, InputLabel, ListItemText, ListSubheader, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react'


interface MultiSelect {
    options: any[];
    label: string;
    data: string[];
    //If header exsits on a string, it will remove it and set the header text to the text after it
    header?: string;
    onChange: (data: any[]) => void;
}
const MultiSelect: React.FC<MultiSelect> = ({options, label, data, onChange, header}) => {
    var currentHeader = "";
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    const handleChange = (event: SelectChangeEvent) => {
        const {
          target: { value },
        } = event;
         
        onChange(typeof value === 'string' ? value.split(',') : value);
    };
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <FormControl fullWidth>
            <InputLabel color="secondary" id={label}>{label}</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              color="secondary"
              //@ts-ignore
              value={data}
              onChange={handleChange}
              input={<OutlinedInput label="Tag" />}
              //@ts-ignore
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              fullWidth
            >
              {options.map((name: string) => {

                if(name.includes(header)) {
                  var headerName = capitalizeFirstLetter(name.replace(header, "").toLocaleLowerCase())
                  currentHeader = headerName;
                  return (
                    <ListSubheader>{headerName}</ListSubheader>
                  )
                }

                return (
                  <MenuItem key={currentHeader != "" ? currentHeader+ "_" + name : name } value={currentHeader != "" ? currentHeader+ "_" + name : name}>
                    <Checkbox color="secondary" checked={data.indexOf(currentHeader != "" ? currentHeader+ "_" + name : name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                )}
              )}
            </Select>
        </FormControl>
    )
}

export default MultiSelect