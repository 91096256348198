import { Person, Settings } from '@mui/icons-material';
import { Avatar, Box, Divider, Grow, Tab, Tabs, Typography } from '@mui/material'
import Loading from 'components/Loading';
import React, { useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useGetDataProviderUserQuery } from 'state/api';
import Error from "../../../components/Error"
import TwoSide from 'components/data/TwoSide';
import Grid2 from '@mui/material/Unstable_Grid2';
import UserInformation from './UserInformation';
import AccessedDevicesCard from './AccessedDevicesCard';
import UserAssignedStudentCard from './UserAssignedStudentCard';
import UserAssignedDevice from './UserAssignedDevice';
import NotesCard from '../NotesCard';
import StudentGroupsCard from '../other/StudentGroupsCard';
const option = [
    {
        name: 'Information',
        link: 'information',
        icon: <Person />
    },
    {
        name: 'settings',
        link: 'settings',
        icon: <Settings />
    }
]
function User() {
    const getEndpoint = (): string => {
        var points = window.location.pathname.split('/');
        return points[points.length - 1];
    }
    const [active, setActive] = useState(getEndpoint())
    const { id } = useParams();
    const { data, isLoading, error } = useGetDataProviderUserQuery(id)
    const navigate = useNavigate();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setActive(newValue);
        navigate(newValue);
    };
    if (isLoading) return (<Loading />)
    if (error) return <Error Message={"Could not find user " + id} />
    return (
        <Box m="1rem">
            <Typography variant='h2'>User Information: {data.primaryEmail}</Typography>
            <Divider sx={{ mb: '1rem' }} />
            <Grid2 container spacing={3}>
                <Grid2 xs={12} md={4}>
                    <Grow in={true}>
                        <Box height="100%">

                            <UserInformation user={data} isLoading={isLoading} />
                        </Box>
                    </Grow>
                </Grid2>
                <Grid2 xs={12} md={8}>
                    <Grow in={true}>
                        <Box height="100%">

                            <AccessedDevicesCard id={data.primaryEmail} />
                        </Box>
                    </Grow>
                </Grid2>
                <Grid2 xs={12} md={6}>
                    <Grow in={true}>
                        <Box height="100%">

                            <UserAssignedStudentCard id={data.primaryEmail} />
                        </Box>
                    </Grow>
                </Grid2>
                <Grid2 xs={12} md={6}>
                    <Grow in={true}>
                        <Box height="100%">

                            <UserAssignedDevice id={data.primaryEmail} />
                        </Box>
                    </Grow>
                </Grid2>
                <Grid2 xs={12}>
                    <Grow in={true}>
                        <Box height="100%">

                            <NotesCard id={data.id} label={"User's Notes"} />
                        </Box>
                    </Grow>
                </Grid2>

                <Grow in={true}>
                    <Box>
                        <StudentGroupsCard id={data.primaryEmail} xs={12} md={12} />
                    </Box>
                </Grow>
            </Grid2>
        </Box>
    )
}

export default User