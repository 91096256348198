import React, { useEffect, useState } from 'react'
import { Field } from '../TicketFormSettings'
import { Box, Checkbox, Divider, FormControl, FormControlLabel, Grow, IconButton, InputLabel, Menu, MenuItem, Select, Slide, Typography } from '@mui/material'
import DataEditable from 'components/data/DataEditable'
import { TextField } from '@mui/material'
import { Add, ArrowDropDown, ArrowDropUp, Check, Delete, Tune } from '@mui/icons-material'
import TwoSide from 'components/data/TwoSide'
import { pureFinalPropsSelectorFactory } from 'react-redux/es/connect/selectorFactory'
import SelectOption from './SelectOption'

interface FieldSelectOption {
    label: string;
    uid: number;
    offset: number;
}

export interface FieldEditableOptions {
    label: string;
    id: number;
    type: string;
    additionalOptions: AdditionalOption[];
    selectOptions?: FieldSelectOption[];
    index: number;
    step: number;
    onPositionChange: (id: number, positionalChange: 1 | -1) => void;
    onDelete: (id: number) => void;
    onUpdate: (id: number, label: string, additionalOptions: any[], selectOptions: FieldSelectOption[]) => void;
}

export interface AdditionalOption {
    id: string;
    value: any;
}
const AutofillOptions = {
    text: [
        {id: "studentDevice", name: "Student's Device"},
        {id: "studentName", name: "Student's Name"},
        {id: "studentEmail", name: "Student's Email"},
    ],
    select: [
        {}
    ]
}
const FieldEditable: React.FC<FieldEditableOptions> = ({label, type, additionalOptions, selectOptions, id, index, step, onPositionChange, onDelete, onUpdate}) => {
    const [stype, setSpecialType] = useState("default");
    const [transition, setTransition] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [fieldSelectOptions, setSelectOptions] = useState([]);
    const [basedOnAnswer, setBasedOnAnswer] = useState(false);
    
    useEffect(() => {
        var option = additionalOptions.find(v => v.id == "selectOption");
        if(option == undefined) {
            additionalOptions.push({id: 'selectOption', value: selectOptions})
        } else {
            option.value = selectOptions;
        }
        onUpdate(id, label, additionalOptions, fieldSelectOptions)
    }, [selectOptions])

    useEffect(() => {
        onUpdate(id, label, additionalOptions, fieldSelectOptions)
    }, [fieldSelectOptions])

    useEffect(() => {
        if(type == "select") {
            if(selectOptions != null) {
                setSelectOptions(selectOptions)

                selectOptions.forEach((opt) => {
                    if(opt.offset != 0) {
                        setBasedOnAnswer(true)
                    }
                })
            } else{
                setSelectOptions([{label: "Yes", offset: 0, uid: Math.random()}, {label: "No", offset: 0, uid: Math.random()}])

            }
            console.log(additionalOptions.find(v => v.id == 'required').value)
        }
    }, [])

    const open = Boolean(anchorEl);


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };


  


    
    const deleteSelf = () => {
        const timer = setTimeout(() => {
            
            onDelete(id);
        }, 1000);

        setTransition(false);
    
        // cleanup function
        return () => {
          clearTimeout(timer);
        };
    }  
    if(type === "student") {
        return (
            <Slide direction="left" in={true} mountOnEnter>
                <Box sx={{m: '1rem 0'}}>
                    <TextField
                        name={"Student Information"}
                        defaultValue={"Student Information"}
                        fullWidth
                        label="Question Title"
                        color="secondary"
                        onChange={(e) => onUpdate(id, e.target.value, additionalOptions, [])}
                        disabled
                    >

                    </TextField>
                    <Typography sx={{mt: '5px'}} variant="subtitle2">This Question is required and is always the first question.</Typography>
                    <Divider sx={{mt: '1rem'}}/>
                </Box>
            </Slide>
        )
    }

  return (
    <Slide direction="left" in={transition} mountOnEnter unmountOnExit>
        <Box sx={{m: '1rem 0'}}>
            {type == "text" && (
                <>
                    <TextField
                        name={label.trim().toLowerCase()}
                        defaultValue={label}
                        fullWidth
                        label="Question Title"
                        color="secondary"
                        onChange={(e) => onUpdate(id, e.target.value, additionalOptions, fieldSelectOptions)}
                    >

                    </TextField>
                    <TwoSide>
                        <TextField
                            label="Autofill Option"
                            select
                            sx={{width: '40%', m: '1rem 0'}}
                            color='secondary'
                            defaultValue={additionalOptions.find(v => v.id == 'autofill')?.value || 'default'}

                            onChange={(e) => {
                                for (let i = 0; i < additionalOptions.length; i++) {
                                    
                                    if(additionalOptions[i].id != null && additionalOptions[i].id == "autofill") {
                                        additionalOptions[i].value = e.target.value;
                                        onUpdate(id, label, additionalOptions, fieldSelectOptions);
                                        return;
                                    }
                                }
                        

                                additionalOptions.push({id: 'autofill', value: e.target.value})
                                onUpdate(id, label, additionalOptions, fieldSelectOptions);
                            }}
                            helperText="If you select somthing other than default, this field will auto fill in with the information.
                            Ex: if you have a student field before this, and you select student's device, it will autofill the device for you"
                        >
                            <MenuItem value={"default"}>Default</MenuItem>
                            {AutofillOptions.text.map(option => (<MenuItem value={option.id}>{option.name}</MenuItem>))}
                        </TextField>
                        <Box sx={{m: '1rem'}}>

                            <FormControlLabel onChange={(e, checked) => {
                                if(additionalOptions == null) {
                                    additionalOptions = []
                                }
                                for (let i = 0; i < additionalOptions.length; i++) {
                                    
                                    if(additionalOptions[i].id != null && additionalOptions[i].id == "required") {
                                        additionalOptions[i].value = checked;
                                        onUpdate(id, label, additionalOptions, fieldSelectOptions);
                                        return;
                                    }
                                }
                        

                                additionalOptions.push({id: 'required', value: checked})
                                onUpdate(id, label, additionalOptions, fieldSelectOptions);

                            }} control={<Checkbox defaultChecked={additionalOptions.find(v => v.id == 'required')?.value || false} color="secondary" />} label="Required?" />

                            <IconButton onClick={() => deleteSelf()} color="error">
                                <Delete/>
                            </IconButton>
                        </Box>

                    </TwoSide>
                </>
            )}

            {type == "select" && (
                <>
                    <TextField
                        name={label.trim().toLowerCase()}
                        defaultValue={label}
                        fullWidth
                        label="Question Title"
                        color="secondary"
                        onChange={(e) => onUpdate(id, e.target.value, additionalOptions, fieldSelectOptions)}
                    >
                    </TextField>
                    <TwoSide>
                        <Box>
                            {fieldSelectOptions.map((option, index) => (
                                <SelectOption 
                                    key={option.uid} 
                                    id={index} 
                                    activeStep={step}
                                    initialIndex={option.offset}
                                    label={option.label}
                                    basedOnAnswer={basedOnAnswer} 
                                    steps={additionalOptions.find((v) => v.id == "steps").value}
                                    onDelete={(id) => setSelectOptions([...fieldSelectOptions.splice(id, 1)])} 
                                    onUpdate={(id, label, offset) => {
                                        fieldSelectOptions[index] = {label: label, offset: offset, uid: option.uid};
                                        setSelectOptions([...fieldSelectOptions])
                                    }}/>
                            ))}
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                <IconButton onClick={() => {fieldSelectOptions.push({label: 'Untitled Option', offset: 0, uid: Math.random()}); setSelectOptions([...fieldSelectOptions])}}>
                                    <Add/>
                                </IconButton>
                                <Typography>Add Option</Typography>
                            </Box>
                            
                        </Box>

                        <Box sx={{m: '1rem'}}>
                            <FormControlLabel onChange={(e, checked) => {
                                if(additionalOptions == null) {
                                    additionalOptions = []
                                }
                                for (let i = 0; i < additionalOptions.length; i++) {

                                    if(additionalOptions[i].id != null && additionalOptions[i].id == "required") {
                                        additionalOptions[i].value = checked;
                                        onUpdate(id, label, additionalOptions, fieldSelectOptions);
                                        return;
                                    }
                                }
                            
                                additionalOptions.push({id: 'required', value: checked})
                                onUpdate(id, label, additionalOptions, fieldSelectOptions);
                            }} control={<Checkbox defaultChecked={additionalOptions.findIndex((v) => v.id == 'required') >= 0 ? additionalOptions.find(v => v.id == 'required').value : false} color="secondary" />} label="Required?" />
                            <IconButton onClick={() => deleteSelf()} color="error">
                                <Delete/>
                            </IconButton>
                        </Box>
                    </TwoSide>
                </>
            )}

            {type == "loaner" && (
            <>
                <TextField
                    name={label.trim().toLowerCase()}
                    defaultValue={label}
                    fullWidth
                    label="Question Title"
                    color="secondary"
                    value="Loaner"
                    disabled
                >
                </TextField>
                <Typography>This will use the loaner database to help input loaners. This question is required and cannot be changed</Typography>
                <TwoSide>
                    <Box></Box>
                    <Box sx={{m: '1rem'}}>
                        <FormControlLabel control={<Checkbox defaultChecked disabled color="secondary" />} label="Required?" />
                        <IconButton onClick={() => deleteSelf()} color="error">
                            <Delete/>
                        </IconButton>
                    </Box>
                </TwoSide>
            </>

            )}

            {type == "device" && (
                <>
                <TextField
                    name={label.trim().toLowerCase()}
                    defaultValue={label}
                    fullWidth
                    label="Question Title"
                    color="secondary"
                    onChange={(e) => onUpdate(id, e.target.value, additionalOptions, fieldSelectOptions)}
                >
                </TextField>
                <Typography variant="subtitle2">This will autocomplete devices to help you input devices</Typography>
                <TwoSide>

                    <Box sx={{m: '1rem'}}>
                        <FormControlLabel
                        onChange={(e, checked) => {
                            for (let i = 0; i < additionalOptions.length; i++) {
                                    
                                if(additionalOptions[i].id != null && additionalOptions[i].id == "autofill") {
                                    additionalOptions[i].value = checked ? 'studentDevice' : '';
                                    onUpdate(id, label, additionalOptions, fieldSelectOptions);
                                    return;
                                }
                            }
                    

                            additionalOptions.push({id: 'autofill', value: checked ? 'studentDevice' : ''})
                            onUpdate(id, label, additionalOptions, fieldSelectOptions);

                        }}
                        control={<Checkbox defaultChecked={additionalOptions.find(v => v.id == 'autofill')?.value != '' || false} color="secondary" />} label="Autofill Device From Student?"/>


                        <FormControlLabel onChange={(e, checked) => {
                            if(additionalOptions == null) {
                                additionalOptions = []
                            }
                            for (let i = 0; i < additionalOptions.length; i++) {
                                
                                if(additionalOptions[i].id != null && additionalOptions[i].id == "required") {
                                    additionalOptions[i].value = checked;
                                    onUpdate(id, label, additionalOptions, fieldSelectOptions);
                                    return;
                                }
                            }
                    

                            additionalOptions.push({id: 'required', value: checked})
                            onUpdate(id, label, additionalOptions, fieldSelectOptions);

                        }} control={<Checkbox defaultChecked={additionalOptions.find(v => v.id == 'required')?.value || false} color="secondary" />} label="Required?" />

                        <IconButton onClick={() => deleteSelf()} color="error">
                            <Delete/>
                        </IconButton>
                    </Box>

                </TwoSide>
            </>                
            )}

            {type == "studentInput" && (
                <>
                <TextField
                    name={label.trim().toLowerCase()}
                    defaultValue={label}
                    fullWidth
                    label="Question Title"
                    color="secondary"
                    onChange={(e) => onUpdate(id, e.target.value, additionalOptions, fieldSelectOptions)}
                >
                </TextField>
                <Typography variant="subtitle2">This will autocomplete students to help you input students</Typography>

                <TwoSide>

                    <Box sx={{m: '1rem'}}>

                        <FormControlLabel onChange={(e, checked) => {
                            if(additionalOptions == null) {
                                additionalOptions = []
                            }
                            for (let i = 0; i < additionalOptions.length; i++) {
                                
                                if(additionalOptions[i].id != null && additionalOptions[i].id == "required") {
                                    additionalOptions[i].value = checked;
                                    onUpdate(id, label, additionalOptions, fieldSelectOptions);
                                    return;
                                }
                            }
                    

                            additionalOptions.push({id: 'required', value: checked})
                            onUpdate(id, label, additionalOptions, fieldSelectOptions);

                        }} control={<Checkbox color="secondary" />} label="Required?" />

                        <IconButton onClick={() => deleteSelf()} color="error">
                            <Delete/>
                        </IconButton>
                    </Box>

                </TwoSide>
            </>                
            )}        

            <Box sx={{
                display: 'flex',
                flexDirection: 'row-reverse'

            }}>
                <IconButton disabled={index == 0} onClick={() => onPositionChange(id, 1)}>
                    <ArrowDropUp/>
                </IconButton>
                <IconButton onClick={() => onPositionChange(id, -1)}>
                    <ArrowDropDown/>
                </IconButton>
                {type == 'select' && (

                    <>

                        <IconButton 
                        
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        id="options-button"
                        >
                            <Tune/>
                        </IconButton>

                        <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'options-button',
                        }}
                        >
                            <MenuItem onClick={() => {setBasedOnAnswer(!basedOnAnswer); handleClose();}}>
                                {basedOnAnswer && (<Check/>)} Go to section based on answer
                            </MenuItem>
                        </Menu>
                    </>
                )}
            </Box>
            <Divider/>
        </Box>
    </Slide>
  )
}

export default FieldEditable