import { Delete, Edit } from '@mui/icons-material';
import { Box, Typography, useMediaQuery, useTheme, TextField, IconButton, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface DataEditableProps {
    data: string;
    callback: (value: string) => void;
    onDelete?: () => void;
    variant?: string;
    width?: string;
    multiline?: boolean;
    title?: string;
  }
  

const DataEditable: React.FC<DataEditableProps> = ({ data, callback, onDelete,multiline, variant, width, title }) => {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const [isEditing, setEditing] = useState(false);
    const [editValue, setEditValue] = useState(data);

    useEffect(() => {
        setEditValue(data)
    }, [data])

    const handleBlur = () => {
        setEditing(false);
        // Call your function here
        callback(editValue);
    }

    const handleChange = (event) => {
        setEditValue(event.target.value);
    }

    const widthString = () => {
        if(width != null) {
            return width;
        } else {
            return isNonMediumScreens ? "20vw" : "auto"
        }
    }


    return (
        <Box width={widthString} sx={{height: 'fit-content', m: '1rem 0'}}>
            {title && (
                <>
                    <Typography variant="h5">
                        {title}
                    </Typography>
                    <Divider/>
                </>
            )}
            <Box
                width={widthString}
                sx={{
                    //@ts-ignore
                    backgroundColor: theme.palette.mode == "light" ? theme.palette.neutral[700] : theme.palette.primary[400],
                    borderRadius: "5px",
                    margin: '5px 0',
                    padding: '.5rem 2rem .5rem 2rem',
                    background: 'rgba(0,0,0,.09)'
                }}
            >

                {isEditing ? 
                    <TextField
                        fullWidth
                        autoFocus
                        value={editValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="standard"
                        multiline={multiline}
                    /> : 
                    <Box
                        display='flex'
                        justifyContent={onDelete != null ? "space-between" : "center"}
                        alignItems={"center"}
                        padding={onDelete != null ? "0 1rem" : "0"}
                        border={onDelete != null ? "1px solid " + theme.palette.primary.contrastText : ""}
                        borderRadius={"5px"}
                        width="100%"

                    >
                        <Typography
                            textAlign={"center"}
                            //@ts-ignore
                            variant={variant || "body1"}
                            onClick={() => setEditing(true)}
                            width="100%"
                        >
                            {editValue}
                        </Typography>
                        <IconButton onClick={(e) => setEditing(true)} size="small">
                            <Edit />
                        </IconButton>
                        {onDelete && 
                        
                            <IconButton color="error" onClick={onDelete}>
                                <Delete/>
                            </IconButton>
                        }
                    </Box>

                }

            </Box>

        </Box>
    )
}

export default DataEditable;
