import {createSlice} from  "@reduxjs/toolkit";


const initialState = {
    mode: localStorage.getItem("mode") === "dark" ? "dark" : "light", //TODO: Load from cookie
    userId: "none",
    userRole: "none",
    domain: "none",
    currentTicket: "",
    allowQrLogin: false,
    experimentalEnabled: false,
    experimentalOptions: [],
    provider: "google",
    scopes: []
}

export const globalSlice  = createSlice({
    name: "global",
    initialState,
    reducers: {
        setMode: (state) => {
            localStorage.setItem("mode", state.mode === "light" ? "dark" : "light");
            state.mode = state.mode === "light" ? "dark" : "light"
        },
        setForcedMode: (state, action) => {
            state.mode = action.payload;
        },
        setDomain: (state, action) => {
            state.domain = action.payload;
        },
        setTicket: (state, action) => {
            state.currentTicket = action.payload;
        },
        setScopes: (state, action) => {
            state.scopes = action.payload;
        },
        setExperimentalEnabled: (state, action) => {
            state.experimentalEnabled = action.payload;
        },
        setExperimentalSettings: (state, action) => {
            state.experimentalOptions = action.payload;
        },
        setRole: (state, action) => {
            state.userRole = action.payload;
        },
        setQrLogin: (state, action) => {
            state.allowQrLogin = action.payload;
        },
        setProviderType: (state, action) => {
            switch (action.payload) {
                case "Mock Google Data Provider":
                    if (process.env.NODE_ENV !== 'development') {
                        state.provider = "mock"
                    }
                    state.provider = "google"
                    break;
                case "Google Admin Data Provider":
                    state.provider = "google"
                    break;
            }
        }
    }
})

export const { setMode } = globalSlice.actions;
export const { setForcedMode } = globalSlice.actions;
export const { setDomain } = globalSlice.actions;
export const { setTicket } = globalSlice.actions;
export const { setScopes } = globalSlice.actions;
export const { setExperimentalEnabled } = globalSlice.actions;
export const { setExperimentalSettings } = globalSlice.actions;
export const { setRole } = globalSlice.actions;
export const { setQrLogin } = globalSlice.actions;
export const {setProviderType} = globalSlice.actions;
export default globalSlice.reducer;