import {Delete, Edit} from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Divider,
    Skeleton,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {DataGrid, GridActionsCellItem, GridColDef, GridToolbar} from "@mui/x-data-grid";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {HasMinimumScopeWithArray, useGetNotesQuery} from "state/api";
import HoverPaper from "../../components/HoverPaper";

interface EditNote {
    note: any;
    open: boolean;
    setIsOpen: (bool: boolean) => void;
    onChange: () => void;
}

const EditNote: React.FC<EditNote> = ({note, open, setIsOpen, onChange}) => {
    const [message, setMessage] = useState(note?.message ?? "")

    useEffect(() => {
        setMessage(note?.message ?? "")
    }, [note])


    const save = async () => {
        const updateData = {...note, message: message};

        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/notes/' + note.id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(updateData)
        })

        onChange()
        setIsOpen(false)
    }
    const maxLength = 500;
    return (
        <Dialog
            open={open}
            onClose={() => setIsOpen(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogContent>
                <Typography variant="h3">Edit Note</Typography>
                <Divider sx={{mb: '1rem'}}/>
                <Stack spacing={1}>

                    <TextField
                        multiline
                        rows={10}
                        label="message"
                        color="secondary"
                        value={message}
                        onChange={(v) => {
                            if (v.target.value.length <= maxLength) {
                                setMessage(v.target.value)
                            }
                        }
                        }
                        helperText={`${message.length}/${maxLength} characters`}
                    />
                    <Button variant="outlined" color="success" disabled={message.length < 5}
                            onClick={() => save()}>Save</Button>
                </Stack>

            </DialogContent>
        </Dialog>
    )
}


interface AddNote {
    open: boolean;
    setIsOpen: (bool: boolean) => void;
    onChange: () => void;
    studentId: string;
}

const AddNote: React.FC<AddNote> = ({open, setIsOpen, onChange, studentId}) => {
    const [message, setMessage] = useState("")
    const maxLength = 500;

    const upload = async () => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/notes/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                message: message,
                attachTo: studentId
            })
        })

        onChange()
        setIsOpen(false)
    }

    return (
        <Dialog
            open={open}
            onClose={() => setIsOpen(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogContent>
                <Typography variant="h3">Add Note</Typography>
                <Divider sx={{mb: '1rem'}}/>
                <Stack spacing={1}>

                    <TextField
                        multiline
                        rows={10}
                        label="message"
                        color="secondary"
                        value={message}
                        onChange={(v) => {
                            if (v.target.value.length <= maxLength) {
                                setMessage(v.target.value)
                            }
                        }
                        }
                        helperText={`${message.length}/${maxLength} characters`}
                    />
                    <Button variant="outlined" color="success" disabled={message.length < 5}
                            onClick={() => upload()}>Submit</Button>
                </Stack>

            </DialogContent>
        </Dialog>
    )
}

interface NotesCard {
    id: string;
    label: string;
}

const NotesCard: React.FC<NotesCard> = ({id, label}) => {
    const [notes, setNotes] = useState([])
    const {data, isLoading, error, refetch} = useGetNotesQuery(id)
    const [canEdit, setCanEdit] = useState(false);
    const [addOpen, setAddIsOpen] = useState(false);
    const [edit, setEdit] = useState({open: false, note: null})
    //@ts-ignore
    const state = useSelector(g => g.global)
    const theme = useTheme()

    useEffect(() => {
        if (state.scopes != null) {
            setCanEdit(HasMinimumScopeWithArray(state.scopes, "write:organization"))
        } else {
            setCanEdit(false)
        }
    }, [state])

    useEffect(() => {
        refetch()
    }, [id])

    useEffect(() => {
        if (!isLoading && data != null) {
            setNotes([...data])
        }
    }, [data])

    const deleteNote = async (id) => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/notes/' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        })

        refetch()
    }

    const getNote = (id) => {
        for (let note of notes) {
            if (note.id === id) {
                return note;
            }
        }
    }


    const notesColums: GridColDef[] = [
        {
            field: "author",
            headerName: "Author",
            flex: 0.3

        },
        {field: "message", headerName: 'Message', flex: 1},
    ]

    const editableColums: GridColDef[] = [
        {
            field: "author",
            headerName: "Author",
            flex: 0.3

        },
        {field: "message", headerName: 'Message', flex: 1},
        {
            field: 'actions',
            type: 'actions',
            headerName: "Actions",
            getActions: (params) => {
                return (
                    [
                        <>
                            <GridActionsCellItem

                                icon={<Edit/>}
                                label="Edit?"
                                onClick={() => setEdit({open: true, note: getNote(params.row.id)})}

                            />
                            <GridActionsCellItem

                                icon={<Delete/>}
                                label="Delete?"
                                onClick={() => deleteNote(params.row.id)}
                            />
                        </>
                    ]
                )
            }
        }
    ]


    if (isLoading) {
        return (
            <HoverPaper elevation={4}>
                <Skeleton height="40vh"/>
            </HoverPaper>
        )
    }


    if (isLoading) {
        return (
            <HoverPaper elevation={4}>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>{label}</Typography>
                <Divider sx={{mb: '1rem'}}/>
                <Skeleton variant="rectangular" height="20vh"/>
                <Skeleton variant="rectangular" height="5vh"/>
                <Skeleton variant="rectangular" height="5vh"/>
            </HoverPaper>
        )
    }


    return (
        <HoverPaper elevation={4}>
            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>{label}</Typography>
            <Divider sx={{mb: '1rem'}}/>

            {canEdit && (
                <>
                    <Button variant="contained" color="secondary" onClick={() => setAddIsOpen(true)}>Add Note</Button>
                    <AddNote open={addOpen} setIsOpen={setAddIsOpen} onChange={() => refetch()} studentId={id}/>
                    <EditNote open={edit.open} setIsOpen={(b) => setEdit({open: b, note: edit.note})}
                              onChange={() => refetch()} note={edit.note}/>
                </>
            )}

            <Box
                height="50vh"

                sx={{

                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },

                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    slots={{toolbar: GridToolbar}}
                    loading={isLoading}
                    getRowId={(row) => row.id}
                    rows={notes || []}
                    columns={canEdit ? editableColums : notesColums}
                />
            </Box>
        </HoverPaper>
    )
}

export default NotesCard