import {
    Autocomplete,
    AutocompleteRenderOptionState,
    CircularProgress,
    createFilterOptions, debounce, FilterOptionsState,
    TextField
} from '@mui/material';
import React, {ReactNode, useEffect} from 'react'
import {throttle} from 'lodash';

interface LazyAutocomplete {
    /**
     * The URL to fetch data from
     */
    dataUrl: string;

    /**
     * The label to display for the input
     */
    label: string;

    /**
     * A function to get the label from the option returned by the API. The label will be displayed in the input
     */
    getLabel: (value: any) => string;

    /**
     * A function to determine if one object is equal to another.
     * This is used to determine if the value in the input is equal to the value in the options list
     * @param option the option to compare
     * @param value the value to compare to
     */
    isOptionEqualToValue: (option: any, value: any) => boolean;

    /**
     * A function to generate the query string to send to the API. Passes the value of the input as the first argument
     * @param query
     */
    query: (query: any) => string;

    /**
     * An optional function to process the response from the API.
     * This is useful if the API returns a different format than the options list expects
     * @param response the response from the API
     */
    processRequest?: (response: Response) => any;

    /**
     * A function to call when the value in the input changes
     * @param val the new value
     */
    onValueChange: (val: any) => any;

    /**
     * A custom render function for the options list
     */
    render?: (props: React.HTMLAttributes<HTMLLIElement>, option: any, state: AutocompleteRenderOptionState) => ReactNode

    /**
     * A function that should return the string name of the group that this option belongs to
     */
    groupBy?: (option: any) => string;

    /**
     * The current value of the input
     */
    value: string;

    /**
     * If true, the user can enter a custom value that is not in the options list
     */
    freeSolo?: boolean;

    /**
     * If true, the input will take up the full width of the container
     */
    fullWidth?: boolean;

    filter?: (options: string[], state: FilterOptionsState<string>) => string[]
}


const LazyAutocomplete: React.FC<LazyAutocomplete> = ({
                                                          dataUrl,
                                                          label,
                                                          getLabel,
                                                          groupBy,
                                                          fullWidth,
                                                          isOptionEqualToValue,
                                                          render,
                                                          processRequest,
                                                          query,
                                                          value,
                                                          onValueChange,
                                                          freeSolo = false,
                                                          filter = createFilterOptions<string>()
                                                      }) => {
    // Create an AbortController instance
    const abortController = new AbortController();

    async function searchApi(q) {
        abortController.abort();
        const newAbortController = new AbortController();

        setIsLoading(true);
        try {
            const response = await fetch(dataUrl + query(q), {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                signal: newAbortController.signal,
            });
            setIsLoading(false);

            if (response.status === 200) {
                if (processRequest != null) {
                    setOptions([...(await processRequest(response))]);
                } else {
                    setOptions([...(await response.json())]);
                }
            }
        } catch (e) {
            setIsLoading(false);
        }
    }

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [search, setSearch] = React.useState(
        options.some((opt) => isOptionEqualToValue(opt, value)) ? value : ''
    );
    const [loading, setIsLoading] = React.useState(true);

    const debouncedSearchApi = debounce(searchApi, 300);

    useEffect(() => {
        debouncedSearchApi(search);

        return () => {
            debouncedSearchApi.clear(); // Clear debounce on unmount or search change
        };
    }, [search]);

    // Update search when value prop changes
    useEffect(() => {
        setSearch(value);
    }, [value]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setSearch(inputValue);

    };

    return (
        <Autocomplete
            sx={{width: "100%"}}
            id="async-autocomplete"
            freeSolo={freeSolo}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            groupBy={groupBy}
            fullWidth={fullWidth}
            getOptionLabel={(opt: any) => {
                if (typeof opt === 'string') {
                    return opt;
                } else if (opt && opt.inputValue) {
                    return `Add "${opt.inputValue}"`;
                } else {
                    return getLabel(opt);
                }
            }}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={(e, v) => {
                if(v == null) {
                    setSearch("");
                    return;
                }
                setSearch(getLabel(v));

                if (freeSolo) {
                    onValueChange(v);
                } else {
                    onValueChange(v);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                const isExisting = options.some((option) =>
                    isOptionEqualToValue(option, inputValue)
                );

                if (inputValue !== '' && !isExisting && freeSolo) {
                    //@ts-ignore
                    filtered.push({label: `Add "${inputValue}"`, inputValue: inputValue, isFreeSolo: true});
                }

                return filtered;
            }}

            value={search}
            options={options}
            loading={loading}

            renderOption={render}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    fullWidth={fullWidth}
                    variant="standard"
                    color="secondary"
                    onChange={(e) => handleInputChange(e)}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}


        />

    )
}

export default LazyAutocomplete


