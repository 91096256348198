import React from 'react'
import LazyAutocomplete from './LazyAutocomplete';

interface DeviceAutocomplete {
    onValueChange: (value: any) => void;
    label: string;
    freeSolo?: boolean;
    value: string;
}
const DeviceAutocomplete:React.FC<DeviceAutocomplete> = ({onValueChange, label, freeSolo, value}) => {
  return (
    <LazyAutocomplete 
        label={label}
        onValueChange={onValueChange}
        dataUrl={process.env.REACT_APP_BASE_URL + '/org/provider/data/devices/query'} 
        getLabel={(e) => e.label}
        isOptionEqualToValue={(e, v) => e == v}
        query={(q) => `?q=${q}`}
        freeSolo={freeSolo}
        fullWidth
        processRequest={async (response) => {
            var data = await response.json()
            var users = data.map((u) => {
                var label = "Serial Number: " + u.serialNumber;

                if(u.annotatedAssetId != null) label += ". \nAsset Id: " + u.annotatedAssetId

                return {
                    label: label,
                    ...u
                }
            })

            // Filter out devices that contain the same label
            users = users.filter((u, i) => users.findIndex((e) => e.label == u.label) == i)
            return [...users]
        }}
        value={value}
    />
  )
}

export default DeviceAutocomplete