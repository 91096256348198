import React from 'react';
import {Box, Button, Divider, FormControlLabel, Stack, Switch, Typography} from "@mui/material";
import Papa from 'papaparse';

interface FilterOption {
    id: string;
    label: string;
    filter: (data: any []) => any [];
}

interface CSVExportEditorProps {
    data: any [];
    formatter?: (data: any []) => any [];
    supportedFilterOptions?: FilterOption [];
}

const CsvExportEditor: React.FC<CSVExportEditorProps> = ({data, formatter, supportedFilterOptions = []}) => {
    // Stores the filters that should be applied to the data
    const [filters, setFilters] =
        React.useState<((data: any []) => any [])[]>([]);
    const onClick = () => {
        // Format the data if a formatter is provided
        let formattedData = data;

        // Apply filters
        for (const filter of filters) {
            formattedData = [...filter(formattedData)];
        }

        if (formatter != null) {
            formattedData = formatter(formattedData);
        }

        const csv = convertToCSV(formattedData);

        downloadCSV(csv, 'export.csv');
    }

    const downloadCSV = (csv, filename) => {
        const blob = new Blob([csv], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const convertToCSV = (data) => {
        const csv = Papa.unparse(data);
        return csv;
    };

    const onToggleFilter = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, option) => {
        // If the filter is being enabled, add it to the list of filters
        if (checked) {
            setFilters([...filters, option.filter]);
        } else {
            // Otherwise, remove it from the list of filters
            setFilters(filters.filter((filter) => filter !== option.filter));
        }
    }
    return (
        <Box>
            <Typography variant={"h6"}>Export Data</Typography>
            <Divider/>
            <Box>
                <Typography>Filter Options</Typography>
                <Stack>
                    {supportedFilterOptions.map((option) => {
                        return (
                            <FormControlLabel
                                control={<Switch color={"secondary"}
                                                 onChange={(e, c) => onToggleFilter(e, c, option)}/>}
                                label={option.label} key={option.id}/>
                        );
                    })}
                </Stack>
            </Box>
            <Button variant={"contained"} color={"success"} onClick={onClick}>Download CSV</Button>
            <Typography variant={"subtitle1"} fontSize={12}>
                Note: As of now, you cannot edit the csv before downloading.
                This feature is planned for a future update
            </Typography>
        </Box>
    );
};

export default CsvExportEditor;