import { Delete } from '@mui/icons-material';
import { Alert, Avatar, Box, Snackbar, useTheme } from '@mui/material';
import { red } from '@mui/material/colors';
import { DataGrid, GridActionsCellItem, GridColDef, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { useGetGroupMembersQuery } from 'state/api';
interface MembersTable {
    id: string;
    isAddingUser: boolean;
}
const OrganizationMembersTable: React.FC<MembersTable> = ({id, isAddingUser}) => {
    const [data, setData] = useState([]);
    const [fetchData, setFetchData] = useState([])
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [loading, setIsLoading] = useState(true);
    const [open, setSnackbarOpen] = useState(false);
    const theme = useTheme();



    useEffect(() => {
        if(fetchData == null) {
            setData([])
        } else {

            setData([...fetchData]);
        }
    }, [fetchData])

    useEffect(() => {
        if(!isAddingUser) {
            setData([])
            fetchMembers()
        }
    }, [id, isAddingUser])


    const fetchMembers = async () => {
        setIsLoading(true)
        const response = await fetch(process.env.REACT_APP_BASE_URL + `/org/groups/${id}/members`, {credentials: "include"});
        if (response.status != 200) {
            setIsLoading(false);
            throw new Error('Network response was not ok');
        }
        setIsLoading(false);
        const membersData = await response.json();

        setFetchData([...membersData])
    }

    const deleteMember = (email) => {
        fetch(process.env.REACT_APP_BASE_URL + `/org/groups/${id}/members?email=${email}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",

            }).then((res) => {
              setSnackbarMessage("User " + email + " was removed successfully!")
              setSnackbarOpen(true);
              fetchMembers()
            })
    }

    const columns: GridColDef[] = [
        {
            field: "icon",
            headerName: "Icon",
            renderCell: (params) => {
                return <Avatar alt={params.row.name} sx={{bgcolor: red[200]}} src={params.row.thumbnailPhotoUrl}/>
            }
            
        },
        {
            field: 'primaryEmail',
            headerName: 'Email',
            flex: 1
        },
        {
            field: 'name',
            headerName: "Name",
            flex: 1
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: "Actions",
            getActions: (params) => {
                return (
                [
                    <GridActionsCellItem 
                    
                        icon={<Delete/>}
                        label="Delete?"
                        onClick={() => deleteMember(params.row.primaryEmail)}
                    />
                ]
                )
            }
        }
    ]
  return (
    <Box
        height="75vh"
        maxWidth={"85vw"}
        sx={{
            "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                cursor: 'grab',
                borderBottom: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
        }}
        >
        <Snackbar open={open} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
          <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <DataGrid
            slots={{toolbar: GridToolbar}}
            loading={loading || !data}
            getRowId={(row) => row.id}
            rows={data}
            columns={columns}
        />
    </Box>
  )
}

export default OrganizationMembersTable;