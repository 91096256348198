import { Close, Print } from '@mui/icons-material';
import { Dialog, AppBar, Toolbar, IconButton, DialogContent, Box, useTheme, Typography, Divider, Stack, Checkbox, FormControlLabel, useMediaQuery, Skeleton, Button, Chip, Avatar } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { GridColDef } from '@mui/x-data-grid';
import CustomTicket from 'components/CustomTicket';
import CustomTicketLayout from 'components/CustomTicketLayout';
import DataField from 'components/data/DataField';
import DataList from 'components/data/DataList';
import TwoSide from 'components/data/TwoSide';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useGetImagesFromTicketQuery, useGetMessagesFromTicketQuery } from 'state/api';

import './PrintTicketStyles.css'
import ImageCard from 'components/data/ImageCard';
import { useDispatch } from 'react-redux';
import { setForcedMode, setMode } from 'state';
import TicketStatusComponent from 'components/data/TicketStatus';

interface Print {
    open: boolean;
    handleClose: () => void;
    ticketInformation: any;
    data: any;
}

const columns: GridColDef[] = [
    { field: 'user', headerName: 'Author', flex: 1 },
    { field: 'message', headerName: 'Message',flex: 1 },
    { field: 'date', headerName: 'Date',flex: 1, type: 'date' },
  ];
  
const PrintTicket:React.FC<Print> =({open, ticketInformation, data, handleClose}) => {
    const [includeImages, setIncludeImages] = useState(false); 
    const {id} = useParams();
    const [includeMessages, setIncludeMessages] = useState(true);
    const [messages, setMessages] = useState([]);
    const {data: messageRequest, isLoading: messagesLoading} = useGetMessagesFromTicketQuery(id)
    const {data: imageRequest, isLoading: imagesLoading} = useGetImagesFromTicketQuery({id, download: true})


    const isSmallScreen = useMediaQuery('(max-width: 600px)');
    const [gridItems, setGridItems] = useState([]);
    const [headerItems, setHeaderItems] = useState([]);
    const theme = useTheme(); 

    const dispatch = useDispatch();
    const darkMode = localStorage.getItem("mode") == 'dark'

    useEffect(() => {
        var items = data.map((i) => ({
            ...i,
            uid: Math.random()
        }));
        for(var i = 0; i < items.length; i++) {
            if(items[i].name == "Student Information") {
                items[i].value = ticketInformation.studentName;
            }
        }
        var headerItems = data.filter((i) => i.type == 'header').map((i) => ({...i, uid: Math.random()}))
        setHeaderItems([...headerItems])
        setGridItems([...items])
    }, [data])

    useEffect(() => {
        setMessages(messageRequest || [])
    }, [messageRequest])

    useEffect(() => {
        if(darkMode && open) {
            dispatch(setForcedMode("light"))
        }
    }, [open])

    const getTicketStatusToString = (data) => {
        switch(data) {
          case 0:
            return "Waiting for Repair"
            break;
          case 1:
            return "Repaired, Waiting for Return"
            break;
          case 2:
            return "In Office"
            break;
          case 3:
            return "Completed"
            break;
          case 4:
            return "Other"
            break;
    
        }
      }
  return (
    <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullScreen
    >
        <AppBar  sx={{ position: 'relative', displayPrint:"none" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                    if(darkMode) {
                        dispatch(setForcedMode("dark"))
                    }

                    handleClose()
                }}
                aria-label="close"
              >
                  <Close />
              </IconButton>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Grid2  container spacing={2}>
                <Grid2 className='print-hidden' xs={12} md={2}>
                    <Box sx={{
                        m: '1rem auto',
                        //@ts-ignore
                        backgroundColor:theme.palette.background.alt,
                        borderRadius:"0.55rem",
                        padding: '1rem',
                        position: 'absolute'
                    }}>
                        <Typography variant="h4">Print Settings</Typography>
                        <Divider/>
                        <Stack>
                            <FormControlLabel value={includeImages} onChange={(v, checked) => setIncludeImages(checked)} control={<Checkbox defaultChecked={includeImages} color="secondary"/>} label="Include Images?" />
                            <FormControlLabel value={includeMessages} onChange={(v, checked) => setIncludeMessages(checked)} control={<Checkbox defaultChecked={includeMessages} color="secondary" />} label="Include Messages?" />
                            <Button onClick={() => window.print()} color="secondary" variant="contained" endIcon={<Print/>}>Print</Button>
                        </Stack>
                    </Box>
                </Grid2>
                <Grid2 sx={{m: '0'}} xs={12} md={10}>
                    <Box sx={{
                        //@ts-ignore
                        width: '100%',
                        position: 'relative',
                    }} className="page">
                        <Box className="header">
                            <TwoSide>
                                <Box>
                                    <Stack direction={"row"} spacing={1} divider={<Divider flexItem orientation='vertical'/>}>
                                        <Box>
                                            <img src={"https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=" + window.location.protocol + "//" + window.location.host + "/org/ticket/" + ticketInformation.id} alt="QR CODE"/>
                                        </Box>
                                        <Box>
                                            <Typography variant="h1">Ticket #{ticketInformation.id.slice(-7)}</Typography>
                                            <Typography variant='subtitle2'>Author: {ticketInformation.author}</Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                                <Stack spacing={0}>
                                    <Typography>Created At: { new Date(ticketInformation.createdAt).toLocaleTimeString() + " " + new Date(ticketInformation.createdAt).toLocaleDateString()}</Typography>
                                    <Stack direction="row" alignItems={'center'}>
                                        <Typography>Status: </Typography>
                                        <TicketStatusComponent status={ticketInformation.status} editable={false} onChange={() => {}}/>

                                    </Stack>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: 'center',
                                        maxWidth: '20vw',
                                    }}>
                                        <Typography sx={{mr: '0.2rem'}}>Assignees: </Typography>
                                        <Stack  direction={"row"} flexWrap={"wrap"} spacing={1}>  
                                            {ticketInformation.assignees.map((a) => (<Chip size="small" key={a} label={a.split("@")[0]} />))}
                                        </Stack>
                                    </Box>
                                    {headerItems.map((i, index) => (
                                        <Typography key={i.uid}>{i.name}: {i.value}</Typography>
                                    ))}
                                </Stack>
                            </TwoSide>
                        </Box>
                        <Divider/>
                        <Grid2 container spacing={1}>
                            {gridItems.map((item, index) => {
                                if(item.type == "title") {
                                    return (
                                        <Grid2 key={item.uid} xs={12}>
                                            <Typography variant='h2'>{item.name}</Typography>
                                        </Grid2>
                                    )
                                }
                                if(item.type == "divider") {
                                    return (
                                        <Grid2 key={item.uid} xs={12}>
                                            <Divider sx={{margin: '.2rem 0'}} />
                                        </Grid2>
                                    )
                                }
                                if(item.type == "field") {
                                    return (
                                        <Grid2 key={item.uid} xs={item.xs || 6}>
                                            <Typography variant="h5">
                                                {item.name}
                                            </Typography>
                                            <Divider/>
                                            <Box className="print-dark-background" sx={{
                                                //@ts-ignore
                                                margin: '5px 0',
                                                padding: '.3rem 2rem .3rem .5rem',
                                                //@ts-ignore
                                                backgroundColor: theme.palette.neutral[700],
                                                background: 'rgba(0,0,0,.07)'
                                                                                                    
                                            }}>
                                                <Typography variant="body1" color={theme.palette.primary.contrastText}>{item.value ?? "None"}</Typography>
                                            </Box>                                        
                                        </Grid2>
                                    )
                                }
                                return <></>
                            })}
                        </Grid2>
                        {(includeMessages && messages.length > 0 )&& (
                            <Box className='print-break-before'>
                                <Divider sx={{m: '1rem 0'}}/>
                                <Typography variant='h2'>
                                    Messages
                                </Typography>
                                <Divider/>

                                {messagesLoading && (
                                    <Skeleton/>
                                )}

                                {messagesLoading ? (<Skeleton/>) : (<DataList GridColDef={columns} data={messages}/>)}
                            </Box>
                        )}
                        {(includeImages && imageRequest != null) && (
                            <Box className={includeMessages ? "print-no-cutoff" : "print-break-before"}>
                                <Divider sx={{m: '1rem 0'}}/>
                                <Typography variant='h2'>
                                    Images
                                </Typography>
                                <Divider/>

                                {imagesLoading ? (
                                <>
                                    <Skeleton  height="7vh" variant="rectangular"/>
                                    <Skeleton  height="5vh" />
                                    <Skeleton  height="8vh" />
                                </>
                                ) : (
                                    <Grid2 container spacing={1}>
                                        {imageRequest.map((i) => (
                                            <Grid2 className="print-no-cutoff" xs={12} md={6}>
                                                <ImageCard image={i.image} title={i.title} description={i.description} author={i.author}/>
                                            </Grid2>
                                        ))}
                                    </Grid2>
                                )}
                            
                            </Box>
                        )}
                    </Box>
                </Grid2>
            </Grid2>
      </DialogContent>
    </Dialog>
  )
}

export default PrintTicket