//@ts-nocheck
import { Search, WindowOutlined, WindowRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputBase,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import FlexBetween from 'components/FlexBetween'
import React from 'react'
import { useDispatch } from 'react-redux';
import { Google } from "@mui/icons-material"
import { useQuery } from "react-query";
import {useGoogleLogin} from "@react-oauth/google"
import Form from "scenes/login/Form"
import HoverPaper from "../../components/HoverPaper";
function Login() {

  
  const dispatch = useDispatch();
  const theme = useTheme();
  const isNonMobileScreen = useMediaQuery("(min-width: 1000px)")
 

  const openInNewTab = (url) => {
    window.location.href = url;
  };


  const login = useGoogleLogin( {
    onSuccess: (codeResponse) => console.log(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });



  return (
    <Box>

      <Box width="100%" backgroundColor={theme.palette.primary.main[500]} p="1rem 6%" textAlign="center">
        <Typography 
        variant="h4" 
        fontWeight="bold" 
        sx={{color: theme.palette.secondary.main}}
        >
        CTickets
        </Typography>
      </Box>

      <Paper
          elevation={16}
          sx={{
            width: isNonMobileScreen ? "50%" : "93%",
            margin: "2rem auto 0 auto",
            padding: "2rem auto",
            borderRadius: "1.5rem",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
          }}
      >
        <Typography fontWeight="500" padding="1.5rem" textAlign="center" variant="h5" sx={{mb: "1.5rem"}}>
          Welcome to CTickets, a manager for orginaztions device tickets.
        </Typography>


        <Form />


      </Paper>
      
    </Box>
  )
}

export default Login