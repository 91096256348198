import {Box, Divider, Stack, TextField, Typography} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import React from 'react'

interface DeviceConfiguration {
    device: any;
    onUpdate?: (device: any) => void;
}

const DeviceConfiguration: React.FC<DeviceConfiguration> = ({device, onUpdate}) => {
    const [object, setObject] = React.useState(device);


    return (
        <Box sx={{m: "2rem 0"}}>
            <Grid2 container spacing={1}>
                <Grid2 md={12}>
                    <Stack spacing={1}>
                        <TextField
                            label={"Serial Number"}
                            value={device.serialNumber}
                            color="secondary"
                            variant="standard"
                        />
                        <TextField
                            label={"MAC Address"}
                            value={device.macAddress.toUpperCase()}
                            color="secondary"
                            variant="standard"
                        />
                        <TextField
                            label={"Model"}
                            value={device.model}
                            color="secondary"
                            variant="standard"
                        />
                    </Stack>
                    <Typography variant="h4" sx={{m: '1.2rem 0 0 0'}}>Editable Values</Typography>
                    <Divider/>
                </Grid2>
                <Grid2 md={6} xs={12}>
                    <TextField
                        label={"Annotated Asset Id"}
                        value={object.annotatedAssetId}
                        color="secondary"
                        variant="standard"
                        onBlur={() => {
                            if(onUpdate)
                                onUpdate(object);
                        }}
                        onChange={(e) => {
                            setObject({
                                ...object,
                                annotatedAssetId: e.target.value
                            })
                        }}
                        fullWidth
                    />
                </Grid2>
                <Grid2 md={6} xs={12}>
                    <TextField
                        label={"Annotated User"}
                        value={object.annotatedUser}
                        fullWidth
                        onBlur={() => {
                            if(onUpdate)
                                onUpdate(object);
                        }}
                        onChange={(e) => {
                            setObject({
                                ...object,
                                annotatedUser: e.target.value
                            })
                        }}
                        color="secondary"
                        variant="standard"
                    />
                </Grid2>
                <Grid2 md={12} xs={12}>
                    <TextField
                        label={"Org Unit Path"}
                        value={object.orgUnitPath}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        onBlur={() => {
                            if(onUpdate)
                                onUpdate(object);
                        }}
                        onChange={(e) => {
                            setObject({
                                ...object,
                                orgUnitPath: e.target.value
                            })
                        }}
                    />
                </Grid2>
                <Grid2 md={12} xs={12}>
                    <TextField
                        label={"Annotated Notes"}
                        value={object.notes}
                        multiline
                        fullWidth
                        color="secondary"
                        variant="standard"
                        onBlur={() => {
                            if(onUpdate)
                                onUpdate(object);
                        }}
                        onChange={(e) => {
                            setObject({
                                ...object,
                                notes: e.target.value
                            })
                        }}
                    />
                </Grid2>

            </Grid2>
        </Box>
    )
}

export default DeviceConfiguration