import {Box, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid'
import React from 'react'
import CustomNoRowsOverlay from "../CustomNoRowsOverlay";

//@ts-ignore
function DataList({GridColDef, data}) {

    const rowData = GridColDef as GridColDef[];
    data = data as [];

    return (
        <Table sx={{overflowY: 'auto', pb: '10vh', mb: '10vh'}}>
            <TableHead>
                <TableRow>
                    {rowData.map(d => 
                        <TableCell>{d.headerName}</TableCell>                            
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {data != null ? data.map((d: any) => (
                    <TableRow key={d.id}>
                        {rowData.map((r) => (
                            <TableCell>{r.type != null && r.type == 'date' ? (new Date(d[r.field]).toLocaleDateString() + ' at ' + new Date(d[r.field]).toLocaleTimeString() ) : d[r.field]}</TableCell>
                        ))}
                    </TableRow>

                )
                ) : (<></>)}
            </TableBody>
        </Table>
    )
}

export default DataList