import { Box, Button, Grow, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams, GridToolbar, GridValueGetterParams } from '@mui/x-data-grid';
import Header from 'components/Header';
import React, { useEffect, useState } from 'react'
import { LazyNavigation, useGetLoanersQuery } from 'state/api'

function Loaners() {
    var {data, isLoading, error} = useGetLoanersQuery("");
    var [loaners, setLoaners] = useState([]);
    const theme = useTheme();
    useEffect(() => {
        if(!isLoading && !error) {
            setLoaners([...data])
        }
    }, [data])


    const columns: GridColDef[] = [
        {
          field: 'serialNumber',
          headerName: 'Device Serial Number',
          type: 'string',
          flex: 1,
        },
        {
            field: 'annotatedId',
            headerName: 'Asset Id',
            type: 'string',
            flex: 1,
        },
        {
            field: 'model',
            headerName: 'Model',
            type: 'string',
            flex: 1,
        },
        {
            field: 'isAssignedTicket',
            type: 'boolean',
            headerName: "Is Assigned Ticket",
            flex:1
        },
        {
          field: 'assignedTicket',
          headerName: 'Assigned Ticket',
          flex: .5,
          valueGetter: (params: GridValueGetterParams) => {
            if(params.row.isAssignedTicket) {

                const objectId = params.row.assignedTicket;
                return `${objectId.timestamp.toString(16)}${objectId.machine.toString(16)}${objectId.pid.toString(16)}${objectId.increment.toString(16)}`
            } else {
                return "None"
            }
          }
        },
          
        
    ];
    return (
        <Box m="1.5rem 2.5rem">
        <Header title="LOANERS" subtitle="List of all loaners" />
        <Grow in={true}>
  
          <Box
            mt="40px"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                cursor: "grab"
              },
              "& .MuiDataGrid-columnHeaders": {
                //@ts-ignore
  
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                //@ts-ignore
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <DataGrid
              slots={{ toolbar: GridToolbar }}
              loading={isLoading || !data}
              getRowId={(row) => row.serialNumber}
              rows={data || []}
              columns={columns}
              onRowClick={(params) => {
                LazyNavigation("/org/devices/" + params.row.serialNumber)
              }}
              initialState={{
                  sorting: {
                      sortModel: [{ field: 'annotatedId', sort: 'desc' }],
                  },
              }}
            />
          </Box>
        </Grow>
        </Box>
    )
}

export default Loaners