import { HorizontalRule } from '@mui/icons-material';
import { Card, CardContent, Typography, Divider,TextField, Stack, Box, Switch, Skeleton } from '@mui/material';
import { TimeField, TimePicker } from '@mui/x-date-pickers';
import DataEditable from 'components/data/DataEditable';
import TwoSide from 'components/data/TwoSide';
import React, { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs';
import { useGetSecurityPolicyQuery } from 'state/api';

interface SecurityPolicyCard {
    id: string;
    onChange: () => void;
}
const SecurityPolicyCard = ({id, onChange}) => {
    const {data, isLoading, error, refetch} = useGetSecurityPolicyQuery(id);
    const [expireTime, setExpireTime] = useState(30);
    useEffect(() => {
        refetch()
    }, [id])

    useEffect(() => {
        if(data != null) {
            var splitExperationTime = data.expirationTime.split(":");
            var hours = Number(splitExperationTime[0])
            var minutes = Number(splitExperationTime[1]) + hours * 60
            setExpireTime(minutes)
        }
    }, [data])

    const updatePolicy = async (policy) => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/settings/security/policies/' + id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(policy)
        })

        await refetch()
        onChange()
    }

    if(isLoading) {
        return (
            <Card sx={{height:"100%"}}>
                <CardContent sx={{height:"100%"}}>
                    <Skeleton height="500" />
                    <Skeleton height="500" />
                    <Skeleton height="500" />
                </CardContent>
            </Card>
        )
    }
    return (
        <Card>
            <CardContent>
                <Typography variant="h4" gutterBottom><b>{data.name}</b> Settings</Typography>
                <Divider sx={{ mb: '1rem' }} />

                <Stack divider={<Divider flexItem />}>
                    <TwoSide>
                        <Box>
                            <Typography variant="h6">Policy Name</Typography>
                            <Typography variant="body1">Name of the Policy.</Typography>
                        </Box>
                        <Box>
                            <DataEditable data={data.name} callback={(v) => {
                                const updateData = { ...data, name: v };
                                updatePolicy(updateData);
                            } } />
                        </Box>
                    </TwoSide>
                    <TwoSide>
                        <Box>
                            <Typography variant="h6">Group Description</Typography>
                            <Typography variant="body1">A short description of the group.</Typography>
                        </Box>
                        <Box>
                            <DataEditable multiline data={data.description} callback={(v) => {
                                const updateData = { ...data, description: v };
                                updatePolicy(updateData);
                            }}/>
                        </Box>
                    </TwoSide>
                </Stack>

                <Divider sx={{ mb: '1rem' }} />

                <Typography variant="h4">Policies</Typography>
                <Divider sx={{ mb: '1rem' }} />

                <Stack>
                    <TwoSide>
                        <Box>
                            <Typography variant="h6">Authentication Expire Time Span</Typography>
                            <Typography variant="body1">How long unitl a authentication cookie expires.</Typography>
                        </Box>
                        <Box>
                            <TextField
                                label="Experation Time in Minutes"
                                type='number'
                                variant="standard"
                                color="secondary"
                                onBlur={() => {
                                    var hours = Math.floor(expireTime / 60);
                                    var minutes = expireTime - (hours * 60)

                                    var str = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:00`
                                    
                                    const updateData = { ...data, expirationTime: str };
                                    updatePolicy(updateData);
                                }}
                                value={expireTime}
                                onChange={(v) => {
                                    var number = Number(v.target.value)

                                    setExpireTime(number);
                                }}
                            />
                        </Box>
                    </TwoSide>
                    <Divider/>
                    <TwoSide>
                        <Box>
                            <Typography variant="h5">Allow QR Code Login</Typography>
                            <Typography variant="body1">True if this group should be allowed to log in with a QR code</Typography>
                        </Box>
                        <Box display="flex" justifyContent={"center"} marginTop="auto">
                            <Switch checked={data.allowQrCodeLogin} onChange={(v,c) => {
                                const updateData = { ...data, allowQrCodeLogin: c };
                                updatePolicy(updateData);
                            }}color="secondary"/>
                        </Box>
                    </TwoSide>
                    <Divider/>

                    <Box mt="1rem">
                        <Typography variant="h5">Restrict Login to Time Range</Typography>
                        <Typography variant="body1">Restrict a login to be between time ranges.</Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            Note: Leave either side blank to have it go to the end of the day. For example 
                            if you leave the start time blank, it will assume that any time up until the end time is valid.
                            If both sides are left blank the user with the policy can log in at any time
                        </Typography>
                    </Box>
                    <Box width="100%" mt="1rem">
                        <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                            <TimeField color="secondary" value={dayjs(data.loginStartTime)} onChange={(v) => {
                                console.log(v)
                                if(v == null || v.hour() == null) {
                                    const updateData = { ...data, loginStartTime: null };
                                    updatePolicy(updateData);
                                }
                                
                                const date = new Date();

                                date.setHours(v.hour())
                                date.setMinutes(v.minute())

                                const updateData = { ...data, loginStartTime: date };
                                updatePolicy(updateData);
                            }} label="Start Time" />
                            
                            <HorizontalRule/>
                            <TimeField color="secondary" value={dayjs(data.loginEndTime)} onChange={(v) => {
                                if(v == null || v.hour() == null) {
                                    const updateData = { ...data, loginEndTime: null };
                                    updatePolicy(updateData);
                                }
                                
                                const date = new Date();

                                date.setHours(v.hour())
                                date.setMinutes(v.minute())

                                const updateData = { ...data, loginEndTime: date };
                                updatePolicy(updateData);
                            }} label="End Time"/>
                        </Stack>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
}

export default SecurityPolicyCard