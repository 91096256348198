import React, { useEffect, useState } from 'react'
import {
    LightModeOutlined,
    DarkModeOutlined,
    Menu as MenuIcon,
    SettingsOutlined,
    ArrowDropDownOutlined,
    BugReportOutlined,
    Close,
    Logout,
    Camera,
    QrCodeScanner,
    SearchOutlined,
    QrCode, Help, HelpOutlined, HelpOutlineOutlined
} from '@mui/icons-material'

import FlexBetween from './FlexBetween';
import { useDispatch } from 'react-redux';
import { setMode } from 'state';
import profileImage from "assets/default-profile.jpg";
import { AppBar, Button, IconButton, InputBase, Toolbar, useTheme, Box, Typography, Menu, MenuItem, Tooltip, Snackbar, Alert, useMediaQuery, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Search from './Search';
import Report from './Report';
import { QrCodeScanner as QrScaner } from "./QrCodeScanner";
import SearchByQrCode from './SearchByQrCode';
import DialogSearch from '../Dialog Search/DialogSearch';
import QrCodeLogin from './QrCodeLogin';
import { useSelector } from 'react-redux';
import { LazyNavigation } from 'state/api';
import NavigationBreadcrumbs from './NavigationBreadcrumbs';
import HelpTooltip from "./HelpTooltip";

//@ts-ignore
function Navbar({ user, isSidebarOpen, setIsSidebarOpen }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [anchorEl, setAnchorEl] = useState(null);
    const [reportOpen, setReportOpen] = useState(false);
    const isOpen = Boolean(anchorEl);
    //@ts-ignore
    const state = useSelector(s => s.global);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarError, setSnackbarError] = useState(false);

    const [qrOpen, setQrOpen] = useState(false);
    const [qrAllowed, setQrAllowed] = useState(false);

    //@ts-ignore
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);


    useEffect(() => {
        setQrAllowed(state.allowQrLogin)
    }, [state])


    const logOut = () => {
        fetch(process.env.REACT_APP_BASE_URL + '/auth/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        }).then(async (response) => {
            sessionStorage.removeItem('role')
            handleClose();
            LazyNavigation('/');
        })
    }


    const onReportSubmit = (report) => {
        if (report == null) return;
        fetch(process.env.REACT_APP_BASE_URL + '/reports', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({ type: report.type, description: report.details })
        }).then(async (response) => {
            if (response.status != 200) {
                setSnackbarError(true);
                setSnackbarMessage("Uh Oh! There was a problem submitting your report. Please contact support at support@ctickets.net")
                setSnackbarOpen(true);
            } else {
                setSnackbarError(false);
                setSnackbarMessage("Success! Thank you for submitting your feedback.")
                setSnackbarOpen(true);
            }

        }).catch(() => {
            setSnackbarError(true);
            setSnackbarMessage("Uh Oh! There was a problem submitting your report. Please contact support at support@ctickets.net")
            setSnackbarOpen(true);
        })
    }


    return (
        <AppBar sx={{
            position: 'static',
            background: 'none',
            width: '100%'
        }}>
            <Report isOpen={reportOpen} handleClose={(report) => { setReportOpen(false); onReportSubmit(report) }} />

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarError ? "error" : "success"} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Toolbar sx={{ justifyContent: 'space-between' }}>
                { /** Left Side */}
                <Box sx={{ maxWidth: '50vw' }}>

                    <FlexBetween>
                        <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                            <MenuIcon />
                        </IconButton>

                        {isNonMobile ? (

                            <>
                                <Search />
                                <Box sx={{ m: '0 1rem' }}>

                                    <NavigationBreadcrumbs />
                                </Box>
                            </>

                        ) : (
                            <Stack direction="row">
                                <DialogSearch />
                                <SearchByQrCode />
                            </Stack>
                        )}


                    </FlexBetween>

                </Box>


                {/** Right side */}
                <FlexBetween gap="1.5rem">

                    <Tooltip title="Change Theme">

                        <IconButton onClick={() => dispatch(setMode())}>
                            {theme.palette.mode === "dark" ? (
                                <DarkModeOutlined sx={{ fontSize: "25px" }} />
                            ) : (
                                <LightModeOutlined sx={{ fontSize: "25px" }} />
                            )}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Report a problem">
                        <IconButton onClick={() => setReportOpen(true)}>
                            <BugReportOutlined sx={{ fontSize: "25px" }} />
                        </IconButton>
                    </Tooltip>
                    <HelpTooltip />

                    {(isNonMobile && qrAllowed) && (

                        <Tooltip title="Login on your phone using a QR Code">
                            <IconButton onClick={() => { setQrOpen(true) }}>
                                <QrCode sx={{ fontSize: "25px" }} />
                            </IconButton>
                        </Tooltip>
                    )}
                    <QrCodeLogin open={qrOpen} setOpen={setQrOpen} />
                    {isNonMobile ? (

                        <FlexBetween>
                            <Button onClick={handleClick}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    textTransform: "none",
                                    gap: "1rem"
                                }}
                            >

                                <Box
                                    component="img"
                                    alt="profile"
                                    src={profileImage}
                                    height="32px"
                                    width="32px"
                                    borderRadius="50%"
                                    sx={{ objectFit: "cover" }}
                                />


                                <Box textAlign="left">

                                    <Typography fontWeight="bold" fontSize="0.85rem" sx={{
                                        //@ts-ignore
                                        color: theme.palette.secondary[100]
                                    }}>
                                        {user.firstName || "Default"} {user.lastName || "User"}
                                    </Typography>
                                    <Typography fontWeight="bold" fontSize="0.75rem" sx={{
                                        //@ts-ignore
                                        color: theme.palette.secondary[200]
                                    }}>
                                        {user.occupation || "Student"}
                                    </Typography>

                                </Box>

                                <ArrowDropDownOutlined
                                    sx={{
                                        //@ts-ignore
                                        color: theme.palette.secondary[300],
                                        fontSize: "25px"
                                    }}
                                />

                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={isOpen}
                                onClose={handleClose}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                                <MenuItem onClick={() => setReportOpen(true)}>Report a Problem</MenuItem>
                                {qrAllowed && (

                                    <MenuItem onClick={() => setQrOpen(true)}>Log in on your phone using code</MenuItem>
                                )}
                                <MenuItem onClick={logOut}>Log out</MenuItem>
                            </Menu>
                        </FlexBetween>
                    ) : (
                        <IconButton onClick={logOut}>
                            <Logout />
                        </IconButton>
                    )}

                </FlexBetween>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar