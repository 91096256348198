import { KeyboardArrowLeft } from "@mui/icons-material";
import { Button, IconButton, ListItemButton, Tab, Tabs, useTheme } from "@mui/material";
import { useMediaQuery, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";


interface DrawerOptions {
    route: string;
    label: string;
    icon: any;
}

interface Drawer {
    options: DrawerOptions[]
}
const ResponsiveDrawer:React.FC<Drawer> = ({options}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const getEndpoint = (): string => {
        var points = window.location.pathname.split('/');
        if(options.findIndex((o) => o.route == points[points.length - 1]) < 0) {
            if(options.length == 0) return "";
            return options[0].route;
        }
        return points[points.length - 1];
    }
    const [active, setActive] = useState(getEndpoint())

    const [isOpen, setIsOpen] = React.useState(!isMobile);
  
    const toggleDrawer = () => {
      setIsOpen(!isOpen);
    };
  
  
    const handleOptionClick = (route) => {
        
    };
    
    const navigate = useNavigate();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setActive(newValue);
        navigate(newValue);
    };
  
    return (
      <React.Fragment>
        <Tabs 
            value={active} 
            onChange={handleChange} 
            textColor="secondary"
            indicatorColor="secondary"
            centered
            sx={{
                mt: '2rem',
                //@ts-ignore
                //backgroundColor:theme.palette.background.alt
            }}
        >
            {options.map(item => {
                return (
                    <Tab icon={item.icon} value={item.route} label={item.label}/>
                )
            })}
        </Tabs>
        <Outlet/>
      </React.Fragment>
    );
  };
  
  export default ResponsiveDrawer;