import { Delete } from '@mui/icons-material';
import { Box, Backdrop, Typography, Divider, LinearProgress, Card, CardContent, Stack, List, ListItemButton, ListItemText, IconButton, Button, Skeleton, TextField, MenuItem, useTheme, SelectChangeEvent, Checkbox, FormControl, InputLabel, OutlinedInput, Select } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Loading from 'components/Loading';
import React, { useEffect, useState } from 'react'
import SecurityPolicyCard from '../SecurityPolicyCard';
import { useGetEventQuery, useGetEventsQuery, useGetTicketStatusesQuery } from 'state/api';
import DataEditable from 'components/data/DataEditable';
import TwoSide from 'components/data/TwoSide';
import DisplayIfTestSuite from 'components/DisplayIfTestSuite';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface TicketStatusSelector {
    currentStatuses: string[];
    onChange: (statuses: string[]) => void;
}

const TicketStatusSelector:React.FC<TicketStatusSelector> = ({currentStatuses, onChange}) => {
    const { data, isLoading, error, refetch } = useGetTicketStatusesQuery("")
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (!isLoading && data != null) {
            setOptions([...data])
        }
    }, [data])

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        onChange(value as string[]);
    };

    return (
        <Box>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel color="secondary">Status</InputLabel>
                <Select
                    multiple
                    value={currentStatuses}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                        return (selected as string[]).map((id) => {
                            const option = options.find((option) => option.id === id);
                            return option ? option.title : '';
                        }).join(', ')
                    }}
                    MenuProps={MenuProps}
                    color="secondary"
                >
                    {options.map((name) => (
                        <MenuItem key={name.id} value={name.id}>
                            <Checkbox checked={currentStatuses.indexOf(name.id) > -1} />
                            <ListItemText primary={name.title} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )
}

interface EventCard {
    id: string;
    onChange: () => void;
}
const EventCard: React.FC<EventCard> = ({ id, onChange }) => {
    const [event, setEvent] = useState(null);
    const { data, isLoading, error, refetch } = useGetEventQuery(id);

    useEffect(() => {
        refetch()
    }, [id])


    useEffect(() => {
        if (!isLoading && data != null) {
            setEvent({ ...data })
        }
    }, [data])

    const updateEvent = async (data) => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/events/' + id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(data)
        })
        onChange()
        refetch()
    }

    const getCurrentStatuses = (): string[] => {

        var events = event.parameters.split(",")

        var eventDict = {};

        events.forEach((event) => {
            var keyValue = event.split("=");
            eventDict[keyValue[0]] = keyValue[1];
        });

        if ("status" in eventDict) {
            //@ts-ignore
            var statuses = eventDict.status.split(" ")
            return statuses;
        }
        


        return [];
    }

    const updateStatuses = (statuses: string[]) => {
        //Create string
        var str = `${statuses.join(" ")}`

        var events = event.parameters.split(",")

        var eventDict = {};

        events.forEach((event) => {
            if(event != "") {

                var keyValue = event.split("=");
                eventDict[keyValue[0]] = keyValue[1];
            }
        });


        eventDict["status"] = str;

        //Combine back the object into key value pairs in array
        var eventArray = [];
        for (var key in eventDict) {
            eventArray.push(`${key}=${eventDict[key]}`);
        }
        var combined = eventArray.join(",");

        const updateData = { ...event, parameters: combined };
        updateEvent(updateData);

        
    }

    if (isLoading || event == null) {
        return (
            <Card sx={{ height: "100%" }}>
                <CardContent sx={{ height: "100%" }}>
                    <Skeleton variant="rectangular" height="500" />
                    <Skeleton variant="rectangular" height="500" />
                    <Skeleton variant="rectangular" height="500" />
                </CardContent>
            </Card>
        )
    }

    return (
        <Card>
            <CardContent>
                <Typography variant="h4" gutterBottom><b>{event.name}</b> Settings</Typography>
                <Divider sx={{ mb: '1rem' }} />

                <Stack divider={<Divider flexItem />}>
                    <TwoSide>
                        <Box>
                            <Typography variant="h5">Event Name</Typography>
                            <Typography variant="body1">Name of the Event.</Typography>
                        </Box>
                        <Box>
                            <DataEditable data={event.name} callback={(v) => {
                                const updateData = { ...event, name: v };
                                updateEvent(updateData);
                            }} />
                        </Box>
                    </TwoSide>
                    <TwoSide>
                        <Box>
                            <Typography variant="h5">Event Description</Typography>
                            <Typography variant="body1">A short description of the event.</Typography>
                        </Box>
                        <Box>
                            <DataEditable multiline data={event.description} callback={(v) => {
                                const updateData = { ...event, description: v };
                                updateEvent(updateData);
                            }} />
                        </Box>
                    </TwoSide>
                </Stack>

                <Divider sx={{ mb: '1rem' }} />

                <Typography variant="h4">Event Settings</Typography>
                <Divider sx={{ mb: '1rem' }} />

                <Stack divider={<Divider flexItem />} spacing={1}>
                    <TwoSide>
                        <Box>
                            <Typography variant="h5">Fires On</Typography>
                            <Typography variant="body1">What event should happen for this to be called.</Typography>
                        </Box>
                        <Box sx={{ width: 300 }}>
                            <TextField
                                label="Fires On"
                                value={event.eventType}
                                select
                                fullWidth
                                color="secondary"
                                onChange={(e) => {
                                    const updateData = { ...event, eventType: e.target.value };
                                    updateEvent(updateData);
                                }}
                            >
                                <MenuItem value={0}>Empty</MenuItem>
                                <MenuItem value={1}>Ticket Created</MenuItem>
                                <MenuItem value={2}>Ticket Deleted</MenuItem>
                                <MenuItem value={3}>Ticket Completed</MenuItem>
                                <MenuItem value={4}>Ticket Status Changed</MenuItem>
                            </TextField>
                        </Box>
                    </TwoSide>

                    {event.eventType == 4 && (
                        <TwoSide>
                            <Box>
                                <Typography variant="h5">Fire on Certian Status</Typography>
                                <Typography variant="body1">Select if you want it to only fire when it is set to a certian status.</Typography>
                            </Box>
                            <Box>
                                <TicketStatusSelector currentStatuses={getCurrentStatuses()} onChange={updateStatuses}/>
                            </Box>
                        </TwoSide>
                    )}
                </Stack>
            </CardContent>
        </Card>
    )
}



function EventSettings() {
    const [currentEvent, setCurrentEvent] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [events, setEvents] = useState([])
    const [backdropOpen, setBackdropOpen] = useState(false);
    const { data, isLoading, error, refetch } = useGetEventsQuery("")
    const theme = useTheme();

    const urlFragment = window.location.hash;



    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        if (!isLoading && data != null) {
            setEvents([...data])
        }
    }, [data])



    const deleteEvent = async (id, index) => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/events/' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        })

        if (response.status == 200 && index == currentIndex) {
            setCurrentIndex(-1);
            setCurrentEvent(null)
        }

        refetch()
    }

    const createNewEvent = async () => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/events/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                id: "",
                name: "New Event",
                description: "New Event",
                eventType: 0,
                parameters: ""
            })
        })

        refetch()
    }

    return (
        <Box mt="1rem">
            <Backdrop sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1
            }} open={isLoading || backdropOpen}>
                <Loading />
            </Backdrop>
            <Typography variant="h3">Event Settings</Typography>
            <Typography variant="subtitle1">Create events which can be used by actions to do simple tasks.</Typography>
            
            <DisplayIfTestSuite>
                <Typography>NOTE! Events will not fire in a test suite.</Typography>
            </DisplayIfTestSuite>

            <Divider sx={{ m: isLoading ? '1rem 0 0rem 0' : '1rem 0 2rem 0' }} />
            {isLoading && (
                <Box sx={{
                    mb: '1rem'
                }}>
                    <LinearProgress color="secondary" />
                </Box>
            )}
            <Grid2 container spacing={5}>
                <Grid2 md={3}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>Events</Typography>
                            <Divider sx={{ mb: '1rem' }} />

                            <Stack>
                                <List>

                                    {events.map((p, i) => (
                                        <ListItemButton key={p.id} onClick={() => {
                                            setCurrentIndex(i)
                                            setCurrentEvent(p.id)
                                        }} sx={{
                                            //@ts-ignore
                                            backgroundColor: currentIndex === i ? theme.palette.secondary[300] : "transparent",

                                            //@ts-ignore
                                            color: currentIndex === i ? theme.palette.primary[600] : theme.palette.secondary[100]
                                        }}>
                                            <ListItemText primary={p.name} />
                                            <IconButton onClick={() => deleteEvent(p.id, i)} size="small">
                                                <Delete />
                                            </IconButton>
                                        </ListItemButton>
                                    ))}
                                </List>
                                <Button color="secondary" variant="outlined" onClick={() => createNewEvent()}>Create New Event</Button>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid2>
                <Grid2 md={9}>
                    {currentEvent != null && (
                        <EventCard id={currentEvent} onChange={() => refetch()} />
                    )}
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default EventSettings