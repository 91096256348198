import { useTheme } from '@mui/material';
import { ResponsiveCalendar, ResponsiveTimeRange } from '@nivo/calendar';
import React, { useEffect, useState } from 'react'
import { useGetCalenderStatsQuery } from 'state/api'

interface  TicketsCalander {
  start?:Date,
  end?: Date
}

const TicketsCalander:React.FC<TicketsCalander> = ({start, end}) => {

    const getDateOneYearAgo = () => {
        const currentDate = new Date();
        const oneYearAgo = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());
        
        const year = oneYearAgo.getFullYear();
        const month = String(oneYearAgo.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(oneYearAgo.getDate()).padStart(2, '0');
        
        const dateString = `${year}-${month}-${day}`;
        return dateString;
      };

      const getCurrentDate = () => {
        const currentDate = new Date();
        
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(currentDate.getDate() ).padStart(2, '0');
        
        const dateString = `${year}-${month}-${day}`;
        return dateString;
      };

      function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        
        return `${year}-${month}-${day}`;
      }
      
    const {data, isLoading, error} = useGetCalenderStatsQuery("");
    const theme = useTheme()
    const [calanderData, setCalanderData] = useState([]);
    const [to, setTo] = useState(end == null ? getCurrentDate() : end)
    const [from, setFrom] = useState(start == null ? getDateOneYearAgo() : start)


    useEffect(() => {
        if(!isLoading) {
            var dates = data.map((d) => {
                return {
                    day: formatDate(new Date(d.key)),
                    value: d.value
                }
            });
            setCalanderData(dates);
        }
    }, [data])

    const calendarTheme = {
        calendar: {
          dayTextColor: theme.palette.text.primary,
          dayBackgroundColor: theme.palette.background.default,
          monthBorderColor: theme.palette.secondary.main,
          dayBorderColor:  theme.palette.secondary[300],
          monthLegendColor: theme.palette.secondary[200],
          monthLegendFontSize: 12,
          monthLegendOffset: 10,
          monthLegendMargin: 0,
          weekDayTextColor: theme.palette.text.secondary,
          weekDayBackgroundColor: theme.palette.background.default,
          weekDayLabelTextColor: theme.palette.text.secondary,
          weekDayLabelBackgroundColor: theme.palette.background.default,
        },
        legends: [
          {
            anchor: 'bottom-right',
            direction: 'row',
            translateY: 36,
            itemCount: 4,
            itemWidth: 42,
            itemHeight: 36,
            itemsSpacing: 14,
            itemDirection: 'right-to-left',
            itemTextColor: theme.palette.secondary[200],
          },
        ],
        // Adjust these colors for better text visibility
        textColor: theme.palette.primary.contrastText,
      };

      console.log(to)
      console.log(from)
      
    return (
        <ResponsiveTimeRange
        data={calanderData}
        from={from}
        to={to}
        theme={{
            textColor: theme.palette.primary.contrastText,
            tooltip: {
                container: {
                    color: theme.palette.primary.contrastText
                }
            }
        }}
        emptyColor={theme.palette.background.default}
        colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
        legends={calendarTheme.legends as any}
        dayBorderColor={calendarTheme.calendar.dayBorderColor}
        
        />
    )
}

export default TicketsCalander