import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { string } from "yup";

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        credentials: "include"
    }),
    reducerPath: "CTicektsApi",
    tagTypes: ["User", "Authenticated", "Students", "Users", "Ticket", "Overview", "Payments", "Audits"],

    endpoints: (build) => ({
        getUser: build.query({
            query: (id) => `org/user/${id}`,
            providesTags: ["User"]
        }),

        getSelf: build.query({
            query: () => `org/users/me`,
            providesTags: ["User"]
        }),

        getIsAuthenticated: build.query({
            query: () => `auth/is-authenticated`,
            providesTags: ["Authenticated"]
        }),

        getStudents: build.query({
            query: () => `org/students`,
            providesTags: ["Students"]
        }),

        getUsers: build.query({
            query: () => `org/users`,
            providesTags: ["Users"]
        }),

        getTicket: build.query({
            query: (id) => `org/tickets/query?id=${id}`,
            providesTags: ["Ticket"]
        }),

        getAllTickets: build.query({
            query: () => `org/tickets/`,
            providesTags: ["Ticket"]
        }),
        getDeviceFromTicket: build.query({
            query: (id) => `org/tickets/${id}/device`,
            providesTags: ["Ticket"]
        }),

        getLoanerFromTicket: build.query({
            query: (id) => `org/tickets/${id}/loaner`,
            providesTags: ["Ticket"]
        }),

        getMessagesFromTicket: build.query({
            query: (id) => `org/tickets/${id}/messages`,
            providesTags: ["Ticket"]
        }),

        getAuditsFromTicket: build.query({
            query: (id) => `org/tickets/${id}/audits`,
            providesTags: ["Ticket"]
        }),

        getStudentFromTicket: build.query({
            query: (id) => `org/tickets/${id}/student`,
            providesTags: ["Ticket"]
        }),
        getImagesFromTicket: build.query({
            query: ({ id, download = true }) => `org/tickets/${id}/images?download=${download}`,
            providesTags: ["Ticket"]
        }),
        getImageFromTicket: build.query({
            query: ({ id, imageId }) => `org/tickets/${id}/images/${imageId}`,
            providesTags: ["Ticket"]
        }),

        getTicketOverview: build.query({
            query: (id) => `org/tickets/${id}`,
            providesTags: ["Ticket"]
        }),
        getDevice: build.query({
            query: (id) => `org/provider/data/devices/${id}`,
            providesTags: []
        }),

        getTicketsFromDateRange: build.query({
            query: () => `org/tickets/range`,
            providesTags: ["Ticket"]
        }),

        getOrgOverview: build.query({
            query: () => `org/overview`,
            providesTags: ["Overview"]
        }),
        getOrgSettings: build.query({
            query: () => `org/settings`,
            providesTags: ["Overview"]
        }),
        getOrgBasic: build.query({
            query: () => `org`,
            providesTags: ["Overview"]
        }),

        getCheckinOverview: build.query({
            query: () => `org/devices/returned/overview`,
            providesTags: ["Overview"]
        }),

        getPaymentSession: build.query({
            query: (id) => `payments/stripe/session?session-id=${id}`,
            providesTags: ["Payments"]
        }),
        getServerAudits: build.query({
            query: () => `org/admin/audits`,
            providesTags: ["Audits"]
        }),
        getLinkProviderSettings: build.query({
            query: () => `org/provider/settings`,
            providesTags: ["Audits"]
        }),
        getAll: build.query({
            query: () => `org/search`,
            providesTags: ["Audits"],

        }),
        getAppHealth: build.query({
            query: () => `_health`,
            providesTags: ["Audits"]
        }),
        getAppMetadata: build.query({
            query: () => `metadata`,
            providesTags: ["Audits"]
        }),
        getOrgBilling: build.query({
            query: () => `org/billing`,
            providesTags: ["Audits"]
        }),
        getForm: build.query({
            query: () => `org/tickets/form`,
            providesTags: ["Audits"]
        }),

        getLayout: build.query({
            query: () => `org/tickets/layout`,
            providesTags: ["Audits"]
        }),

        getTable: build.query({
            query: () => `org/tickets/table`,
            providesTags: ["Audits"]
        }),

        getDataProviderUser: build.query({
            query: (id) => `org/provider/data/users/${id}`,
            providesTags: ["Audits"]
        }),
        getDataProviderUsers: build.query({
            query: () => `org/provider/data/users`,

        }),

        getStudent: build.query({
            query: (id) => `org/students/query/any?q=${id}`
        }),

        getStudentsTickets: build.query({
            query: (id) => `org/tickets/student/${id}`
        }),

        getGroups: build.query({
            query: () => `org/groups/`
        }),
        getCalenderStats: build.query({
            query: () => `org/tickets/stats/calendar`
        }),
        getWeekStats: build.query({
            query: (data?: { end?: Date, start?: Date }) => `org/tickets/stats/week${(data.end != null || data.start != null) ? '?' : ''}` +
                `${data.end != null ? `end=${data.end.toUTCString()}` : ''}${(data.end != null && data.start != null) ? '&' : ''}${data.start != null ? `start=${data.start.toUTCString()}` : ''}`

        }),
        getGroupMembers: build.query({
            query: (id) => `org/groups/${id}/members`

        }),
        getTicketAssignees: build.query({
            query: (id) => `org/tickets/${id}/assignees`
        }),
        getGroup: build.query({
            query: (id) => `org/groups/${id}`
        }),

        getAllDataFromProvider: build.query({
            query: () => `org/provider/data/all`
        }),
        getLoaners: build.query({
            query: () => `org/loaners`
        }),
        getRecentUsers: build.query({
            query: (id) => `org/provider/data/devices/${id}/recentUsers`
        }),
        getAssignedStudentFromDevice: build.query({
            query: (id) => `org/provider/data/devices/${id}/assignedUser`
        }),
        getAccessedDevicesFromUser: build.query({
            query: (id) => `org/provider/data/users/${id}/devices`
        }),
        getUserAssignedDevice: build.query({
            query: (id) => `org/provider/data/users/${id}/assignedDevice`
        }),
        getUserAssignedStudent: build.query({
            query: (id) => `org/provider/data/users/${id}/assignedStudent`
        }),
        getGenerateLoginCode: build.query({
            query: (route = "/org/dashboard") => `auth/code/generate?route=${route}`
        }),
        getRequiredScopes: build.query({
            query: () => `/org/provider/data/credentials/scopes`
        }),
        getExperimentalScopes: build.query({
            query: () => `/org/provider/data/credentials/scopes/experimental`
        }),
        getStudentBatches: build.query({
            query: () => `/org/students/batch`
        }),
        getSecuritySettings: build.query({
            query: () => `/org/settings/security`
        }),
        getOrgUnitsPaths: build.query({
            query: () => `/org/provider/data/orgunits`
        }),
        getAllSecurityPolicies: build.query({
            query: () => `/org/settings/security/policies`
        }),
        getSecurityPolicy: build.query({
            query: (id) => `/org/settings/security/policies/${id}`
        }),
        getTicketStatuses: build.query({
            query: () => `/org/tickets/status`
        }),
        getTicketStatus: build.query({
            query: (id) => `/org/tickets/status/${id}`
        }),
        getEvents: build.query({
            query: () => `/org/events`
        }),
        getEvent: build.query({
            query: (id) => `/org/events/${id}`
        }),

        getAction: build.query({
            query: (id) => `/org/actions/${id}`
        }),

        getActions: build.query({
            query: () => `/org/actions`
        }),

        getNotes: build.query({
            query: (id) => `/org/notes/query/${id}`
        }),

        getStudentGroups: build.query({
            query: () => `/org/students/groups`
        }),
        getStudentGroup: build.query({
            query: (id) => `/org/students/groups/${id}`
        }),

        getStudentGroupMembers: build.query({
            query: (id) => `/org/students/groups/${id}/members`
        }),

        getCheckinDocument: build.query({
            query: (id) => `/org/checkin/devices/${id}`
        }),
        getCheckinDocumentByDevice: build.query({
            query: ({id, year}) => `/org/checkin/query?query=` + 'device%3D' + id + `&sort=device&sortDirection=asc&year=${year}&includeNonExistentDevices=false&page=0&limit=1`
        }),
        getFullImage: build.query({
            query: (id) => `/org/images/${id}/image`
        }),
        getImageDocument: build.query({
            query: (id) => `/org/images/${id}/document`
        }),
        getDeviceCheckinHistory: build.query({
            query: (id) => `/org/checkin/${id}/history`
        }),

        getLocations: build.query({
            query: () => `/org/inventory/locations`
        }),
        getLocationsDevices: build.query({
            query: (id) => `/org/inventory/locations/${id}/devices`
        }),
        getLocationTransactions: build.query({
            query: (id) => `/org/inventory/locations/${id}/transactions`
        }),
        getLocation: build.query({
            query: (id) => `/org/inventory/locations/${id}`
        }),
        getInventoryTransactions: build.query({
            query: (id) => `/org/inventory/transactions`
        }),
        getInventoryDevices: build.query({
            query: () => `/org/inventory/devices`
        }),
        getInventoryDevice: build.query({
            query: (id) => `/org/inventory/devices/${id}`
        }),
        getInventoryDeviceLocation: build.query({
            query: (id) => `/org/inventory/devices/${id}/location`
        }),
        getInventoryDeviceTransactions: build.query({
            query: (id) => `/org/inventory/devices/${id}/transactions`
        }),

        getDocPage: build.query({
            query: (url) => `/docs?url=${encodeURI(url)}`
        }),

        getDocPages: build.query({
            query: (url) => `/docs/pages`
        }),

        getStudentDevices: build.query({
            query: (id) => `/org/students/${id}/devices`
        }),

        getStudentsForDevice: build.query({
            query: (id) => `/org/students/devices/${id}`
        }),

    })
})

export const {
    useGetUserQuery,
    useGetSelfQuery,
    useGetIsAuthenticatedQuery,
    useGetStudentsQuery,
    useGetUsersQuery,
    useGetTicketQuery,
    useGetAllTicketsQuery,
    useGetDeviceQuery,
    useGetDeviceFromTicketQuery,
    useGetLoanerFromTicketQuery,
    useGetMessagesFromTicketQuery,
    useGetAuditsFromTicketQuery,
    useGetStudentFromTicketQuery,
    useGetTicketOverviewQuery,
    useGetImagesFromTicketQuery,
    useGetTicketsFromDateRangeQuery,
    useGetOrgOverviewQuery,
    useGetOrgSettingsQuery,
    useGetCheckinOverviewQuery,
    useGetPaymentSessionQuery,
    useGetServerAuditsQuery,
    useGetLinkProviderSettingsQuery,
    useGetAllQuery,
    useGetAppHealthQuery,
    useGetAppMetadataQuery,
    useGetOrgBillingQuery,
    useGetFormQuery,
    useGetLayoutQuery,
    useGetTableQuery,
    useGetDataProviderUserQuery,
    useGetStudentQuery,
    useGetStudentsTicketsQuery,
    useGetGroupsQuery,
    useGetCalenderStatsQuery,
    useGetWeekStatsQuery,
    useGetDataProviderUsersQuery,
    useGetGroupMembersQuery,
    useGetTicketAssigneesQuery,
    useGetGroupQuery,
    useGetAllDataFromProviderQuery,
    useGetLoanersQuery,
    useGetRecentUsersQuery,
    useGetAssignedStudentFromDeviceQuery,
    useGetAccessedDevicesFromUserQuery,
    useGetUserAssignedDeviceQuery,
    useGetUserAssignedStudentQuery,
    useGetGenerateLoginCodeQuery,
    useGetRequiredScopesQuery,
    useGetExperimentalScopesQuery,
    useGetStudentBatchesQuery,
    useGetSecuritySettingsQuery,
    useGetOrgUnitsPathsQuery,
    useGetAllSecurityPoliciesQuery,
    useGetSecurityPolicyQuery,
    useGetImageFromTicketQuery,
    useGetTicketStatusQuery,
    useGetTicketStatusesQuery,
    useGetEventQuery,
    useGetEventsQuery,
    useGetActionQuery,
    useGetActionsQuery,
    useGetNotesQuery,
    useGetStudentGroupsQuery,
    useGetStudentGroupQuery,
    useGetStudentGroupMembersQuery,
    useGetOrgBasicQuery,
    useGetCheckinDocumentQuery,
    useGetCheckinDocumentByDeviceQuery,
    useGetImageDocumentQuery,
    useGetFullImageQuery,
    useGetDeviceCheckinHistoryQuery,
    useGetLocationsQuery,
    useGetLocationsDevicesQuery,
    useGetInventoryTransactionsQuery,
    useGetLocationQuery,
    useGetLocationTransactionsQuery,
    useGetInventoryDevicesQuery,
    useGetInventoryDeviceTransactionsQuery,
    useGetInventoryDeviceQuery,
    useGetInventoryDeviceLocationQuery,
    useGetDocPageQuery,
    useGetDocPagesQuery,
    useGetStudentDevicesQuery,
    useGetStudentsForDeviceQuery
} = api;
export function objectIdToString(objectId) {
    if (objectId && typeof objectId === 'object') {
        const { timestamp, machine, pid, increment } = objectId;

        // Convert each component to a hexadecimal string and pad with zeros if needed
        const timestampHex = timestamp.toString(16).padStart(8, '0');
        const machineHex = machine.toString(16).padStart(6, '0');
        const pidHex = (pid >>> 0).toString(16).padStart(4, '0');
        const incrementHex = increment.toString(16).padStart(6, '0');

        // Concatenate the components to create the ObjectId string
        const objectIdString = timestampHex + machineHex + pidHex + incrementHex;

        return objectIdString;
    } else {
        throw new Error('Invalid ObjectId object.');
    }
}


export const FetchHelper = (route: string, method: "GET" | "POST" | "PATCH", callback: (value: Response) => void, body?: object) => {
    fetch(process.env.REACT_APP_BASE_URL + route, {
        method: method,
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: "include"
    }).then(async function (response) {
        callback(response)
    }).catch((reason) => {
        callback(null);
    })

}

export const LazyNavigation = (route) => {
    const host = document.location.origin;

    document.location.href = host + route;
}

const NavigateToNewPage = (route) => {
    window.open(route, '_blank');
}
export const NavigateToProvider = (provider: "google" | "azure", type: "device" | "user", id: string) => {
    switch (provider) {
        case "google":

            switch (type) {
                case "device":
                    NavigateToNewPage("https://admin.google.com/ac/chrome/devices/" + id)
                    break;
                case "user":
                    NavigateToNewPage("https://admin.google.com/ac/users/" + id)
                    break;
            }

            break;
    }
}


export var CTicketsUserScopes = [];

const GetScopesArrayFromServer = async () => {
    let res = await fetch(process.env.REACT_APP_BASE_URL + "/auth/permissions", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: "include"
    })

    if (res.status == 200) {
        return await res.json()
    }
}

const GetScopesArray = (): string[] => {
    const lastRefreshTime = new Date(localStorage.getItem('lastRefreshTime') ?? "")
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - lastRefreshTime.getTime();
    if (timeDifference >= 1800000 || Number.isNaN(timeDifference)) {
        GetScopesArrayFromServer().then((s) => {
            localStorage.setItem("lastRefreshTime", Date.now().toString())
            if (s != null) {
                CTicketsUserScopes = s;
            }
            console.log("Updated Permissions")
        });
    }

    return CTicketsUserScopes;
}

/**
 * 
 * @param scope Converts the scope to a string representation
 * @returns A string with the scope or null if that index is not defined
 */
export const ConvertScopeIntToString = (scope: number): string | null => {
    var scopes = GetScopesArray();
    if (scope >= scopes.length) {
        return null;
    }
    return scopes[scope];
}

/**
 * Compares the scope against the minimum scope provided. The scope provided can be in the string form or the enum form
 * @param scope The scope to compare to the minimum scope. Can be in enum form or string form
 * @param minimumScope The minimum scope allowed for this to return true. Ex. write:permission:1 will allow write:permission or write 
 * @param allowLowerScope If true, will return true if the user has a lower level scope than the one provided. Ex: if the scope is read:device:checkin and the user has read:device:checkin:notes it will return true
 * @returns Returns true if the scope is >= the minimum scope
 */
export const CompareScopes = (scope: any, minimumScope: string, allowLowerScope: boolean = false): boolean => {
    if (scope == 0 || scope == "admin" || scope === minimumScope) return true;

    var stringScope = typeof scope == "string" ? scope : ConvertScopeIntToString(scope);
    var splitUserScopes = stringScope.split(":")
    var splitMinimumScope = minimumScope.split(":")

    //We only return false if we dont check for a lower scope
    if (splitMinimumScope.length < splitUserScopes.length && !allowLowerScope) return false;

    for (let i = 0; i < Math.min(splitUserScopes.length, splitMinimumScope.length); i++) {
        if (splitUserScopes[i] !== splitMinimumScope[i]) {

            // If the minimum scope is read and the user scope is write, we allow it
            if (splitMinimumScope[i] === "read" && splitUserScopes[i] === "write") continue;

            return false;
        }
    }

    return true;
}

/**
 * Checks the provided array of scopes agains a minimum scope, returns true of the scopes arrray contains the minimum scope
 * @param scopes A list of scopes to see if any scopes are >= the minimum scope
 * @param minimumScope The minimum scope allowed for this to return true. Ex. write:permission:1 will allow write:permission or write
 * @param allowLowerScope If true, will return true if the user has a lower level scope than the one provided. Ex: if the scope is read:device:checkin and the user has read:device:checkin:notes it will return true
 * @returns True if the list of scopes contains at least one scope that is >= the minimum scope
 */
export const HasMinimumScopeWithArray = (scopes: any[], minimumScope: string, allowLowerLevel: boolean = false): boolean => {
    for (var i = 0; i < scopes.length; i++) {
        if (CompareScopes(scopes[i], minimumScope, allowLowerLevel)) {
            return true;
        }
    }

    return false;
}
export interface AllQuery {
    users: User[];
    devices: Device[];
    tickets: any[];
}


export interface Device {
    serialNumber: string;
    status: Status;
    lastSync: Date;
    lastEnrollmentTime: Date;
    orgUnitPath: null;
    notes: string;
    platformVersion: null;
    firmwareVersion: null;
    kind: null;
    etag: null;
    annotatedAssetId: string;
    annotatedLocation: null;
    annotatedUser: string;
    bootMode: null;
    deviceFiles: string[];
    macAddress: null;
    model: null;
    osVersion: null;
    platformType: null;
}

export enum Status {
    Active = "active",
}

export interface User {
    id: string;
    primaryEmail: string;
    name: string;
    givenName: string;
    familyName: string;
    emails: string[];
    phones: string[];
    orgUnitPath: null;
    thumbnailPhotoUrl: string;
    aliases: string[];
}

export interface Student {
    id: string
    serialNumber: string
    accountId: string
    annotatedAssetId: string
    deviceId: string
    name: string
    email: string
    loaner: any
    loanerAssetId: any
    tickets: any[]
}

export interface ReturnDataDocument {
    id: string
    device: string
    student: any;
    returnDate: string
    notes: string
    returner: string
    returnedCharger: boolean
    returnedCase: boolean
    returnedDevice: boolean
    inGoodCondition: boolean
    images: string[]
    year: string
    studentName: string
}

//Main Function Calls to be called at the start
GetScopesArray();
