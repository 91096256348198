import {Box, Divider, Stack, TextField, Typography} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react'

interface UserConfiguration {
    user: any;
    onUpdate?: (user: any) => void;
}

const UserConfiguration: React.FC<UserConfiguration> = ({user, onUpdate}) => {
    const [object, setObject] = React.useState(user);
    return (
        <Box sx={{m: "2rem 0"}}>
            <Grid2 container spacing={1}>
                <Grid2 md={12}>
                    <Stack spacing={1}>
                        <TextField
                            label={"Email"}
                            value={user.primaryEmail}
                            color="secondary"
                            variant="standard"
                        />
                    </Stack>
                    <Typography variant="h4" sx={{m: '1.2rem 0 0 0'}}>Editable Values</Typography>
                    <Divider/>
                </Grid2>
                <Grid2 md={6} xs={12}>
                    <TextField
                        label={"Name"}
                        value={object.name}
                        color="secondary"
                        variant="standard"
                        onBlur={() => {
                            if(onUpdate)
                                onUpdate(object);
                        }}
                        onChange={(e) => {
                            setObject({
                                ...object,
                                name: e.target.value
                            })
                        }}
                        fullWidth
                    />
                </Grid2>
                <Grid2 md={12} xs={12}>
                    <TextField
                        label={"Org Unit Path"}
                        value={object.orgUnitPath}
                        color="secondary"
                        variant="standard"
                        fullWidth
                        onBlur={() => {
                            if(onUpdate)
                                onUpdate(object);
                        }}
                        onChange={(e) => {
                            setObject({
                                ...object,
                                orgUnitPath: e.target.value
                            })
                        }}
                    />
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default UserConfiguration