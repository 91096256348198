import { Box, Tab, Tabs, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

function OrganizationSettingsNav() {
    const defaultSettings = [
        {
            name: 'Overview',
            link: 'overview'
        },

    ]

    const onwerSettings = [
        {
            name: 'Overview',
            link: 'overview'
        },
        {
            name: 'Groups',
            link: 'groups'
        },
        {
            name: "Security",
            link: 'security'
        },
        {
            name: "Events",
            link: 'events'
        },
        {
            name: "Actions",
            link: 'actions'
        }
    ]

    //@ts-ignore
    const state = useSelector(s => s.global);

    const navigate = useNavigate();
    const getEndpoint = (): string => {
        var points = window.location.pathname.split('/');
        return points[points.length - 1];
    }
    const [active, setActive] = useState(getEndpoint())
    const theme = useTheme();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setActive(newValue);
        navigate(newValue);
    };
    const [routes, setRoutes] = useState(defaultSettings);
    useEffect(() => {
        if(state.userRole == "Creator") {
            setRoutes([...onwerSettings])
        } else {
            setRoutes([...defaultSettings])
        }
    }, [state])
  return (
    <Box>
    <Tabs 
        value={active} 
        onChange={handleChange} 
        textColor="secondary"
        indicatorColor="secondary"
        
        sx={{
            mt: '2rem',
            //@ts-ignore
            //backgroundColor:theme.palette.background.alt
        }}
    >
        {routes.map(item => {
            return (
                <Tab value={item.link} label={item.name}/>
            )
        })}
    </Tabs>
    <Outlet/>
    </Box>
  )
}

export default OrganizationSettingsNav