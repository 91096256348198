import React, { useEffect, useRef, useState } from 'react';
import { Formik, Field, Form, useFormikContext, FormikContext } from 'formik';
import * as Yup from 'yup';
import { Stepper, Step, StepLabel, Box, Button, Grid, TextField, Select, MenuItem, FormControl, InputLabel, Autocomplete, Grow, Typography, Divider } from '@mui/material';

interface UpdateCallback {
  formId: string;
  callback: (value: any) => string;
}

interface AutocompleteOption {
  label: string;
  id: string;
  offset?: number;
}

export interface FormItem {
  step: number;
  name: string;
  label: string;
  getter?: 'students' | 'loaners' | 'devices';
  type: 'select' | 'text' | 'autocomplete';
  data?: AutocompleteOption[];
  initialValue?: string;
  valueMutator?: (string) => string;
  autocompleteOptions?: AutocompleteOption[];
  validator?: () => Yup.Schema<any>;
  updateCallback?: UpdateCallback;
}

interface CustomFormProps {
  formItems: FormItem[];
  steps: string[];
  onFormSubmit?: (values: Record<string, any>, opts: any) => void;
 
}

interface StepContentProps {
  formItems: FormItem[];
  
}

const StepContent: React.FC<StepContentProps> = ({ formItems }) => {
  const { errors } = useFormikContext();

  const formik = useFormikContext<any>();
  const { values, setFieldValue } = useFormikContext();
  const previousValuesRef = useRef(values);

  useEffect(() => {
    Object.entries(values).forEach(([key, value]) => {
      if (value !== previousValuesRef.current[key]) {
        formItems.forEach(item => {
          if (item.updateCallback) {
            const { formId, callback } = item.updateCallback;
            if (formId === key) {
              const newFieldValue = callback(value);
              if (newFieldValue !== value) {
                // Using setTimeout here to defer the update until after render
                setTimeout(() => {
                  setFieldValue(item.name, newFieldValue);
                }, 0);
              }
            }
          }
        });
      }
    });
    previousValuesRef.current = values;
  }, [values, setFieldValue, formItems]);
  
  



  return (
    <Grid container direction="column" spacing={2}>
      {formItems.map((item: FormItem, index: number) => (
        <Grid item key={index}>
          <Grow in={true} timeout={1000}>
            <div>
              {item.type === 'text' && (
                <Field
                  as={TextField}
                  name={item.name}
                  label={item.label}
                  error={Boolean(errors[item.name])}
                  helperText={errors[item.name] || ''}
                  color="secondary"
                  InputLabelProps={{
                    shrink: Boolean(values[item.name]),
                  }}
                  value={values[item.name]}
                  fullWidth
                />
              )}
              {item.type === 'select' && (
                <FormControl fullWidth>
                  <InputLabel color="secondary">{item.label}</InputLabel>
                  <Field
                    as={Select}
                    name={item.name}
                    color="secondary"
                    onChange={(event) => {
                      const selectedOption = item.data?.find(option => option.id === event.target.value);
                      setFieldValue(`${item.name}Offset`, selectedOption?.offset || 0);
                      setFieldValue(item.name, event.target.value);
                    }}
                  >
                    {item.data?.map((option, index) => (
                      <MenuItem key={index} value={option.id}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              )}

                {item.type === 'autocomplete' && (
                  <FormikContext.Consumer>
                    {({ setFieldValue, errors }) => (
                      <Autocomplete
                        freeSolo
                        options={item.autocompleteOptions || []}
                        fullWidth
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
                        //@ts-ignore
                        getOptionSelected={(option, value) => option.id === value.id} 
                        value={item.autocompleteOptions?.find(option => option.id === values[item.name]) || ''}
                        renderInput={(params) => (
                          //@ts-ignore
                          <TextField {...params} color="secondary" label={item.name} error={Boolean(errors[item.name])} helperText={errors[item.name] || ''} />
                        )}
                        onChange={(event, value) => {
                          const selectedValue = typeof value === 'string' ? value : value ? value.id : '';
                          setFieldValue(item.name, selectedValue);
                        }}
                      />

                    )}
                  </FormikContext.Consumer>
                )}

            </div>

          </Grow>
        </Grid>
      ))}
    </Grid>
  );
};

const CustomForm: React.FC<CustomFormProps> = ({ formItems, steps, onFormSubmit }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [stepStack, setStepStack] = useState<number[]>([]);
  const [visitedSteps, setVisitedSteps] = useState<number[]>([]);



  // Group formItems by step
  const formItemsByStep = formItems.reduce((result, item) => {
    (result[item.step] = result[item.step] || []).push(item);
    return result;
  }, {} as Record<number, FormItem[]>);

  // Compute initialValues
  const initialValues = formItems.reduce((acc, curr) => {
    return { ...acc, [curr.name]: curr.initialValue || '', [`${curr.name}Offset`]: 0 };
  }, {});
  

  // Compute validationSchema
  const validationSchema = Yup.object().shape(
    formItems.reduce((acc, curr) => {
      return curr.validator ? { ...acc, [curr.name]: curr.validator() } : acc;
    }, {})
  );

  return (
    <Box m="1rem" width="70%" mx="auto">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit || ((values) => { console.log(values);})}
        validateOnMount={false}
        validateOnBlur={false}

      >
        {({ values, validateForm, resetForm }) => (
          <Form>
            <Stepper color="secondary" activeStep={currentStep}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel color="secondary">{step}</StepLabel> 
                </Step>
              ))}
            </Stepper>
            <Box m={2}>
              {steps.map((step, index) => (
                <Box key={index} hidden={currentStep !== index}>
                  <Typography variant="h3">{step}</Typography> 
                  <Divider sx={{m: '1rem 0'}}/>
                  <StepContent formItems={formItemsByStep[index] || []} /> 
                </Box>
              ))}
            </Box>
            <Box m={2}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                  {currentStep > 0 && (
                    <Button variant="contained" onClick={() => {
                      setStepStack(prevStack => {
                        const newStack = [...prevStack];
                        const lastStep = newStack.pop() || 0; // Pop last step from the stack
                        setCurrentStep(lastStep);
                        return newStack;
                      });
                    }}>
                      Back
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {currentStep < steps.length - 1 ? (
                    <Button
                    variant="contained"
                    onClick={() => {
                      const currentStepItems = formItemsByStep[currentStep];
                      const currentStepValues = currentStepItems.reduce((acc, curr) => {
                        return { ...acc, [curr.name]: values[curr.name] };
                      }, {});
                      const currentStepSchema = Yup.object().shape(
                        currentStepItems.reduce((acc, curr) => {
                          return curr.validator ? { ...acc, [curr.name]: curr.validator() } : acc;
                        }, {})
                      );
                      
                      currentStepSchema
                      .validate(currentStepValues, { abortEarly: false })
                      .then(() => {
                        const stepOffset = values[`${currentStepItems.find(item => item.type === 'select')?.name}Offset`] || 0;
                        if (stepOffset < 0) {
                          onFormSubmit?.(values, {resetForm: resetForm});
                        } else {
                          setStepStack(prevStack => [...prevStack, currentStep]);
                          setCurrentStep((step) => step + 1 + stepOffset);
                          setVisitedSteps((steps) => [...steps, currentStep]);  
                        }
                      })
                      .catch((error) => console.log(error));
                    
                    }}
                  >
                    Next
                  </Button>


                  ) : (
                  <Button variant="contained" color="primary" onClick={() => {
                    const visitedStepItems = visitedSteps.reduce((items, step) => {
                      return [...items, ...(formItemsByStep[step] || [])];
                    }, [] as FormItem[]);
                    const visitedStepValues = visitedStepItems.reduce((acc, curr) => {
                      return { ...acc, [curr.name]: values[curr.name] };
                    }, {});
                    const visitedStepSchema = Yup.object().shape(
                      visitedStepItems.reduce((acc, curr) => {
                        return curr.validator ? { ...acc, [curr.name]: curr.validator() } : acc;
                      }, {})
                    );
                    visitedStepSchema
                      .validate(visitedStepValues, { abortEarly: false })
                      .then(() => {
                        // If validation passes, call the onFormSubmit function
                        onFormSubmit?.(values, {resetForm: resetForm});
                      })
                      .catch((error) => console.log(error));
                  }}>
                    Submit
                  </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CustomForm;
