import { Box, Dialog, DialogContent, Divider, Grow, Typography } from '@mui/material'
import React, { useState } from 'react'
import SetupCredentials from './Setup/SetupCredentials'
import SetupLink from './Setup/SetupLink'

function SetupProvider() {
    const [step, setStep] = useState(0)
    const [open, setIsOpen] = useState(true);
    const [device, setDevice] = useState({} as any)
    const [user, setUser] = useState({} as any)

    const navigate = () => {
        const host = document.location.origin;
        document.location.href = host + "/org/dashboard/"
    }
    return (
      <Box>
        <Dialog maxWidth="lg" open={open} >
            <DialogContent>
                <Box sx={{
                    textAlign: "center"
                }}>
                    <Grow in={true}>
                        <Box>

                            <Typography variant="h2" sx={{m: '1rem 0'}}>Welcome to your organization!</Typography>
                            <Typography variant='h5' >Lets get you set up!</Typography>
                            <Divider sx={{mb: '2rem'}}/>
                        </Box>
                    </Grow>

                    <SetupCredentials onSubmit={() => setStep(1)}/>

                    {step == 1 && (<SetupLink OnSetupComplete={() => {setIsOpen(false); navigate()}}/>)}
                </Box>
            </DialogContent>

        </Dialog>


      </Box>
    )
}

export default SetupProvider