import { Add, Delete, Edit } from '@mui/icons-material';
import { Autocomplete, Avatar, Box, Button, Checkbox, Chip, Dialog, DialogContent, Divider, FormControlLabel, IconButton, MenuItem, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react'
import { useGetTicketAssigneesQuery, useGetUsersQuery } from 'state/api';

interface Assignees {
    id: string;
    canEdit: boolean;
    onChange?: () => void;
}
interface Assignee {
    name: string;
    iconUrl?: string;
    onClick: () => void;
}

const Assignee: React.FC<Assignee> = ({name, iconUrl, onClick}) => {

    return (
        <Chip size="small"  onClick={onClick} avatar={<Avatar alt={name} src={iconUrl} />} label={name} variant="outlined"/>
    )
}

interface AddAssignee {
    open: boolean,
    setOpen: (boolean) => void;
    onAdd: (user:string, notify: boolean) => void;
    onRemove: (user:string) => void;
    options: any[],
    currentAssignees: any[]
}

const AddAssignee: React.FC<AddAssignee> = ({open, setOpen, onAdd, options, currentAssignees, onRemove}) => {
    const theme = useTheme();
    const [userToAdd, setUserToAdd] = useState("");
    const [addableAssignees, setAddableAssignees] = useState([])
    const [notifyUser, setNotifyUser] = useState(false);

    useEffect(() => {
        if(options != null){

            //Remove any options that are in current assignees
            var filteredArr = options.filter(v => currentAssignees.findIndex(d => d.email == v.email) == -1)
    
            setAddableAssignees([...filteredArr || []])
        } else{
            setAddableAssignees([])
        }
    }, [options, currentAssignees])

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
            onClose={() => setOpen(false)}
        >
            <DialogContent>
                <Typography variant="h3">Edit Assignees</Typography>
                <Divider sx={{mb: '1rem'}}/>
                <Stack>
                    {currentAssignees.map((a) => (
                        <Box key={a} sx={{
                            mt: '1rem',
                            //@ts-ignore
                            backgroundColor:theme.palette.background.alt,
                            borderRadius:"0.55rem",
                            padding: '1rem',
                            mb: '1rem'
                              
                        }}>

                            <Grid2 container spacing={2}>
                                <Grid2 xs={10}>
                                    <Stack direction="row" spacing={2}>
                                        <Avatar alt={a.name} src={a.iconUrl}/>
                                        <Box display="flex" flexWrap={"wrap"} alignContent={"center"}>
                                            <Typography>{a.email}</Typography>
                                        </Box>
                                    </Stack>
                                </Grid2>
                                <Grid2 xs={2}>
                                    <IconButton onClick={() => {onRemove(a.email); setOpen(false);}} color="error">
                                        <Delete/>
                                    </IconButton>
                                </Grid2>
                            </Grid2>
                        </Box>
                    ))}
                </Stack>
                <Divider sx={{mb:'1rem'}}/>
                {addableAssignees != null && (
                    <Stack spacing={1}>

                        <TextField 
                            label="Add Assignee"
                            select
                            color={'secondary'}
                            fullWidth
                            value={userToAdd}
                            onChange={(v) => setUserToAdd(v.target.value)}
                        >
                            
                            {addableAssignees.map((v) => (
                                <MenuItem key={v.email} value={v.email}>{v.email}</MenuItem>
                            ))}

                        </TextField>
                        
                        <FormControlLabel control={<Checkbox color="secondary" value={notifyUser} onChange={(e,c) => setNotifyUser(c)} />} label="Notify User?" />

                        <Button onClick={() => {onAdd(userToAdd, notifyUser); setOpen(false)}} variant="outlined" color="secondary" disabled={userToAdd == ""}>Assign</Button>
                    </Stack>

                
                )}
            </DialogContent>
        </Dialog>
    )
}

const Assignees: React.FC<Assignees> = ({id, canEdit, onChange}) => {
    
    const [isEditing, setIsEditing] = useState(false);
    const [assignees, setAssignees] = useState([]);
    const {data: users, isLoading: usersIsLoading} = useGetUsersQuery("");
    const {data: assigneesResponse, isLoading: assigneesLoading, refetch: refetchAssignees} = useGetTicketAssigneesQuery(id);
    const isSmallScreen = useMediaQuery('(max-width: 600px)');

    useEffect(() => {
        if(!assigneesLoading) {
            setAssignees([...assigneesResponse])
        }
    }, [assigneesResponse])

    const addAssignee = async (email, notify) => {
        await fetch(process.env.REACT_APP_BASE_URL + `/org/tickets/${id}/assignees?email=${email}&notify=${notify}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            })

        if(onChange != null)
            onChange()
        refetchAssignees()
    }

    const removeAssignee = async (email) => {
        await fetch(process.env.REACT_APP_BASE_URL + `/org/tickets/${id}/assignees?email=${email}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            })

        if(onChange != null)
            onChange()
        refetchAssignees()
    }

    

    const handleBlur = () => {
        setIsEditing(false);
    }
    return (
        <Box>
            <AddAssignee open={isEditing} setOpen={setIsEditing} options={users} currentAssignees={assignees} onAdd={(email, notify) => {
                addAssignee(email, notify)
            }} onRemove={(email) => {removeAssignee(email)}}/>

                <Box sx={{
                    display: 'flex',
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: 'center',
                    maxWidth: isSmallScreen ? "100%" : '20vw'
                }}>
                    {canEdit ? (
                        <>
                            <Typography sx={{mr: '0.2rem'}}>Assignees: </Typography>
                            <Stack direction={"row"} flexWrap={"wrap"} spacing={1}>  
                                {assignees.map((a) => (<Assignee onClick={() => setIsEditing(true)} key={a.email} name={a.firstName + " " + a.lastName} iconUrl={a.avatar}/>))}
                                <IconButton size="small" onClick={() => setIsEditing(true)}>
                                    <Add fontSize='inherit'/>
                                </IconButton>
                            </Stack>
                        </>
                    ) : (
                        <>
                            <Typography sx={{mr: '0.2rem'}}>Assignees: </Typography>
                            <Stack direction={"row"} flexWrap={"wrap"} spacing={1}>  
                                {assignees.map((a) => (<Assignee onClick={() => {}} key={a.email} name={a.firstName + " " + a.lastName} iconUrl={a.avatar}/>))}
                            </Stack>
                        </>
                    )}

            </Box>
        </Box>
    )
}

export default Assignees