import { ChevronRightOutlined } from '@mui/icons-material';
import { Box, Breadcrumbs, ButtonGroup, Link, List, ListItem, ListItemButton, ListItemText, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material'
import Error from 'components/Error';
import Header from 'components/Header'
import Loading from 'components/Loading';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { CTicketsUserScopes, useGetSelfQuery } from 'state/api';

const adminSettings = [

    {
        name: 'Account',
        link: 'account',
        requireCreator: false,
    },
    {
        name: 'Provider Settings',
        link: 'provider',
        requireCreator: true,
    },
    {
        name: 'Server Audits',
        link: 'audits',
        requireCreator: false,
    },
    {
        name: 'Organization Settings',
        link: 'organization',
        requireCreator: true,
    },
    {
        name: 'Ticket Settings',
        link: 'ticket/form',
        requireCreator: true,
    },
    {
        name: 'Billing',
        link: 'billing',
        requireCreator: true,
    },
    {
        name: 'Data Export',
        link: 'export'
    }, 
    {
        name: 'Experimental',
        link: 'experimental',
        requireCreator: true,
    }, 

]

const defaultSettings = [
    {
        name: 'Account',
        link: 'account'
    }
]



function Settings() {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const getEndpoint = (): string => {
        var points = window.location.pathname.split('/');
        return points[points.length - 1];
    }
    //@ts-ignore
    const state = useSelector(s => s.global);
    const [active, setActive] = useState(getEndpoint())
    const [settings, updateSettings] = useState(defaultSettings);
    const {data, isLoading, error} = useGetSelfQuery("");
    const navigate = useNavigate();

    useEffect(() => {
        if(data != null && (data.user.scopes.includes(CTicketsUserScopes.indexOf("write:organization")) || data.user.scopes.includes(0))) {

            if(state.userRole == 'Creator') {
                updateSettings(adminSettings)
            } else {
                updateSettings(adminSettings.filter(s => !s.requireCreator))
            }
        }
    }, [data, state])

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setActive(newValue);
        navigate(newValue);
    };

    if(isLoading) return (<Loading/>)
    if(error) return (<Error Message="Error loading settings!"/>)
    return (
    <Box m="1.5rem 2.5rem">
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Header title={"Settings"} subtitle={"Customize your settings"}/>     
        </Box>

        <Box>
            <Tabs 
                value={active} 
                onChange={handleChange} 
                textColor="secondary"
                indicatorColor="secondary"
                sx={{
                    mt: '2rem',
                    //@ts-ignore
                    //backgroundColor:theme.palette.background.alt
                }}
            >
                {settings.map(item => {
                    return (
                        <Tab value={item.link} label={item.name}/>
                    )
                })}
            </Tabs>
        </Box>
        <Outlet/>
{/* 
        <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(5, 1fr)"
            gridAutoRows="160px"
            gap="100px"
            sx={{
            "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
            }}
        >
            <Box sx={{
                gridColumn:"span 1",
                gridRow:"span 4",
                p:"1.25rem 1rem",
                flex:"1 1 100%",
                //@ts-ignore
                backgroundColor:theme.palette.background.alt,
                borderRadius:"0.25rem",
            }}>
                <List>
                    {
                        settings.map((item) => {

                            return (
                            <ListItem key={item.name}>
                                <ListItemButton onClick={() => {
                                    navigate(`${item.link}`);
                                    setActive(`/${item.link}`);
                                  }}
                                  sx={{
                                    //@ts-ignore
                                    backgroundColor: active === item.link ? theme.palette.secondary[300] : "transparent",
                                    //@ts-ignore
                                    color: active === item.link ? theme.palette.primary[600] : theme.palette.secondary[100]
                                  }}

                                  >
                                   
                                    <ListItemText primary={item.name} />
                                    {active === item.link && (
                                      <ChevronRightOutlined sx={{ml:"auto"}} />
                                    )}
                                </ListItemButton>
                            </ListItem>
                            )
                        })
                    }
                </List>
            </Box>

            <Box sx={{
                gridColumn:"span 4",
                gridRow:"auto",
                p:"1.25rem 1rem",
                flex:"1 1 100%",
                //@ts-ignore
                backgroundColor:theme.palette.background.alt,
                borderRadius:"0.55rem",
            }}>
                <Outlet/>
            </Box>
        </Box> */}
    </Box>
  )
}

export default Settings