import React from 'react';
import LazyAutocomplete from "./LazyAutocomplete";
import {Box} from "@mui/material";

interface StudentGroupsAutocompleteProps {
    onChange: (value: StudentGroup[]) => void;
}

export interface StudentGroup {
    name: string;
    id: string;
    description: string;
    students: any[];
    users: any[];
    devices: any[];
}
const StudentGroupsAutocomplete:React.FC<StudentGroupsAutocompleteProps> = ({onChange}) => {
    return (
        <Box>
            <LazyAutocomplete
                dataUrl={process.env.REACT_APP_BASE_URL + '/org/students/groups/query'}
                label={"Student Groups"}
                getLabel={(v) => v.name}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
                query={(q) => `?type=name%2Bowner&limit=20&query=${q === "" ? "all" : q}`}
                onValueChange={(v) => onChange(v)}
                value={""}
                fullWidth
            />
        </Box>
    );
};

export default StudentGroupsAutocomplete;