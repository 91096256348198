import React from 'react'
import LazyAutocomplete from './LazyAutocomplete';
import { Box, Avatar } from '@mui/material';

interface QueryAllAutocomplete {
    onValueChange: (value: any) => void;
    label: string;
    freeSolo?: boolean;
    showIcon?: boolean;
    initialValue?: string;
    value: string;
}
const UserAutocomplete: React.FC<QueryAllAutocomplete> = ({ onValueChange, label, freeSolo, value, initialValue, showIcon }) => {

    if (showIcon) {
        return (
            <LazyAutocomplete
                label={label}
                fullWidth
                onValueChange={onValueChange}
                dataUrl={process.env.REACT_APP_BASE_URL + '/org/provider/data/users/query'}
                getLabel={(e) => e.label}
                isOptionEqualToValue={(e, v) => e == v}
                query={(q) => `?q=${q}`}
                freeSolo={freeSolo}
                render={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <Avatar
                            alt={option.name}
                            src={option.thumbnailPhotoUrl}
                        />
                        {option.primaryEmail}
                    </Box>

                )}
                processRequest={async (response) => {
                    var data = await response.json()
                    var users = data.map((u) => {
                        var label = "Email: " + u.primaryEmail;
                        if (u.name != null) label += '\n Name: ' + u.name;
                        return {
                            label: label,
                            id: u.id,
                            ...u
                        }
                    })
                    return [...users]
                }}
                value={value}
            />
        )
    }





    return (
        <LazyAutocomplete
            label={label}
            fullWidth
            onValueChange={onValueChange}
            dataUrl={process.env.REACT_APP_BASE_URL + '/org/provider/data/users/query'}
            getLabel={(e) => e.label}
            isOptionEqualToValue={(e, v) => e == v}
            query={(q) => `?q=${q}`}
            freeSolo={freeSolo}
            processRequest={async (response) => {
                var data = await response.json()
                var users = data.map((u) => {
                    var label = "Email: " + u.primaryEmail;
                    if (u.name != null) label += '\n Name: ' + u.name;
                    return {
                        label: label,
                        id: u.id,
                        ...u
                    }
                })
                return [...users]
            }}
            value={value}
        />
    )
}

export default UserAutocomplete