import React, {useEffect, useState} from 'react';
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogContent,
    Divider,
    Grow,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import StudentGroupsAutocomplete, {StudentGroup} from "../../components/Autocomplete/StudentGroupsAutocomplete";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {ReturnDataDocument} from "../../state/api";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import {Article, Visibility} from "@mui/icons-material";
import {CheckinImages} from "../data/device/CheckDeviceIn";
import LazyTextField from "../../components/data/LazyTextField";
import {appendQuery} from "./DeviceCheckinOverview";
import {useNavigate} from "react-router-dom";

const StudentGroupCheckin = () => {
    const [currentGroup, setCurrentGroup] = useState(null);

    useEffect(() => {
        // Get the params from the url
        const params = new URLSearchParams(window.location.search);
        const groupId = params.get('group');

        // If there is a group id, get the group from the api and set it as the current group
        if (groupId) {
            fetch(process.env.REACT_APP_BASE_URL + '/org/students/groups/' + groupId, {
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then((data) => {
                setCurrentGroup(data);
            })
        }
    }, []);

    return (
        <Box>
            <Typography variant={'h4'}>Check in devices by Group</Typography>
            <Divider/>
            <StudentGroupsAutocomplete onChange={(v) => {
                //@ts-ignore
                window.history.pushState({}, "", appendQuery(window.location.search, {group: v.id}));
                setCurrentGroup(v);

            }}/>
            <Divider sx={{mb: '1rem'}}/>

            {currentGroup && (
                <StudentGroupCheckinDisplay group={currentGroup}/>
            )}
        </Box>
    );
};

interface StudentGroupCheckinDisplayProps {
    group: StudentGroup;
}

const StudentGroupCheckinDisplay: React.FC<StudentGroupCheckinDisplayProps> = ({group}) => {
    const navigate = useNavigate();

    /**
     * Additional data dialog open
     */
    const [dialogOpen, setDialogOpen] = useState(false);

    /**
     * Selected member for the dialog
     */
    const [selectedMember, setSelectedMember] = useState(null);

    /**
     * Members retrieved from api
     */
    const [members, setMembers] = useState([] as ReturnDataDocument[]);

    /**
     * Table Format:
     * Student Name | Device Serial Number | Device Annotated Asset ID | Turned In Device | Turned In Case | Turned In Charger | Turned In At
     */
    const colDefs: GridColDef[] = [
        {
            field: 'studentName',
            headerName: 'Student Name',
            flex: 1,
            editable: false,
        },
        {
            field: 'device',
            headerName: 'Device Serial Number',
            flex: 1,
            editable: false,
        },
        {
            field: 'returnedDevice',
            headerName: 'Turned In Device',
            flex: .6,
            editable: false,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value}
                    onChange={(e, c) => {
                        UpdateMember(params.row as ReturnDataDocument, 'device', c)
                    }}
                    color='secondary'
                />
            ),
        },
        {
            field: 'returnedCase',
            headerName: 'Turned In Case',
            flex: .6,
            editable: false,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value}
                    onChange={(e, c) => {
                        UpdateMember(params.row as ReturnDataDocument, 'case', c)
                    }}
                    color='secondary'
                />
            ),
        },
        {
            field: 'returnedCharger',
            headerName: 'Turned In Charger',
            flex: .6,
            editable: false,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value}
                    onChange={(e, c) => {
                        UpdateMember(params.row as ReturnDataDocument, 'charger', c)
                    }}
                    color='secondary'
                />
            ),
        },
        {
            field: 'returnDate',
            headerName: 'Turned In At',
            flex: .8,
            editable: false,
            type: "dateTime",
            valueGetter: (params) => {
                // Convert the string into a date
                return new Date(params.row.returnDate)
            }
        },
        {
            field: 'returner',
            headerName: 'Turned In By',
            flex: 1,
            editable: false,
        },
        {
            field: '',
            headerName: 'Actions',
            flex: .5,
            editable: false,
            renderCell: (params) => (
                <Stack direction="row" spacing={1}>
                    <IconButton onClick={() => {
                        setSelectedMember(params.row.device);
                        setDialogOpen(true);
                    }} color={"success"}>
                        <Article/>
                    </IconButton>
                    <IconButton onClick={() => {
                        navigate('/org/devices/' + params.row.device)
                    }}>
                        <Visibility/>
                    </IconButton>
                </Stack>
            ),
        }
    ]

    const getMembers = async () => {
        // Every time the group changes, we need to fetch the members of the group
        fetch(process.env.REACT_APP_BASE_URL + '/org/students/groups/' + group.id + '/members/checkin', {
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
        }).then((data) => {
            setMembers([...data]);
        })
    }

    useEffect(() => {
        //@ts-ignore
        getMembers();

        //@ts-ignore
    }, [group])


    const UpdateMember = async (member: ReturnDataDocument, type: 'device' | 'case' | 'charger', value: boolean) => {
        let response = await fetch(process.env.REACT_APP_BASE_URL + `/org/checkin/device/update?deviceId=${member.device}&type=${type}&value=${value}`, {
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
            method: "POST"
        })

        if (response.status == 200) {
            let updatedMembers = members.map((m) => {
                if (m.device == member.device) {
                    // Convert type into the correct field
                    switch (type) {
                        case "device":
                            m.returnedDevice = value;
                            break;
                        case "case":
                            m.returnedCase = value;
                            break;
                        case "charger":
                            m.returnedCharger = value;
                            break;
                    }
                }
                return m;
            })
            setMembers(updatedMembers);
        }
    }

    return (
        <Grow in={true}>
            <Card>
                <CardContent>
                    <ReturnDataInfoDialog open={dialogOpen} setOpen={setDialogOpen} device={selectedMember} refetch={getMembers}/>

                    <Typography variant={'h3'}>{group.name}</Typography>
                    <Typography variant={'subtitle1'}>{group.description}</Typography>
                    <Divider sx={{mb: '1rem'}}/>

                    <DataGrid
                        columns={colDefs}
                        rows={members ?? []}
                        getRowId={(row) => row.device}
                        autoHeight
                        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                    />
                </CardContent>
            </Card>
        </Grow>

    )
}


interface ReturnDataInfoDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    device: string;
    refetch: any;
}
const ReturnDataInfoDialog:React.FC<ReturnDataInfoDialogProps> = ({open, setOpen, device, refetch}) => {
    const [document, setDocument] = useState(null);
    const updateData = async () => {
        // Get the return document for this device
        const response = await fetch(process.env.REACT_APP_BASE_URL + `/org/checkin/${device}`, {
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
        })

        if(response.status == 200) {
            const data = await response.json();
            setDocument(data);
        }
    }

    const updateNotes = async (notes: string) => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + `/org/checkin/${device}/notes?notes=${notes}`, {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
        })

        if(response.status == 200) {
            refetch();
        }
    }

    const onImageAdded = (success) => {
        // If the image was added successfully, forse a reset of the data
        if(success) {
            updateData()

            // Also force this to remount
            refetch();
        }
    }

    const onImageRemoved = (success) => {
        // If the image was removed successfully, forse a reset of the data
        if(success) {
            updateData()

            // Also force this to remount
            refetch();
        }
    }

    useEffect(() => {
        setDocument(null)
        if (!open || device == null) return;
        updateData()
    }, [device]);

    return (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth={"md"} fullWidth>
            <DialogContent>
                {document && (
                    <>
                        <Typography variant={'h3'}>{document.device}</Typography>
                        <Typography variant={'subtitle1'}>Notes and Images for this return data</Typography>
                        <Divider sx={{mb: '1rem'}}/>
                        <Box sx={{m: '1rem 0'}}>
                            <LazyTextField
                                value={document.notes}
                                label='Notes'
                                multiline
                                multilineLevels={4}
                                onChange={(v) => updateNotes(v)}
                            />
                        </Box>
                        <Box>
                            <CheckinImages data={document} refetch={refetch} onImageAdded={onImageAdded} onImageRemoved={onImageRemoved}/>
                        </Box>
                    </>
                )}
            </DialogContent>
        </Dialog>
    )
}

export default StudentGroupCheckin;