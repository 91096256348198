import { Card, CardContent, Typography, Divider } from '@mui/material'
import CustomTicketTable from 'components/CustomTicketTable'
import React from 'react'
import HoverPaper from "../../../components/HoverPaper";

const DeviceTicketsCard = ({tickets, isLoading}) => {
    return (
        <HoverPaper elevation={4}>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Device's Tickets</Typography>
            <Divider sx={{mb: '1rem'}}/>

            <CustomTicketTable data={tickets} defaultBackground navigateOnClick onlyActive={false} />
        </HoverPaper>
    )
}

export default DeviceTicketsCard