import React from "react";
import {Box, MenuItem, TextField} from "@mui/material";


interface YearSelectorProps {
    year: string;
    onChange: (year: string) => void;
}

const YearSelector: React.FC<YearSelectorProps> = ({onChange, year}) => {
    // Returns a list of years from 20 years ago to 20 years in the future
    const years = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = currentYear - 20; i <= currentYear + 20; i++) {
            // Format year as "YYYY-YYYY" and push onto years array
            years.push(`${i}-${i + 1}`);
        }
        return years;
    }

    return (
        <Box>
            <TextField
                select
                label="Academic Year"
                value={year}
                onChange={(e) => onChange(e.target.value)}
                helperText="Select the academic year"
                color={"secondary"}
                sx={{
                    // Move to right side of screen
                    float: "right",
                }}
            >
                {years().map((year) => (
                    <MenuItem key={year} value={year} color={"secondary"}>{year}</MenuItem>
                ))}
            </TextField>
        </Box>
    )
}

export default YearSelector;