import React, {useEffect} from 'react';
import {
    AppBar,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    Divider, IconButton,
    Skeleton,
    Toolbar,
    Typography
} from "@mui/material";
import {DataGrid, GridColDef, GridValueGetterParams} from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import SnackbarHandler from "../../components/SnackbarHandler";

interface TicketActionsProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onChange: () => void;
}

interface Reason {
    reason: string;
    ticket: any;
    remove: boolean;
}

const TicketCleanupTable: React.FC<{staleTickets: Reason[], onChange: () => void}> = ({staleTickets, onChange}) => {
    const [staleTicketsData, setStaleTicketsData] = React.useState<Reason[]>(staleTickets);
    const [selected, setSelected] = React.useState<Reason[]>([]);
    const [snackbar, setSnackbar] = React.useState({open: false, message: "", severity: "success"});
    const smallScreen: GridColDef[] = [
        { field: 'reason', headerName: 'Reason', flex: 4 },
        {
            field: 'ticketStudentName',
            headerName: 'Ticket Owner',
            type: 'string',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => params.row.ticket.studentName,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            type: 'date',
            flex: .5,
            valueGetter: (params: GridValueGetterParams) => new Date(params.row.ticket.createdAt),
        },
        {
            field: 'viewTicket',
            headerName: 'View Ticket',
            type: 'string',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => params.row.ticket.studentName,
            renderCell: (params: GridValueGetterParams) => (
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => {
                        const newPageUrl = window.location.protocol + "//" + window.location.host + "/org/ticket/" + params.row.ticket.id;
                        // Open the ticket in a new tab
                        window.open(newPageUrl, "_blank")
                    }}
                >
                    View Ticket
                </Button>
            ),
        },

        {
            field: "ticketActions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
                <Checkbox
                    checked={selected.includes(params.row)}
                    onChange={(e, c) => {
                        if (c) {
                            setSelected([...selected, params.row]);
                        } else {
                            setSelected(selected.filter((r) => r !== params.row));
                        }
                    }}
                    color='secondary'
                />
            ),
        }

    ];

    // Make a fetch request to remove the selected tickets
    const removeTickets = async () => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/tickets/cleanup/deactivate', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(selected.map((r) => r.ticket.id)),
        });
        if (response.status === 200) {
            // Remove the selected tickets from the table
            setStaleTicketsData([...staleTicketsData.filter((r) => !selected.includes(r))]);

            // Show a success message
            setSnackbar({open: true, message: "Successfully removed tickets", severity: "success"});

            onChange()
        } else {
            // Show an error message
            setSnackbar({open: true, message: "Failed to remove tickets", severity: "error"});
        }
    }

    useEffect(() => {
        // Update the stale tickets data
        setStaleTicketsData(staleTickets);
    }, [staleTickets]);
    return (
        <Box sx={{mt: "2rem"}}>
            <SnackbarHandler open={snackbar.open} onClose={() => setSnackbar({...snackbar, open:false})}
                             //@ts-ignore
                             message={snackbar.message} severity={snackbar.severity} />
            <Box style={{height: 400, width: '100%'}}>
                <DataGrid
                    rows={staleTicketsData}
                    columns={smallScreen}
                    getRowId={(row) => row.ticket.id}
                />
            </Box>
            <Box sx={{mt: '1rem'}}>
                <Button color={"secondary"} variant={"contained"} onClick={removeTickets}>Remove Selected ({selected.length})</Button>

            </Box>
        </Box>
    )
}

const TicketActions: React.FC<TicketActionsProps> = ({open, setOpen, onChange}) => {
    const [staleTickets, setStaleTickets] = React.useState<Reason[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        // Make a fetch request to get all the stale tickets
        const fetchStaleTickets = async () => {
            setIsLoading(true);
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/tickets/cleanup/active', {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include",
            });
            setIsLoading(false);
            if (response.status === 200) {
                const data = await response.json();
                setStaleTickets(data);
            }
        }

        if (open) {
            fetchStaleTickets();
        }
    }, [open]);
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullScreen
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setOpen(false)}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Typography variant={"h3"}>Clean Up Tickets</Typography>
                <Typography>
                    Tickets where the damaged device has been in use a while after the ticket was created will be shown
                    here. You can select the tickets you want to remove and click the "Remove Selected" button to remove
                    them. They will still exist, they will just be marked as completed.
                </Typography>
                <Divider/>

                {isLoading ? (
                    <Box>
                        <Skeleton variant={"rectangular"} height={200}/>
                        <Skeleton variant={"rectangular"} height={200}/>
                    </Box>
                ): (
                    <TicketCleanupTable onChange={onChange} staleTickets={staleTickets}/>
                )}

            </DialogContent>
        </Dialog>
    );
};

export default TicketActions;