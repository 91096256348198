import React, {useEffect, useState} from 'react';
import {Box, Divider, Skeleton, Typography} from "@mui/material";
import SnackbarHandler from "../../components/SnackbarHandler";
import CSVExportEditor from "../../components/data/CSVExportEditor";
import { ReturnDataDocument, Student, Device, User} from 'state/api';

const dataFormatter = (data: FullCheckinData []) => {
    return data.map((item) => {
        return {
            'Student Name': item.student?.name ?? item.returnDataDocument.studentName ?? "N/A",
            "Student Email": item.student?.email ?? "N/A",
            'Device Serial Number': item.device?.serialNumber,
            'Device Asset Id': item.device?.annotatedAssetId,
            'Returned Device': item.returnDataDocument.returnedDevice ? "TRUE" : "FALSE", // Using a checkmark for true
            'Returned Case': item.returnDataDocument.returnedCase ? "TRUE" : "FALSE",
            'Returned Charger': item.returnDataDocument.returnedCharger ? "TRUE" : "FALSE",
            'Returned By': item.returnDataDocument.returner,
            'Notes': item.returnDataDocument.notes,
        };
    });
}

const sortByDevice = (data: FullCheckinData []) => {
    // Sort by the device serial number
    return data.sort((a, b) => {
        if (a.device?.serialNumber < b.device?.serialNumber) return -1;
        if (a.device?.serialNumber > b.device?.serialNumber) return 1;
        return 0;
    });
}

const sortByStudentName = (data: FullCheckinData []) => {
    // Sort by the student name
    return data.sort((a, b) => {
        if (a.student?.name < b.student?.name) return -1;
        if (a.student?.name > b.student?.name) return 1;
        return 0;
    });
}

const sortByStudentEmail = (data: FullCheckinData []) => {
    // Sort by the student email
    return data.sort((a, b) => {
        if (a.student?.email < b.student?.email) return -1;
        if (a.student?.email > b.student?.email) return 1;
        return 0;
    });
}

const ignoreDevicesWithoutStudents = (data: FullCheckinData []) => {
    // Remove devices without students
    var filteredData =  data.filter((item) => {
        return item.student != null
            && item.returnDataDocument.student != null
            && item.returnDataDocument.studentName !== "Unknown";
    });

    console.log(filteredData)

    return filteredData;
}

const FilterOptions = [
    {
        id: 'device',
        label: 'Sort by Device',
        filter: sortByDevice
    },
    {
        id: 'studentName',
        label: 'Sort by Student Name',
        filter: sortByStudentName
    },
    {
        id: 'studentEmail',
        label: 'Sort by Student Email',
        filter: sortByStudentEmail
    },
    {
        id: 'ignoreDevicesWithoutStudents',
        label: 'Ignore Devices Without Students',
        filter: ignoreDevicesWithoutStudents
    }
]

interface FullCheckinData {
    returnDataDocument: ReturnDataDocument;
    student?: Student;
    device?: Device;
    user?: User;
}

const ExportCheckinData = () => {
    const [data, setData] = React.useState<FullCheckinData[]>(null);
    const [snackbarData, setSnackbarData] = useState({open: false, message: "", severity: "success"});

    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL + `/org/checkin/full`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include"
        })
            .then(async function (response) {
                const data = await response.json();
                setData(data);
            })
            .catch((reason) => {
                console.log(reason);
                setSnackbarData({open: true, message: "Error fetching data", severity: "error"})
            })
    }, []);

    return (
        <Box>
            <SnackbarHandler open={snackbarData.open}
                             onClose={() => setSnackbarData({...snackbarData, open: false})}
                             message={snackbarData.message}
                             //@ts-ignore
                             severity={snackbarData.severity}/>
            <Typography variant={"h3"}>Export Checkin Data</Typography>
            <Typography variant={"body1"}>Export Checkin Data as a CSV</Typography>
            <Divider sx={{mb: '1rem'}}/>
            {data == null && (
                <Box>
                    <Typography>Loading data. This may take a while...</Typography>
                    <Skeleton variant={"rectangular"} height={200}/>
                </Box>
            )}
            {data != null && (
                <Box>
                    <CSVExportEditor supportedFilterOptions={FilterOptions} data={data} formatter={dataFormatter}/>
                </Box>
            )}

        </Box>
    );
};

export default ExportCheckinData;