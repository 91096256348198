import React from 'react';
import {Button, Dialog, DialogContent, Divider, Typography} from "@mui/material";
import InventoryLocationsAutocomplete from "../../../components/Autocomplete/InventoryLocationsAutocomplete";
import {LoadingButton} from "@mui/lab";
import checkDeviceIn from "../../data/device/CheckDeviceIn";
import {SnackbarHandlerWrapper} from "../../../components/SnackbarHandler";

interface DeviceCheckinDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    id: string;
}

const DeviceCheckinDialog:React.FC<DeviceCheckinDialogProps> = ({open,setOpen,id}) => {
    const [location, setLocation] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [snackbarData, setSnackbarData] = React.useState({open: false, message: "", severity: "success"});
    const checkin = async () => {
        setLoading(true);

        var res = await fetch(process.env.REACT_APP_BASE_URL + `/org/inventory/locations/${location.id}/devices/checkin?sku=${id}`, {
            credentials: 'include',
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
        })

        setLoading(false)

        if (res.status === 200) {
            setSnackbarData({open: true, message: "Device Checked In. This page will refresh", severity: "success"})
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        } else {
            setSnackbarData({open: true, message: "Error checking in Device", severity: "error"})
        }
    }
    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={"sm"}>
            <DialogContent>
                <SnackbarHandlerWrapper snackbarData={snackbarData} setSnackbarData={setSnackbarData}/>
                <Typography variant={"h3"}>Checkin in Device?</Typography>
                <Divider sx={{m: '.5rem 0 1rem 0'}}/>

                <InventoryLocationsAutocomplete onValueChange={(v) => setLocation(v)} label={"Checkin To?"} value={""}/>

                <LoadingButton loading={loading} variant={"outlined"} sx={{mt: '1rem'}} color={"success"} onClick={checkin}>
                    Checkin
                </LoadingButton>
                <Button
                    variant={"outlined"}
                    sx={{mt: '1rem', ml: '1rem'}}
                    color={"error"}
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export const DeviceCheckinDialogWrapper = ({id}) => {
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <Button variant={"outlined"} color={"warning"} onClick={() => setOpen(true)}>Checkin</Button>
            {open && (
                <DeviceCheckinDialog open={open} setOpen={setOpen} id={id}/>

            )}
        </>
    )
}

export default DeviceCheckinDialog;