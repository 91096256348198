import { Box, Button, Card, CardActions, CardContent, Divider, Skeleton, Typography } from '@mui/material'
import DataField from 'components/data/DataField';
import React from 'react'
import { LazyNavigation, useGetUserAssignedStudentQuery } from 'state/api'

const UserAssignedStudentCard = ({id}) => {
    const {data, isLoading, error} = useGetUserAssignedStudentQuery(id);

    if(isLoading || data == undefined || data == null) {
        <Card>
            <CardContent>
                <Skeleton height="20vh"/>
            </CardContent>
        </Card>
    }

    if(error) {
        return (        
        <Card>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Student Information</Typography>
                <Divider sx={{mb: '1rem'}}/>
            </CardContent>
        </Card>)
    }
    return (
        <Card sx={{height: '100%'}}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Student Information</Typography>
                <Divider sx={{mb: '1rem'}}/>

                {data == null ? (
                    <Box>

                    </Box>
                ) : (
                    <Box>

                        <DataField compact title="Name" data={data.name} width={"100%"} />
                        <DataField compact title="Email" data={data.email} width={"100%"} />
                        <DataField compact title="Device" data={data.serialNumber} width={"100%"} />
                        <Divider sx={{m: '1rem 0 .2rem 0'}}/>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Current Assigned Loaner</Typography>
                        <DataField compact title="Loaner" data={data.loaner} width="100%"/>
                        <DataField compact title="Loaner Asset Id" data={data.loanerAssetId} width="100%"/>
                    </Box>
                )}

            </CardContent>
            <CardActions>
                <Button color="secondary" variant="outlined" onClick={() => LazyNavigation("/org/students/" + data.id)}>View Student</Button>
            </CardActions>
        </Card>
    )
}

export default UserAssignedStudentCard