import React from 'react';
import LazyAutocomplete from "./LazyAutocomplete";

interface InventoryLocationsAutocompleteProps {
    onValueChange: (value: any) => void;
    label: string;
    freeSolo?: boolean;
    value: string;
}
const InventoryLocationsAutocomplete: React.FC<InventoryLocationsAutocompleteProps> = ({onValueChange, value, freeSolo, label}) => {
    return (
        <LazyAutocomplete
            label={label}
            onValueChange={onValueChange}
            dataUrl={process.env.REACT_APP_BASE_URL + '/org/inventory/locations/query'}
            getLabel={(e) => e?.label ?? ""}
            isOptionEqualToValue={(e, v) => e == v}
            query={(q) => `?query=${q}&max=100`}
            freeSolo={freeSolo}
            fullWidth
            processRequest={async (response) => {
                var data = await response.json()
                var locations = data.map((location) => {
                    var label = "Name: " + location.name;

                    return {
                        label: label,
                        ...location
                    }
                })


                return [...locations]
            }}
            value={value}
        />
    )
};

export default InventoryLocationsAutocomplete;