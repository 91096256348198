import { Add } from '@mui/icons-material';
import { Box, Typography, Divider, Stack, Button, MenuItem, TextField, useTheme, Card, CardContent } from '@mui/material';
import DataEditable from 'components/data/DataEditable';
import TwoSide from 'components/data/TwoSide';
import React, { useEffect, useState } from 'react'
import { ConvertScopeIntToString, useGetAllSecurityPoliciesQuery, useGetGroupQuery } from 'state/api'
import AddUserToGroup from './AddUserToGroup';
import OrganizationMembersTable from './OrganizationMembersTable';
import Loading from 'components/Loading';
import Grid2 from '@mui/material/Unstable_Grid2';
import GroupPermissionsEditor from './GroupPermissionsEditor';
import { useSelector } from 'react-redux';
import { cwd } from 'process';


/**
 *  
 *  "read",
    "read:ticket",
    "read:ticket:overview",
    "read:ticket:messages",
    "read:ticket:images",
    "read:ticket:audits",
    "read:user",
    "read:device",
    "read:student",
    "read:organization",
    "read:organization:settings",
 */
const ReadOnlyScopes = [
    {
        label: "Read All",
        permission: "read"
    },
    {
        label: "Read Ticket",
        permission: "read:ticket"
    },
    {
        label: "Read Ticket Overview",
        permission: "read:ticket:overview"
    },
    {
        label: "Read Ticket Messages",
        permission: "read:ticket:messages"
    },
    {
        label: "Read Ticket Images",
        permission: "read:ticket:images"
    },
    {
        label: "Read Ticket Audits",
        permission: "read:ticket:audits"
    },
    {
        label: "Read User",
        permission: "read:user"
    },
    {
        label: "Read Device",
        permission: "read:device"
    },
    {
        label: "Read Device Checkin",
        permission: "read:device:checkin"
    },
    {
        label: "Read Device Checkin Images",
        permission: "read:device:checkin:images"
    },
    {
        label: "Read Device Checkin Notes",
        permission: "read:device:checkin:notes"
    },
    {
        label: "Read Student",
        permission: "read:student"
    },
    {
        label: "Read Student Groups",
        permission: "read:student:groups"
    },
    {
        label: "Read Organization",
        permission: "read:organization"
    },
    
]

/**
 *     "write",
    "write:ticket",
    "write:ticket:overview",
    "write:ticket:messages",
    "write:ticket:images",
    "write:user",
    "write:organization"
 */

const WriteScopes = [
    {
        label: "Write",
        permission: "write"
    },
    {
        label: "Write Ticket",
        permission: "write:ticket"
    },
    {
        label: "Create Tickets",
        permission: "write:ticket:create"
    },
    {
        label: "Write Ticket Overview",
        permission: "write:ticket:overview"
    },
    {
        label: "Write Ticket Messages",
        permission: "write:ticket:messages"
    },
    {
        label: "Write Ticket Images",
        permission: "write:ticket:images"
    },
    {
        label: "Ticket Actions",
        permission: "write:ticket:actions"
    },
    {
        label: "Write Student",
        permission: "write:student"
    },
    {
        label: "Write Student Groups",
        permission: "write:student:groups"
    },
    {
        label: "Create Student Groups",
        permission: "write:student:groups:create"
    },
    {
        label: "Delete Student Groups",
        permission: "write:student:groups:delete"
    },
    {
        label: "Write Student Group Members",
        permission: "write:student:groups:members"
    },
    {
        label: "Add Student Group Members",
        permission: "write:student:groups:members:add"
    },
    {
        label: "Remove Student Group Members",
        permission: "write:student:groups:members:remove"
    },
    {
        label: "Edit Metadata of Student Groups (Name, Description, Owner, ect)",
        permission: "write:student:groups:metadata"
    },
    {
        label: "Write Users",
        permission: "write:user"
    },
    {
        label: "Write Organization",
        permission: "write:organization"
    },
    {
        label: "Write Device",
        permission: "write:device"
    },
    {
        label: "Write Device Checkin",
        permission: "write:device:checkin"
    },
    {
        label: "Write Device Checkin Notes",
        permission: "write:device:checkin:notes"
    },
    {
        label: "Write Device Checkin Images",
        permission: "write:device:checkin:images"
    },
    {
        label: "Checkin Devices",
        permission: "write:device:checkin:actions"
    },
    
 
]

const ExperimentalScopes = [
    {
        label: "All Experimental Settings",
        permission: "experimental"
    },
    {
        label: "All Student Experimental Settings",
        permission: 'experimental:student'
    },
    {
        label: "Deprovision Students",
        permission: 'experimental:student:deprovision'
    },
    {
        label: "Create Students",
        permission: 'experimental:student:create'
    },
    {
        label: "Provider Settings",
        permission: 'experimental:provider'
    },
    {
        label: "Create User",
        permission: "experimental:provider:create"
    }
    
]

const ticketScopes = [
    {
        label: "Full Ticket access", 
        value: "write:ticket"
    },
    {
        label: "Read Only", 
        value: "read:ticket"
    },
    {
        label: "Read Only Overview", 
        value: "read:ticket:overview"
    },
    {
        label: "Read Only Personal Tickets", 
        value: "read:ticket:self"
    }, 
    {
        label: "None",
        value: "none:ticket"
    }
]

const userScopes = [
    {
        label: "Full Access",
        value: "write:user"
    },
    {
        label: "Read Only Users", 
        value: "read:user"
    },
    {
        label: "None",
        value: "none:user"
    }
]

const studentScopes = [
    {
        label: "View Students", 
        value: "read:student"
    },
    {
        label: "None",
        value: "none:student"
    }
]

const deviceScopes = [
    {
        label: "Read Devices", 
        value: "read:device"
    },
    {
        label: "None",
        value: "none:device"
    }
]


const organizationScopes = [
    {
        label: "Read Organization Settings", 
        value: "read:organization"
    },
    {
        label: "Update Organization Settings",
        value: "write:organization"
    },
    {
        label: "None",
        value: "none:organization"
    }
]


interface GroupSecurityPolicy {
    currentPolicy: string;
    onPolicyChange: (v) => void;
}
const GroupSecurityPolicy:React.FC<GroupSecurityPolicy> = ({currentPolicy, onPolicyChange}) => {
    const {data, isLoading, refetch} = useGetAllSecurityPoliciesQuery("");
    const [policies, setPolicies] = useState([]);
    useEffect(() => {
        refetch()
    }, [currentPolicy])
    useEffect(() => {
        if(data != null) {
            setPolicies([...data])
        }
    }, [data]) 
    return (
        <Box>
            <TextField 
                label="Policy"
                value={currentPolicy == undefined ? '' : currentPolicy}
                select
                fullWidth
                color="secondary"
                onChange={(v) => onPolicyChange(v.target.value)}
            >
                <MenuItem key='' value={''}>Default Policy</MenuItem>
                {policies.map((v) => (
                    
                    <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>
                ))}
            </TextField>
        
        </Box>
    )
}

interface OrganizationGroup {
    id: string;
    onChange: () => void;
}
const OrganizationGroup:React.FC<OrganizationGroup> = ({id, onChange}) => {
    const [currentPermissions, setCurrentPermissions] = useState([])
    const [initialPermissions, setInitialPermissions] = useState([])
    const [isAddingUser, setIsAddingUser] = useState(false);
    const [currentGroup, setCurrentGroup] = useState({} as any);
    const {data, isLoading, refetch} = useGetGroupQuery(id);
    const theme = useTheme();
    //@ts-ignore
    const state = useSelector(s => s.global);

    useEffect(() => {
        refetch();
    }, [id])

    useEffect(() => {
        if(!isLoading) {
            setCurrentPermissions(convertIntArrayToScopes(data.scopes || []) || [])
            setInitialPermissions(convertIntArrayToScopes(data.scopes || []) || [])
            setCurrentGroup({...data})
        }
    }, [data])

    const updateGroup = (group) => {
        fetch(process.env.REACT_APP_BASE_URL + `/org/groups/${group.id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(group)
            }).then(async (v) => {
                onChange()
                await refetch()
            })
    }


    const updateGroupInDb = (scopes) => {
        var filteredPermissions = scopes.filter((v) => !v.startsWith("none"))
        fetch(process.env.REACT_APP_BASE_URL + '/org/groups/' + id + "/scopes", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(filteredPermissions)
            }).then(async (v) => {
                onChange()
            })

        
    }

    
    const convertIntArrayToScopes = (scopes: number[]) => {
        var stringScopes = [];
        if(scopes == null) return [];
        scopes.forEach((s) => stringScopes.push(ConvertScopeIntToString(s)))
        return stringScopes;
    }

    const loadPermissions = (perms: string[]) => {
        //We load the permissions in groups
        var simplePermissions = [];
        var groups = ["ticket", "user", "student", "device", "organization"]
        groups.forEach(group => {
            //Load ticket permission
            var filteredArray = filterArrayByKeyword(perms, group)
            if(filteredArray.length == 0) {
                simplePermissions.push("none:" + group)
            } else {
                simplePermissions.push(filteredArray[0])
            }
        });
        if(perms.includes("admin")) simplePermissions.push("admin")

        setCurrentPermissions([...simplePermissions])

    }

    function filterArrayByKeyword(arr, keyword): string[] {
        const filteredArray = arr.filter((item) => item.includes(keyword));
        return filteredArray;
    }

    function UpdateScopes(permissions: string[], type: string) {
        //Remove all of the
        var newPermissions = currentPermissions.filter((v) => !v.startsWith(type))
        var combined = [...permissions, ...newPermissions]
        //Add permissions to the array, then filter out duplicates
        var unique = combined.filter((item, index) => combined.indexOf(item) === index);

        setCurrentPermissions([...unique])
        updateGroupInDb(unique)
    }

    if(isLoading || data == null) {
        return <Loading/>
    }
      
    return (
        <Card sx={{mt:'1rem'}}>
            <CardContent>

                <Box sx={{

                    }}>
                        <Typography variant="h4">{currentGroup.name} Group Settings</Typography>
                        <Divider/>
                        <Stack divider={<Divider flexItem/>}>
                            <TwoSide>
                                <Box>
                                    <Typography variant="h6">Group Name</Typography>
                                    <Typography variant="body1">Name of the Group.</Typography>
                                </Box>
                                <Box>
                                    <DataEditable data={currentGroup.name} callback={(v) => {
                                        const updatedGroup = { ...currentGroup, name: v };
                                        setCurrentGroup(updatedGroup);
                                        updateGroup(updatedGroup);
                                    }} />
                                </Box>
                            </TwoSide>

                            <TwoSide>
                                <Box>
                                    <Typography variant="h6">Group Description</Typography>
                                    <Typography variant="body1">A short description of the group.</Typography>
                                </Box>
                                <Box>
                                    <DataEditable data={currentGroup.description} callback={(v) => {
                                        const updatedGroup = { ...currentGroup, description: v };
                                        setCurrentGroup(updatedGroup);
                                        updateGroup(updatedGroup);
                                    }} />
                                </Box>
                            </TwoSide>
                        </Stack>
                        <Divider sx={{m: '1rem 0'}}/>
                        <TwoSide>

                            <Typography variant="h4" sx={{m: '1rem 0'}}>Members</Typography>
                            <Button endIcon={<Add/>} onClick={() => setIsAddingUser(true)} color="secondary">
                                Add User
                            </Button>
                        
                        </TwoSide>
                        <Divider/>

                        <AddUserToGroup id={id} isOpen={isAddingUser} onClose={() => {setIsAddingUser(false); refetch()}}/>                                   
                        <OrganizationMembersTable id={id} isAddingUser={isAddingUser}/>
                        <Typography variant="h3">Permissions</Typography>
                        <Typography variant="subtitle1">Permissions determine what a user can or cannot do. <i>Write</i> means that they can view, update, delete, or add data, while <i>Read</i> means that they can only view the data.</Typography>
                        <Divider sx={{mb: '1rem'}}/>

                        <Box>
                            {/**Test New Permission */}
                            <Grid2 container>
                                <Grid2 xs={12} md={6}>
                                    <GroupPermissionsEditor startsWith='read' initialValue={initialPermissions} label={"Read Permissions"} permissions={ReadOnlyScopes} onChange={(permissions) => {UpdateScopes(permissions, "read")}}/>
                                </Grid2>
                                <Grid2 xs={12} md={6}>
                                    <GroupPermissionsEditor startsWith="write" initialValue={initialPermissions} label={"Write Permissions"} permissions={WriteScopes} onChange={(permissions) => {UpdateScopes(permissions, "write")}}/>
                                </Grid2>
                            </Grid2>
                        </Box>

                        {state.experimentalEnabled && (
                            <Box>
                                <Divider sx={{m: '1rem 0'}}/>
                                <Typography variant="h3">Experimental Permissions</Typography>
                                <Divider sx={{mb: '1rem'}}/>

                                <GroupPermissionsEditor startsWith='experimental' initialValue={initialPermissions} label='Experimental Permissions' permissions={ExperimentalScopes} onChange={(perms) => UpdateScopes(perms, "experimental")} />

                                <Divider/>
                            </Box>
                        )}

                        <Divider />
                        <TwoSide>
                            <Box>
                                <Typography variant="h4">Group Security Policy</Typography>
                                <Typography variant="subtitle1">
                                    Which security policy should affect the group. <br/>
                                    You can configure policies at <b>Settings &gt; Organization Settings &gt; Security</b>
                                </Typography>
                            </Box>
                            <Box width="20vw">
                                <GroupSecurityPolicy currentPolicy={currentGroup.securityPolicy} onPolicyChange={(v) => {
                                    const updatedGroup = { ...currentGroup, securityPolicy: v == "" ? null : v };
                                    setCurrentGroup(updatedGroup);
                                    updateGroup(updatedGroup);
                                }}/>
                            </Box> 
                        </TwoSide>


                        <Typography mt="2rem" variant="h3">Other Settings</Typography>
                        <Divider sx={{mb: '1rem'}}/>
                        <Stack divider={<Divider/>}>
                            <TwoSide>
                                <Box>
                                    <Typography variant="h4">Make this group a Administrator Group</Typography>
                                    <Typography variant="subtitle1">Making this group a admin group will give it full access to all roles except 
                                    <br/>for a few things that only the creator can access
                                    <br/>Note: This will overwrite all the above permissions
                                    </Typography>
                                </Box>
                                <Box width="20vw">
                                    <TextField
                                    id="is-default-select"
                                    select
                                    label="Is Admin Group?"
                                    color="secondary"
                                    value={currentPermissions.includes("admin") ? "true" : "false"}
                                    onChange={(v) => {
                                        if(v.target.value == "true") {
                                            currentPermissions.push("admin")
                                        } else {
                                            var index = currentPermissions.indexOf("admin")
                                            if(index != -1) {
                                                currentPermissions.splice(index, 1);
                                            }
                                        }
                                        setCurrentPermissions([...currentPermissions])
                                        updateGroupInDb(currentPermissions)
                                    }}
                                    fullWidth
                                    >
                                        <MenuItem value={"true"}>Yes</MenuItem>
                                        <MenuItem value={"false"}>No</MenuItem>
                                    </TextField>
                                </Box>
                            </TwoSide>

                            <TwoSide>
                                <Box maxWidth={"50%"}>
                                    <Typography variant="h4">Minimum Group Email Level</Typography>
                                    <Typography variant="subtitle1">
                                        Determines which level of email should be the lowest. 
                                        <br/>For example: Setting it to Severe, all members in the group who have recieve emails on will recieve only severe emails.
                                        <br/> Setting it to Info will send only info and severe emails. 
                                        <br/>
                                        <br/>
                                        Note: The user must have recieve emails On if they wish to recieve these emails
                                    </Typography>
                                </Box>
                                <Box width="20vw">
                                    <TextField
                                    id="is-default-select"
                                    select
                                    label="Minimum Email Level?"
                                    color="secondary"
                                    fullWidth
                                    value={currentGroup.minimumEmailLevel || 0}
                                    onChange={(v) => {
                                        currentGroup.minimumEmailLevel = v.target.value;
                                        updateGroup(currentGroup)
                                    }}
                                    >
                                        <MenuItem value={3}>Severe</MenuItem>
                                        <MenuItem value={2}>Warnings</MenuItem>
                                        <MenuItem value={1}>Info</MenuItem>
                                        <MenuItem value={0}>None</MenuItem>
                                    </TextField>
                                </Box>
                            </TwoSide>
                        </Stack>

                        <Box>

                        </Box>
                </Box>
            </CardContent>
        </Card>
    )
}

export default OrganizationGroup