import { Box, Divider, Grow, Typography, useTheme } from '@mui/material'
import React from 'react'

interface DataField {
    title?: string,
    data: any,
    width: any
    compact?: boolean,
    darkBackground?: boolean,
    padding?: string
}
//@ts-ignore
const DataField: React.FC<DataField>= ({title, data, width, compact, darkBackground = false, padding}) => {
    const theme = useTheme();
    if(width == "100%") width = 'auto';
    if(title == "Loaner") console.log(data)
    return (
    <Grow in={true}>

        <Box sx={{width: width, p: compact ? '5px 0' : '1rem'}}>
            {(title != "" && title != null) && (
                <>
                    <Typography variant="h5">
                        {title}
                    </Typography>
                    <Divider/>
                </>
            )}
            {darkBackground ? (
                <Box sx={{
                    //@ts-ignore
                    backgroundColor: theme.palette.mode == "light" ? theme.palette.neutral[700] : theme.palette.primary[400],
                    margin: '5px 0',
                    padding: padding || '.5rem 2rem .5rem .2rem',
                    background: 'rgba(0,0,0,.5)',
                }}>
                    <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis'}} variant="body1" color={theme.palette.primary.contrastText}>{data ?? "None"}</Typography>
                </Box>
            ) : (
                <Box sx={{
                    //@ts-ignore
                    backgroundColor: theme.palette.mode == "light" ? theme.palette.neutral[700] : theme.palette.primary[400],
                    borderRadius: "5px",
                    margin: '5px 0',
                    padding: padding || '0 2rem 0 .2rem',
                    background: 'rgba(0,0,0,.03)',

                }}>
                    <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body1" color={theme.palette.primary.contrastText}>{data ?? "None"}</Typography>
                </Box>
            )}

            
        </Box>
    </Grow>
  )
}

export default DataField