import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

/**
 * Will display the children of this component if the environemnt is a testing environment
 * @param param0 Children to display
 * @returns 
 */
const DisplayIfTestSuite = (props: { children: React.ReactNode}) => {
    const { children } = props
    const [isTestSuite, setIsTestSuite] = useState(false);

    //@ts-ignore
    const state = useSelector(g => g.global);

    useEffect(() => {
        setIsTestSuite(state.domain == "gmail.com")
    }, [state])

    if (isTestSuite) {
        return <>
        {children}</>;
    }
    return <></>;
}

export default DisplayIfTestSuite