import React from 'react'
import LazyAutocomplete from './LazyAutocomplete'

interface QueryAllAutocomplete {
    onValueChange: (value: any) => void;
    label: string;
    freeSolo?: boolean;
    value;
    fullWidth?: boolean;
}
const QueryAllAutocomplete:React.FC<QueryAllAutocomplete> = ({onValueChange, label, freeSolo = false, value, fullWidth}) => {
  return (
    <LazyAutocomplete 
        value={value}
        label={label}
        fullWidth={fullWidth}
        onValueChange={onValueChange}
        dataUrl={process.env.REACT_APP_BASE_URL + '/org/search/query'}
        getLabel={(e) => e.label}
        isOptionEqualToValue={(o, v) => o == v}
        query={(q) => `?q=${q}`}
        freeSolo={freeSolo}
        groupBy={(option) => option.type ?? "Unknown"}
        processRequest={async (response) => {
            var data = await response.json()

            var users = data.users.map((u) => {
                return {
                    type: 'Users',
                    label: u.name + " " + u.primaryEmail,
                    link: "/org/users/"+u.id,
                    ...u
                }
            })
            var devices = data.devices.map((u) => {
                return {
                    type: "Devices",
                    label: u.serialNumber + " " + (u.annotatedAssetId ?? ""),
                    link: "/org/devices/"+u.serialNumber,
                    ...u
                }
            })
            var students = data.students.map((u) => {
                return {
                    type: "Students",
                    label: u.name + " " + u.email,
                    link: "/org/students/"+ u.email,
                    ...u
                }
            })
            var tickets = data.tickets.map((u) => {
                return {
                    type: "Tickets",
                    label: "Ticket: " + u.id,
                    link: "/org/ticket/"+u.id,
                    ...u
                }
            })

            var studentGroups = data.groups.map((g) => {
                return {
                    type: "Groups",
                    label:  g.name,
                    link: "/org/students/groups/" + g.id,
                    ...g

                }
            })


            var inventoryDevices = data.inventoryDevices.map((u) => {
                return {
                    type: "Inventory",
                    label: u.sku,
                    link: "/org/inventory/devices/"+u.sku,
                    sku: u.sku
                }
            })


            var inventoryLocations = data.inventoryLocations.map((u) => {
                return {
                    type: "Locations",
                    label: u.name,
                    link: "/org/inventory/locations/"+u.id,
                    ...u
                }
            })


            return [...students, ...devices, ...users, ...inventoryDevices, ...inventoryLocations,  ...tickets, ...studentGroups ];
        }}  
    />
  )
}

export default QueryAllAutocomplete