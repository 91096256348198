import { Close } from '@mui/icons-material';
import { AppBar, Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, Stack, TextField, Toolbar, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2';
import OUPathAutocomplete from 'components/Autocomplete/OUPathAutocomplete';
import Loading from 'components/Loading';
import SnackbarHandler from 'components/SnackbarHandler';
import React, { useState } from 'react'
import { setForcedMode } from 'state';

interface CreateConfiguration {
    open: boolean;
    stateHandler: (state: boolean) => void;
}
interface Configuration {
    studentOU: string;
    changePasswordAtNextLogin: boolean;
    deviceOU: string;
    setUserAsAnnotatedUser: boolean;
}
const CreateConfiguration: React.FC<CreateConfiguration> = ({open, stateHandler}) => {
    const [studentOU, setStudentOU] = useState("")
    const [deviceOU, setDeviceOU] = useState("")
    const [changePasswordAtNextLogin, setChangePasswordAtNextLogin] = useState(false)
    const [setUserAsAnnotatedUser, setSetUserAsAnnotatedUser] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const createConfiguration = async () => {
        setIsLoading(true)
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/students/batch/configuration', {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                studentOU: studentOU,
                deviceOU: deviceOU,
                changePasswordAtNextLogin: changePasswordAtNextLogin,
                setUserAsAnnotatedUser: setUserAsAnnotatedUser
            })  
        })

        setIsLoading(false)
        if(response.status == 200) {
            var json = await response.json()
            navigate("/org/students/create?batch=" + json.id)
        } else {
            setSnackbarOpen(true)
        }


    }

    const navigate = (route) => {
        const host = document.location.origin;
        document.location.href = host + route
    }

    const isValid = () => {
        return studentOU != "" && deviceOU != ""
    }

    return (
        <Dialog
            open={open}
            onClose={() => stateHandler(false)}
            aria-labelledby="configuration-dialog-title"
            aria-describedby="configuration-dialog-description"
            maxWidth="lg"
            fullWidth
        >
            <AppBar sx={{ position: 'relative', displayPrint: "none" }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => stateHandler(false)}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <SnackbarHandler open={snackbarOpen} onClose={() => setSnackbarOpen(false)} message={'There was a problem creating the batch. Please try again later...'} severity={'error'} />
            {isLoading && (<Loading line/>)}
            <DialogTitle>
                <Typography variant="h1">Create a Configuration for Batch Creations</Typography>
                <Typography variant="subtitle1">Create configurations for students, which means things like Annotated Asset Id, OU, ect.</Typography>
                <Typography variant="subtitle2">Note: Leave fields blank if you do not want to update them</Typography>

            </DialogTitle>

            <DialogContent>
                <Grid2 container>
                    <Grid2 md={6} xs={12}>
                        <Typography variant="h3">User Configuration</Typography>
                        <Stack spacing={1}>
                            <Box width="50%">

                                <OUPathAutocomplete 
                                    label="Student OU" 
                                    onValueChange={(e) => setStudentOU(e)} 
                                    fullWidth
                                />
                            </Box>

                            <FormControlLabel control={<Checkbox checked={changePasswordAtNextLogin} onChange={(e,c) => setChangePasswordAtNextLogin(c)} color="secondary" defaultChecked />} label="Change Password at Next Login" />
                        </Stack>
                    </Grid2>
                    <Grid2 md={6} xs={12}>
                        <Typography variant="h3">Device Configuration</Typography>
                        <Stack spacing={1}>
                            <Box width="50%">
                                <OUPathAutocomplete 
                                    label="Device OU" 
                                    onValueChange={(e) => setDeviceOU(e)} 
                                    fullWidth
                                />
                            </Box>
                        </Stack>
                    </Grid2>
                </Grid2>
                <Box sx={{m: '4rem 1rem'}}>

                    <Button color="secondary" sx={{width: '100%'}} variant="contained" onClick={() => createConfiguration()}>Create New Batch</Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default CreateConfiguration