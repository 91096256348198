import React from 'react'
import LazyAutocomplete from './LazyAutocomplete';

interface DeviceAutocomplete {
    onValueChange: (value: any) => void;
    label: string;
    freeSolo?: boolean;
    value: string;
}
const InventoryDeviceAutocomplete:React.FC<DeviceAutocomplete> = ({onValueChange, label, freeSolo, value}) => {
    return (
        <LazyAutocomplete
            label={label}
            onValueChange={onValueChange}
            dataUrl={process.env.REACT_APP_BASE_URL + '/org/inventory/devices/query'}
            getLabel={(e) => e?.label ?? ""}
            isOptionEqualToValue={(e, v) => e == v}
            query={(q) => `?query=${q}&max=100`}
            freeSolo={freeSolo}
            fullWidth
            processRequest={async (response) => {
                var data = await response.json()
                var devices = data.map((device) => {
                    var label = "SKU: " + device.sku;
                    if(device.locationName != null) label += ". \nLocation: " + device.locationName

                    return {
                        label: label,
                        ...device
                    }
                })

                // Filter out devices that contain the same label
                devices = devices.filter((device, i) => devices.findIndex((e) => e.label == device.label) == i)

                return [...devices]
            }}
            value={value}
        />
    )
}

export default InventoryDeviceAutocomplete