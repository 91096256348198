import React, {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";

interface HandheldQRScannerFC {
    onValidInput: (device) => void;
    onInvalidInput: (errorMsg) => void;
    onLoading: () => void;
    maxDelay?: number;
    disabled?: boolean;
    freeSolo?: boolean;
    displayInfo?: boolean;
}

/**
 * This component is used to scan QR codes from a handheld device. Reads the input from the keyboard and
 * sends a fetch request to the backend to retrieve the device data.
 * @param onValidInput
 * @param onInvalidInput
 * @param onLoading
 * @param maxDelay
 * @param disabled
 * @param displayInfo
 * @param freeSolo
 * @constructor
 */
const HandheldQRScanner: React.FC<HandheldQRScannerFC> = ({
                                                              onValidInput, onInvalidInput, disabled, onLoading,
                                                              displayInfo = false,
                                                              freeSolo = false, maxDelay = 500
                                                          }) => {

    const [barcodeData, setBarcodeData] = useState('');
    let delayTimer;

    const handleBarcodeScanned = async (input) => {
        // Use this for testing
        // input = "d633f0f9-feae-98b4-d070-236c29cf99aa";
        onLoading();
        // Save the barcode data in a variable, as it may change before the fetch operation completes
        try {
            if (freeSolo) {
                onValidInput(input);
                return;
            }

            // Query database
            const response = await fetch(process.env.REACT_APP_BASE_URL + `/org/provider/data/devices/${input}`, {
                method: "GET",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (response.status === 200) {
                const data = await response.json();
                onValidInput(data);

                // If the device is found, add a long delay before accepting new input
                // This is to prevent the user from accidentally scanning multiple devices
                // in quick succession
                delayTimer = setTimeout(() => {
                    setBarcodeData('');
                }, maxDelay);
            } else {
                onInvalidInput(`Device "${input}" not found`);
            }
        } catch (error) {
            console.error("Error during fetch:", error);
        } finally {
            // Reset the barcode data and clear the delay timer
            setBarcodeData('');
            clearTimeout(delayTimer);
        }
    };

    useEffect(() => {
        if (disabled) return;
        let input = '';
        const handleKeyPress = (event) => {
            if (disabled) return;
            // Make sure that the key pressed is a letter or number or a space
            if (!event.key.match(/^[a-zA-Z0-9]$/) && event.key !== ' ') {
                return;
            }
            input += event.key;


            // If there's an existing timer, clear it
            if (delayTimer) {
                clearTimeout(delayTimer);
            }

            // Set a new delay timer
            delayTimer = setTimeout(() => handleBarcodeScanned(input), maxDelay);
        };

        // Add an event listener to the document to capture barcode scans
        document.addEventListener('keydown', handleKeyPress);

        // Clean up the event listener and the delay timer when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            clearTimeout(delayTimer);
        };
    }, [maxDelay, onValidInput, onInvalidInput]);


    return (
        <Box>
            { /** Empty component */}
            {displayInfo && (
                <Box>
                    {barcodeData != '' ? (
                        <Typography>Scanned: {barcodeData}</Typography>
                    ) : (
                        <Typography>Waiting for input...</Typography>
                    )}
                </Box>
            )}
        </Box>
    )
}

export default HandheldQRScanner;