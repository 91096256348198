import { Delete, FileDownload, Image, Message, Receipt, ViewDay } from '@mui/icons-material'
import { Box } from '@mui/material'
import ResponsiveDrawer from 'components/ResponsiveDrawer'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CompareScopes } from 'state/api'

const options = [
  { route: "overview", label: "Overview", icon: <ViewDay />, minimumScope: "read:ticket:overview" },
  { route: "messages", label: "Messages", icon: <Message />, minimumScope: "read:ticket:messages" },
  { route: "images", label: "Images", icon: <Image />, minimumScope: "read:ticket:images" },
  { route: "audits", label: "Audits", icon: <Receipt />, minimumScope: "read:ticket:audits" },
  { route: "actions", label: "Actions", icon: <Delete />, minimumScope: "write:ticket:actions" },
]
function Ticket() {
  //@ts-ignore
  const global = useSelector(s => s.global)

  var [routeOptions, setOptions] = useState([])

  useEffect(() => {
    var opt = options.filter((o) => {
      for (var i = 0; i < global.scopes.length; i++) {
        if (CompareScopes(global.scopes[i], o.minimumScope)) return true;
      }


      return false;
    })

    setOptions(opt)
  }, [global])
  return (
    <Box>
      <ResponsiveDrawer options={routeOptions} />
    </Box>
  )
}

export default Ticket