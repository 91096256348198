import React, { useEffect, useState } from 'react';
import {Box, Slide, useMediaQuery} from "@mui/material";
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {setDomain, setExperimentalEnabled, setExperimentalSettings, setQrLogin, setRole, setScopes} from "state/index"
import { ConvertScopeIntToString, useGetOrgSettingsQuery, useGetSelfQuery, useGetUserQuery } from 'state/api';

import Navabar from "components/Navbar";
import Sidebar from "components/Sidebar";
import { useDispatch } from 'react-redux';
function Layout() {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(isNonMobile);
  const dispatch = useDispatch();
  const { data, isLoading, refetch } = useGetSelfQuery("");
  const { data: organizationData } = useGetOrgSettingsQuery("");
  const [user, setUser] = useState(null);
  useEffect(() => {
    if(!isLoading) {
      setUser(data.user || {})
      if(data.user.scopes != undefined) {
        var scopes = [];
        scopes = data.user.scopes.map((s) => ConvertScopeIntToString(s));
        dispatch(setScopes(scopes ?? [""]))
      }

      dispatch(setRole(data.user.occupation))

      var domain = data.user.email.split('@')[1]
      console.log(domain)
      dispatch(setDomain(domain))

      //Set security policies
      if(data.user.role != 0) {

        dispatch(setQrLogin(data.securityPolicy.allowQrCodeLogin || false))
      } else {
        dispatch(setQrLogin(true))
      }
    }
  },[data])

  useEffect(() => {
    if(organizationData != null) {
      dispatch(setExperimentalEnabled(organizationData.organization.experimentalSettingsEnabled || false))
      dispatch(setExperimentalSettings(organizationData.organization.experimentalSettings || []))
    }
  }, [organizationData])

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <Slide direction='right' in={isSidebarOpen}>
        <Box>

          <Sidebar 
            user={user || {}}
            isNonMobile={isNonMobile}
            drawerWidth="250px"
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
            />

        </Box>

      </Slide>
        <Box flexGrow={1}>
            <Navabar
              user={user  || {}}
              //@ts-ignore
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
            />
            <Outlet />
        </Box>
    </Box>
  )
}

export default Layout