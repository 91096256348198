import { Delete } from '@mui/icons-material';
import { Backdrop, Box, Button, Card, CardContent, Divider, IconButton, LinearProgress, List, ListItemButton, ListItemText, MenuItem, Skeleton, Stack, TextField, Typography, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Loading from 'components/Loading';
import DataEditable from 'components/data/DataEditable';
import TwoSide from 'components/data/TwoSide';
import React, { useEffect, useState } from 'react'
import { useGetActionQuery, useGetActionsQuery, useGetEventsQuery } from 'state/api';
import CustomizeEmail from './CustomizeEmail';
import DisplayIfTestSuite from 'components/DisplayIfTestSuite';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface Members {
    members: string;
    onChange: (members: string) => void;
}
const MembersSelector: React.FC<Members> = ({ members, onChange }) => {
    const [membersString, setMembersString] = useState("");

    useEffect(() => {
        setMembersString(members)
    }, [members])

    const handleBlur = () => {
        if(isValid(membersString)) {
            onChange(membersString)
        }
    }

    const validateEmails = (emailList: string) => {
        const emails = emailList.split(' ');
        const emailRegex = /^(\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,4}\s*?,?\s*?)+$/;

        if (emails.length > 10) {
            return "You can't have more than 10 emails";
        }

        for (let email of emails) {
            if(email == "") {
                return `Cannot be empty`
            }
            if (!emailRegex.test(email)) {
                return `${email} is not a valid email`;
            }
        }
        if(emailList.includes(",")) {
            return "Cannot contain a comma!";
        }
        return 'Add Up to 10 emails, each seperated by a space';
    }

    const isValid = (emailList: string) => {
        const emails = emailList.split(' ');
        const emailRegex = /^(\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,4}\s*?,?\s*?)+$/;

        if (emails.length > 10) {
            return false;
        }

        for (let email of emails) {
            if (!emailRegex.test(email)) {
                return false;
            }
        }

        //Check to make sure there are no commas
        if(emailList.includes(",")) {
            return false;
        }
        
        return true;
    }



    return (
        <TextField
            sx={{width: "100%"}}
            label="Emails to Add"
            helperText={validateEmails(membersString)}
            value={membersString}
            multiline
            onBlur={(e) => handleBlur()}
            onChange={(v) => setMembersString(v.target.value)}
            color={isValid(membersString) ? "secondary" : "error"}
        />
    )
}

interface EventSelector {
    currentEvent: string;
    onChange: (event) => void;
}
const EventSelector = ({ currentEvent, onChange }) => {
    const { data, isLoading, error, refetch } = useGetEventsQuery("");
    const [events, setEvents] = useState([]);

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        if (!isLoading && data != null) {
            setEvents([...data])
        }
    }, [data])

    return (
        <TextField
            label="Event"
            color='secondary'
            value={currentEvent}
            onChange={(e) => onChange(e.target.value)}
            select
            sx={{ width: 300 }}
        >
            <MenuItem value={"000000000000000000000000"}>Never Fire</MenuItem>
            {events.map((v) => (
                <MenuItem value={v.id} id={v.id}>{v.name}</MenuItem>
            ))}
        </TextField>
    )
}

interface ActionCard {
    id: string;
    onChange: () => void;
}
const ActionCard: React.FC<ActionCard> = ({ id, onChange }) => {
    const { data, isLoading, error, refetch } = useGetActionQuery(id);
    const [action, setAction] = useState(null);

    useEffect(() => {
        refetch()
    }, [id])

    useEffect(() => {
        if (!isLoading && data != null) {
            setAction({ ...data })
        }
    }, [data])

    const updateAction = async (updateData) => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/actions/' + id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(updateData)
        })
        onChange()
        refetch()
    }

    const getMembers = () => {
        const parameters = action.parameters.split(', ');
        let parametersDict = {};
        parameters.forEach(parameter => {
            if(parameter != "") {

                const [key, value] = parameter.split('=');
                if(key != "" && value != "") {
    
                    parametersDict[key] = value;
                }
            }
        });

        
        return parametersDict["email"] as string;
    }

    const setMembers = (membersString) => {
        const parameters = action.parameters.split(', ');
        let parametersDict = {};
        parameters.forEach(parameter => {
            const [key, value] = parameter.split('=');
            parametersDict[key] = value;
        });

        
        parametersDict["email"] = membersString;

        let parametersString = Object.entries(parametersDict).map(([key, value]) => `${key}=${value}`).join(', ');

        const updateData = { ...action, parameters: parametersString };
        updateAction(updateData);
    }
    if (isLoading || action == null) {
        return (
            <Card sx={{ height: "100%" }}>
                <CardContent sx={{ height: "100%" }}>
                    <Skeleton variant="rectangular" height="500" />
                    <Skeleton variant="rectangular" height="500" />
                    <Skeleton variant="rectangular" height="500" />
                </CardContent>
            </Card>
        )
    }
    return (
        <Card>
            <CardContent>
                <Typography variant="h4" gutterBottom><b>{action.name}</b> Settings</Typography>
                <Divider sx={{ mb: '1rem' }} />

                <Stack divider={<Divider flexItem />}>
                    <TwoSide>
                        <Box>
                            <Typography variant="h5">Action Name</Typography>
                            <Typography variant="body1">Name of the Action.</Typography>
                        </Box>
                        <Box>
                            <DataEditable data={action.name} callback={(v) => {
                                const updateData = { ...action, name: v };
                                updateAction(updateData);
                            }} />
                        </Box>
                    </TwoSide>
                    <TwoSide>
                        <Box>
                            <Typography variant="h5">Action Description</Typography>
                            <Typography variant="body1">A short description of the action.</Typography>
                        </Box>
                        <Box>
                            <DataEditable multiline data={action.description} callback={(v) => {
                                const updateData = { ...action, description: v };
                                updateAction(updateData);
                            }} />
                        </Box>
                    </TwoSide>
                </Stack>

                <Divider sx={{ mb: '1rem' }} />

                <Typography variant="h4">Action Settings</Typography>

                <Divider sx={{ mb: '1rem' }} />


                <Stack divider={<Divider flexItem />} spacing={1}>
                    <TwoSide>
                        <Box>
                            <Typography variant="h5">Subscribe to</Typography>
                            <Typography variant="body1">Select which event to subscribe to, meaning when that event is triggered, this action will run.</Typography>
                        </Box>
                        <Box>
                            <EventSelector currentEvent={action.fireOn} onChange={(e) => {
                                const updateData = { ...action, fireOn: e };
                                updateAction(updateData);
                            }} />
                        </Box>
                    </TwoSide>
                    <TwoSide>
                        <Box>
                            <Typography variant="h5">Action Type</Typography>
                            <Typography variant="body1">What should happen when this action is fired.</Typography>
                        </Box>
                        <Box sx={{ width: 300 }}>
                            <TextField
                                label="Action Type "
                                value={action.actionToTake}
                                select
                                fullWidth
                                color="secondary"
                                onChange={(e) => {
                                    const updateData = { ...action, actionToTake: e.target.value };
                                    updateAction(updateData);
                                }}
                            >
                                <MenuItem value={0}>Nothing</MenuItem>
                                <MenuItem value={1}>Send Email</MenuItem>
                                <MenuItem value={2}>Attach Members to Ticket</MenuItem>
                            </TextField>
                        </Box>
                    </TwoSide>

                    {action.actionToTake == 1 && (
                        <TwoSide>
                            <Box>

                                <Typography variant="h5">Customize Email</Typography>
                                <Typography variant="body1">Customize what the email looks like and who to send it to.</Typography>
                            </Box>
                            <Box sx={{width:'100%'}}>
                                <CustomizeEmail parameters={action.parameters} onChange={(v) => {
                                    const updateData = { ...action, parameters: v };
                                    updateAction(updateData);
                                }} />
                            </Box>
                        </TwoSide>
                    )}

                    {action.actionToTake == 2 && (
                        <Stack>
                            <Box>

                                <Typography variant="h5">Customize Members to add</Typography>
                                <Typography variant="body1">Customize which members should be added to the ticket when a action is called.</Typography>
                                <Typography color="text.secondary" variant="subtitle1" gutterBottom>Note: Members will not be notified when added to a ticket by a Action.</Typography>
                            </Box>
                            <Box sx={{m:'1rem 0'}}>
                                <MembersSelector members={getMembers() ?? ""} onChange={setMembers}/>
                            </Box>
                        </Stack>
                    )}
                </Stack>

            </CardContent>
        </Card>
    )
}

const ActionsSettings = () => {
    const [currentAction, setCurrentAction] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [events, setEvents] = useState([])
    const [backdropOpen, setBackdropOpen] = useState(false);
    const { data, isLoading, error, refetch } = useGetActionsQuery("")
    const theme = useTheme();

    useEffect(() => {
        refetch()
    }, [])


    useEffect(() => {
        if (!isLoading && data != null) {
            setEvents([...data])
        }
    }, [data])

    const deleteAction = async (id, index) => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/actions/' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        })

        if (response.status == 200 && index == currentIndex) {
            setCurrentIndex(-1);
            setCurrentAction(null)
        }


        refetch()
    }

    const createAction = async () => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/actions/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                id: "",
                name: "New Action",
                description: "New Action",
                actionToTake: 0,
                parameters: "",
                fireOn: ""
            })
        })

        refetch()
    }
    return (
        <Box mt="1rem">
            <Backdrop sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1
            }} open={isLoading || backdropOpen}>
                <Loading />
            </Backdrop>
            <Typography variant="h3">Action Settings</Typography>
            <Typography variant="subtitle1">Create actions which can do automated tasks that will run when called by events that you create.</Typography>
            <DisplayIfTestSuite>
                <Typography>NOTE! Actions will not fire in a test suite.</Typography>
            </DisplayIfTestSuite>
            <Divider sx={{ m: isLoading ? '1rem 0 0rem 0' : '1rem 0 2rem 0' }} />
            {isLoading && (
                <Box sx={{
                    mb: '1rem'
                }}>
                    <LinearProgress color="secondary" />
                </Box>
            )}
            <Grid2 container spacing={5}>
                <Grid2 md={3}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>Actions</Typography>
                            <Divider sx={{ mb: '1rem' }} />

                            <Stack>
                                <List>

                                    {events.map((p, i) => (
                                        <ListItemButton key={p.id} onClick={() => {
                                            setCurrentIndex(i)
                                            setCurrentAction(p.id)
                                        }} sx={{
                                            //@ts-ignore
                                            backgroundColor: currentIndex === i ? theme.palette.secondary[300] : "transparent",

                                            //@ts-ignore
                                            color: currentIndex === i ? theme.palette.primary[600] : theme.palette.secondary[100]
                                        }}>
                                            <ListItemText primary={p.name} />
                                            <IconButton onClick={() => deleteAction(p.id, i)} size="small">
                                                <Delete />
                                            </IconButton>
                                        </ListItemButton>
                                    ))}
                                </List>
                                <Button color="secondary" variant="outlined" onClick={() => createAction()}>Create New Action</Button>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid2>
                <Grid2 md={9}>
                    {currentAction != null && (
                        <ActionCard id={currentAction} onChange={() => refetch()} />
                    )}
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default ActionsSettings