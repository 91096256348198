import { Edit } from '@mui/icons-material';
import { Chip, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useGetTicketStatusQuery, useGetTicketStatusesQuery } from 'state/api';
import * as iconName from "@mui/icons-material"

interface TicketStatusChip {
    status: any;
}
export const TicketStatusChip: React.FC<TicketStatusChip> = ({ status }) => {

    if (status == null) {
        return (<Chip icon={React.createElement(iconName["QuestionMark"])} size="small" label="Uknown Status" variant="outlined" color='error' />)
    } else {
        if (iconName[status.iconUrl] != null) {

            return (
                <Chip icon={React.createElement(iconName[status.iconUrl])} size="small" label={status.title} variant="outlined" color={status.color} />
            )
        } else {
            return (<Chip icon={React.createElement(iconName["QuestionMark"])} size="small" label="Uknown Status" variant="outlined" color='error' />)
        }
    }

}

interface TicketStatusSelectMenu {
    currentStatus: string;
    onChange: (string) => void;
    onClose: () => void;
    anchorEl: HTMLElement;
    open: boolean;
}
const TicketStatusSelectMenu: React.FC<TicketStatusSelectMenu> = ({ currentStatus, open, onChange, anchorEl, onClose }) => {

    const { data, isLoading, error, refetch } = useGetTicketStatusesQuery("");
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        refetch()
    }, [currentStatus])

    useEffect(() => {
        if (!isLoading && data != null) {
            setStatuses([...data]);
        }
    }, [isLoading, data])

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}

            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {statuses.filter((o) => o.id != currentStatus).map((v, i) => (<MenuItem key={v.id} onClick={() => { onChange(v.id); onClose() }}>{v.title}</MenuItem>))}
        </Menu>
    )
}

interface TicketStatus {
    status: string;
    editable: boolean;
    onChange: (status) => void;
}

const TicketStatusComponent: React.FC<TicketStatus> = ({ status, editable, onChange }) => {

    const { data, isLoading, error, refetch } = useGetTicketStatusQuery(status);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    useEffect(() => {
        refetch();
    }, [status])

    useEffect(() => {
        if (!isLoading && data != null) {
            setCurrentStatus(data);
        }
    }, [isLoading, data])



    return (
        <>
            {editable ? (
                <>
                    <Typography sx={{ ml: '.5rem' }} onClick={() => handleClick}>
                        {<TicketStatusChip status={currentStatus} />}
                        <IconButton onClick={handleClick}>

                            <Edit sx={{ fontSize: '13px' }} />
                        </IconButton>
                    </Typography>
                    <TicketStatusSelectMenu currentStatus={currentStatus?.id ?? ""} anchorEl={anchorEl} open={open} onChange={(id) => {
                        onChange(id)
                    }} onClose={() => {
                        setAnchorEl(null)
                    }} />
                </>
            ) : (
                <Typography sx={{ ml: '.5rem' }} >{<TicketStatusChip status={currentStatus} />}</Typography>
            )}

        </>
    )
}

export default TicketStatusComponent