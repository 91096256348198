import React, {useEffect} from 'react';
import {Button, Dialog, DialogContent, Divider, Stack, Typography} from "@mui/material";
import {useGetInventoryDeviceQuery, useGetStudentQuery} from "../../../state/api";
import StudentAutocomplete from "../../../components/Autocomplete/StudentAutocomplete";
import UserAutocomplete from "../../../components/Autocomplete/UserAutocomplete";


interface AssignStudentProps {
    currentStudent: any;
    onChange: (student: any) => void;
}
const AssignStudent: React.FC<AssignStudentProps> = ({currentStudent, onChange}) => {

    return (
        <UserAutocomplete onValueChange={(v) => onChange(v)} label={"Assign New User"} value={currentStudent}/>
    )
}

interface DeviceCheckoutDialogProps {
    onChange: () => void;
    open: boolean;
    setOpen: (open: boolean) => void;
    device: string;
}

export const DeviceCheckoutDialog: React.FC<DeviceCheckoutDialogProps> = ({onChange, open, setOpen, device}) => {
    const {data: InventoryDevice, isLoading, refetch: RefetchInventoryDevice} = useGetInventoryDeviceQuery(device);
    const {data: Student, refetch: RefetchStudent} = useGetStudentQuery(device ?? "");
    const [student, setStudent] = React.useState(null);
    useEffect(() => {
        RefetchInventoryDevice();
        RefetchStudent();
    }, [device]);

    useEffect(() => {
        setStudent({id: Student?.accountId})
    }, [Student]);

    const Checkout = () => {
        fetch(process.env.REACT_APP_BASE_URL + `/org/inventory/devices/${device}/checkout?student=${student.id}&update=${student.id != Student.accountId}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        }).then((res) => {
            if(res.status == 200) {
                setOpen(false);
                onChange();
            }
        })
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
            <DialogContent>
                <Typography variant={"h3"}>Checkout {device}?</Typography>
                <Divider sx={{mb: '1rem 0'}}/>

                {Student ? (
                    <>
                        <Typography>Current assigned student: {Student.name}</Typography>
                    </>
                ) : (
                    <>
                        <Typography>Current assigned student: None</Typography>
                    </>
                )}
                <Stack spacing={2} sx={{mt: '1rem'}}>
                    <AssignStudent currentStudent={student ?? ""} onChange={(v) => {
                        setStudent(v)
                    }}/>

                    {student != null && student?.id != Student?.accountId && (
                        <Typography>
                            WARNING: This will re-assign the device to {student.givenName + " " + student.familyName}
                            {Student ? " and will remove it from " + Student.name : ""}.
                        </Typography>

                    )}
                    <Button variant={"outlined"} color={"success"} onClick={Checkout}>Checkout</Button>
                </Stack>

            </DialogContent>
        </Dialog>
    )
}

interface DeviceCheckoutDialogWrapperProps {
    onChange: () => void;
    device: string;
}

const DeviceCheckoutDialogWrapper: React.FC<DeviceCheckoutDialogWrapperProps> = ({onChange, device}) => {

    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Button variant={"outlined"} color={"success"} onClick={() => setOpen(true)}>Checkout</Button>

            {open && (
                <DeviceCheckoutDialog onChange={onChange} open={open} setOpen={setOpen} device={device}/>
            )}
        </>
    )
};

export default DeviceCheckoutDialogWrapper;