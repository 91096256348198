import { InputBase, IconButton, TextField, Autocomplete, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useGetAllQuery } from 'state/api'
import Loading from './Loading';
import { StringSchema } from 'yup';
import QueryAllAutocomplete from './Autocomplete/QueryAllAutocomplete';

interface SearchOption {
  id: string,
  link: string
}

function Search() {
  const GoTo = (option) => {

    if(typeof option === "string" && isUrl(option)) {
      document.location.href = option
    }
    lazyNavigation(option.link);

  }

  const isUrl = (data: string) => {
    return data.split('/').length > 0
  }


  const lazyNavigation = (route) => {
    const host = document.location.origin;
    document.location.href = host + route
  }   

  return (
    <>
      <Box sx={{width: 300, mb: '1.3rem'}}>
        
        <QueryAllAutocomplete 
          label="Search..."
          onValueChange={(val) => {
            GoTo(val)
          }}
          value=""
          fullWidth
        />
      </Box>
    </>
  )

  /**
   * 
   *         <Autocomplete
            disablePortal
            id="combo-box-demo"
            freeSolo
            onChange={(event: any, newValue: string | null) => {
              GoTo(newValue)
            }}
            options={searchOptions.map((option) => option.id)}
            sx={{ width: 300, mb: '1.3rem' }}
            renderInput={(params) =><TextField color='secondary' variant='standard' {...params} label="Search..." />}
        />
   */
}

export default Search