import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Grow, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import CustomTicketTable from 'components/CustomTicketTable';
import FlexBetween from 'components/FlexBetween';
import Header from 'components/Header';
import TwoSide from 'components/data/TwoSide';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useGetAllTicketsQuery } from 'state/api';
import {Build, CleaningServices} from "@mui/icons-material";
import TicketActions from "./TicketActions";
import RequiresScope from "../../components/RequiresScope";

function Tickets() {

  const { data, error, isLoading, refetch } = useGetAllTicketsQuery({});
  const [tickets, setTickets] = useState(data);
  const [checked, setChecked] = React.useState(true);
  const navigate = useNavigate();
  const [ticketActionsOpen, setTicketActionsOpen] = useState(false);

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (!isLoading && data) {
      if (checked) {
        setTickets(data.filter((d) => d.isActive));
      } else {
        setTickets(data);
      }
    }
  }, [checked, data, isLoading]);
  const navigateToTicket = (row: GridRowParams) => {
    navigate(`../ticket/${row.id.toString()}`);
  }

  const getTicketStatusToString = (params: GridValueGetterParams) => {
    var data = params.row.status;
    switch (data) {
      case 0:
        return "Waiting for Repair"
        break;
      case 1:
        return "Repaired, Waiting for Return"
        break;
      case 2:
        return "In Office"
        break;
      case 3:
        return "Completed"
        break;
      case 4:
        return "Other"
        break;

    }
  }


  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'studentName', headerName: 'Student', flex: 1 },
    {
      field: 'device',
      headerName: 'Damaged Device Serial Number',
      type: 'string',
      flex: 1,
    },
    {
      field: 'loanerId',
      headerName: 'Loaner',
      flex: 1,
    },
    {
      field: 'issue',
      headerName: 'Issue',
      description: "The issue listed on the ticket",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.issue != "other" ? params.row.moreInfo : params.row.issue + " " + params.row.moreInfo}`,
    },

    {
      field: 'status',
      headerName: 'Ticket Status',
      flex: .7,
      valueGetter: getTicketStatusToString

    }

  ];

  const smallScreen: GridColDef[] = [
    { field: 'studentName', headerName: 'Student', flex: 1 },
    {
      field: 'device',
      headerName: 'Damaged Device Serial Number',
      type: 'string',
      flex: 1,
    },
    {
      field: 'issue',
      headerName: 'Issue',
      description: "The issue listed on the ticket",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.issue != "other" ? params.row.moreInfo : params.row.issue + " " + params.row.moreInfo}`,
    },

    {
      field: 'status',
      headerName: 'Ticket Status',
      flex: .7,
      valueGetter: getTicketStatusToString

    }

  ];




  return (
    <Box m="1.5rem 2.5rem">
      <TwoSide>
        <Header title="TICKETS" subtitle="List of all tickets" />

        <Box>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={checked} onChange={(e, isChecked) => setChecked(isChecked)} color='secondary' />} label="View only Active Tickets?" />
            <RequiresScope scope={"write:ticket"}>
              <Button color={"secondary"} variant={"contained"} endIcon={<CleaningServices/>} onClick={() => setTicketActionsOpen(true)}>Clean Tickets</Button>

            </RequiresScope>
          </FormGroup>
        </Box>

      </TwoSide>
      <TicketActions onChange={() => refetch()} open={ticketActionsOpen} setOpen={setTicketActionsOpen}/>
      <Grow in={true}>
        <Box
          mt="40px"
          height="100%">
          <CustomTicketTable onRowClick={navigateToTicket} data={tickets || []} onlyActive={false} />

        </Box>

      </Grow>
    </Box>
  )
}

export default Tickets