import { Box, Dialog, DialogContent, Divider, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react'
import { useGetFormQuery, useGetLayoutQuery } from 'state/api';


interface Members {
  members: string;
  onChange: (members: string) => void;
  allowEmpty: boolean;
  label: string;
}
const EmailSelector: React.FC<Members> = ({ members, label, allowEmpty, onChange }) => {
  const [membersString, setMembersString] = useState("");
  const allowedFillerItems = ["{authorEmail}", "{studentEmail}"]
  useEffect(() => {
    setMembersString(members)
  }, [members])

  const handleBlur = () => {
    if (isValid(membersString)) {
      onChange(membersString)
    }
  }

  const validateEmails = (emailList: string) => {
    const emails = emailList.split(' ');
    const emailRegex = /^(\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,4}\s*?,?\s*?)+$/;
    const fillerRegex =  /\{(.+?)\}/g;
    if (emails.length > 10) {
      return "You can't have more than 10 emails";
    }

    for (let email of emails) {
      if (email == "" && !allowEmpty) {
        return `Cannot be empty`
      } else if (allowEmpty) continue;
      
      if(fillerRegex.test(email)) {
        if(allowedFillerItems.includes(email)) continue;
        return `${email} is not a valid filler item`
      } 

      if (!emailRegex.test(email)) {
        return `${email} is not a valid email`;
      }
    }

    if (emailList.includes(",")) {
      return "Cannot contain a comma!";
    }

    return 'Add Up to 10 emails, each seperated by a space';
  }

  const isValid = (emailList: string) => {
    const emails = emailList.split(' ');
    const emailRegex = /^(\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,4}\s*?,?\s*?)+$/;
    const fillerRegex =  /\{(.+?)\}/g;

    if (emails.length > 10) {
      return false;
    }

    for (let email of emails) {
      if (email == "" && !allowEmpty) {
        continue;
      } else if (allowEmpty) continue;

      if(fillerRegex.test(email)) {
        if(allowedFillerItems.includes(email)) continue;
        return false;
      } 


      if (!emailRegex.test(email)) {
        return false;
      }
    }

    if (emailList.includes(",")) {
      return false;
    }

    return true;
  }



  return (
    <TextField
      sx={{ width: "100%" }}
      label={label}
      helperText={validateEmails(membersString)}
      value={membersString}
      multiline
      onBlur={(e) => handleBlur()}
      onChange={(v) => setMembersString(v.target.value)}
      color={isValid(membersString) ? "secondary" : "error"}
    />
  )
}

interface LazyTextField {
  value: string;
  label: string;
  multiline?: boolean;
  multilineLevels?: number;
  onChange: (val: string) => void;
  helperText?: (string) => string;
  validator?: (string) => boolean;
}
const LazyTextField: React.FC<LazyTextField> = ({ value, label, multiline, multilineLevels, onChange, helperText, validator }) => {
  const [temp, setTemp] = useState(value ?? "")

  useEffect(() => {
    setTemp(value ?? "");
  }, [value])
  return (
    <TextField
      label={label}
      value={temp}
      onChange={(e) => setTemp(e.target.value)}
      onBlur={(e) => onChange(e.target.value)}
      fullWidth
      color={validator != null ? validator(temp) ? "success" : "error" : "success"}
      multiline={multiline}
      rows={multilineLevels}
      helperText={helperText != null ? helperText(temp) : ""}
    />
  )
}


const EmailFillerItems = () => {
  const [open, setIsOpen] = useState(false)


  return (
    <Box>
      <Typography variant="subtitle1">
        Note, you can have emails filled when the action is called, for example {"{"}authorEmail{"}"} will be filled
        with the authors email.
      </Typography >
      <Typography
        variant="subtitle1"
        onClick={() => setIsOpen(true)}
        style={{ cursor: 'pointer' }}
      >
         View Filler Items
      </Typography>

      <Dialog
        open={open}
        onClose={() => setIsOpen(false)}
      >
        <DialogContent>
          <Box position={"static"}>
            <Typography variant="h3">Filler Options</Typography>
            <Divider/>
          </Box>


          <List>
            <ListItem>
              <ListItemText primary={"{authorEmail}"} secondary={"Fills with the authors email of the ticket"} />
            </ListItem>

            <ListItem>
              <ListItemText primary={"{studentEmail}"} secondary={"Fills with the students email of the ticket"} />
            </ListItem>


          </List>

        </DialogContent>
      </Dialog>

    </Box>
  )
}

const FillerItemsHelper = ({ formOptions }) => {
  const [open, setIsOpen] = useState(false)


  return (
    <Box>
      <Typography variant="subtitle1">
        Note, you can add filler items that will be filled with relevant data when called. For example {'{'}ticketLink{'}'} will
        be replaced with a link to the ticket.
      </Typography >
      <Typography
        variant="subtitle1"
        onClick={() => setIsOpen(true)}
        style={{ cursor: 'pointer' }}
      >
        View Filler Items
      </Typography>

      <Dialog
        open={open}
        onClose={() => setIsOpen(false)}
      >
        <DialogContent>
          <Box position={"static"}>
            <Typography variant="h3">Filler Options</Typography>
            <Divider/>
          </Box>

          <List>


            {formOptions.map((option) => (
              <ListItem>
                <ListItemText primary={option.filler} secondary={option.description} />
              </ListItem>
            ))}

          </List>

        </DialogContent>
      </Dialog>

    </Box>
  )
}

interface CustomzieEmail {
  parameters: string;
  onChange: (string) => void;
}
const CustomizeEmail: React.FC<CustomzieEmail> = ({ parameters, onChange }) => {



  const { data, isLoading, error, refetch } = useGetFormQuery("");

  const [fillerOptions, setFillerOptions] = useState([])

  useEffect(() => {
    if (!isLoading && data != null) {
      var opt = data.fields;


      var options = []

      var staticOptions = [
        {
          filler: "{ticketLink}",
          description: "This will be replaced with a link to the ticket"
        },
        {
          filler: "{ticketId}",
          description: "This will be replaced with the id of the ticket."
        },
        {
          filler: "{studentName}",
          description: "This will be replaced with the name of the student"
        },
        {
          filler: "{ticketStatus}",
          description: "This will be replaced with the staus of the ticket."
        },
        {
          filler: "{studentEmail}",
          description: "This will be replaced with the students email"
        }
      ]

      options.push(...staticOptions);

      options.push(...opt.map((o) => {
        return {

          filler: "{" + o.label + "}",
          description: "Fills in with the data from the " + o.label + " field"
        }
      }))

      setFillerOptions(options)
    }
  }, [data])

  const getEmails = () => {
    var dict = convertToDictionary();

    return dict["email"] ?? ""
  }

  const getCCs = () => {
    var dict = convertToDictionary();

    return dict["cc"] ?? ""
  }

  const getSubject = () => {
    var dict = convertToDictionary();

    return dict["subject"] ?? ""
  }

  const getBody = () => {
    var dict = convertToDictionary();


    var sanitized = unsanitizeString(dict["body"] ?? "")


    return sanitized ?? ""
  }

  const updateParameters = (parameter, newValue) => {
    var dict = convertToDictionary();

    if (parameter == "body" || parameter == "subject") {
      dict[parameter] = sanitizeString(newValue);
    } else {

      dict[parameter] = newValue;
    }


    onChange(dictionaryToString(dict))
  }

  const dictionaryToString = (dict) => {
    return Object.entries(dict).map(([key, value]) => `${key}=${value}`).join(',');
  }


  const sanitizeString = (str) => {
    if(str == null) str = ""
    str = str.replace(/\n/g, "<br/>");
    str = str.replace(/,/g, "%2C");

    return str;
  }

  const unsanitizeString = (str) => {
    if(str == null) str = ""
    str = str.replace(/<br\/>/g, "\n");
    str = str.replace(/%2C/g, ",");
    return str;
  }



  const convertToDictionary = () => {
    var params = parameters.split(",")
    let parametersDict = {};
    params.forEach(parameter => {
      if (parameter != "") {

        const [key, value] = parameter.split('=');
        if (key != "" && value != "") {

          parametersDict[key] = value;
        }
      }
    });

    return parametersDict;
  }

  const isValidFillerItem = (item: string) => {
    const fillerItemPattern = /\{(.+?)\}/g;
    let match = fillerItemPattern.exec(item);

    while (match != null) {
      if (fillerOptions.findIndex(v => v.filler == "{" + match[1] + "}") == -1) {
        return false;
      }
      match = fillerItemPattern.exec(item);
    }

    return true;
  }

  const validateFillerItem = (item: string) => {
    const fillerItemPattern = /\{(.+?)\}/g;
    let match = fillerItemPattern.exec(item);

    while (match != null) {
      if (fillerOptions.findIndex(v => v.filler == "{" + match[1] + "}") == -1) {
        return `Error: {${match[1]}} is not a valid filler item.`;
      }
      match = fillerItemPattern.exec(item);
    }

    return '';
  }


  return (
    <Grid2 container spacing={1} sx={{ width: '100%' }}>
      <Grid2 md={12}>
        <EmailFillerItems />
      </Grid2>

      <Grid2 md={12}>
        <EmailSelector allowEmpty={false} label="To Email" members={getEmails()} onChange={(m) => updateParameters("email", m)} />
      </Grid2>

      <Grid2 md={12}>
        <EmailSelector allowEmpty label="CC Members" members={getCCs()} onChange={(m) => updateParameters("cc", m)} />
      </Grid2>

      <Grid2 md={12}>
        <Divider sx={{ m: '1rem 0 0 0' }} />
      </Grid2>

      <Grid2 md={12} sx={{ mb: '1rem' }}>
        <FillerItemsHelper formOptions={fillerOptions} />
      </Grid2>

      <Grid2 md={12}>
        <LazyTextField
          label="Subject"
          value={getSubject()}
          onChange={(m) => updateParameters("subject", m)}
          validator={isValidFillerItem}
          helperText={validateFillerItem}
        />
      </Grid2>
      <Grid2 md={12}>
        <LazyTextField
          label="Body"
          value={getBody()}
          onChange={(m) => updateParameters("body", m)}
          multiline
          multilineLevels={20}
          validator={isValidFillerItem}
          helperText={validateFillerItem}
        />
      </Grid2>
    </Grid2>
  )
}

export default CustomizeEmail