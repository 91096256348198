import { AppBar, Box, Button, Toolbar, Typography, useTheme } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import FlexBetween from 'components/FlexBetween'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { setForcedMode } from 'state'
import { LazyNavigation } from 'state/api'


function Navbar() {
  const theme = useTheme();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setForcedMode("light"))
  }, [])
  return (
    <AppBar sx={{
      position: 'static',

      width: '100%'
      }}>

      <Toolbar>
        <Box sx={{
          display: 'flex',
          flexDirection: "row",
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignContent: 'center',
          width: '100%'
        }}>
          <Box color={theme.palette.secondary.main} display="flex" alignItems="center" gap="0.5rem">
            <Typography variant="h2" fontWeight="bold">
              CTickets
            </Typography>
          </Box>

          <FlexBetween>
              <Button color="secondary" variant="contained" onClick={() => LazyNavigation("/docs")}>Documentation</Button>
            <Button color="secondary" variant="contained" onClick={() => LazyNavigation("/login")}>Login/Register</Button>
          </FlexBetween>
        </Box>



      </Toolbar>
          
      </AppBar>
  )
}
function HomeLayout() {
  return (
    <Box>
      <Navbar />
      <Outlet/>
    </Box>
  )
}

export default HomeLayout
