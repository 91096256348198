import { CheckOutlined, HourglassBottomOutlined, KeyboardReturnOutlined, PendingOutlined, QuestionMarkOutlined } from '@mui/icons-material';
import { Box, Chip, Skeleton, useTheme } from '@mui/material';
import { DataGrid, GridCallbackDetails, GridCellParams, GridColDef, GridFilterItem, GridFilterOperator, GridRenderCellParams, GridRowParams, GridToolbar, GridValueGetterParams, MuiEvent } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useGetTableQuery, useGetTicketStatusesQuery } from 'state/api';
import TicketStatusComponent, { TicketStatusChip } from './data/TicketStatus';


interface Table {
  data: any[],
  onlyActive: boolean;
  onRowClick?: (params: GridRowParams<any>) => void;
  navigateOnClick?: boolean;
  autoHeight?: boolean;
  defaultBackground?: boolean;
}
const CustomTicketTable: React.FC<Table> = ({ data, onlyActive, onRowClick, navigateOnClick, defaultBackground, autoHeight }) => {
  const [rowParams, setRowParams] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [statusMapped, setStatusesMapped] = useState(new Map());
  const [statusObjectsMapped, setStatusObjectsMapped] = useState(new Map());

  const { data: statuses, isLoading: statusLoading } = useGetTicketStatusesQuery("");



  const theme = useTheme();
  const { data: tableRows, isLoading } = useGetTableQuery("");
  const navigate = useNavigate();


  useEffect(() => {
    if(!statusLoading && statuses != null) {
      const newMap = new Map();
      const objectMap = new Map();

      statuses.forEach(status => {
        objectMap.set(status.id, status);
        newMap.set(status.id, status.title);
      });


      setStatusObjectsMapped(objectMap);
      setStatusesMapped(newMap);
    }
  }, [statuses])

  useEffect(() => {
    if (onlyActive) {
      data = data.filter((ticket) => ticket.isActive)
    }
    if (data == null) {
      setRowData([])
      return;
    }

    var items = data.map((ticket) => {
      var json = JSON.parse(ticket.data);
      var ticketData = { ...ticket, ...json };
      if (ticketData.studentName !== "Unknown") {
        ticketData["Student Information"] = ticketData.studentName;
      }
      return ticketData;
    });

    setRowData(items);
  }, [data]);

  useEffect(() => {
    if (!isLoading && tableRows.length > 0) {
      var dataRows = [...tableRows];
      var index = dataRows.findIndex((d) => d.field === "status");
      var createdAtIndex = dataRows.findIndex((d) => d.field === "createdAt");
      if (index !== -1) {
        dataRows[index] = Object.assign({}, dataRows[index], {
          valueGetter: (params) => {
            return statusMapped.get(params.row.status);
          },

          renderCell: (params: GridRenderCellParams) => {
            return (<TicketStatusChip status={statusObjectsMapped.get(params.row.status)} />)
          },
        
          
        
        });
        // dataRows[index] = Object.assign({}, dataRows[index], {
        //   editable: true,
        // });
      }

      if (createdAtIndex !== -1) {
        dataRows[createdAtIndex] = Object.assign({}, dataRows[createdAtIndex], {
          type: "dateTime"
        });
        dataRows[createdAtIndex] = Object.assign({}, dataRows[createdAtIndex], {
          valueGetter: (params) => {
            return new Date(params.row.createdAt)
          }
        });
      }
      setRowParams(dataRows);
    }
  }, [tableRows, isLoading, statusMapped]);



  const getLink = (endpoint: string) => {
    const host = document.location.origin;
    return host + endpoint;
  }

  if (rowParams.length == 0) {
    return (<Skeleton variant="rectangular" height={500} />)
  }


  if (defaultBackground) {
    return (
      <Box
        height="75vh"

        sx={{

          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            cursor: 'grab',
            borderBottom: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          slots={{ toolbar: GridToolbar }}
          loading={isLoading || !rowData}
          getRowId={(row) => row.id}
          rows={rowData}
          columns={rowParams}
          onRowClick={(params) => {
            if (onRowClick) onRowClick(params);
            if (navigateOnClick) {
              document.location.href = getLink(`/org/ticket/${params.row.id.toString()}`);
            }
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'createdAt', sort: 'desc' }],
            },
          }}
          autoHeight={autoHeight}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Box>
    )
  } else {

    return (
      <Box
        height="75vh"
        sx={{

          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            cursor: 'grab',
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            //@ts-ignore

            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            //@ts-ignore
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          slots={{ toolbar: GridToolbar }}
          loading={isLoading || !rowData}
          getRowId={(row) => row.id}
          rows={rowData}
          columns={rowParams}
          autoHeight={autoHeight}
          initialState={{
            sorting: {
              sortModel: [{ field: 'createdAt', sort: 'desc' }],
            },
          }}
          onRowClick={(params) => {
            if (onRowClick) onRowClick(params);
            if (navigateOnClick) {
              document.location.href = getLink(`/org/ticket/${params.row.id.toString()}`);
            }
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Box>
    )
  }
}

export default CustomTicketTable