import {DesktopAccessDisabled} from '@mui/icons-material'
import {Box, Divider, Skeleton, Typography} from '@mui/material'
import ViewInProviderButton from 'components/ViewInProviderButton'
import DataField from 'components/data/DataField'
import React from 'react'
import HoverPaper from "../../../components/HoverPaper";

const DeviceInfoCard = ({device, isLoading}) => {


    if (isLoading) {
        return (
            <HoverPaper elevation={4}>
                <Skeleton height="20vh"/>
            </HoverPaper>
        )

    }

    if (!device) {
        return (
            <HoverPaper>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Device Information</Typography>
                <Typography sx={{fontSize: 12}} color="text.secondary" gutterBottom>{device.serialNumber}</Typography>
                <Divider sx={{mb: '1rem'}}/>

                <Box display="flex" justifyContent="center" alignContent="center">
                    <DesktopAccessDisabled fontSize='large'/>
                </Box>
            </HoverPaper>
        )
    }
    return (
        <HoverPaper elevation={4}>
            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Device Information</Typography>
            <Typography sx={{fontSize: 12}} color="text.secondary" gutterBottom>{device.serialNumber}</Typography>
            <Divider sx={{mb: '1rem'}}/>

            <DataField compact title="Serial Number" data={device.serialNumber} width="100%"/>
            <DataField compact title="Asset Id" data={device.annotatedAssetId} width="100%"/>
            <DataField compact title="Annotated User" data={device.annotatedUser} width="100%"/>
            <DataField compact title="Annotated Location" data={device.annotatedLocation} width="100%"/>
            <DataField compact title="Notes" data={device.notes} width="100%"/>
            <DataField compact title="OU" data={device.orgUnitPath} width="100%"/>
            {device.activeTimeRanges != null && (<DataField compact title="Last Login Time"
                                                            data={new Date(device.activeTimeRanges.at(-1)).toDateString()}
                                                            width="100%"/>)}

            <ViewInProviderButton type="device" id={device.deviceId}/>
        </HoverPaper>
    )
}

export default DeviceInfoCard