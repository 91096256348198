import { Avatar, Box, Card, CardContent, Divider, Skeleton, Typography, useTheme } from '@mui/material'
import { red } from '@mui/material/colors'
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'
import React from 'react'
import { LazyNavigation, useGetAccessedDevicesFromUserQuery } from 'state/api'

const AccessedDevicesCard = ({ id }) => {
  const theme = useTheme()
  const { data, isLoading } = useGetAccessedDevicesFromUserQuery(id);
  const devicesColumns: GridColDef[] = [
    { field: "serialNumber", headerName: 'Serial Number', flex: 1 },
    { field: "annotatedAssetId", headerName: 'Asset Id', flex: 1 },
    { field: "model", headerName: 'Model', flex: 1 },
  ]

  if (isLoading) {
    <Card>
      <CardContent>
        <Skeleton height="40vh" />
      </CardContent>
    </Card>
  }
  return (
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Devices Accessed</Typography>
        <Divider sx={{ mb: '1rem' }} />
        <Box
          height="50vh"

          sx={{

            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              cursor: 'grab',
              borderBottom: "none",
            },

            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            loading={isLoading}
            getRowId={(row) => row.serialNumber}
            rows={data || []}
            columns={devicesColumns}
            onRowClick={(params) => {
              LazyNavigation("/org/devices/" + params.row.serialNumber)
            }}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default AccessedDevicesCard