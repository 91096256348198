import { Box, Typography, Divider, useTheme } from '@mui/material';
import Header from 'components/Header';
import Loading from 'components/Loading';
import React, { useEffect } from 'react'
import { useGetDeviceQuery } from 'state/api';
import DataField from './DataField';

//@ts-ignore
function Device({data, header}) {
  const theme = useTheme();
  return (
    <Box  sx={{
      //@ts-ignore
      backgroundColor: theme.palette.background.alt,
      overflowY: 'auto'
      }} height="100%"
    >
      <Box p=".5rem">
        <Header title={header} subtitle={data.serialNumber}/>
      </Box>


      <Typography m="1rem .5rem 0 .5rem" variant='h4'>Device Information</Typography>
      <Divider/>

      <Box sx={{

        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }}>
        <DataField title={"Serial Number"} data={data.serialNumber} width={"100%"}/>
        <DataField title={"Annotated Asset Id"} data={data.annotatedAssetId} width={"100%"}/>
        <DataField title={"Annotated User"} data={data.annotatedUser} width={"100%"}/>
        <DataField title={"Annotated Location"} data={data.location} width={"100%"}/>
      </Box>

      <Box sx={{

        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }}>
        <DataField title={"Notes"} data={data.notes} width={"100%"}/>
        <DataField title={"Model"} data={data.model} width={"100%"}/>
        <DataField title={"Org Unit Path"} data={data.orgUnitPath} width={"100%"}/>
        <DataField title={"Status"} data={data.status} width={"100%"}/>
      </Box>

      <Typography m="5rem .5rem 0 .5rem" variant='h4'>Debug Information</Typography>
      <Divider/>

      <Box sx={{

        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }}>
        <DataField title={"Boot Mode"} data={data.bootMode} width={"100%"}/>
        <DataField title={"ETag"} data={data.etag} width={"100%"}/>
        <DataField title={"Firmware Version"} data={data.firmwareVersion} width={"100%"}/>
        <DataField title={"Kind"} data={data.kind} width={"100%"}/>
      </Box>



      <Box sx={{  

        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }}>
        <DataField title={"Last Enrollement Time"} data={new Date(data.lastEnrollmentTime).toDateString()} width={"100%"}/>
        <DataField title={"Last Sync"} data={new Date(data.lastSync).toDateString()} width={"100%"}/>
        <DataField title={"MAC Address"} data={data.macAddress} width={"100%"}/>
      </Box>


      <Box sx={{  

        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }}>
        <DataField title={"OS Version"} data={data.osVersion} width={"100%"}/>
        <DataField title={"Platform Type"} data={data.platformType} width={"100%"}/>
        <DataField title={"Platform Version"} data={data.platformVersion} width={"100%"}/>
      </Box>

    </Box>
  )
}

export default Device