import { Box, Typography, Divider, Card, CardContent, Skeleton } from '@mui/material'
import CustomTicketTable from 'components/CustomTicketTable';
import Loading from 'components/Loading';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetStudentsTicketsQuery } from 'state/api'
import HoverPaper from "../../../components/HoverPaper";

function StudentTickets() {
  const {id} = useParams();
  const {data, isLoading, refetch} = useGetStudentsTicketsQuery(id);
  const [tickets, setTickets] = useState([]);
  useEffect(() => {
    refetch()
  }, [id])

  useEffect(() => {
    if(data != null) {
      setTickets([...data]);
    }
  }, [data])
  return (
      <HoverPaper elevation={4} sx={{height: '100%'}}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Student Tickets</Typography>
        <Divider sx={{mb:'1rem'}} />
        {isLoading  ? (
            <Skeleton height="40vh"/>
        ) : (
            <CustomTicketTable defaultBackground data={tickets} onlyActive={false} navigateOnClick/>
        )}
      </HoverPaper>
  )
}

export default StudentTickets