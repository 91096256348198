import  {styled} from "@mui/system";
import {Box} from "@mui/material";

const FlexBetween = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}) ;

export default FlexBetween;
