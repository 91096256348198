import { Delete } from '@mui/icons-material';
import { Card, CardActions, CardContent, CardMedia, IconButton, Typography } from '@mui/material';
import React from 'react'

interface ImageCard {
    image: string;
    title: string;
    author: string;
    description: string;
    onDelete?: () => void;
}
const ImageCard: React.FC<ImageCard> = ({image, title, author, description, onDelete}) => {
  return (
    <Card>
        <CardMedia
            component="img"
            alt="image"
            width="100%"
            height="auto"
            src={`data:image/jpeg;base64,${image}`}            
        />
        <CardContent>
            <Typography gutterBottom  variant="h3">
                {title}
            </Typography>

            <Typography variant="body2" color="text.secondary">
                {description}
            </Typography>

            <Typography variant="subtitle1">
                Author: {author}
            </Typography>
        </CardContent>
        {onDelete != null && (
            <CardActions>
                <IconButton onClick={() => onDelete()}>
                    <Delete/>
                </IconButton>
            </CardActions>
        )}
    </Card>
  )
}

export default ImageCard