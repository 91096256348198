import {PersonOff} from '@mui/icons-material';
import {Button, Divider, Skeleton, Typography} from '@mui/material'
import DataField from 'components/data/DataField';
import React from 'react'
import {LazyNavigation, useGetAssignedStudentFromDeviceQuery} from 'state/api'
import HoverPaper from "../../../components/HoverPaper";

const DeviceAssignedStudent = ({id}) => {
    const {data, isLoading} = useGetAssignedStudentFromDeviceQuery(id);
    if (isLoading) {
        return (
            <HoverPaper elevation={4}>
                <Skeleton height="40vh"/>
            </HoverPaper>
        )
    }

    if (data == null) {
        return (
            <HoverPaper>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Device's Assigned
                    Student</Typography>
                <Divider sx={{mb: '1rem'}}/>

                <PersonOff/>
            </HoverPaper>
        )
    }
    return (
        <HoverPaper elevation={4}>
            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Device's Assigned Student</Typography>
            <Divider sx={{mb: '1rem'}}/>

            <DataField compact title="Name" data={data.name} width={"100%"}/>
            <DataField compact title="Email" data={data.email} width={"100%"}/>
            <DataField compact title="Device" data={data.serialNumber} width={"100%"}/>
            <Divider sx={{m: '1rem 0 .2rem 0'}}/>
            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Current Assigned Loaner</Typography>
            <DataField compact title="Loaner" data={data.loaner} width="100%"/>
            <DataField compact title="Loaner Asset Id" data={data.loanerAssetId} width="100%"/>

            <Button variant="outlined" color="secondary" onClick={(e) => LazyNavigation('/org/students/' + data.id)}>View
                Student</Button>
        </HoverPaper>
    )
}

export default DeviceAssignedStudent