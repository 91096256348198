
import { Box, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar, GridValueGetterParams } from '@mui/x-data-grid';
import Header from 'components/Header';
import React from 'react'
import Grow from "@mui/material/Grow"
import { useGetStudentsQuery } from 'state/api';
import { useNavigate } from 'react-router-dom';

function Students() {

    const theme = useTheme();
    const {data, isLoading} = useGetStudentsQuery("");
    const navigate = useNavigate()
    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name',flex: 0.7 },
        { field: 'email', headerName: 'Email',flex: 1 },
        {
          field: 'serialNumber',
          headerName: 'Device Serial Number',
          type: 'string',
          flex: 1,
        },
        {
          field: 'annotatedAssetId',
          headerName: 'Device Asset Number',
          type: 'string',
          flex: 1,
        },
        {
          field: 'loaner',
          headerName: 'Assigned Loaner (if any)',
          type: 'string',
          flex: 1,
        },
        {
          field: 'loanerAssetId',
          headerName: 'Assigned Loaner Asset Id (if any)',
          type: 'string',
          flex: 1,
        },
        {
          field: 'tickets',
          headerName: 'Number of Tickets',
          flex: .5,
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.tickets != null ? params.row.tickets.length : '0'}`,
        },
      ];



      //@ts-ignore
      const navigateToStudent = (row: GridRowParams) => {
        navigate(`../students/${row.id.toString()}`);
      }

    return (
      <Box m="1.5rem 2.5rem">
      <Header title="STUDENTS" subtitle="List of all students" />
      <Grow in={true}>

        <Box
          width="100%"
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              cursor: 'grab',
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              //@ts-ignore

              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              //@ts-ignore
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            slots={{ toolbar: GridToolbar }}
            loading={isLoading || !data}
            getRowId={(row) => row.id}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            rows={data || []}
            columns={columns}
            onRowClick={(params) => navigateToStudent(params)}
          />
        </Box>
      </Grow>
      </Box>
    )
}

export default Students