import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack, Typography } from '@mui/material';
import SnackbarHandler from 'components/SnackbarHandler';
import React, { useState } from 'react'

interface DeleteOrg {
    open: boolean;
    setOpen: (boolean) => void;
}
const DeleteOrg:React.FC<DeleteOrg> = ({open, setOpen}) => {
    const [count, setCount] = useState(0);

    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [snackbarError, setSnackbarError] = useState(false)

    const deleteOrganization = async () => {
        var response = await fetch(process.env.REACT_APP_BASE_URL + '/org/delete', {
            method: "GET",
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            }
        })
        if(response.status != 200) {
            setSnackbarError(true)
            setSnackbarMessage("There was a issue delete the organization. Please contact support.")
            setSnackbarOpen(true)
        } else {
            setSnackbarError(false)
            setSnackbarMessage("The Organization has been delete. You will be logged out soon. We are sorry to see you go.")
            setSnackbarOpen(true)

            //This does not create a history entry
            window.location.replace(window.location.pathname + window.location.search + window.location.hash)
        }
    }
  return (
    <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
    >
        <SnackbarHandler open={snackbarOpen} onClose={() => setSnackbarOpen(false)}message={snackbarMessage} severity={snackbarError ? "error" : "success"}/>

        <DialogContent>
            <Typography sx={{mb: '1rem'}} variant="h2">Delete Organization?</Typography>
            <Typography variant="h5">Warning! This action will delete the organizaiton. This action cannot be undone. It is recommended to backup any tickets</Typography>

            <Stack sx={{m: '1rem 0'}}>
                <FormControlLabel control={<Checkbox color="secondary" onChange={(e,c) => setCount(c ? count + 1 : count - 1)}/>} label="I Understand that this action cannot be undone." />
                <FormControlLabel control={<Checkbox color="secondary" onChange={(e,c) => setCount(c ? count + 1 : count - 1)}/>} label="I Understand that that I will lose access to all tickets created." />
            </Stack>
            <Button sx={{mr: '2rem'}} onClick={() => {setOpen(false); setCount(0)}} color="warning" variant="contained">
                Cancel
            </Button>
            <Button onClick={() => deleteOrganization()} disabled={count < 2} color="error" variant="contained">
                Delete my Organization
            </Button>
        </DialogContent>
    </Dialog>
  )
}

export default DeleteOrg