import {Box, Grow, Skeleton} from '@mui/material';
import CustomTicket from 'components/CustomTicket';
import Loading from 'components/Loading';
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {useGetTicketQuery} from 'state/api';

function TicketOverview() {
    const {id} = useParams();
    const {data, isLoading, error, refetch} = useGetTicketQuery(id);
    const [ticketData, setTicketData] = useState({});
    const [ticketInformation, setTicketInformation] = useState(null)
    const [loading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!isLoading && data != null) {
            var ticketDocument = JSON.parse(data[0].data);
            setTicketData({...ticketDocument})
            setTicketInformation({...data[0]})

            setIsLoading(false)
        }
    }, [data, isLoading])

    const updateTicket = async (field: any) => {
        var dataString = JSON.stringify(field);
        if (dataString == data[0].data) return;
        var result = await fetch(process.env.REACT_APP_BASE_URL + "/org/tickets/" + id + '/data', {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({data: `${dataString}`})
        })

        if (result.status == 200) {
            setTicketData(null)
            setTicketInformation(null)
            await refetch()
        }
    }

    const updateStudent = async (student: any, fields: any) => {
        var obj = {};
        if (typeof student == "string") {
            obj = {
                customStudent: true,
                studentName: student,
                studentId: student,
                data: JSON.stringify(fields)
            }
        } else {
            obj = {
                customStudent: false,
                studentName: student.name,
                studentId: student.id,
                data: JSON.stringify(fields)
            }
        }
        var result = await fetch(process.env.REACT_APP_BASE_URL + "/org/tickets/" + id + '/data/student', {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(obj)
        })

        if (result.status == 200) {
            setTicketData(null)
            setTicketInformation(null)
            await refetch()
        }
    }

    if (isLoading || loading) return <Loading/>

    return (
        <Box>
            {(isLoading || loading || ticketInformation == null || ticketData == null) ? <Skeleton/> : (
                <Grow in={true}>
                    <Box>
                        <CustomTicket
                            onStudentUpdate={updateStudent}
                            refetch={refetch}
                            //@ts-ignore
                            ticketInformation={ticketInformation} data={ticketData} onFieldUpdate={updateTicket}/>
                    </Box>

                </Grow>
            )}

        </Box>
    )
}

export default TicketOverview