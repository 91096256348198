import { Slide, Dialog, DialogTitle, DialogContentText, DialogContent, TextField, Button, DialogActions, MenuItem } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react'


interface ReportComponent {
    isOpen:boolean;
    handleClose: (report: Report) => void;
}

interface Report {
    type: string;
    details: string;
}


const ReportTypes = ["Bug", "Feature", "Other"]


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="down" ref={ref} {...props} />;
  });


const Report: React.FC<ReportComponent> = ({isOpen, handleClose}) => {
    const [report, setReport] = React.useState<Report>({type: "", details: ""});

    const updateReport = (report: Report) => {
      setReport({...report})
    }

    const submitReport = () => {

    }
    return (
      <Dialog fullWidth TransitionComponent={Transition} open={isOpen} onClose={() => {handleClose(null)}}>
        <DialogTitle>Submit Report</DialogTitle>
        <DialogContent>
            <DialogContentText>Subit a problem or a wanted feature.</DialogContentText>

            <TextField
                autoFocus
                margin="normal"
                id="name"
                label="Issue?"
                fullWidth
                variant="standard"
                color="secondary"
                onChange={(e) => {
                    report.type = e.target.value;
                    updateReport(report);
                }}
                select
            >
                {ReportTypes.map((type) => (<MenuItem value={type}>{type}</MenuItem>))}
            </TextField>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="More Information"
                multiline
                fullWidth
                variant="outlined"
                color="secondary"
                onChange={(e) => {
                    report.details = e.target.value;
                    updateReport(report);
                }}
                rows={4}
            />
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={() => {handleClose(null)}}>Cancel</Button>
          <Button color="success" disabled={report.details === "" || report.type === ""} onClick={() => {setReport({details: "", type:""}); handleClose(report)}}>Submit</Button>
        </DialogActions>
      </Dialog>
    )
}

export default Report