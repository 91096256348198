import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useGetAppHealthQuery, useGetAppMetadataQuery } from 'state/api'

function Version() {
    const theme = useTheme();
    const {data: health, isLoading, error} = useGetAppHealthQuery("");
    const {data: meta, isLoading: metaIsLoading, error: metaError} = useGetAppMetadataQuery("");


    if(isLoading || metaIsLoading) return (<></>);



    return (
        <Typography sx={{
            position: 'fixed',
            bottom: '0',
            right: '0',
            background: 'rgba(0, 0, 0, 0.01)',
            color: 'rgba(0,0,0,0.5)',
            borderRadius: "5px",
            zIndex: "1000",
            }}
            color={theme.palette.primary.contrastText}
            variant="subtitle2">CTickets V{meta != null ? meta.version : "??"}. App Status: {health != null ? health.status : "DOWN"}</Typography>
    )
}

export default Version