import {Box, Button, Divider, MenuItem, Paper, TextField, Tooltip, Typography, useTheme} from '@mui/material'
import Error from 'components/Error';
import Loading from 'components/Loading';
import DataField from 'components/data/DataField'
import DataOutlined from 'components/data/DataOutlined';
import TwoSide from 'components/data/TwoSide'
import React, { useEffect, useState } from 'react'
import { useGetSelfQuery } from 'state/api';

function AccountSettings() {
  const theme = useTheme();
  const {data, isLoading, error} = useGetSelfQuery("");


  const [me, setMe] = useState(data);

  const [shouldRecieveEmails, setShouldRecieveEmails] = useState(false);

  useEffect(() => {
    setMe(data);
    setShouldRecieveEmails(data.user.shouldReceiveEmails)
  }, [data])

  if(isLoading) return (<Loading/>)

  if(error != null) return (<Error Message="There was a problem loading your account!"/>);

  const getRoleName = (role: number) => {
    switch(role) {
      case 0:
        return "Creator"
      case 1:
        return "Admin"
      default:
        return "Student"
    }
  }

  const updateShouldRecieveEmails = (value: string) => {
    var sre = value == "true"
    setShouldRecieveEmails(sre);
    fetch(process.env.REACT_APP_BASE_URL + '/org/users/me', {
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json'
      },
      credentials: "include",
      body: JSON.stringify({shouldReceiveEmails: sre})
      }).then(async (response) => {

          }).catch((reason) => {

          })
  }

  return (
    <Box sx={{mt: '1rem'}}>
      <Typography variant="h3">Account Settings</Typography>
      <Typography variant='subtitle2'>View and customize your account</Typography>
      <Divider sx={{mt: '1rem'}}/>
      
      <Paper elevation={16} sx={{
            mt: '1rem',
            padding: '1rem',
            mb: '1rem'
        }}
        >

          <TwoSide>
            <Box>
              <Typography variant="h6">Email</Typography>
              <Typography variant="body1">The main email assosicated with this account</Typography>
            </Box>
            <Box>
              <DataOutlined data={me.user.email}/>
            </Box>
          </TwoSide>

          <Divider/>

          <TwoSide>
            <Box>
              <Typography variant="h6">Account Type</Typography>
              <Typography variant="body1">The type of the account.</Typography>
            </Box>
            <Box>
              <DataOutlined  data={me.user.occupation}/>
            </Box>
          </TwoSide>
          <Divider/>

              <TwoSide>
                <Box>
                  <Typography variant="h6">Recieve Emails?</Typography>
                  <Typography variant="body1">Set to yes if you wish to recieve important emails.</Typography>
                </Box>
                <Box>
                  <TextField
                    sx={{
                      width: '10vw'
                    }}
                    id="recieve-emails"
                    color="secondary"
                    select
                    onChange={(e) => {
                      updateShouldRecieveEmails(e.target.value)}}
                    label="Recieve Emails?"
                    value={shouldRecieveEmails}
                  >
                    <MenuItem key={"true"} value="true">Yes</MenuItem>
                    <MenuItem key={"false"} value="false">No</MenuItem>
                  </TextField>
                </Box>
              </TwoSide>

      </Paper>

    </Box>
  )
}

export default AccountSettings