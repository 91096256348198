import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'

interface Permission {
  label: string;
  permission: string;
}
interface GroupPermissionsEditor {
  permissions: Permission[];
  initialValue: string[];
  startsWith: string;
  label: string;
  onChange: (permissions: string[]) => void;
}
const GroupPermissionsEditor: React.FC<GroupPermissionsEditor> = ({ permissions, onChange, startsWith, initialValue, label }) => {

  const [checkedPermissions, setCheckedPermissions] = useState(initialValue);
  const [stringPermissions, setStringPermissions] = useState([]);

  useEffect(() => {
    setCheckedPermissions(initialValue.filter((v) => v.startsWith(startsWith)))
  }, [initialValue])

  useEffect(() => {
    setStringPermissions([...permissions.map((p) => p.permission)])
  }, [permissions])

  /**
   * Gets the immediate children of a permission.
   * @param permission Parent permission
   * @returns A array of immediate children
   */
  const GetImmediateChildren = (permission) => {
    //Any permission that has a +1 length of this permission is a immediate child, any more or less is not

    var permissionLength = permission.split(":").length

    //Get all permissions that can be children, then only get the ones that have a + 1 length
    var matchingPermissions = stringPermissions
      .filter((v) => v.startsWith(permission) && v != permission)
      .filter((v) => v.split(":").length == permissionLength + 1);

    return matchingPermissions;
  }

  /**
   * Gets all of the permissions instead of just the immediat children
   * @param permission Parent Permission
   * @returns A array of all children
   */
  const GetAllChildren = (permission) => {
    var allChildren = stringPermissions.filter((v) => v.startsWith(permission) && v != permission);
    return allChildren;
  }

  /**
   * Gets the immediate children for a permission from the provided array
   * @param permission Permission to get children from
   * @param permissionArray Permission array
   * @returns Array of immediate children
   */
  const GetImmediateChildrenFromArray = (permission: string, permissionArray: string[]) => {
    //Any permission that has a +1 length of this permission is a immediate child, any more or less is not

    var permissionLength = permission.split(":").length

    //Get all permissions that can be children, then only get the ones that have a + 1 length
    var matchingPermissions = permissionArray
      .filter((v) => v.startsWith(permission) && v != permission)
      .filter((v) => v.split(":").length == permissionLength + 1);

    return matchingPermissions;
  }

  /**
   * Checks to see if a checkbox should be indeterminate
   * A checkbox is considered indeterminate if some of its immediate children are checked, but not all of them are checked.
   * @param permission Permission of the checkbox
   * @returns True if the checkbox should be indeterminate
   */
  const IsIndeterminate = (permission: string) => {
    //A checkbox is considered indeterminate if some of its immediate children are checked, but not all of them are checked.

    //Get all of the permissions immediate children
    var children = GetAllChildren(permission);

    var checkedChildren = checkedPermissions.filter((v) => v.startsWith(permission) && v != permission);

    return children.length > 0 && checkedChildren.length > 0 && children.length != checkedChildren.length
  }

  /**
   * Checks to see if a checkbox should be checked. 
   * A checkbox is checked if either it is within the checkedPermissions, or all of its immediate children are checked
   * @param permission Permission of the checkbox
   * @returns True if the checkbox should be checked
   */
  const IsChecked = (permission: string) => {
    //A checkbox is checked if either it is within the checkedPermissions, or all of its immediate children are checked

    if (checkedPermissions.includes(permission)) return true;

    var children = GetImmediateChildren(permission);

    var checkedChildren = GetImmediateChildrenFromArray(permission, checkedPermissions)

    if (children.length != 0 && children.length == checkedChildren.length) {
      checkedPermissions.push(permission);
      setCheckedPermissions([...checkedPermissions])
      return true;
    }

    //Check if parent is checked
    var parentPermission = permission.split(":").slice(0, -1).join(":");

    if (checkedPermissions.includes(parentPermission)) {
      checkedPermissions.push(permission)
      setCheckedPermissions([...checkedPermissions])
      return true;
    }

    return false;
  }

  /**
   * Gets the spacing needed for a checkbox
   * @param permission The permission of the checkbox
   * @returns A number that represents how much it should be moved to the right
   */
  const Spacing = (permission: string) => {
    const spacing = 4;

    return spacing * (permission.split(":").length - 1);
  }

  /**
   * Updates the checked permission by removing or adding the permission to the list of checked permissions 
   * @param permission Permission of the checkbox
   * @param checked True if the checkbox was checked
   * @returns void
   */
  const OnCheckboxChange = (permission: string, checked: boolean) => {
    //If checked, we add to the checkedPermissions if it isnt in there already
    //If it is not checked, we filter out the checkedPermissions

    if (checked && !checkedPermissions.includes(permission)) {
      checkedPermissions.push(permission);
      setCheckedPermissions([...checkedPermissions])
    }

    //We need to uncheck the parent permission if it is checked
    if (!checked) {

      //We need to remove all parents of this permission
      var parent = permission.split(":").slice(0, -1).join(":");


      //We need to remove all parents
      //Ex, if write:ticket:actions is removed, then we need to remove write:ticket if it exists, and write if it exists
      while (parent != "") {
        var parentIndex = checkedPermissions.indexOf(parent)

        if (parentIndex != -1) {
          //Remove the parent permission
          checkedPermissions.splice(parentIndex, 1);

        }

        //Move back one permisison
        parent = parent.split(":").slice(0, -1).join(":");
      }

      //Remove the permission
      var newArray = checkedPermissions.filter((v) => !v.startsWith(permission))

      //Remove the permission along with all the children
      setCheckedPermissions([...newArray])

      onChange(newArray);
      return;
    }


    onChange(checkedPermissions)

  }


  return (
    <Box>
      <FormControl component="fieldset" variant="standard">
        <FormLabel color="secondary" component="legend">{label}</FormLabel>
        <FormGroup>
          {permissions.map((perm) => (
            <Box key={perm.permission} ml={Spacing(perm.permission)}>
              <FormControlLabel
                label={perm.label}
                control={
                  <Checkbox
                    color="secondary"
                    checked={IsChecked(perm.permission)}
                    indeterminate={IsIndeterminate(perm.permission)}
                    onChange={(event, checked) => OnCheckboxChange(perm.permission, checked)}
                  />
                }
              />
            </Box>
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  )
}

export default GroupPermissionsEditor