import {Email, Person} from '@mui/icons-material';
import {Box, Grow, Pagination, Paper, Typography, useMediaQuery, useTheme} from '@mui/material'
import {GridColDef, GridRowParams, GridValueGetterParams} from '@mui/x-data-grid';
import TicketsCalander from 'components/Charts/TicketsCalander';
import CustomTicketTable from 'components/CustomTicketTable';
import Header from 'components/Header'
import Loading from 'components/Loading';
import StatBox from 'components/StatBox';
import LineChart from 'components/data/LineChart';
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom';
import {useGetOrgOverviewQuery} from 'state/api';
import HoverPaper from "../../components/HoverPaper";

function Dashbaord() {
    const theme = useTheme();
    const [page, setPage] = useState(1);
    const {data: overview, isLoading, refetch} = useGetOrgOverviewQuery("");

    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const navigate = useNavigate();
    const [data, setOverview] = useState(overview);


    useEffect(() => {
        const interval = setInterval(async () => {
            // Call your method here
            await refetch();
        }, 10000); // 10 seconds in milliseconds

        // Return a cleanup function to clear the interval
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        setOverview(overview || {})
    }, [overview])


    const getTicketStatusToString = (params: GridValueGetterParams) => {
        var data = params.row.status;
        switch (data) {
            case 0:
                return "Waiting for Repair"
                break;
            case 1:
                return "Repaired, Waiting for Return"
                break;
            case 2:
                return "In Office"
                break;
            case 3:
                return "Completed"
                break;
            case 4:
                return "Other"
                break;

        }
    }


    const columns: GridColDef[] = [
        {field: 'studentName', headerName: 'Student', flex: 1},
        {
            field: 'device',
            headerName: 'Damaged Device Serial Number',
            type: 'string',
            flex: 1,
        },
        {
            field: 'loanerId',
            headerName: 'Loaner',
            flex: 1,
        },
        {
            field: 'issue',
            headerName: 'Issue',
            description: "The issue listed on the ticket",
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.issue != "other" ? params.row.moreInfo : params.row.issue + " " + params.row.moreInfo}`,
        },
        {
            field: 'status',
            headerName: 'Ticket Status',
            flex: .7,
            valueGetter: getTicketStatusToString

        }

    ];


    const smallColumns: GridColDef[] = [
        {field: 'studentName', headerName: 'Student', flex: 1},
        {
            field: 'device',
            headerName: 'Damaged Device Serial Number',
            type: 'string',
            flex: 1,
        },
        {
            field: 'issue',
            headerName: 'Issue',
            description: "The issue listed on the ticket",
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.issue != "other" ? params.row.moreInfo : params.row.issue + " " + params.row.moreInfo}`,
        },
        {
            field: 'status',
            headerName: 'Ticket Status',
            flex: .7,
            valueGetter: getTicketStatusToString

        }

    ];

    const navigateToTicket = (row: GridRowParams) => {
        navigate(`../ticket/${row.id.toString()}`);
    }

    const handlePageChange = () => {

    }
    if (isLoading) {
        return <Loading/>
    }

    return (
        <Box m="1.5rem 2.5rem">
            <Header title={"Dashboard"} subtitle={"Welcome to your Dashboard!"}/>

            <Grow in={true}>

                <Box
                    mt="20px"
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="160px"
                    gap="20px"
                    sx={{
                        "& > div": {gridColumn: isNonMediumScreens ? undefined : "span 12"},
                    }}
                >
                    <StatBox
                        title="Total Students"
                        value={data.studentCount}
                        increase="0%"
                        description={""}
                        icon={
                            <Person
                                sx={{
                                    //@ts-ignore
                                    color: theme.palette.secondary[300], fontSize: "26px"
                                }}
                            />
                        }
                    />
                    <StatBox
                        title="Total Active Tickets"
                        value={data.totalActiveTickets}
                        increase={(Math.abs(Math.ceil((data.totalActiveTickets / data.totalTicketCount) * 100)) || 0) + "%"}
                        description={""}
                        icon={
                            <Email
                                sx={{
                                    //@ts-ignore
                                    color: theme.palette.secondary[300], fontSize: "26px"
                                }}
                            />
                        }
                    />


                    <HoverPaper elevation={4}
                           sx={{
                               gridColumn: "span 8",
                               gridRow: "span 2",
                               p: "1rem"
                           }}
                    >
                        {page == 1 && (
                            <>
                                <Typography>Tickets created in the last week</Typography>
                                <LineChart/>
                            </>
                        )}
                        {page == 2 && (
                            <>
                                <Typography>Calander of tickets</Typography>
                                <TicketsCalander/>
                            </>
                        )}
                        <Pagination size="small" count={2} page={page} onChange={(e, p) => setPage(p)}/>
                    </HoverPaper>

                    <StatBox
                        title="Total Completed Tickets"
                        value={data.totalTicketCount - data.totalActiveTickets}
                        increase={(Math.abs(Math.ceil((data.totalActiveTickets / data.totalTicketCount) * 100) - 100) || 0) + "%"}
                        description={""}
                        icon={
                            <Email
                                sx={{
                                    //@ts-ignore
                                    color: theme.palette.secondary[300], fontSize: "26px"
                                }}
                            />
                        }
                    />

                    <StatBox
                        title="Tickets Created Today"
                        value={data.ticketsCreatedToday}
                        increase={(Math.abs(Math.ceil((data.ticketsCreatedToday / data.totalTicketCount) * 100)) || 0) + "%"}
                        description={""}
                        icon={
                            <Email
                                sx={{
                                    //@ts-ignore
                                    color: theme.palette.secondary[300], fontSize: "26px"
                                }}
                            />
                        }
                    />


                    {/* ROW 2 */}

                    <HoverPaper elevation={4}
                           sx={{
                               mt: '1rem',
                               gridColumn: "span 12",
                               gridRow: "span 4",
                               mb: '5vh',
                               padding: '1rem'
                           }}

                    >
                        <Box>
                            <Typography  variant="h4">Assigned Tickets: </Typography>
                            <CustomTicketTable defaultBackground autoHeight
                                               onRowClick={navigateToTicket} data={data.assignedTickets || []}
                                               onlyActive={false}/>
                        </Box>

                    </HoverPaper>

                </Box>
            </Grow>


        </Box>
    )
}

export default Dashbaord