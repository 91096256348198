import React, {useEffect, useState} from 'react';
import {Collapse, Drawer, List, ListItem, ListItemText, Toolbar} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {Link, useLocation} from 'react-router-dom';
import {useGetDocPagesQuery} from "../state/api";

const renderSubItems = (subItems, depth, selectedPath) => {
    if (!subItems || subItems.length === 0) {
        return null;
    }

    return (
        <List component="div" disablePadding>
            {subItems.map((subItem) => (
                <DocsSidebarItem
                    key={subItem.title}
                    item={subItem}
                    depth={depth + 1}
                    selectedPath={selectedPath}
                />
            ))}
        </List>
    );
};

interface DocsSidebarItemProps {
    item: {
        title: string;
        href: string;
        subItems?: DocsSidebarItemProps['item'][];
    };
    depth?: number;
    selectedPath: string;
    isOpen?: boolean; // Add isOpen property
}

const DocsSidebarItem: React.FC<DocsSidebarItemProps> = ({item, depth = 1, selectedPath, isOpen}) => {
    const [open, setOpen] = useState(isOpen);

    const hasSubItems = item.subItems && item.subItems.length > 0;

    const handleClick = () => {
        if (hasSubItems) {
            setOpen(!open);
        }
    };

    const isActive =
        item.href === selectedPath ||
        (hasSubItems && item.subItems.some(subItem => subItem.href === selectedPath));

    return (
        <>
            <ListItem
                button
                component={Link}
                to={item.href}
                onClick={handleClick}
                sx={{
                    pl: `${depth * 16}px`,
                    ...(isActive && {backgroundColor: 'rgba(0, 0, 0, 0.1)'}),
                }}
            >
                <ListItemText primary={item.title}/>
                {hasSubItems && (open ? <ExpandLess/> : <ExpandMore/>)}
            </ListItem>
            {hasSubItems && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {renderSubItems(item.subItems, depth, selectedPath)}
                </Collapse>
            )}
        </>
    );
};
const DocsSidebar = () => {

    const tet = [
        {
            title: 'Home',
            href: '/docs/home',
            subItems: [],
        },
        {
            title: 'Getting Started',
            href: '/docs/getting-started',
            subItems: [
                {
                    title: 'Setting up Provider',
                    href: '/docs/getting-started/provider',
                },
                {
                    title: 'Setting up Groups',
                    href: '/docs/getting-started/groups',
                },
                {
                    title: "Creating your First Ticket",
                    href: '/docs/getting-started/creating-ticket',
                }
            ],
        },
    ];

    const [items, setItems] = useState([]);
    const location = useLocation();
    const selectedPath = location.pathname;
    const {data, isLoading, refetch} = useGetDocPagesQuery("");

    const findParent = (path, currentOptions) => {
        for(const option of currentOptions) {
            if(option.href === path) {
                return option;
            } else if (option.subItems) {
                const result = findParent(path, option.subItems);
                if(result) {
                    return result;
                }
            }
        }
    }

    useEffect(() => {
        if (data) {
            // Format data
            const result = [];

            // Loop through each page
            for (const page of data) {
                // Split the path into an array
                const path = page.url.split('/');

                // Get the last element of the path
                const title = page.title

                // Get the parent path
                const parentPath = "/docs" + path.slice(0, path.length - 1).join('/');

                // See if the parent path exists
                const parent = findParent(parentPath, result);

                // If the parent path exists
                if (parent) {
                    // Add the page to the parent
                    parent.subItems.push({
                        title: title,
                        href: "/docs" + page.url,
                        subItems: []
                    })
                } else {
                    // Otherwise, add the page to the root
                    result.push({
                        title: title,
                        href: "/docs" + page.url,
                        subItems: []
                    })
                }
            }

            setItems(result)
        }

    }, [data])

    const calculateIsOpen = (item) => {
        if (item.href === selectedPath || (item.subItems && item.subItems.some(subItem => subItem.href === selectedPath))) {
            return true;
        }

        if (item.subItems) {
            for (const subItem of item.subItems) {
                if (calculateIsOpen(subItem)) {
                    return true;
                }
            }
        }

        return false;
    };

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: '200px',
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: '200px', boxSizing: 'border-box'},
            }}
        >
            <Toolbar/>
            <List>
                {items.map((item) => (
                    <DocsSidebarItem
                        key={item.title}
                        item={item}
                        selectedPath={selectedPath}
                        isOpen={calculateIsOpen(item)}
                    />
                ))}
            </List>
        </Drawer>
    );
};

export default DocsSidebar;