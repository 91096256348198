import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, Stack, Typography } from '@mui/material';
import UserAutocomplete from 'components/Autocomplete/UserAutocomplete';
import SnackbarHandler from 'components/SnackbarHandler';
import React, { useState } from 'react'

interface TransferOwnershp {
    open: boolean;
    setOpen: (boolean) => void;

}
const TransferOwnership: React.FC<TransferOwnershp> = ({open, setOpen}) => {
    const [user, setUser] = useState(null);
    const [count, setCount] = useState(0)


    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [snackbarError, setSnackbarError] = useState(false)

    const transfer = async () => {

        var response = await fetch(process.env.REACT_APP_BASE_URL + '/org/admin/transfer?account=' + user.primaryEmail, {
            method: "POST",
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            }
        })

        if(response.status != 200) {
            setSnackbarError(true)
            setSnackbarMessage("There was a issue transferring ownership")
            setSnackbarOpen(true)
        } else {
            setSnackbarError(false)
            setSnackbarMessage("Success! Ownership was transfered, you will be logged out now.")
            setSnackbarOpen(true)

            //This does not create a history entry
            window.location.replace(window.location.pathname + window.location.search + window.location.hash)
        }

        
    }
  return (
    <Dialog 
        open={open}
        onClose={() => setOpen(false)}    
        maxWidth="md"
        fullWidth
    >

        <SnackbarHandler open={snackbarOpen} onClose={() => setSnackbarOpen(false)}message={snackbarMessage} severity={snackbarError ? "error" : "success"}/>
        <DialogTitle>
            <Typography variant="h2">Transfer Ownership</Typography>
            <Divider/>
        </DialogTitle>
        <DialogContent>
            <Typography>
                You are about to transfer ownership. Only transfer ownership to a account that you trust. Once you transfer Ownership
                you wont be able to transfer it back without having access to the account that you transfered ownership to.
            </Typography>
            <Typography sx={{mt: '1rem'}}>
                The account that is transfered ownership will automatically be upgraded to a super admin, and will be removed from any 
                group that they are apart of.
            </Typography>

            <Box mt="1rem">
                <UserAutocomplete 
                    label="User"
                    value='' 
                    onValueChange={(v) => setUser(v)}                   
                />
            </Box>

            {user != null && (
                <Box mt=".5rem">
                    {user.isAdmin == false && (
                        <Box>
                            <Typography color="red">Warning! This account is not a adminstrator account. It is not recommended to transfer to this account.</Typography>
                        </Box>
                    )}
                        <Box sx={{mt: '1rem'}}>
                            
                            <Stack>

                                <FormControlLabel control={<Checkbox onChange={(e,v) => setCount(!v ? count - 1 : count + 1)} color="secondary" />} label={"I understand that I am transfering ownership of this organization to " + user.primaryEmail} />
                                <FormControlLabel control={<Checkbox onChange={(e,v) => setCount(!v ? count - 1 : count + 1)} color="secondary" />} label={"I understand that my account will be removed from the organization until it gets added back by a admin"} />
                                <FormControlLabel control={<Checkbox onChange={(e,v) => setCount(!v ? count - 1 : count + 1)} color="secondary" />} label={"I understand that this action cannot be undone"} />
                                <Button sx={{mt: '1rem'}} disabled={count != 3} onClick={() => transfer()} color="warning" variant="contained">Transfer Ownership to {user.primaryEmail}</Button>
                            </Stack>
                        </Box>
                </Box>

            )}


        </DialogContent>
    </Dialog>
  )
}

export default TransferOwnership