import { AddOutlined, DeleteOutline, RemoveRedEyeOutlined, ViewCompactOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog, DialogContent,
  Divider,
  IconButton,
  LinearProgress, Paper,
  Stack,
  Typography
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import DontDisplayIfTestSuite from 'components/DontDisplayIfTestSuite'
import Header from 'components/Header'
import RequiresScope from 'components/RequiresScope'
import React, { useEffect, useState } from 'react'
import { LazyNavigation, useGetStudentGroupsQuery } from 'state/api'
import GroupMembersTable from './GroupMembersTable'
import Loading from 'components/Loading'
import HoverPaper from "../../components/HoverPaper";

interface StudentGroupCard {
  group: any;
  onChange: () => void;
}

const StudentGroupCard: React.FC<StudentGroupCard> = ({ group, onChange }) => {

  const [deleteOpen, setDeleteOpen] = useState(false);


  const view = () => {
    LazyNavigation("/org/students/groups/" + group.id)
  }

  return (
    <HoverPaper elevation={4} sx={{
      height: '100%',
      mt: '1rem',
      padding: '1rem',
      mb: '2rem',
    }}>

        <DeleteGroupDialog groupId={group.id} open={deleteOpen} setOpen={setDeleteOpen} onChange={onChange} />
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box>
            <Typography variant="h3">{group.name}</Typography>
            <Typography variant="subtitle1">{group.description}</Typography>
            <Typography variant="subtitle1">Owner: {group.owner}</Typography>
          </Box>
          <Box>
            <IconButton onClick={view}>
              <RemoveRedEyeOutlined />
            </IconButton>
            <RequiresScope scope="write:student:groups">
              <IconButton onClick={() => setDeleteOpen(true)} color="error">
                <DeleteOutline />
              </IconButton>
            </RequiresScope>
          </Box>
        </Stack>
        <Divider />
        <Box>
          {/**
           * 
        <GroupMembersTable group={group} />
           */}

        </Box>
    </HoverPaper>
  )
}

interface DeleteDialogProps {
    groupId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    onChange: () => void;
}
const DeleteGroupDialog:React.FC<DeleteDialogProps> = ({groupId, open, setOpen, onChange}) => {
  const deleteGroup = async () => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/students/groups/' + groupId, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: "include",
    })

    onChange()

  }

  return (
      <Dialog
          open={open}
          onClose={() => setOpen(false)}
          maxWidth={"xs"}
          fullWidth
      >
        <DialogContent>
          <Typography variant="h3">Delete Group</Typography>
          <Typography variant="subtitle1">Are you sure you want to delete this group? This action cannot be undone.</Typography>
          <Stack direction="row" justifyContent={"flex-end"}>
            <Button variant="contained" sx={{mr: '1rem'}} color={"secondary"} onClick={() => setOpen(false)}>Cancel</Button>
            <Button variant="contained" onClick={() => deleteGroup()} color="error">Delete</Button>
          </Stack>
        </DialogContent>
      </Dialog>
  )
}

function StudentGroups() {
  const { data, isLoading, error, refetch } = useGetStudentGroupsQuery({})
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      setGroups(data ?? [])
    }
  }, [data])

  const addGroup = async () => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/students/groups', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: "include",
      body: JSON.stringify({
        "name": "New Student Group",
        "description": "A New Student Group",
        "members": [],
      })
    })

    refetch()
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Box>
        <Header title={"Student Groups"} subtitle={"Assign students to groups for easier tracking"} />
      </Box>

      {/** Add */}
      <Box sx={{ m: '1rem .2rem' }}>
        <DontDisplayIfTestSuite>
          <RequiresScope scope={"write:student:groups"}>
            <Button onClick={() => addGroup()} endIcon={<AddOutlined />} variant="outlined" color="secondary">Add Group</Button>
          </RequiresScope>
        </DontDisplayIfTestSuite>

      </Box>
      <Divider sx={{}}/>
      {isLoading && <LinearProgress color="secondary"/>}

      {/** Group Display */}
      <Box sx={{ m: '1rem' }}>
        <Grid2 container spacing={1}>

          {groups.map(g => (
            <Grid2 key={g.id} md={4} xs={12}>

              <StudentGroupCard group={g} onChange={() => refetch()} />
            </Grid2>

          ))}
        </Grid2>
      </Box>
    </Box>
  )
}

export default StudentGroups