import { Forward } from '@mui/icons-material';
import { Box, Checkbox, Divider, FormControlLabel, Stack, Typography } from '@mui/material';
import DataField from 'components/data/DataField';
import React from 'react'

interface FieldChange {
    value: boolean;
    label: string;
    setValue: (boolean) => void;
    baseValue: string;
    changedValue: string;
    disabled?: boolean
}
const FieldChange:React.FC<FieldChange> = ({value, setValue, baseValue, changedValue, label,disabled}) => {
  return (
    <Box>
        <Typography>{label}</Typography>
        <Divider/>
        <Stack sx={{mt: '0'}} direction={"row"} alignItems={"center"}>
            <Checkbox disabled={disabled} color="secondary" defaultChecked />
            <DataField data={baseValue == "" ? "Empty Field" : baseValue} width={"auto"}/>
            <Forward/>
            <DataField data={changedValue ?? "None"} width={"auto"}/>
        </Stack> 
    </Box>
  )
}

export default FieldChange