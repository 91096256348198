import {
  Box,
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  Slider,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import Error from 'components/Error'
import Loading from 'components/Loading'
import DataEditable from 'components/data/DataEditable'
import DataField from 'components/data/DataField'
import DataOutlined from 'components/data/DataOutlined'
import EditableList from 'components/data/EditableList'
import TwoSide from 'components/data/TwoSide'
import React, { useEffect, useState } from 'react'
import { FetchHelper, useGetOrgSettingsQuery, useGetSelfQuery } from 'state/api'
import TransferOwnership from './TransferOwnership'
import DeleteOrg from './DeleteOrg'

function OrganizationSettings() {
  const theme = useTheme();
  const {data, isLoading, error, refetch}  = useGetOrgSettingsQuery("");
  const [orgSettings, setOrgSettings] = useState(data);
  const [transfer, setTransfer] = useState(false);
  const [deleteOrg, setDelete] = useState(false);
  const [warnAt, setWarnAt] = useState(90);
  const {data: me} = useGetSelfQuery("");
  useEffect(() => {
    if(!isLoading) 
      setOrgSettings(data);
  }, [data])

  if(isLoading || !data || !orgSettings) return (<Loading/>)
  if(error) return (<Error Message={"There was a error loading org settings!"}/>) 

  const updateName = (name: string) => {
    FetchHelper("/org/settings", "PATCH", (response) => {
      if(response == null) {
        console.log("ERROR")
      }
      refetch()
    }, {name: name})
  }

  const updateDefaultRole = (role: number) => {
    FetchHelper("/org/settings", "PATCH", (response) => {
      if(response == null) {
        console.log("ERROR")
      }
      refetch()
    }, {defaultRole: role})
  }
  function formatFileSize(bytes) {
    const abbreviations = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    let size = bytes;
    let unitIndex = 0;
  
    while (size >= 1000 && unitIndex < abbreviations.length - 1) {
      size /= 1024;
      unitIndex++;
    }
  
    const formattedSize = size.toFixed(3).replace(/\.?0+$/, ''); // Remove trailing zeros
  
    return `${formattedSize}${abbreviations[unitIndex]}`;
  }

  const getMarks = (max: number) => {
    var step = max / 4;
    var steps = [{value: 0, label: '0mb'}];
    
    for(var i = 0; i < 5 ; i++) {
      steps.push({value: max - (step * i),label: max - (step * i) + "mb" })
    }

    steps.push({value: max * (warnAt / 100), label: 'Warning'})
    return steps;

  }

  return (
    <Box sx={{mt: '1rem'}}>
      <Typography variant="h3">Organization Settings</Typography>
      <Typography variant='subtitle2'>View and customize your Organization</Typography>
      <Divider sx={{mt: '1rem'}}/>

      <Paper elevation={16} sx={{
        mt: '1rem',
        padding: '1rem',
        mb: '1rem'
      }}>
        <Typography variant='h3'>Organization Overview</Typography>

        <TwoSide >
          <Box>
            <Typography variant="h6">Organization Name</Typography>
            <Typography variant="body1">Name of the Organization.</Typography>
          </Box>
          <Box>
            <DataEditable 
              data={orgSettings.organization.name}
              callback={(val) => {updateName(val)}}
              />
          </Box>
        </TwoSide>
        {
          (me && me.user.role == 0) && 
          (<>
            <Divider/>
            <TwoSide>
              <Box>
                <Typography variant="h6">Delete Organization</Typography>
                <Typography variant="body1">Delete this organization.</Typography>
              </Box>
              <Box>
                <Button color="error" variant="outlined" onClick={() => setDelete(true)}>Delete {orgSettings.organization.name}</Button>
              </Box>
            </TwoSide>
            <Divider/>
            <TwoSide>
              <Box>
                <Typography variant="h6">Transfer Ownership</Typography>
                <Typography variant="body1">Transfer Ownership to a different account.</Typography>
              </Box>
              <Box>
                <Button color="warning" variant="outlined" onClick={() => setTransfer(true)}>Transfer Ownership</Button>
              </Box>
            </TwoSide>
            <TransferOwnership open={transfer} setOpen={setTransfer} />
            <DeleteOrg open={deleteOrg} setOpen={setDelete} />
          </>)
        }

 
      </Paper>
    </Box>
  )
}

export default OrganizationSettings