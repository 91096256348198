import { Box, Card, CardContent, Divider, Typography, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import RequiresScope from 'components/RequiresScope';
import React, { useEffect, useState } from 'react'
import { LazyNavigation } from 'state/api';
import HoverPaper from "../../../components/HoverPaper";


interface StudentGroupsCard {
    id: string;
    xs?: number;
    md?: number;
}
/**
 * Displays the groups associated with this user, will display a empty card if the user does not have permissions for groups
 * @param param0 
 * @returns 
 */
const StudentGroupsCard: React.FC<StudentGroupsCard> = ({ id, xs = 0, md = 0 }) => {
    const theme = useTheme();
    const [groups, setGroups] = useState([]);
    const queryUsers = async () => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + `/org/students/groups/query?type=member%2Bowner&query=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        })

        if (response.status == 200) {
            var json = await response.json();

            setGroups([...json])
        }
    }

    useEffect(() => {
        queryUsers()
    }, [id])




    const gridColDef: GridColDef[] = [
        { field: 'name', headerName: 'Group Name', flex: 0.7 },
        { field: 'owner', headerName: 'Group Owner', flex: 1 },
    ];

    return (
        <RequiresScope scope={"read:student:groups"} >
            <HoverPaper elevation={4}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Assigned Groups</Typography>
                <Divider sx={{ mb: '1rem' }} />
                <Typography variant='subtitle1'>Click to View Group</Typography>
                <Box
                    width="100%"
                    mt="40px"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            cursor: 'grab',
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${theme.palette.secondary[200]} !important`,
                        },
                    }}
                >
                    <DataGrid
                        slots={{ toolbar: GridToolbar }}
                        getRowId={(row) => row.id}
                        autoHeight
                        rows={groups || []}
                        columns={gridColDef}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                        onRowClick={(p) => LazyNavigation("/org/students/groups/" + p.row.id)}
                    />
                </Box>
            </HoverPaper>

        </RequiresScope>
    )
}

export default StudentGroupsCard