import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HasMinimumScopeWithArray } from 'state/api';



interface RequiresScope {
    scope: string;
    children: any;

    /** 
     * If true, will return true if the user has a lower level scope than the one provided. Ex: if the scope is read:device:checkin and the user has read:device:checkin:notes it will return true
     */
    allowLowerLevel?: boolean;
}
/**
 * Will only display the children of this component if the user has the required scope
 * @param scope Minimum Scope 
 * @returns 
 */
const RequiresScope:React.FC<RequiresScope> = ({ scope, allowLowerLevel = false, children }) => {
    //@ts-ignore
    const global = useSelector(s => s.global)
    const [hasScope, setHasScope] = useState(false);
    useEffect(() => {

        if(global.scopes != null) {
            //Check the scope against the user scope
            setHasScope(HasMinimumScopeWithArray(global.scopes, scope, allowLowerLevel))
        }
    }, [global])

    if(hasScope) {
        return children;
    }

    return <></>;
};

export default RequiresScope;
