import { useTheme } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setForcedMode } from 'state';

/**
 * When put inside of a component, it will force the webpage to use light mode until this component is unmounted 
 * @returns JSX
 */
function ForceLightMode() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const darkMode = localStorage.getItem("mode") == 'dark'
    useEffect(() => {
        if (darkMode) {
            dispatch(setForcedMode("light"))
        }
        return () => {
            if (darkMode) {
                dispatch(setForcedMode("dark"))
            }
        }
    }, [])


    
    return (
        <>
        </>
    )
}

export default ForceLightMode