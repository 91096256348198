import {DesktopAccessDisabled} from '@mui/icons-material';
import {Box, Button, Card, CardActions, CardContent, Divider, Skeleton, Typography} from '@mui/material';
import DataField from 'components/data/DataField';
import React from 'react'
import {LazyNavigation, useGetDeviceQuery} from 'state/api'
import HoverPaper from "../../../components/HoverPaper";

interface StudentDeviceInfoCard {
    id: string
}

const StudentDeviceInfoCard: React.FC<StudentDeviceInfoCard> = ({id}) => {
    const {data, isLoading, error} = useGetDeviceQuery(id);

    if (isLoading) {
        return (
            <HoverPaper elevation={4}>
                <Skeleton height="20vh"/>
                <Skeleton height="5vh"/>
                <Skeleton height="5vh"/>
            </HoverPaper>
        )
    }

    if (error || data == null || data.device == null) {
        return (
            <HoverPaper elevation={4}>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Device Information</Typography>
                <Divider sx={{mb: '1rem'}}/>

                <Box display="flex" justifyContent="center" alignContent="center">
                    <DesktopAccessDisabled fontSize='large'/>
                </Box>
            </HoverPaper>
        )

    }

    return (
        <HoverPaper elevation={4} sx={{height: '100%'}}>
            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Device Information</Typography>
            <Divider sx={{mb: '1rem'}}/>

            {(
                <>

                    <DataField title="Serial Number" data={data.device.serialNumber} compact width={"100%"}/>
                    <DataField title="Asset Id" data={data.device.annotatedAssetId} compact width={"100%"}/>
                    <DataField title="Annotated User" data={data.device.annotatedUser} compact width={"100%"}/>
                    <DataField title="Model" data={data.device.model} compact width={"100%"}/>
                    <DataField title="OU" data={data.device.orgUnitPath} compact width={"100%"}/>

                    {(

                        <Button onClick={() => LazyNavigation("/org/devices/" + data.device.serialNumber)}
                                color="secondary"
                                variant="outlined">View More</Button>
                    )}
                </>
            )}
        </HoverPaper>
    )
}

export default StudentDeviceInfoCard