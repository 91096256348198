//@ts-nocheck

import React, { useMemo } from 'react'
import { ResponsiveLine, Serie } from "@nivo/line";
import { Box, Typography, useTheme } from '@mui/material';
import { useGetTicketsFromDateRangeQuery, useGetWeekStatsQuery } from 'state/api';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { current } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
//@ts-ignore

interface  LineChart{
  start?:Date,
  end?: Date,
  isBasis?: boolean,
  isDashboard?: boolean
}

const LineChart:React.FC<LineChart> = ({start, end, isBasis, isDashboard = true}) => {
  const theme = useTheme();
  const {data, isLoading} = useGetWeekStatsQuery({start: start, end: end});  
  //@ts-ignore
  const [totalTicektsLine, completedTicketsLine] = useMemo(() => {
    if(!data) return [];

    //const active = countObjectsByDate(data.map((ticket) => ticket.isActive))

    var createdData = data.tickets.map((d) => {
      return {x: d.key, y: d.value}
    });

    var completedData = data.completed.map((d) => {
      return {x: d.key, y: d.value}
    });



    end ??= new Date()
    start ??= new Date().setDate(end.getDate() - 7)
    
    const totalTicektsLine = {
      id: "Total Tickets",
      label: "Tickets Created",
      color: theme.palette.secondary.main,
      data: [...fillMissingDates(createdData, start, end)],
    };
    const completedTicketsLine = {
      id: "Completed",
      label: "Completed Tickets",
      color: theme.palette.secondary[200],
      data: [...fillMissingDates(completedData, start, end)]
    }
    

    
    return [totalTicektsLine, completedTicketsLine]


  }, [data])
    

  function fillMissingDates(data, startDate: Date, endDate:Date) {
    var totalArray = []
    const currentDate = new Date(startDate);

    while(currentDate <= endDate) {

      var points = data.filter((p) => new Date(p.x).getUTCDate() == currentDate.getDate() && new Date(p.x).getUTCMonth() == currentDate.getMonth() && new Date(p.x).getUTCFullYear() == currentDate.getFullYear())
      var yVal = 0;
      if(points.length > 0) {
        yVal = points[0].y;
      }
      totalArray.push({x: currentDate.toDateString(), y: yVal})
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return totalArray;
  }
  
  function generateWeeklyArray(objects: any[]) {
    const end = new Date()
    const start = new Date().setDate(end.getDate() - 7)

    let currentDate = new Date(start); // Start from the beginning date

    let points: {x: any, y: any}[] = [];
    while (currentDate <= end) {
      

      var ticektsForToday = objects.filter((t) => new Date(t.createdAt).getDate() == currentDate.getDate() && new Date(t.createdAt).getMonth() == currentDate.getMonth() );
      
      points.push({x: currentDate.toUTCString(), y: ticektsForToday.length})
    
      // Increment the date by one day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return points;
  }

  function countObjectsByDate(objects: any[]) {
    const counts: Record<string, number> = {};
    for (const obj of objects) {
      const d = new Date(obj.createdAt);
      const dateStr = d.toISOString().substr(0, 10);
      counts[dateStr] = (counts[dateStr] || 0) + 1;
    }
    return Object.entries(counts).map(([dateStr, count]) => ({ x: new Date(dateStr).toUTCString(), y: count }));
  }


  if(isLoading || !data) {
    return (<></>);
  }



  return (
      <ResponsiveLine
        data={[totalTicektsLine, completedTicketsLine]}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: theme.palette.secondary[200],
              },
            },
            legend: {
              text: {
                fill: theme.palette.secondary[200],
              },
            },
            ticks: {
              line: {
                stroke: theme.palette.secondary[200],
                strokeWidth: 1,
              },
              text: {
                fill: theme.palette.secondary[200],
              },
            },
          },
          legends: {
            text: {
              fill: theme.palette.secondary[200],
            },
          },
          tooltip: {
            container: {
              color: theme.palette.secondary.contrastText,
            },
          },
        }}
        margin={{ top: 20, right: 50, bottom: isDashboard ? 50 : 100, left: 70 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        yFormat=" >-.2f"
        curve={isBasis ? "monotoneX" :"monotoneX"}
        enableArea={isDashboard}
        axisTop={null}
        axisRight={null}
        axisBottom={ {
          format: (v) => {
            if (isDashboard) return v.slice(0, 3);
            return v;
          },
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: isDashboard ? 0 : 75,
          legend: "Day",
          legendOffset: 30,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickValues: 5,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Number of tickets created",
          legendOffset: -60,
          legendPosition: "middle",
        }}
        enableGridX={!isDashboard}
        enableGridY={!isDashboard}
        enablePoints={isDashboard}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}

        useMesh={true}
        legends={
          isDashboard
            ? [
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 30,
                  translateY: -40,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]
            : undefined
        }
      />
  )
}

export default LineChart