import { Box, Button, Card, CardActions, CardContent, Divider, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { LazyNavigation, useGetStudentQuery } from 'state/api'
import Student from './Student'
import DataField from 'components/data/DataField'
import state from 'state'
import DeprovisionStudent from './DeprovisionStudent'
import HoverPaper from "../../../components/HoverPaper";

/**
 * Queries the student by using their name, id, or email
 * @returns 
 */
const StudentInformationCard = ({ id, view = false }) => {
    const { data, isLoading, error, refetch } = useGetStudentQuery(id)


    if (isLoading) {
        return (
            <HoverPaper elevation={4}>
                <Skeleton height="20vh" />
            </HoverPaper>
        )
    }

    if (data == null) {
        return (
            <HoverPaper elevation={4}>
                <Typography>No Student Assigned</Typography>
            </HoverPaper>
        )
    }
    return (
        <HoverPaper elevation={4} sx={{height: '100%'}}>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Student Information</Typography>
            <Divider />

            <DataField compact title="Name" data={data.name} width={"100%"} />
            <DataField compact title="Email" data={data.email} width={"100%"} />
            <DataField compact title="Device" data={data.serialNumber} width={"100%"} />


            <Divider sx={{ m: '1rem 0 .2rem 0' }} />
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Current Assigned Loaner</Typography>
            <DataField compact title="Loaner" data={data.loaner} width="100%" />
            <DataField compact title="Loaner Asset Id" data={data.loanerAssetId} width="100%" />

            {view && (
                <Button variant="outlined" color="secondary" onClick={() => LazyNavigation("/org/students/" + data.id)}>View Student</Button>
            )}

        </HoverPaper>
    )
}

export default StudentInformationCard