import { FileDownload } from '@mui/icons-material';
import {Box, Typography, Divider, Button, useTheme, Stack, Paper} from '@mui/material'
import DataOutlined from 'components/data/DataOutlined'
import TwoSide from 'components/data/TwoSide'
import React, { useState } from 'react'
import ExportTicketPdf from './ExportTicketPdf';

function DataExport() {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [openTicketPdf, setOpenTicketPdf] = useState(false);
  const downloadFile = (url) => {
  
    // Open a new window with the file URL
    window.open(process.env.REACT_APP_BASE_URL + url, "_blank");
  };

  const exportTicketsAsPDF = async () => {
    setLoading(true)

    const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/tickets', {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json'
      },
      credentials: "include"
    })

    setLoading(false)

    if(response.status == 200) {
      var tickets = await  response.json()

    }
  }

  return (
    <Box sx={{mt: '1rem'}}>
      <Typography variant="h3">Export Data</Typography>
      <Typography variant='subtitle2'>Export and Backup data</Typography>
      <Divider sx={{mt: '1rem'}}/>

      <Paper elevation={16}
        sx={{
          mt: '1rem',
          padding: '1rem',
          mb: '1rem'
        }}>
        <TwoSide>
          <Box>
              <Typography variant="h6">Download Backup</Typography>
              <Typography variant="body1">Download a backup of all saved data.</Typography>
            </Box>
            <Box>
              <Button endIcon={<FileDownload/>} onClick={() => downloadFile("/org/downloads/backup")} color="secondary" variant="contained">Download</Button>
            </Box>
        </TwoSide>
        <Divider/>
        <TwoSide>
          <Box>
              <Typography variant="h6">Download Students</Typography>
              <Typography variant="body1">Download a backup of all students.</Typography>
            </Box>
            <Box>
              <Button endIcon={<FileDownload/>}  onClick={() => downloadFile("/org/downloads/students")} color="secondary" variant="contained" >Download</Button>
            </Box>
        </TwoSide>
        <Divider/>
        <TwoSide>
          <Box>
              <Typography variant="h6">Download Tickets</Typography>
              <Typography variant="body1">Download all saved tickets</Typography>
            </Box>
            <Box>
              <Stack spacing={1}>

                <Button endIcon={<FileDownload/>}  onClick={() => downloadFile("/org/downloads/tickets")}  variant="contained" color="secondary">Download as JSON</Button>
                <Button endIcon={<FileDownload/>} onClick={() => {setOpenTicketPdf(true)}} variant="contained" color="secondary">Download as PDF</Button>
                <ExportTicketPdf isOpen={openTicketPdf} setOpen={setOpenTicketPdf}/>

              </Stack>
            </Box>
        </TwoSide>
      </Paper>
    </Box>
  )
}

export default DataExport