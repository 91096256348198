import {useTheme} from '@emotion/react';
import {Divider, Grow, Paper, Typography, useMediaQuery} from '@mui/material';
import {GridColDef} from '@mui/x-data-grid';
import Loading from 'components/Loading';
import DataList from 'components/data/DataList';
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {useGetAuditsFromTicketQuery} from 'state/api';

function TicketAudits() {
    const {id} = useParams();
    const {data, isLoading, error} = useGetAuditsFromTicketQuery(id);
    const [audits, setAudits] = useState(data)
    const isSmallScreen = useMediaQuery('(max-width: 600px)');

    const theme = useTheme();
    useEffect(() => {
        console.log(data)
        if (data == null) {
            setAudits([])
        } else {
            var arr = [...data];

            setAudits(arr.reverse() || [])
        }
    }, [data])
    if (isLoading) {
        return (<Loading/>)
    }

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID', flex: 1},
        {field: 'message', headerName: 'Message', flex: 0.7},
        {field: 'user', headerName: 'User', flex: 0.7},
    ];

    const smallColumn: GridColDef[] = [
        {field: 'message', headerName: 'Message', flex: 0.7},
        {field: 'user', headerName: 'User', flex: 0.7},
    ]


    return (
        <Grow in={true}>
            <Paper elevation={16} sx={{
                m: '1rem auto',
                padding: '1rem',
                width: isSmallScreen ? "100%" : '80%',
                paddingBottom: isSmallScreen ? '100%' : "auto"

            }}
            >
                <Typography variant="h3">Audits</Typography>
                <Typography variant='subtitle2'>Ticket Audits</Typography>
                <Divider sx={{mt: '1rem'}}/>

                <DataList GridColDef={isSmallScreen ? smallColumn : columns} data={audits}/>
            </Paper>
        </Grow>
    )
}

export default TicketAudits