import { Box, Checkbox, Dialog, DialogContent, Divider, List, ListItem, ListItemButton, ListItemIcon, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import DeviceAutocomplete from 'components/Autocomplete/DeviceAutocomplete';
import UserAutocomplete from 'components/Autocomplete/UserAutocomplete';
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import SetupLink from '../Setup/SetupLink';

const ReconfigureProvider = ({open, setOpen}) => {

    //@ts-ignore
    const state = useSelector(s => s.global);

    // const [device, setDevice] = useState({} as any)
    // const [user, setUser] = useState({} as any)
    // const [deviceField, setDeviceField] = useState("")
    // const [userField, setUserField] = useState("")

    return (
        <Box>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth="lg"
                fullWidth
            >
                <DialogContent>
                    <Typography variant="h3">Re-Configure Provider</Typography>
                    <Typography sx={{mt: '10px'}} variant="subtitle1">
                        Warning! This will force a relink of all students with the new provided information, meaning if the user isnt set up for this 
                        configuration, they will not link. If you do not have it set up, but still want to change the configuration, and change 
                        students manually, you can enabel <b>Create Student</b> in experimental settings and create students, otherwise you will have to 
                        manually change the settings in your data provider (Google Admin, Microsoft Azure, ect)

                    </Typography>
                    <Divider sx={{m: '1rem 0 2rem 0'}} />
{/* 
                    <Grid2 container spacing={5}>
                        <Grid2 md={6}>
                            <DeviceAutocomplete 
                                label="Search Devices"
                                onValueChange={(v) => {
                                    delete v.label;
                                    setDevice({...v})
                                }}
                                value=""
                            />

                        </Grid2>
                        <Grid2 md={6}>
                            <UserAutocomplete
                                label="Search User"
                                onValueChange={(v) => {
                                    delete v.label
                                    setUser({ ...v })
                                }} 
                                value={''}                                                            
                            />                        
                        </Grid2>
                        <Grid2 md={6}>
                            {Object.keys(device).length > 0 && (<Typography>Now select one field to match with a user</Typography>)}
                            <List>

                                {Object.keys(device).map((key) => (
                                    <ListItem key={key} disablePadding>
                                        <ListItemButton>
                                            <ListItemIcon>
                                              <Checkbox
                                                checked={deviceField == key}
                                                edge="start"
                                                tabIndex={-1}
                                                disableRipple
                                                color="secondary"
                                                onClick={(e) => setDeviceField(key)}
                                              />
                                            </ListItemIcon>
                                        </ListItemButton>
                                        <TextField 
                                            fullWidth
                                            label={key}
                                            value={device[key]}
                                            variant="standard"
                                            color="secondary"
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid2>
                        <Grid2 md={6}>
                            {Object.keys(user).length > 0 && (<Typography>Now select one field to match with the device</Typography>)}
                            <List>
                                {Object.keys(user).map((key) => (
                                    <ListItem key={key} disablePadding>
                                        <ListItemButton>
                                            <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                tabIndex={-1}
                                                checked={userField == key} 

                                                disableRipple
                                                color="secondary"
                                                onClick={(e) => setUserField(key)}
                                            />
                                            </ListItemIcon>
                                        </ListItemButton>
                                        <TextField 
                                            fullWidth
                                            label={key}
                                            value={user[key]}
                                            variant="standard"
                                            color="secondary"
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid2>
                    </Grid2> */}
                    <Box sx={{
                    textAlign: "center"
                    }}>
                        
                        <SetupLink OnSetupComplete={() => {setOpen(false)}}/> 
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default ReconfigureProvider