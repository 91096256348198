import {Delete, PublishOutlined, Upload} from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Divider,
    Grow,
    IconButton,
    LinearProgress,
    Paper,
    Skeleton,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Loading from 'components/Loading';
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {CompareScopes, useGetImageFromTicketQuery, useGetImagesFromTicketQuery, useGetSelfQuery} from 'state/api';
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import {SnackbarHandlerWrapper} from "../../components/SnackbarHandler";

interface TicketImageCard {
    id: string;
}

const TicketImageCard = ({imageData, id, imageId, self, refetch}) => {

    const [image, setImage] = useState(null);
    const [canDelete, setCanDelete] = useState(false);
    const {data, isLoading, error} = useGetImageFromTicketQuery({id, imageId})


    useEffect(() => {
        console.log(self)
        console.log(imageData.authorId)
        setCanDelete(self.user?.userId == imageData.authorId);
    }, [self])


    useEffect(() => {
        if (!isLoading) {
            setImage("data:image/png;base64," + data)
        } else {
            setImage(null)
        }
    }, [data])

    const deleteImage = async (imageId: string) => {
        fetch(process.env.REACT_APP_BASE_URL + `/org/tickets/${id}/images/${imageId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include"
        })
            .then(async function (response) {
                refetch()
            })
            .catch((reason) => {
                console.log(reason);
            })
    }

    if (isLoading || image == null) {
        return (
            <Card>
                <Skeleton height={200} variant="rectangular"/>

                <CardContent>
                    <Typography gutterBottom variant="h3">
                        {imageData.title}
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                        {imageData.description}
                    </Typography>

                    <Typography variant="subtitle1">
                        Author: {imageData.author}
                    </Typography>
                </CardContent>
            </Card>
        )
    }


    return (
        <Card>
            <CardMedia
                component="img"
                alt="image"
                width="100%"
                height="auto"
                image={image}
            />
            <CardContent>
                <Typography gutterBottom variant="h3">
                    {imageData.title}
                </Typography>

                <Typography variant="body2" color="text.secondary">
                    {imageData.description}
                </Typography>

                <Typography variant="subtitle1">
                    Author: {imageData.author}
                </Typography>
            </CardContent>
            {canDelete && (
                <CardActions>
                    <IconButton onClick={() => {
                        deleteImage(imageId)
                    }}>
                        <Delete/>
                    </IconButton>
                </CardActions>
            )}
        </Card>

    )
}

function TicketImages() {
    const {id} = useParams();
    const {data: images, isLoading, error, refetch} = useGetImagesFromTicketQuery({id: id, download: false})
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const [formInfo, setFormInfo] = useState({title: "", description: ""})
    const [file, setFile] = useState({});
    const me = useGetSelfQuery({});
    const theme = useTheme();
    const [snackbarData, setSnackbarData] = React.useState({open: false, message: "", severity: "success"})

    const [loading, setLoading] = useState(false);
    //@ts-ignore
    const scopes = useSelector(s => s.global.scopes);
    //@ts-ignore
    const self = useSelector(s => s.global);
    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        for (var i = 0; i < scopes.length; i++) {
            if (CompareScopes(scopes[i], "write:ticket:images")) {
                setCanEdit(true);
                return
            }
        }
        setCanEdit(false)
    }, [scopes])

    const [data, setData] = React.useState(images || []);


    React.useEffect(() => {
        // When the messages data changes, update the local state variable
        setData(images || []);
    }, [images]);


    if (isLoading || me.isLoading) return (<Loading/>)

    //@ts-ignore


    const handleFormSubmit = async (values: any, onSubmitProps: any) => {
        try {
            setLoading(true)

            const base = await getBase64(file as File);
            //@ts-ignore
            const resizedBase = await resizeImage(base, 800, 800); // 4:3 aspect ratio

            values.image = base;

            fetch(process.env.REACT_APP_BASE_URL + `/org/tickets/${id}/images`, {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include"
            })
                .then(async function (response) {
                    setSnackbarData({open: true, message: "Image uploaded successfully", severity: "success"})
                    setLoading(false);
                    // Refresh the messages data by refetching from the API
                    await refetch();
                })
                .catch((reason) => {
                    setSnackbarData({open: true,
                        message: "There was a problem uploading the image. Please try again later", severity: "error"})
                    setLoading(false);
                    console.log(reason);
                })
        }catch(e) {
            setLoading(false)
            setSnackbarData({open: true,
                message: "There was a problem uploading the image. Please try again later", severity: "error"})
        }

    }


    const getBase64 = (file: File) => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                //@ts-ignore
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const resizeImage = async (base64: string, targetWidth: number, targetHeight: number) => {
        return new Promise<string>((resolve) => {
            const img = new Image();
            img.src = base64;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d')!;

                canvas.width = targetWidth;
                canvas.height = targetHeight;

                ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

                const resizedBase64 = canvas.toDataURL('image/jpeg', 0.8); // Adjust quality as needed
                resolve(resizedBase64);
            };
        });
    };

    return (
        <Grow in={true}>
            <Paper elevation={16} sx={{
                m: '1rem auto',
                padding: '1rem',
                width: isNonMediumScreens ? '80%' : "100%",
                paddingBottom: !isNonMediumScreens ? '100%' : "auto"
            }}>
                <SnackbarHandlerWrapper snackbarData={snackbarData} setSnackbarData={setSnackbarData}/>

                <Typography variant="h3">Images</Typography>
                <Typography variant='subtitle2'>Ticket Images</Typography>
                <Divider sx={{mt: '1rem'}}/>
                {isLoading && (
                    <LinearProgress color="secondary"/>
                )}
                {loading && (
                    <LinearProgress color="secondary"/>

                )}
                <Grid2 sx={{m: '1rem 0', p: '1rem 0'}} container spacing={1}>
                    {data.map((i) => (

                        <Grid2 key={i.id} md={6} xs={12}>
                            <TicketImageCard imageData={i} id={id} imageId={i.id} self={me.data} refetch={refetch}/>
                        </Grid2>
                    ))}
                    {data.length === 0 && (
                        <Box sx={{
                            //Center the overlay
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            <CustomNoRowsOverlay header={"No Images"}/>

                        </Box>
                    )}
                </Grid2>
                <Divider/>
                <Box>
                    {canEdit && (
                        <Grid2 sx={{mt: '1rem'}} rowSpacing={1} container columnSpacing={1}>
                            <Grid2 xs={12} md={3}>
                                <Box sx={{width: '100%'}}>
                                    <input
                                        hidden
                                        accept="image/*"
                                        id="file-input"
                                        type="file"
                                        onChange={(e) => {
                                            //@ts-ignore
                                            setFile(e.currentTarget.files[0]);
                                        }}/>
                                    <label htmlFor="file-input">
                                        <TextField
                                            fullWidth
                                            label="Image"
                                            color="secondary"
                                            //@ts-ignore
                                            value={file?.name || ""}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton aria-label="upload" component="span">
                                                        <PublishOutlined/>
                                                    </IconButton>
                                                ),
                                            }}/>
                                    </label>

                                </Box>
                            </Grid2>
                            <Grid2 xs={12} md={3}>

                                <TextField
                                    label="Title"
                                    color="secondary"
                                    name="title"
                                    fullWidth
                                    value={formInfo.title}
                                    onChange={(e) => {
                                        //@ts-ignore
                                        formInfo.title = e.target.value;
                                        setFormInfo({...formInfo});
                                    }}/>
                            </Grid2>
                            <Grid2 xs={12} md={4}>

                                <TextField
                                    label="Description"
                                    color="secondary"
                                    name="description"
                                    value={formInfo.description}
                                    fullWidth
                                    onChange={(e) => {
                                        //@ts-ignore
                                        formInfo.description = e.target.value;
                                        setFormInfo({...formInfo});
                                    }}
                                    multiline/>
                            </Grid2>
                            <Grid2 xs={12} md={2}>
                                <Button sx={{height: '100%'}} fullWidth onClick={() => handleFormSubmit(formInfo, null)}
                                        variant="contained" color="secondary" endIcon={<Upload/>}>Upload</Button>
                            </Grid2>
                        </Grid2>
                    )}
                </Box>

            </Paper>
        </Grow>

    )
}

export default TicketImages