import {Box, Typography, Divider, Link, useTheme, Paper} from '@mui/material'
import Loading from 'components/Loading';
import Error from 'components/Error';
import DataOutlined from 'components/data/DataOutlined'
import TwoSide from 'components/data/TwoSide'
import React, { useState } from 'react'
import { useGetOrgBillingQuery } from 'state/api';

function BillingSettings() {
  const theme = useTheme();
  var {data, isLoading, error} = useGetOrgBillingQuery("");

  const [pricing, setPricing] = useState(null);

  

  if(isLoading) return (<Loading/>)
  if(error) return (<Error Message="Error loading billing data!"/>)
  
  return (
    <Box sx={{mt: '1rem'}}>
      <Typography variant="h3">Billing Settings</Typography>
      <Typography variant='subtitle2'>Manage your subscription</Typography>
      <Divider sx={{mt: '1rem'}}/>

      <Paper elevation={16} sx={{
        mt: '1rem',
        padding: '1rem',
        mb: '1rem'
      }}>
          <Typography>
            Pricing is based off of <i>${data.baseCost} +  ${data.perUnitCost} per 100 devices</i>.
          </Typography>
          <Divider/>
          <TwoSide>
            <Box>
              <Typography variant="h5">Estimated Cost</Typography>
              <Typography variant="body1">calculated as ${data.baseCost} + (${data.perUnitCost} * {data.usageCount} units)</Typography>
            </Box>
            <Box>
              <DataOutlined data={"~$" + data.nextCharge} />
            </Box>
          </TwoSide>
          <Divider/>

          <TwoSide>
            <Box>
              <Typography variant="h5">Date of Next Payment</Typography>
              <Typography variant="body1">Date of the next payment </Typography>
            </Box>
            <Box>
              <DataOutlined data={new Date(data.currentPeriodEnd).toDateString()} />
            </Box>
          </TwoSide>
          <Divider/>
          <TwoSide>
            <Box>
              <Typography variant="h5">View Latest Invoice</Typography>
              <Typography variant="body1">Go here to view your latest invoice</Typography>
            </Box>
            <Box>
              <DataOutlined data={<Link target="_blank" rel="noopener" color="inherit" href={data.latestInvoiceUrl}>View Invoice Here</Link>}/>
            </Box>
          </TwoSide>
          <Divider/>
          
          <TwoSide>
            <Box>
              <Typography variant="h5">Edit Payment Plan</Typography>
              <Typography variant="body1">Go here to update your payment information, cancel your plan, or view your past invoices</Typography>
            </Box>
            <Box>
              <DataOutlined data={<Link target="_blank" rel="noopener" color="inherit" href="https://billing.stripe.com/p/login/test_4gwg2l2fE1lX8sU000">Edit Plan Here</Link>}/>
            </Box>
          </TwoSide>

      </Paper>
    </Box>
  )
}

export default BillingSettings