import { Box, Button, Typography, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import DeviceAutocomplete from 'components/Autocomplete/DeviceAutocomplete';
import UserAutocomplete from 'components/Autocomplete/UserAutocomplete';
import React, { useEffect, useState } from 'react'
import DeviceConfiguration from './DeviceConfiguration';
import UserConfiguration from './UserConfiguration';
import CreateUser from './CreateUser';
import CreateStudentDialog from './CreateStudentDialog';
import { useSelector } from 'react-redux';
import { HasMinimumScopeWithArray } from 'state/api';

interface CreateBatch {
    id: string;
}
const CreateBatch = ({id}) => {
    const theme = useTheme();
    const [batchStudents, setBatchStudents] = useState([]);
    const [configuration, setConfiguration] = useState({})
    //@ts-ignore
    const state = useSelector(s => s.global);
    const [creatingUser, setCreatingUser] = useState(false);
    const [creatingStudent, setCreatingStudent] = useState(false);
    const [user, setUser] = useState(null)
    const [device, setDevice] = useState(null)

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Student',flex: 1 },
        {
          field: 'serialNumber',
          headerName: 'Damaged Device Serial Number',
          type: 'string',
          flex: 1,
        },
        {
          field: 'annotatedAssetId',
          headerName: 'Annotated Asset Id',
          type: 'string',
          flex: 1
        },
        {
          field: 'email', 
          headerName: 'Email', 
          flex: 1,
        }
    ];

    const getBatchConfiguration = async () => {
        if(id == "" || id == undefined) {
          return;
        }
    
        var response = await fetch(process.env.REACT_APP_BASE_URL + '/org/students/batch/configuration/'+id, {
          method: "GET",
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        })
    
        if(response.status == 200) {
          var json = await response.json()
          setConfiguration({...json})
        } else {
            const host = document.location.origin;
            document.location.href = host + "/org/students/create"
        }
    }

    useEffect(() => {
      if(id == "" || id == undefined) {
        return ;
      }
    

      const set = async () => {

        var response = await fetch(process.env.REACT_APP_BASE_URL + '/org/students/batch/'+id + "/students", {
          method: "GET",
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        })
  
        if(response.status == 200) {
          var students = await response.json();
          setBatchStudents(students);
        }
      }

      set();
    }, [configuration])

    const refresh = () => {
      setCreatingStudent(false)
      setUser(null)
      setDevice(null)
      getBatchConfiguration()
    }

    useEffect(() => {
      getBatchConfiguration()
    }, [id])
    
    return (
    <Box mt="2rem">
        <CreateUser open={creatingUser} setOpen={setCreatingUser} configuration={configuration}/>
        <Grid2 container columnSpacing={2}>
            <Grid2 md={6} xs={12}>
                <Typography variant="h4">User</Typography>
                <Box width={"70%"}>

                    <UserAutocomplete freeSolo label={"User"} onValueChange={(v) => setUser(v)} value={''}/>
                    {
                      (state.experimentalOptions.includes("CreateUser") && HasMinimumScopeWithArray(state.scopes, "experimental:provider:create")) && (
                       <>
                        <Typography sx={{textAlign: 'center', m: '.5rem 0'}}>Or</Typography>
                        <Button onClick={() => setCreatingUser(true)} variant="outlined" color="secondary" fullWidth>Create User</Button>
                       </> 
                      )
                    }
                </Box>

                {user != null && (<UserConfiguration user={user}/>)}
            </Grid2>

            <Grid2 md={6} xs={12}>
                <Typography  variant="h4">Device</Typography>
                <DeviceAutocomplete freeSolo label={"Device"} onValueChange={(v) => setDevice(v)} value={""}/>
            
                {device != null && (<DeviceConfiguration device={device} />)}
            </Grid2>
        </Grid2>
        <Box sx={{margin: '1rem 2rem'}}>
            <Button disabled={user == null || device == null} onClick={() => setCreatingStudent(true)} fullWidth variant="contained" color="secondary">Create Student</Button>
        </Box>
        {creatingStudent && (
          <CreateStudentDialog onStudentCreate={() => refresh()} user={user} device={device} open={creatingStudent} setOpen={setCreatingStudent} configuration={configuration}/>
        )}
        <Box
          width="100%"
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              //@ts-ignore
            
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              //@ts-ignore
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
            <Typography variant="h4">Created Students</Typography>
            <DataGrid
                slots={{ toolbar: GridToolbar }}
                loading={!batchStudents}
                getRowId={(row) => row.id}
            
                rows={batchStudents || []}
                columns={columns}
            />
        </Box>
    </Box>
  )
}

export default CreateBatch