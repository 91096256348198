import React from 'react';
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogContent,
    Divider,
    MenuItem,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useGetInventoryDeviceQuery, useGetLocationsQuery} from "../../../state/api";

interface LocationsSelectProps {
    header: string;
    onSelected: (location) => void;
    ignore?: string;
}

const LocationsSelect: React.FC<LocationsSelectProps> = ({header, onSelected, ignore}) => {
    const {data} = useGetLocationsQuery("");

    return (
        <TextField
            select
            label={header}
            fullWidth
            variant={"outlined"}
            color={"secondary"}
            onChange={(e) => onSelected(e.target.value)}
        >
            {data?.map((location) => (
                location.id !== ignore && (
                    <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                )
            ))}
        </TextField>
    );


}

interface MoveDeviceDialogProps {
    open: boolean;
    setOpen: (boolean) => void;
    device: any;
}

const MoveDeviceDialog: React.FC<MoveDeviceDialogProps> = ({open, setOpen, device}) => {
    const [location, setLocation] = React.useState(null)
    const [snackbarData, setSnackbarData] = React.useState({open: false, message: "", severity: "success"})

    const handleLocationChange = async (e) => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + `/org/inventory/devices/${device.sku}/move?location=${location}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        })

        if (response.status === 200) {
            setOpen(false);
        } else {
            setSnackbarData({open: true, message: "Error moving device", severity: "error"})
        }
    }

    if (device == null) {
        return (<></>)
    }
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth={"sm"}
        >
            <DialogContent>
                <Typography variant={'h4'}>Move {device.sku}</Typography>
                <Divider/>

                <Stack spacing={2} sx={{mt: '1rem'}}>
                    <LocationsSelect ignore={device.location} header={"Move To?"} onSelected={(l) => setLocation(l)}/>
                    <ButtonGroup sx={{width: "100%"}}>
                        <Button fullWidth variant={"outlined"} color={"error"}
                                onClick={() => setOpen(false)}>Cancel</Button>
                        <Button fullWidth variant={"outlined"} color={"success"}
                                onClick={handleLocationChange}>Move</Button>
                    </ButtonGroup>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export const MoveDeviceDialogWrapper = ({device}) => {
    const [open, setOpen] = React.useState(false);
    const {data, refetch} = useGetInventoryDeviceQuery(device);

    React.useEffect(() => {
        refetch()
    }, [device])
    return (
        <>
            {data ? (
                <>
                    <Button variant={"outlined"} color={"success"} onClick={() => setOpen(true)}>Move</Button>
                    <MoveDeviceDialog open={open} setOpen={setOpen} device={data}/>
                </>

            ) : (
                <Button disabled variant={"outlined"} color={"success"}>Move</Button>
            )}

        </>
    )
}

export default MoveDeviceDialog;