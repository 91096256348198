import { FormatAlignLeft, Splitscreen, Window } from '@mui/icons-material';
import { Box, Card, CardContent, Grow, Skeleton, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DataGrid, GridToolbar, GridValueGetterParams } from '@mui/x-data-grid';
import CustomTicketTable from 'components/CustomTicketTable';
import React, { useState } from 'react'

interface DataContainer {
    data: any;
    loading: boolean;
}
const DataContainer: React.FC<DataContainer> = ({data, loading}) => {
    const theme = useTheme();
    const [mode, setMode] = useState(6);

    const userRows = [
        { field: 'name', headerName: 'Name',flex: 1 },
        { field: 'primaryEmail', headerName: 'Primary Email', flex: 1,},    
    ]

    const deviceRows = [
        { field: 'serialNumber', headerName: 'Serial Number',flex: 1 },
        { field: 'annotatedAssetId', headerName: 'Annotated Asset Id', flex: 1,},    
    ]

    const studentRows = [
        { field: 'name', headerName: 'Name',flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1,},    
    ]

    
    const lazyNavigation = (route) => {
        const host = document.location.origin;
        document.location.href = host + route
    }   
    

    return (
        <Grid2 spacing={2} container>
            <Grid2 xs={12}>
            <ToggleButtonGroup onChange={(e, v) => setMode(Number(v))} value={mode.toString()} exclusive>
                <ToggleButton value={"6"} aria-label="Small mode">
                    <Window />
                </ToggleButton>
                <ToggleButton value={"12"} aria-label="Large mode">
                    <Splitscreen />
                </ToggleButton>
            </ToggleButtonGroup>
            </Grid2>
            <Grid2 xs={12} md={mode}>
                {loading ? (
                    <Skeleton height="75vh" width="100%" />
                ) : (
                    <Grow in={!loading}>

                        <Card>
                            <CardContent>
                                <Typography variant="h3">User Accounts</Typography>
                                <Box
                                height="75vh"
                                maxWidth={"85vw"}
                                sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    //@ts-ignore
                                    color: `${theme.palette.secondary[200]} !important`,
                                },
                                }}
                                >                  
                                    <DataGrid
                                        slots={{ toolbar: GridToolbar }}
                                        loading={!data}
                                        getRowId={(row) => row.id}
                                        rows={data.users || []}
                                        columns={userRows}
                                        onRowClick={(params) => lazyNavigation("/org/users/" + params.row.id)}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grow>
                )}
            </Grid2>
            <Grid2 xs={12} md={mode}>
                {loading ? (
                    <Skeleton height="75vh" width="100%" />
                ) : (
                    <Grow in={!loading}>
                        <Card>
                            <CardContent>
                                <Typography variant="h3">Devices</Typography>
                                <Box
                                    height="75vh"
                                    maxWidth={"85vw"}
                                    sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        //@ts-ignore
                                        color: `${theme.palette.secondary[200]} !important`,
                                    },
                                    }}
                                    >                  
                                        <DataGrid
                                            slots={{ toolbar: GridToolbar }}
                                            loading={!data}
                                            getRowId={(row) => row.serialNumber}
                                            rows={data.devices || []}
                                            columns={deviceRows}
                                            onRowClick={(params) => lazyNavigation("/org/devices/" + params.row.serialNumber)}

                                        />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grow>
                )}
            </Grid2>
            <Grid2 xs={12} md={mode}>
                {loading ? (
                    <Skeleton height="75vh" width="100%" />
                ) : (
                    <Grow in={!loading}>
                        <Card>
                            <CardContent>
                                <Typography variant="h3">Students</Typography>
                                <Box
                                    height="75vh"
                                    maxWidth={"85vw"}
                                    sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        //@ts-ignore
                                        color: `${theme.palette.secondary[200]} !important`,
                                    },
                                    }}
                                    >                  
                                        <DataGrid
                                            slots={{ toolbar: GridToolbar }}
                                            loading={!data}
                                            getRowId={(row) => row.id}
                                            rows={data.students || []}
                                            columns={studentRows}
                                            onRowClick={(params) => lazyNavigation("/org/students/" + params.row.id)}

                                        />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grow>
                )}
            </Grid2>
            <Grid2 xs={12} md={mode}>
                {loading ? (
                    <Skeleton height="75vh" width="100%" />
                ) : (
                    <Grow in={!loading}>
                        <Card>
                            <CardContent>
                                <Typography variant="h3">Tickets</Typography>

                                <CustomTicketTable defaultBackground navigateOnClick data={data.tickets} onlyActive={false}/>
                            </CardContent>
                        </Card>
                    </Grow>
                )}
            </Grid2>
        </Grid2>
    ) 
}

export default DataContainer