import Loading from 'components/Loading';
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { LazyNavigation } from 'state/api';

function TryItOut() {
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        var to = searchParams.get("to") ?? "/org/dashboard"
        tryForFree(to)
    }, [])

    const tryForFree = async (navigateTo) => {
        try{
            var res = await fetch(process.env.REACT_APP_BASE_URL + '/auth/sample', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                })
            
            if(res.status == 200) {
                LazyNavigation(navigateTo)
            } else {

                LazyNavigation("/login")
            }
        }catch(e) {
            LazyNavigation("/login")
        }

    }

    return (
        <Loading 
            message="We're getting your test suite ready for you..."
            backdrop/>
    )
}

export default TryItOut