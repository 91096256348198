import React, {useEffect, useState} from 'react'
import {Box, Button, Card, CardContent, Divider, Skeleton, Typography, useMediaQuery, useTheme} from '@mui/material';
import * as yup from "yup";
import "./styles.css";
import {useNavigate} from 'react-router-dom';
import {useGetAllQuery, useGetFormQuery} from 'state/api';
import CustomForm, {FormItem} from 'components/CustomForm';
import SkeletonLoading from 'components/SkeletonLoading';
import SnackbarHandler from 'components/SnackbarHandler';
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";



interface SearchOption {
    id: string,
    label: string
}

const commonIssues = ["Damaged Screen", "Wont Charge", "Damaged Keyboard", "Damaged Trackpad", "Missing Keys", "Other"]

function CreateTicket() {

    const {data, isLoading: getLoading, error} = useGetAllQuery("");
    const {data: form, isLoading: formIsLoading, error: formError} = useGetFormQuery("");
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setIsSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarError, setSnackbarError] = useState(false);
    const [snackbarAction, setSnackbarAction] = useState(<></>)
    const [steps, setSteps] = useState([]);
    const [fields, setFields] = React.useState<FormItem[]>([]);

    useEffect(() => {
        if (!formIsLoading && !getLoading) {

            setSteps(form.steps);

            var fieldsFromRequest = form.fields;

            const newFields = fieldsFromRequest.map(field => convertFieldToUsabledata(field));
            setFields(newFields)

        }
    }, [form, getLoading])

    useEffect(() => {
        // If the error is a 401, or 405, redirect to login
        if (error || formError) {
            // @ts-ignore
            if (ror.status == 401 || error.status == 405) {
                window.location.href = "/login"
            } else {
                setSnackbarError(true)
                setSnackbarMessage("There was a problem loading the page. Please contact support")
                setIsSnackbarOpen(true)
            }
        }

    }, [error, formError]);


    const convertFieldToUsabledata = (field) => {

        const getValidator = () => {
            if (field.required) return () => yup.string().required(field.label + " is required")
            else return () => yup.string()
        }

        const getAutocompleteOptions = () => {
            switch (field.getter) {
                case 'students':
                    return data.students.map((student) => {
                        return {
                            id: student.accountId,
                            label: `Name: ${student.name}. Email: ${student.email} Device: ${student.deviceId} Asset Id: ${student.annotatedAssetId}`
                        }
                    })
                    break;
                case 'devices':
                    return data.devices.map((device) => {
                        return {
                            id: `Serial Number: ${device.serialNumber}. Annotated Asset Id: ${device.annotatedAssetId}. Model: ${device.model}`,
                            label: `Serial Number: ${device.serialNumber}. Annotated Asset Id: ${device.annotatedAssetId}. Model: ${device.model}`
                        }
                    })
                    break;
                case 'loaners':
                    return data.loaners.map((loaner) => {
                        return {
                            id: `Serial Number: ${loaner.serialNumber}. Annotated Asset Id: ${loaner.annotatedAssetId}. Model: ${loaner.model}`,
                            label:
                                `Serial Number: ${loaner.serialNumber}. Annotated Asset Id: ${loaner.annotatedAssetId}. Model: ${loaner.model}`
                        }
                    })
                    break;
                default:
                    return [];
            }
        }

        const getSelectOptions = () => {
            return field.selectOptions.map((o) => {
                return {id: o.value, label: o.label, offset: o.offset}
            })
        }

        const getUpdateCallback = () => {
            switch (field.autofill) {
                case 'studentDevice' :
                    console.log(field)
                    return {
                        formId: 'Student Information', callback: (value: string) => {

                            //Loop through students to find the right one
                            var student = data.students.filter((s) => s.accountId == value);
                            if (student.length == 0) {
                                return '';
                            }
                            var device = data.devices.filter((d) => d.serialNumber === student[0].deviceId);
                            if (device.length == 0) {
                                return ''
                            }
                            return `Serial Number: ${device[0].serialNumber}. Annotated Asset Id: ${device[0].annotatedAssetId}. Model: ${device[0].model}`;
                        }
                    }
                    break;
                case 'studentName':
                    return {
                        formId: 'Student Information', callback: (value: string) => {

                            //Loop through students to find the right one
                            var student = data.students.filter((s) => s.accountId == value);
                            if (student.length == 0) {
                                return '';
                            }
                            return student[0].name;
                        }
                    }
                case 'studentEmail':
                    return {
                        formId: 'Student Information', callback: (value: string) => {

                            //Loop through students to find the right one
                            var student = data.students.filter((s) => s.accountId == value);
                            if (student.length == 0) {
                                return '';
                            }
                            return student[0].email;
                        }
                    }
                    break;
                default:
                    return undefined
            }
        }

        //TODO: Update these fields (valueMutator, updateCallback)

        var item: FormItem = {
            step: field.step,
            name: field.label,
            type: field.type,
            initialValue: '',
            label: field.label,
            getter: field.getter,
            validator: getValidator(),
            data: getSelectOptions(),
            autocompleteOptions: getAutocompleteOptions(),
            updateCallback: getUpdateCallback()
        }


        return item;
    }



    const lazyNavigation = (route) => {
        const host = document.location.origin;
        document.location.href = host + route
    }

    const resetAfterTimeout = () => {
        setTimeout(() => {
            window.location.reload();
        }, 5000);
    }


    const submitForm = (values: {}, {resetForm}) => {

        //Get rid of all the offset values
        const filteredObject = Object.keys(values)
            .filter(key => !key.endsWith("Offset"))
            .reduce((acc, key) => {
                acc[key] = values[key];
                return acc;
            }, {});
        fetch(process.env.REACT_APP_BASE_URL + "/org/tickets/upload", {
            method: "POST",
            body: JSON.stringify({student: values["Student Information"], data: `${JSON.stringify(values)}`}),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include"
        }).then(async function (response) {
            if (response.status == 200) {
                var d = await response.json()
                setSnackbarError(false)
                setSnackbarMessage("Ticket created! This page will reset in 5 seconds. You can view your ticket here")
                setSnackbarAction(
                    <Button onClick={() => lazyNavigation("/org/ticket/" + d.id)} color="secondary">
                        View Ticket
                    </Button>
                )
                setIsSnackbarOpen(true)
                resetAfterTimeout()
            } else {
                setSnackbarError(true)
                setSnackbarMessage("There was a problem creating the ticket. Please contact support")
                setIsSnackbarOpen(true)
            }
        }).catch((reason) => {
            setSnackbarError(true)
            setSnackbarMessage("There was a problem creating the ticket. Please contact support")
            setIsSnackbarOpen(true)
        })


    }



    return (

        <Card sx={{
            width: '75%',
            margin: '1rem auto',

        }}>
            <CardContent>

                <Typography variant="h3">Create Ticket</Typography>
                <Divider sx={{m: '0 0 2rem 0'}}/>
                {(formIsLoading || isLoading || getLoading) && (
                    <Box>
                        <Skeleton variant={"rectangular"} height={200}/>
                    </Box>
                )}
                <CustomForm formItems={fields} steps={steps} onFormSubmit={submitForm}/>

                <SnackbarHandler open={openSnackbar} onClose={() => setIsSnackbarOpen(false)}
                                 severity={snackbarError ? "error" : "success"} message={snackbarMessage} action={snackbarAction}/>
            </CardContent>
        </Card>

    )
}

export default CreateTicket