import { Button, Dialog, DialogActions, DialogContent, Menu, MenuItem, TextField, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react'
import {Formik} from "formik";
import * as yup from "yup";

interface CreateUser {
    open: boolean;
    setOpen: (boolean) => void;
    configuration: any;
}

const createUserSchema = yup.object().shape({
    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    primaryEmail: yup.string().required("required").email("Must be a valid email"),
    password: yup.string().required("Password is Required").min(4, 'Must be larger than 4 characters'),
    changePasswordAtNextLogin: yup.boolean(),
    orgUnitPath: yup.string().required("required")
})



const CreateUser:React.FC<CreateUser> = ({open, setOpen, configuration}) => {

    console.log(configuration)

    const initialValues = {
        firstName: "",
        lastName: "",
        primaryEmail: "",
        password: "",
        changePasswordAtNextLogin: configuration.changePasswordAtNextLogin,
        orgUnitPath: configuration.studentOu
    }
    const handleFormSubmit = async (values:any, onSubmitProps: any) => {

    }
  return (
    <Dialog 
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
    >
        <DialogContent>
            <Typography variant="h3">Create a User</Typography>


            <Formik 
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={createUserSchema}
                isInitialValid={false}
            > 
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    resetForm,
                    isValid,
                }) => (
                    <Grid2 sx={{
                        m: '1rem 0'
                    }} container spacing={2}>
                        <Grid2 md={12}>
                            <TextField 
                                label="Primary Email"
                                color="secondary"
                                variant="standard"
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.primaryEmail}
                                name="primaryEmail"
                                error={Boolean(touched.primaryEmail) && Boolean(errors.primaryEmail)}
                                //@ts-ignore
                                helperText={touched.primaryEmail && errors.primaryEmail}
                            />    
                        </Grid2>
                        <Grid2 md={6} xs={12}>
                            <TextField 
                                label="First Name"
                                color="secondary"
                                variant="standard"
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.firstName}
                                name="firstName"
                                error={Boolean(touched.firstName) && Boolean(errors.firstName)}
                                //@ts-ignore
                                helperText={touched.firstName && errors.firstName}
                            />  
                        </Grid2>
                        <Grid2 md={6} xs={12}>
                            <TextField 
                                label="Last Name"
                                color="secondary"
                                variant="standard"
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.lastName}
                                name="lastName"
                                error={Boolean(touched.lastName) && Boolean(errors.lastName)}
                                //@ts-ignore
                                helperText={touched.lastName && errors.lastName}
                            />  
                        </Grid2>
                        <Grid2 md={12}>
                            <TextField 
                                label="Password"
                                color="secondary"
                                variant="standard"
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                                name="password"
                                error={Boolean(touched.password) && Boolean(errors.password)}
                                //@ts-ignore
                                helperText={touched.password && errors.password}
                            /> 
                        </Grid2>
                        <Grid2 md={6} xs={12}>
                            <TextField 
                                label="Org Unit Path"
                                color="secondary"
                                variant="standard"
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.orgUnitPath}
                                name="orgUnitPath"
                                error={Boolean(touched.orgUnitPath) && Boolean(errors.orgUnitPath)}
                                //@ts-ignore
                                helperText={touched.orgUnitPath && errors.orgUnitPath}
                            />  
                        </Grid2>
                        <Grid2 md={6} xs={12}>
                            <TextField 
                                label="Change Password At Next Login"
                                color="secondary"
                                variant="standard"
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.changePasswordAtNextLogin}
                                name="changePasswordAtNextLogin"
                                error={Boolean(touched.changePasswordAtNextLogin) && Boolean(errors.changePasswordAtNextLogin)}
                                //@ts-ignore
                                helperText={touched.orgUnitPath && errors.changePasswordAtNextLogin}
                                select
                            >
                                {/**@ts-ignore */}
                                <MenuItem value={true}>Yes</MenuItem>    

                                {/**@ts-ignore */}
                                <MenuItem value={false}>No</MenuItem>    
                            </TextField>  
                        
                        </Grid2>

                        <DialogActions>
                            <Button color="error" variant="contained" onClick={() => setOpen(false)}>Close</Button>
                            <Button disabled={!isValid} color="success" variant="contained" onClick={() => setOpen(false)}>Create User</Button>
                        </DialogActions>  
                    </Grid2>
                )}
            </Formik>
        </DialogContent>
      
    </Dialog>
  )
}

export default CreateUser