import React, {useEffect, useState} from 'react'
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Slide,
    Typography,
    useTheme
} from "@mui/material";

import {
    AdminPanelSettingsOutlined,
    ChevronLeft,
    ChevronRightOutlined,
    ComputerOutlined,
    DevicesOutlined,
    DynamicFormOutlined,
    GroupsOutlined,
    HomeOutlined, Inventory,
    NoteAddOutlined,
    PersonAddAltOutlined,
    PersonOutlined,
    ReceiptOutlined,
    SettingsOutlined,
    SsidChartOutlined
} from "@mui/icons-material"

import {useLocation, useNavigate} from 'react-router-dom';
import FlexBetween from './FlexBetween';
import profileImage from 'assets/default-profile.jpg';
import {HasMinimumScopeWithArray, useGetOrgBasicQuery, useGetSelfQuery} from 'state/api';
import {useDispatch, useSelector} from 'react-redux';
import {setProviderType} from 'state';

//TODO: Get from server
const navItems = [
    {
        text: "Dashboard",
        icon: <HomeOutlined/>,
        requireRole: [],

    },
    {
        text: "Search Organization",
        route: "search",
        icon: <DynamicFormOutlined/>,
        requireRole: ["read:organization"],

    },
    {
        text: "Manage",
        requireRole: ["read:ticket", "read:student", "read:device", "read:organization", "read:user", "read:student:groups"],
        icon: null
    },
    {
        text: "Students",
        icon: <PersonOutlined/>,
        requireRole: ["read:student"],
    },
    {
        text: "Tickets",
        route: "ticket",
        icon: <ReceiptOutlined/>,
        requireRole: ["read:ticket"],
    },
    // AS OF NOW, THIS IS REDUNDANT. ADMINS CAN VIEW USERS IN THE ORG SETTINGS, AND USERS CAN VIEW THEMSELVES IN THE PROFILE PAGE
    // {
    //   text: "Users",
    //   icon: <VerifiedUserOutlined />,
    //   requireRole: ["read:user"]
    //
    // },
    {
        text: "Loaners",
        icon: <ComputerOutlined/>,
        requireRole: ["read:device"],
    },
    {
        text: "Stats",
        icon: <SsidChartOutlined/>,
        requireRole: ["read:organization"],
    },
    {
        text: "Student Groups",
        route: "students/groups",
        icon: <GroupsOutlined/>,
        requireRole: ["read:student:groups"],
    },
    {
        text: "Actions",
        icon: null,
        requireRole: ["write:ticket:create", "read:device:checkin"],
    },
    {
        text: "Create Ticket",
        route: "ticket/create",
        icon: <NoteAddOutlined/>,
        requireRole: ["write:ticket:create"],
    },

    {
        text: "Create Students",
        route: "students/create",
        icon: <PersonAddAltOutlined/>,
        requireRole: ["write:student"],
        requireExperimental: "CreateStudent"
    },
    {
        text: "Inventory Management",
        icon: null,
        requireRole: ["read:organization"],
    },
    {
        text: "Return Data",
        route: "devices/returned/",
        icon: <DevicesOutlined/>,
        requireRole: ["read:device:checkin"],
    },
    {
        text: "Inventory Overview",
        route: "inventory/",
        icon: <Inventory/>,
        requireRole: ["read:device:checkin"],
    },
    {
        text: "Admin",
        icon: null,
        requireRole: ["read:organization"],
    },
    {
        text: "Server Settings",
        route: "settings",
        icon: <AdminPanelSettingsOutlined/>,
        requireRole: ["read:organization"],
    },


]

const studentNav = [
    {
        text: "Dashboard",
        route: "me/dashboard",
        icon: <HomeOutlined/>
    },
    {
        text: "Manage",
        icon: null
    },
    {
        text: "Your Tickets",
        route: "ticket/me",
        icon: <ReceiptOutlined/>
    },
]

//@ts-ignore
function Sidebar({user, drawerWidth, isSidebarOpen, setIsSidebarOpen, isNonMobile}) {

    const {pathname} = useLocation();
    const [active, setActive] = useState(0);
    const dispatch = useDispatch();
    const {data, isLoading} = useGetSelfQuery("");
    const {data: org, isLoading: orgLoading} = useGetOrgBasicQuery("");
    const navigate = useNavigate();
    const theme = useTheme();
    //@ts-ignore
    const state = useSelector(s => s.global);
    const [nav, setNav] = useState(studentNav);

    React.useEffect(() => {
        if (data == null) return;


        //Do the nav based off of role.
        var items = navItems.filter((item) => {
            //Check to see if it requires a experimental settings

            if (item.requireExperimental != null && !state.experimentalOptions.includes(item.requireExperimental)) {
                return false;
            }

            //0 is the admin scope
            if (data.user.scopes.includes(0)) return true;

            if (item.requireRole.length == 0) return true;

            // Loop through scopes to see if user has required scope
            for (var i = 0; i < item.requireRole.length; i++) {
                if (HasMinimumScopeWithArray(state.scopes, item.requireRole[i], true)) {
                    return true;
                }
            }

            return false;
        })
        console.log(items)
        setNav([...items]);

    }, [data, state]);

    useEffect(() => {
        if (org != null) {
            dispatch(setProviderType(org.providerType))
        }
    }, [org])

    // useEffect(() => {
    //   var path = getActiveTab(pathname);

    //   //setActive(getIndex(path))
    // }, [pathname])

    useEffect(() => {
        const sidebarStatus = localStorage.getItem('sidebarStatus');
        if (sidebarStatus !== 'true') {
            setIsSidebarOpen(false);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('sidebarStatus', isSidebarOpen ? 'true' : 'false');
    }, [isSidebarOpen]);


    const routeExists = (route) => {
        nav.forEach(path => {
            if (path.route == route || path.text.toLowerCase() == route) {
                return true;
            }
        });
        return false;
    }

    const getIndex = (path) => {
        return nav.findIndex((v) => {
            if (v.route) {
                v.route.includes(path)
            } else return false;
        })
    }

    const getActiveTab = (path) => {
        const paths = path.split("/")
        for (var i = 0; i < paths.length; i++) {
            if (routeExists(paths[i])) {
                return paths[i];
            }
        }
    }

    return (
        <Box component="nav">
            {isSidebarOpen && (
                <Drawer open={isSidebarOpen}
                        onClose={() => setIsSidebarOpen(false)}
                        variant="persistent"
                        anchor="left"

                        sx={{
                            position: "relative",
                            width: drawerWidth,
                            "& .MuiDrawer-paper": {
                                //@ts-ignore
                                color: theme.palette.secondary[200],
                                //@ts-ignore
                                backgroundColor: theme.palette.background.alt,
                                boxSizing: "border-box",
                                borderWidth: isNonMobile ? 0 : "2px",
                                width: drawerWidth
                            }
                        }}
                >
                    <Box width="100%" sx={{overflowY: 'auto'}}>
                        <Box m="1.5rem 2rem 2rem 3rem">
                            <FlexBetween color={theme.palette.secondary.main}>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Typography variant="h4" fontWeight="bold">
                                        CTickets
                                    </Typography>
                                </Box>
                                {!isNonMobile && (
                                    <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                                        <ChevronLeft/>
                                    </IconButton>
                                )}
                            </FlexBetween>
                        </Box>
                        <List>

                            {
                                //@ts-ignore
                                nav.map(({text, icon, route}, index) => {

                                    if (!icon) {
                                        return (
                                            <Typography key={text} sx={{m: "2.25rem 0 1rem 3rem"}}>
                                                {text}
                                            </Typography>
                                        )
                                    }
                                    const lcText = text.toLowerCase();

                                    return (
                                        <Slide timeout={(Math.log(index) * 250) + 100} direction="right" in={true}
                                               mountOnEnter unmountOnExit>
                                            <ListItem sx={{
                                                //@ts-ignore
                                                backgroundColor: active == index ? theme.palette.secondary[300] : "transparent",
                                                //@ts-ignore
                                                color: active == index ? theme.palette.primary[600] : theme.palette.secondary[100]
                                            }} key={text} disablePadding>
                                                <ListItemButton selected={active == index} color="secondary"
                                                                onClick={() => {
                                                                    navigate(`${route || lcText}`);
                                                                    setActive(index);
                                                                }}>
                                                    <ListItemIcon
                                                        sx={{
                                                            ml: "2rem",
                                                            //@ts-ignore
                                                            color: active == index ? theme.palette.primary[600] : theme.palette.secondary[200]

                                                        }}
                                                    >
                                                        {icon}
                                                    </ListItemIcon>

                                                    <ListItemText primary={text}/>
                                                    {active == index && (
                                                        <ChevronRightOutlined sx={{ml: "auto"}}/>
                                                    )}
                                                </ListItemButton>
                                            </ListItem>
                                        </Slide>
                                    )

                                })}
                        </List>
                    </Box>


                    <Box marginTop="auto" marginBottom="2rem">
                        <Divider/>
                        <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 3rem">
                            <Box
                                component="img"
                                alt="profile"
                                src={profileImage}
                                height="40px"
                                width="40px"
                                borderRadius="50%"
                                sx={{objectFit: "cover"}}
                            />

                            <Box textAlign="left">

                                <Typography fontWeight="bold" fontSize="0.9rem" textOverflow="ellipsis" sx={{
                                    //@ts-ignore
                                    color: theme.palette.secondary[100],
                                }}>
                                    {user.firstName || "Default"} {user.lastName || "User"}
                                </Typography>
                                <Typography fontWeight="bold" fontSize="0.9rem" sx={{
                                    //@ts-ignore
                                    color: theme.palette.secondary[200]
                                }}>
                                    {user.occupation || "Student"}
                                </Typography>
                            </Box>
                            <IconButton sx={{margin: '0', padding: '0'}} onClick={() => navigate('/org/settings')}>
                                <SettingsOutlined sx={{
                                    //@ts-ignore
                                    color: theme.palette.secondary[300],
                                    fontSize: "25px"
                                }}/>
                            </IconButton>
                        </FlexBetween>
                    </Box>
                </Drawer>
            )}
        </Box>
    )
}
//@ts-ignore
export default Sidebar;