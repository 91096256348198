import React from 'react';
import {Box, Button, ButtonGroup, Divider, Typography, useTheme} from "@mui/material";
import HoverPaper from "../../../components/HoverPaper";
import {useGetInventoryDevicesQuery} from "../../../state/api";
import {DataGrid, GridColDef, GridToolbar, GridValueGetterParams} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";

const DeviceCard = ({device}) => {

    return (
        <HoverPaper elevation={4} sx={{
            minHeight: '100px',
            height: '100%',
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        }}>
            <Box>
                <Typography variant={"h6"}>{device.sku}</Typography>
                <Typography variant={"subtitle1"}>{device.locationName}</Typography>
            </Box>
            <ButtonGroup>
                <Button variant={"outlined"} color={"warning"}>View</Button>
                <Button variant={"outlined"} color={"error"}>Delete</Button>
            </ButtonGroup>
        </HoverPaper>
    )
}

const DevicesOverview = () => {
    const {data, isLoading, refetch} = useGetInventoryDevicesQuery({});
    const navigate = useNavigate();
    const theme = useTheme();
    React.useEffect(() => {
        refetch()
    }, [])

    const columns: GridColDef[] = [
        {
            field: 'sku',
            headerName: 'SKU',
            type: 'string',
            flex: 1,
        },
        {
            field: 'type',
            headerName: 'Type',
            type: 'string',
            flex: 1,
        },
        {
            field: 'locationName',
            headerName: 'Current Location',
            type: 'string',
            flex: 1,
        }
    ];

    if (isLoading) {
        return (
            <Box>
                <Typography variant={"h5"}>Loading...</Typography>
            </Box>
        )
    }
    return (
        <Box sx={{padding: '1rem'}}>
            <Typography variant={"h2"}>Inventory Devices</Typography>
            <Divider/>
            <Box
                mt="40px"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                        cursor: "grab"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        //@ts-ignore

                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        //@ts-ignore
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    slots={{toolbar: GridToolbar}}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    loading={isLoading || !data}
                    getRowId={(row) => row.sku}
                    rows={data || []}
                    columns={columns}
                    onRowClick={(params) => {
                        navigate("/org/inventory/devices/" + params.row.sku)
                    }}
                />
            </Box>
        </Box>
    );
};

export default DevicesOverview;