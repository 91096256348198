import React from 'react';
import Header from "../../components/Header";
import {Box, Button, Divider, Skeleton, Stack, Typography} from "@mui/material";
import HoverPaper from "../../components/HoverPaper";
import Grid2 from "@mui/material/Unstable_Grid2";
import DeviceInventoryPiChart from "../../components/Charts/DeviceInventoryPiChart";
import {DataGrid, GridColDef, GridToolbar} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import {useNavigate} from "react-router-dom";
import {useGetInventoryTransactionsQuery} from "../../state/api";
import LocationRelationVisChart from "../../components/Charts/LocationRelationVisChart";
import Loading from "../../components/Loading";


const RecentInventoryTransactions = () => {

    const [data, setData] = React.useState([])
    const {data: transactions, isLoading} = useGetInventoryTransactionsQuery({});

    React.useEffect(() => {
        if (transactions) {
            setData(transactions)
        }
    }, [transactions])


    const colDefs: GridColDef[] = [
        {
            field: 'sku',
            headerName: 'Device',
            flex: 1,
            editable: false,
        },
        {
            field: 'sourceName',
            headerName: 'Source',
            flex: 1,
            editable: false,
        },
        {
            field: 'destinationName',
            headerName: 'Destination',
            flex: 1,
            editable: false,
        },
        {
            field: 'author',
            headerName: 'Author',
            flex: 1,
            editable: false,
        },
        {
            field: 'time',
            headerName: 'Time',
            flex: 1,
            editable: false,
            type: "dateTime",
            valueGetter: (params) => {
                // Convert the UTC Timestamp to a date string in the local timezone
                const date = new Date(params.row.time);
                return date;
            }
        },
    ]
    return (
        <HoverPaper sx={{height: '100%'}} elevation={4}>
            <Typography variant={"h4"}>Inventory Transactions</Typography>
            <Divider/>
            {data ? (
                <DataGrid
                    sx={{height: '90%', mt: '1rem'}}
                    columns={colDefs}
                    rows={data ?? []}
                    getRowId={(row) => row.id}
                    slots={{noRowsOverlay: CustomNoRowsOverlay}}
                    //Default sort by time
                    sortModel={[
                        {
                            field: 'time',
                            sort: 'desc',
                        },
                    ]}


                />
            ) : (
                <Skeleton variant={"rectangular"} height={"90%"}/>
            )}

        </HoverPaper>
    )
}


const InventoryOverview = () => {
    // const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    // const theme = useTheme()
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const refreshDevices = async () => {
        fetch(process.env.REACT_APP_BASE_URL + `/org/inventory/devices/edit/refresh`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        }).then((res) => {
            setLoading(false);

            // Refresh the page
            window.location.reload();
        })
    }

    const checkoutAllStudents = async () => {
        fetch(process.env.REACT_APP_BASE_URL + `/org/inventory/devices/edit/students`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        }).then((res) => {
            setLoading(false);

            // Refresh the page
            window.location.reload();
        })
    }

    return (
        <Box padding={"1rem"}>
            <Header title={"Inventory"} subtitle={"View your current device inventory"}/>
            <Divider/>
            <Box sx={{mt: "1rem"}}>
                {loading && (
                    <Loading backdrop/>
                )}
                <Grid2 spacing={2} container>
                    <Grid2 xs={12} md={4}>
                        <DeviceInventoryPiChart/>
                    </Grid2>
                    <Grid2 xs={12} md={8}>
                        <RecentInventoryTransactions/>
                    </Grid2>
                    <Grid2 xs={12} md={4}>
                        <HoverPaper elevation={4} sx={{height: '100%'}}>
                            <Typography variant={"h4"}>Actions</Typography>
                            <Divider/>
                            <Box sx={{mt: '1rem', height: '100%'}}>
                                <Stack spacing={2}>
                                    <Button variant={"outlined"}
                                            color={"success"}
                                            onClick={() => navigate("/org/inventory/devices")}>
                                        Devices
                                    </Button>


                                    <Button variant={"outlined"}
                                            color={"warning"}
                                            onClick={() => navigate("/org/inventory/locations")}>
                                        Inventory Locations
                                    </Button>


                                    <Button
                                        variant={"outlined"}
                                        color={"success"}
                                        onClick={refreshDevices}
                                        >
                                        Refresh Devices
                                    </Button>
                                    <Button
                                        variant={"outlined"}
                                        color={"warning"}
                                        onClick={checkoutAllStudents}
                                    >
                                        Checkout all Students
                                    </Button>
                                </Stack>
                            </Box>
                        </HoverPaper>
                    </Grid2>
                    {/*<Grid2 xs={12} md={8}>*/}
                    {/*    <HoverPaper sx={{height: '100%'}} elevation={4}>*/}
                    {/*        <Typography variant={"h4"}>Inventory Locations Relations</Typography>*/}
                    {/*        <Divider/>*/}
                    {/*        <LocationRelationVisChart/>*/}
                    {/*    </HoverPaper>*/}
                    {/*</Grid2>*/}
                </Grid2>
            </Box>
        </Box>
    );
};

export default InventoryOverview;