import { Circle } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, Backdrop, DialogContentText, DialogTitle, Stack, Typography, useTheme } from '@mui/material'
import { useGoogleLogin } from '@react-oauth/google';
import Loading from 'components/Loading';
import SnackbarHandler from 'components/SnackbarHandler';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';

interface StackItem {
    data: JSX.Element;
}
const StackItem: React.FC<StackItem> = ({data}) => {
    return (
        <Box ml="2rem" display="flex" flexDirection={"row"}>
            *
            {data}
        </Box>
    )
}

interface SetupCredentials {
    onSubmit: () => void;
}
const SetupCredentials = ({onSubmit}) => {
    const theme = useTheme()
    const [loading, setIsLoading] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    //@ts-ignore
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<"success" | "warning" | "error">("success")
    const scopes = [
        "https://www.googleapis.com/auth/admin.directory.device.chromeos.readonly",
        "https://www.googleapis.com/auth/admin.directory.domain.readonly",
        "https://www.googleapis.com/auth/admin.directory.user.readonly",
        "https://www.googleapis.com/auth/admin.directory.orgunit.readonly"
    ]
    
    const google = useGoogleLogin({
        scope: scopes.join(' '),
        state: "offline",
        //@ts-ignore
        access_type: "offline",
        responseType: "code",
        prompt: "consent",
        flow: 'auth-code',
        onSuccess: (codeResponse) => {setNewToken(codeResponse)},
        onError: (error) => console.log('Login Failed:', error)
    });

    const [open, setIsOpen] = useState(false);


    const setNewToken = (tokenResponse: any) => {
        setBackdropOpen(true);
        fetch(process.env.REACT_APP_BASE_URL + '/org/provider/data/credentials/update?relink=false', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                code: tokenResponse.code,
                type:"google",
                redirectURI: document.location.origin,
                scope: tokenResponse.scope
            })
            }).then(async (response) => {
                    setBackdropOpen(false);
                    if(response.status != 200) {
                        setSnackbarMessage("There was a problem setting the token. Make sure your account has all the necessary permissions ")
                        setSnackbarSeverity("error")
                        setSnackbarOpen(true)
                        return;
                    }
                    setSnackbarMessage("The token was successfully set")
                    setSnackbarSeverity("success")
                   setSnackbarOpen(true)
                   onSubmit();
                }).catch((reason) => {
                    setBackdropOpen(false);
                    setSnackbarMessage("There was a problem executing the request. Please try again later. ")
                    setSnackbarSeverity("error")
                    setSnackbarOpen(true)
                })
    }
    

    return (
      <Box>
        {loading && (<Loading/>)}

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
        >
            <Loading/>
        </Backdrop>

        <SnackbarHandler open={snackbarOpen} onClose={() => setSnackbarOpen(false) } message={snackbarMessage} severity={snackbarSeverity} />

        <Typography variant="body1">
            First, lets get your data linked. To start with this, we need to get access to your admin console. We only request read only access (unless you enable Experimental features)
            Before you do this, it is a good idea to create a seperate account for this. Make sure the account has access to devices and users as well as API access.
        </Typography>

        <Button sx={{m: '1rem 0'}} color="secondary" variant="outlined" onClick={() => setIsOpen(true)}>
            Register Token
        </Button>

        <Dialog
            open={open}
            onClose={() => setIsOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Allow CTickets to have read-only access to Google Admin?"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Please note, for CTickets to be able to create the link between a Google User and a device to manage
                tickets, CTickets needs read-only access to Google Admin.
                <br/>
                We will only ask for scopes related to users and chromebooks. If you wish to read more about API access, you can 
                do so <a href="https://developers.google.com/identity/protocols/oauth2/scopes#admin-directory" style={{color: theme.palette.primary.contrastText}}>here</a>
                <br/>
                <br/>
                Note: you can revoke CTickets access anytime you wish by following <a href="https://myaccount.google.com/u/0/permissions" style={{color: theme.palette.primary.contrastText}}>this link</a> and removing CTickets
                <br/>
                Here is why we need these permissions:
                <Stack>
                    <StackItem data={
                        <Typography><a>Device:</a>  We need to acceess devices for the liking process</Typography>
                    }/>
                    <StackItem data={
                        <Typography><a>User:</a>  We need to acceess users for the liking process</Typography>
                    }/>
                </Stack>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setIsOpen(false)} variant="contained">Disagree</Button>
            <Button onClick={() => {setIsOpen(false); google()}} autoFocus variant="contained">
                Agree
            </Button>
            </DialogActions>
        </Dialog>
      </Box>
    )
}

export default SetupCredentials