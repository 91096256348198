import React, {useEffect} from 'react';
import {useGetInventoryDeviceQuery} from "../../../state/api";
import {Button, Card, CardContent, Divider, Typography} from "@mui/material";
import DataField from "../../../components/data/DataField";
import {useNavigate} from "react-router-dom";
import HoverPaper from "../../../components/HoverPaper";

const DeviceInventoryCard = ({device}) => {
    const {data, isLoading, refetch} = useGetInventoryDeviceQuery(device.serialNumber);
    const navigate = useNavigate()
    useEffect(() => {
        refetch()
    }, [device]);


    return (
        <HoverPaper elevation={4}>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Device Inventory Information</Typography>
            <Divider sx={{mb: '1rem'}}/>

            {data != null ? (
                <>
                    <DataField compact title="SKU" data={data.sku} width="100%"/>
                    <DataField compact title="Type" data={data.type} width="100%"/>
                    <DataField compact title="Location" data={data.locationName} width="100%"/>
                    <DataField compact title="Is In Inventory" data={data.isInInventory ? "Yes" : "No"} width="100%"/>
                    {data.isInInventory && data.location != null && (
                        <Button onClick={() => navigate("/org/inventory/locations/" + data.location)} variant={"outlined"} color={"warning"}>View Location</Button>
                    )}
                    <Button onClick={() => navigate("/org/inventory/devices/" + data.sku)}  variant={"outlined"} color={"secondary"}>View Inventory Device</Button>

                </>
            ) : (
                <>
                    <Typography>Device does not exist in inventory.</Typography>
                </>
            )}
        </HoverPaper>
    );
};

export default DeviceInventoryCard;