import React from 'react';
import {Button, Dialog, DialogContent, Divider, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import UserAutocomplete from "../../components/Autocomplete/UserAutocomplete";
import DeviceAutocomplete from "../../components/Autocomplete/DeviceAutocomplete";
import UserConfiguration from "./UserConfiguration";
import DeviceConfiguration from "./DeviceConfiguration";
import CreateStudentDialog from "./CreateStudentDialog";

interface QuickCreateProps {
    open: boolean;
    setOpen: (open: boolean) => void;

}

const QuickCreate: React.FC<QuickCreateProps> = ({open, setOpen}) => {
    const [user, setUser] = React.useState(null);
    const [device, setDevice] = React.useState(null);
    const [creatingUser, setCreatingUser] = React.useState(false);
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth={"md"}
            fullWidth
        >
            <DialogContent>
                <Typography variant={'h3'}>Quickly create a single student.</Typography>
                <Divider sx={{m: '1rem 0'}}/>
                <Grid2 container spacing={2}>
                    <Grid2 xs={12} md={6}>
                        <UserAutocomplete freeSolo onValueChange={setUser} label={"User"} value={user}/>
                        {user != null && (<UserConfiguration user={user} onUpdate={(d) => setUser(d)}/>)}
                    </Grid2>
                    <Grid2 xs={12} md={6}>
                        <DeviceAutocomplete freeSolo onValueChange={setDevice} label={"Device"} value={device}/>

                        {device != null && (<DeviceConfiguration device={device}/>)}
                    </Grid2>

                    {user != null && device != null && (
                        <Grid2 xs={12}>
                            <Button color={"secondary"} fullWidth variant={"contained"} onClick={() => setCreatingUser(true)}>Create</Button>

                            <CreateStudentDialog user={user} device={device} open={creatingUser}
                                                 isNonBatch
                                                 setOpen={setCreatingUser} onStudentCreate={() => {
                            }}/>
                        </Grid2>
                    )}
                </Grid2>
            </DialogContent>
        </Dialog>
    );
};

export default QuickCreate;