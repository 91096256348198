import { Breadcrumbs, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const NavigationBreadcrumbs = () => {
    const [options, setOptions] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const pathnames = location.pathname.split('/');
        //Remove the org path
        pathnames.shift();
        //Check to see if the last path is empty or just a / if so remove it
        if (pathnames[pathnames.length - 1] === '' || pathnames[pathnames.length - 1] === '/') {
            pathnames.pop();
        }
        console.log(pathnames)
        setOptions(pathnames);
    }, [location])

    const getPathNameString = (path) => {
        // Only allow a max of 12 characters, if the path is longer than 7 characters, only show last 7 characters
        // With a ... in front of it
        if (path.length > 12) {
            return "..." + path.substring(path.length - 7, path.length);
        } else return path;
    }
    const routerDomNavigation = (path) => {
        navigate(path);
    }

    const buildPath = (path, index): string => {
        return "/" + options.slice(0, index + 1).join('/') + "/";
    }
    return (
        <Breadcrumbs aria-label="breadcrumb">
            {options.map((v, index) => {
            
                if (index + 1 < options.length) {
                    return (
                        <Link underline="hover" sx={{cursor: "pointer"}} color="inherit" onClick={() => {routerDomNavigation(buildPath(v, index))}}>
                            {getPathNameString(v)}
                        </Link>
                    )
                } else return (
                    <Typography color="text.primary">{getPathNameString(v)}</Typography>
                )
            })}
        </Breadcrumbs>
    )
}

export default NavigationBreadcrumbs