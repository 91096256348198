import {Box, Checkbox, Divider, Link, Skeleton, Typography, useTheme} from '@mui/material'
import {DataGrid, GridColDef, GridToolbar} from '@mui/x-data-grid'
import RequiresScope from 'components/RequiresScope'
import React, {useEffect, useState} from 'react'
import {LazyNavigation, useGetDeviceCheckinHistoryQuery} from 'state/api'
import HoverPaper from "../../../components/HoverPaper";

interface DeviceCheckinHistory {
    id: string;
    idIsStudent?: boolean
    typeHidden?: "device" | "student"
}

const DeviceCheckinHistory: React.FC<DeviceCheckinHistory> = ({id, idIsStudent = false, typeHidden = "device"}) => {

    const {data, isLoading, error, refetch} = useGetDeviceCheckinHistoryQuery(id)
    const [deviceId, setDeviceId] = useState(!idIsStudent ? id : null)

    const theme = useTheme();


    /**
     * This function should really only be called if the id provided is a student id, in which case we will get a students assigned device
     */
    const getAssignedDevice = async () => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + `/org/students/` + id, {
            method: "GET",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        if (response.status == 200) {
            //Set the assigned device to the deviceId
            var res = await response.json()

            if (res.serialNumber) {
                setDeviceId(res.serialNumber)
            }
        }
    }

    useEffect(() => {
        if (idIsStudent) {
            getAssignedDevice()
        }
    }, [id, idIsStudent])

    const columns: GridColDef[] = [
        {
            field: 'year',
            headerName: 'Year',
            flex: .5
        },
        {
            field: "studentName",
            headerName: "Student",
            flex: 1,

        },
        {
            field: "device",
            headerName: "Device",
            flex: 1,

        },
        {
            field: 'returnedDevice',
            headerName: 'Returned Device',
            flex: .5,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value}
                    onChange={(e, c) => {
                    }}
                    color='secondary'
                    disabled //Temp until fix 
                />
            ),
        },

        {
            field: 'returnedCase',
            headerName: 'Returned Case',
            type: 'string',
            flex: .5,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value}
                    onChange={(e, c) => {
                    }}
                    color='secondary'
                    disabled //Temp until fix 
                />
            ),
        },
        {
            field: 'returnedCharger',
            headerName: 'Returned Charger',
            type: 'string',
            flex: .5,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value}
                    onChange={(e, c) => {
                    }}
                    color='secondary'
                    disabled //Temp until fix 
                />
            ),
        },
        {
            field: 'images',
            headerName: 'Image Count',
            type: 'string',
            flex: .5,
            valueGetter: (params) => {
                return params.value.length
            }
        },
        {
            field: 'returner',
            headerName: 'Returned By',
            type: 'string',
            flex: 1,
        },
    ]

    const visibality = typeHidden == "device" ? {device: false} : {studentName: false}


    if (isLoading) {
        return (
            <HoverPaper elevation={4}>
                <Skeleton height="20vh"/>
            </HoverPaper>
        )
    }
    return (
        <HoverPaper elevation={4}>
            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Device Checkin History</Typography>
            {deviceId && (
                <RequiresScope allowLowerLevel scope="read:device:checkin">
                    <Link color='secondary' href={"/org/devices/returned?type=1&device=" + deviceId}>Check Device
                        In</Link>
                </RequiresScope>
            )}
            <Divider sx={{mb: '1rem'}}/>
            <Box
                width="100%"
                mt="20px"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        cursor: 'grab',
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    slots={{toolbar: GridToolbar}}
                    getRowId={(row) => row.id}
                    autoHeight
                    rows={data || []}
                    columns={columns}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                ...visibality
                            },
                        },
                    }}

                    onRowClick={(p) => LazyNavigation("/org/devices/returned?type=1&device=" + p.row.device + "&year=" + p.row.year)}
                />
            </Box>
        </HoverPaper>
    )
}

export default DeviceCheckinHistory