import React, {useEffect} from "react";
import {Navigate, useLocation} from "react-router-dom"
import {useGetIsAuthenticatedQuery} from "state/api";
import Loading from "./Loading";

export const PrivateRoute = (props: { children: React.ReactNode }): JSX.Element => {
    const {children} = props
    const location = useLocation()

    const {data, isLoading, error, refetch} = useGetIsAuthenticatedQuery("");

    const refreshCycle = () => {
        // Every 10 seconds, check if the user is authenticated
        setInterval(() => {
            refetch()
            // Recursively call this function
            refreshCycle()
        }, 1000 * 60 * 10)
    }

    useEffect(() => {
        refreshCycle();
    }, []);


    if (isLoading) {

        return (
            <Loading/>
        );
    }


    return !error ? (
        <>{children}</>
    ) : (
        <Navigate
            replace={true}
            to="/login"
            state={{from: `${location.pathname}${location.search}`}}
        />
    )
}
