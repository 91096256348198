import { Box, Typography, Divider, useTheme } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import Loading from 'components/Loading';
import DataField from 'components/data/DataField'
import React from 'react'
import { useParams } from 'react-router-dom';
import { useGetStudentQuery } from 'state/api';

function StudentInformation() {
  const theme = useTheme();
  const {id} = useParams();
  const {data, isLoading, error} = useGetStudentQuery(id);
  if(isLoading) return (<Loading/>)

  const getLink = (endpoint: string) => {
    const host = document.location.origin;
    return host + endpoint;
  }
  return (
    <Box m="1rem">
      <Typography variant="h3">Information</Typography>
      <Typography variant='subtitle2'>Student Information</Typography>
      <Divider sx={{mt: '1rem'}}/>

      <Box sx={{
          mt: '1rem',
          //@ts-ignore
          backgroundColor:theme.palette.background.alt,
          borderRadius:"0.55rem",
          padding: '1rem',
          mb: '1rem'
      }}>
          <Grid2 container spacing={1}>
              <Grid2 xs={12} md={6}>
                  <DataField data={data.email} title={"Primary Email"} width={"100%"}/>
              </Grid2>
              <Grid2 xs={12} md={6}>
                  <DataField data={<a href={getLink("/org/devices/" + data.serialNumber)}>{data.serialNumber}</a>} title={"Device Serial Number"} width={"100%"}/>
              </Grid2>
              <Grid2 xs={12} md={6}>
                  <DataField data={<a href={getLink("/org/users/" + data.accountId)}>{data.accountId}</a>} title={"Account Id"} width={"100%"}/>
              </Grid2>
              <Grid2 xs={12} md={6}>
                  <DataField data={data.annotatedAssetId} title={"Device Annotated Asset Id"} width={"100%"}/>
              </Grid2>
          </Grid2>
      </Box>

      <Box sx={{
          mt: '1rem',
          //@ts-ignore
          backgroundColor:theme.palette.background.alt,
          borderRadius:"0.55rem",
          padding: '1rem',
          mb: '1rem'
      }}>
          <Typography>Audits</Typography>
          <Divider/>
      </Box>
  </Box>
  )
}

export default StudentInformation