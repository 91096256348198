import { Button } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import { NavigateToProvider } from 'state/api';

interface ViewInProviderButton {
    type: "device" | "user";
    id: string;
}
const ViewInProviderButton:React.FC<ViewInProviderButton> = ({type, id}) => {
    //@ts-ignore
    const global = useSelector(g => g.global)
    


    if(global.provider == "google") {
        return (
            <Button color="secondary" variant="outlined" fullWidth onClick={() => NavigateToProvider("google", type, id)}>
                View {type.toUpperCase()} In Google Admin
            </Button>
        )
    }

    return (<></>)
}

export default ViewInProviderButton