import React from 'react'
import {Alert, Slide, Snackbar} from "@mui/material"

interface SnackbarHandler {
    open: boolean;
    onClose: () => void;
    message: string;
    severity: string;
    action?: any;
}

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const SnackbarHandler:React.FC<SnackbarHandler> = ({open, onClose, message, severity, action}) => {
  return (
    <Snackbar action={action} TransitionComponent={TransitionLeft} open={open} autoHideDuration={6000} onClose={() => onClose()}>
        {/* @ts-ignore */}
        <Alert onClose={() => onClose()} severity={severity} sx={{ width: '100%' }}>
          {message}
          {action}
        </Alert>
    </Snackbar>
  )
}

interface SnackbarHandlerWrapperProps {
    snackbarData: any;
    setSnackbarData: (snackbarData: any) => void;

}
export const SnackbarHandlerWrapper:React.FC<SnackbarHandlerWrapperProps> = ({snackbarData, setSnackbarData}) => {
    return (
        <SnackbarHandler open={snackbarData.open}
                         onClose={() => setSnackbarData({...snackbarData, open: false})}
                         message={snackbarData.message} severity={snackbarData.severity}/>
    )
}

export default SnackbarHandler