import React from 'react';
import PieChart from "./PieChart";
import HoverPaper from "../HoverPaper";
import {CircularProgress, Divider, Link, Skeleton, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useGetInventoryDevicesQuery} from "../../state/api";

const DeviceInventoryPiChart = () => {
    const [loading, setLoading] = React.useState(false)
    const [formattedData, setFormattedData] = React.useState(null)
    const navigate = useNavigate();
    const {data, isLoading, isError, refetch} = useGetInventoryDevicesQuery({});

    React.useEffect(() => {
        refetch()
    }, [])

    React.useEffect(() => {
        if (data) {
            setLoading(true)
            var d = [] as {id: string, label: string, value: number}[];
            var names = [] as string[];
            data.forEach((item) => {
                // Get the location name
                var name = item.locationName ?? "Unknown Location";

                if(!item.isInInventory) {
                    name = "Checked Out"
                }

                // If the location is not in the array, add it
                if(!names.includes(name)) {
                    names.push(name);
                    d.push({id: name, label: name, value: 1})
                } else {
                    // If the location is in the array, increment the value
                    d[names.indexOf(name)].value++;
                }
            })

            setFormattedData([...d])
        }
    }, [data])



    return (
        <HoverPaper elevation={4} sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "400px",
        }}>
            <Typography variant={"h4"}>Inventory Overview</Typography>
            <Link color={"secondary"} onClick={() => navigate("/org/inventory/locations")}>Info</Link>
            <Divider/>
            {formattedData == null ? (
                <Skeleton variant={"rectangular"} height={"100%"} width={"100%"}/>
            ) : (
                <PieChart input={formattedData}/>
            )}
        </HoverPaper>

    );
};

export default DeviceInventoryPiChart;