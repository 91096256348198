import React from 'react';
import {Button, Dialog, DialogContent, Divider, LinearProgress, MenuItem, TextField, Typography} from "@mui/material";
import InventoryDeviceAutocomplete from "../../../components/Autocomplete/InventoryDeviceAutocomplete";
import {SnackbarHandlerWrapper} from "../../../components/SnackbarHandler";

interface DialogProps {
    id: string;
    onChange: (value?: string) => void
}
const RemoveStudentsDeviceDialog:React.FC<DialogProps> = ({onChange}) => {
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <Button onClick={() => setOpen(true)} color="secondary" variant="outlined">Remove Device</Button>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    <Typography>Remove Device</Typography>
                </DialogContent>
            </Dialog>

        </>
    );

}

export const AddDeviceToStudentDialog:React.FC<DialogProps> = ({id, onChange}) => {
    const [open, setOpen] = React.useState(false);
    const [snackbarData, setSnackbarData] = React.useState({open: false, message: "", severity: ""} as any);
    const [loading, setLoading] = React.useState(false);
    const [type, setType] = React.useState("Device")
    const addDevice = async (value?: any) => {
        setSnackbarData({...snackbarData, open: false})

        var device = value.isFreeSolo ? value.inputValue : value.sku;

        setLoading(true)

        var response = await fetch(process.env.REACT_APP_BASE_URL + "/org/students/" + id + "/devices/" + device+`?type=${type}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
        setLoading(false)


        if(response.status == 200) {
            setSnackbarData({open: true, message: "Device added to student", severity: "success"});
            setOpen(false);
            onChange();
        } else {
            setSnackbarData({open: true, message: "Could not add device to student", severity: "error"});
        }

    }

    return (
        <>
            <Button onClick={() => setOpen(true)} color="secondary" variant="outlined">Add Device</Button>
            <SnackbarHandlerWrapper snackbarData={snackbarData} setSnackbarData={setSnackbarData}/>
            {open && (
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    fullWidth
                    maxWidth="sm"
                >
                    {loading && (
                        <LinearProgress color={"secondary"}/>
                    )}
                    <DialogContent>
                        <Typography variant={"h3"}>Add Device</Typography>

                        <Divider sx={{mb: '1rem'}}/>

                        <TextField
                            label={"Type"}
                            helperText={"The type of the device to checkin (if the device is new)"}
                            select
                            color={"secondary"}
                            variant={"outlined"}
                            fullWidth
                            sx={{m: '1rem 0 .5rem 0'}}
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        >
                            <MenuItem value={"Device"}>Device</MenuItem>
                            <MenuItem value={"Barrel Charger"}>Barrel Charger</MenuItem>
                            <MenuItem value={"Type-C Charger"}>Type-C Charger</MenuItem>
                            <MenuItem value={"Accessory"}>Accessory</MenuItem>
                        </TextField>


                        <InventoryDeviceAutocomplete  freeSolo onValueChange={addDevice} label={"Add Device"} value={""}/>
                    </DialogContent>
                </Dialog>
            )}

        </>
    );

}

const StudentAssignedDevicesDialog = () => {
    return (
        <div>

        </div>
    );
};

export default StudentAssignedDevicesDialog;