import {Box, Button, ButtonGroup, Divider, Stack, Typography, useTheme, Switch, Backdrop, Paper} from '@mui/material'
import TwoSide from 'components/data/TwoSide';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react'
import EnableExperimentalSettings from './EnableExperimentalSettings';
import { useDispatch } from 'react-redux';
import { setExperimentalSettings } from 'state';
import { useGetOrgSettingsQuery } from 'state/api';
import Loading from 'components/Loading';

function ExperimentalSettings() {
    const theme = useTheme();
    const [loading, setIsLoading] = useState(false);
    const {data, isLoading, error, refetch} = useGetOrgSettingsQuery("");
    //@ts-ignore
    const state = useSelector(state => state.global);
    const dispatch = useDispatch();
    const [overlayVisible, setOverlayVisable] = useState(false);
    const [enable, setEnable] = useState(false);
    const [settings, setSettings] = useState([])
    useEffect(() => {
        if(data != null) {
            setIsLoading(false)
            setSettings([...data.organization.experimentalSettings]);
            setOverlayVisable(!data.organization.experimentalSettingsEnabled)
        }
    }, [data])

    const updateExperimentalSetting = async (setting, isAdding) => {
        setIsLoading(true)
        var reponse = await fetch(process.env.REACT_APP_BASE_URL + '/org/experimental?setting=' + setting, {
            method: isAdding ? "POST" : "DELETE",
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        });

        if(reponse.status == 200) {
            if(isAdding) {
               
                refetch()
                dispatch(setExperimentalSettings([...state.experimentalOptions, setting]))
            } else {
                refetch()
                var arr = state.experimentalOptions.filter(s => s != setting);
                dispatch(setExperimentalSettings([...arr]))
            }
        }


        

    }

    return (
      <Box m="1rem 0">
        <Backdrop sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1
        }} open={loading || isLoading}>
            <Loading/>
        </Backdrop>
          <Typography variant='h3'>Experimental Settings</Typography>
          <Typography variant='subtitle2'>Enable/Disable experimental settings for your organization</Typography>
          <Typography variant="subtitle1" mt="1rem" color="red">
            Warning!! These features are experimental and require scopes that are not already required for the base program to work. 
            Because of this only the organization creator and the account that was used for the link provider can access these settings. 
            You will also have to re-authorize a refresh token for the link provider.
            </Typography>
          <Divider sx={{mt: '.4rem'}}/>

          <Paper elevation={16} sx={{
              mt: '1rem',

              padding: '1rem',
              mb: '2rem',
          }}>
            <EnableExperimentalSettings open={enable} setOpen={setEnable}/>

            {overlayVisible && (

                <Button onClick={() => setEnable(true)} color="warning" variant="contained">
                    Enable Experimental Settings
                </Button>
            )}
            <Stack sx={{
              filter: overlayVisible ? 'blur(5px)' : 'none'
            }} divider={<Divider/>}>

                <TwoSide>
                    <Box>
                        <Typography variant="h4" mb="1rem">Enable Action of Deprovisoning devices/students</Typography>
                        <Typography variant="body1">
                            This feature allows you to go to <b>Student &gt; Actions</b> and change the students device, while <br/>
                            deprovisioning the device or only moving it to a specific OU. <br/>
                            This requires the scope <b>Experimental &gt; Students &gt; Deprovision</b>
                        </Typography>
                    </Box>

                    <Box>
                        <Switch value={settings.includes("DeprovisionDevice")} checked={settings.includes("DeprovisionDevice")} onChange={(e, checked) => updateExperimentalSetting("DeprovisionDevice", checked)} color="secondary"/>
                    </Box>
                </TwoSide>

                <TwoSide>
                    <Box>
                        <Typography variant="h4" mb="1rem">Enable Action of Creating Students</Typography>
                        <Typography variant="body1">
                            This feature allows you to go to <b>Create Student</b> and link devices with users.<br/>
                            It will use the current provider configuration to update the fields automatically. <br/>
                            This requires the scope <b>Experimental &gt; Students &gt; Create</b>
                        </Typography>
                    </Box>
                    <Box>
                        <Switch value={settings.includes("CreateStudent")} checked={settings.includes("CreateStudent")} onChange={(e, checked) => updateExperimentalSetting("CreateStudent", checked)} color="secondary"/>
                    </Box>
                </TwoSide>

                <TwoSide>
                    <Box>
                        <Typography variant="h4" mb="1rem">Enable Action of Creating Users</Typography>
                        <Typography variant="body1">
                            This feature allows you to create users within the data provider.<br/>
                            You can create users within the <b>Create Student</b> with the specified OU and password.<br/> It will require the scope <b>Experimental &gt; Users &gt; Create</b>
                        </Typography>
                    </Box>
                    <Box>
                        <Switch value={settings.includes("CreateUser")} checked={settings.includes("CreateUser")} onChange={(e, checked) => updateExperimentalSetting("CreateUser", checked)} color="secondary"/>
                    </Box>
                </TwoSide>

                <TwoSide>
                    <Box>
                        <Typography variant="h4" mb="1rem">Enable Action of Re-Configuring Link Provider</Typography>
                        <Typography variant="body1">
                            This feature allows you to go to <b>Settings &gt; Link Provider</b> and re-configure the link provider.<br/>
                            You will be able to change how it is configured, then automatically update the students, or do it yourself. <br/>
                            Only you can do this action.
                        </Typography>
                    </Box>
                    <Box>
                        <Switch value={settings.includes("ReconfigureProvider")} checked={settings.includes("ReconfigureProvider")} onChange={(e, checked) => updateExperimentalSetting("ReconfigureProvider", checked) } color="secondary"/>
                    </Box>
                </TwoSide>
            </Stack>

          </Paper>
      </Box>
    )
}

export default ExperimentalSettings