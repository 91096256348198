import { Backdrop, Box, Button, Card, CardContent, Divider, IconButton, LinearProgress, List, ListItemButton, ListItemText, Stack, Typography, useTheme } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import React, { useEffect, useState } from 'react'
import SecurityPolicyCard from './SecurityPolicyCard';
import { useGetAllSecurityPoliciesQuery } from 'state/api';
import Loading from 'components/Loading';
import { Delete } from '@mui/icons-material';

const SecurityPolices = () => {
    const [currentPolicy, setCurrentPolicy] = useState(null);
    const [policies, setPolicies] = useState([])
    const [currentIndex, setCurrentIndex] = useState(-1);
    const {data, isLoading, error, refetch} = useGetAllSecurityPoliciesQuery("");
    const [backdropOpen, setBackdropOpen] = useState(false);
    const theme = useTheme();
    useEffect(() => {
        if(data != null) {
            setPolicies([...data])
        }
    }, [data])

    const createNewPolicy = async () => {
        setBackdropOpen(true)
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/settings/security/policies', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include"
        })

        await refetch()
        setBackdropOpen(false)

    }
    const deletePolicy = async (id, index) => {
        setBackdropOpen(true)
        if(currentIndex === index) {
            setCurrentIndex(-1);
            setCurrentPolicy(null)
        }
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/settings/security/policies/' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include"
        })

        await refetch()
        setBackdropOpen(false)
    }

    useEffect(() => {
        refetch()
    }, [])
    
    return (
    <Box mt="1rem">
        <Backdrop sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1
        }} open={isLoading || backdropOpen}>
            <Loading/>
        </Backdrop>
        <Typography variant="h3">Security Policies Settings</Typography>
        <Divider sx={{m: isLoading ? '1rem 0 0rem 0' : '1rem 0 2rem 0'}}/>
        {isLoading && (
            <Box sx={{
                mb: '1rem'
            }}>
                <LinearProgress color="secondary" />
            </Box>
        )}
        <Grid2 container spacing={5}>
            <Grid2 md={3}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>Policies</Typography>
                        <Divider sx={{mb: '1rem'}}/>

                        <Stack>
                            <List>

                                {policies.map((p, i) => (
                                    <ListItemButton key={p.id}  onClick={() => {
                                        setCurrentIndex(i)
                                        setCurrentPolicy(p.id)
                                    }} sx={{
                                        //@ts-ignore
                                        backgroundColor: currentIndex === i ? theme.palette.secondary[300] : "transparent",
                
                                        //@ts-ignore
                                        color: currentIndex === i ? theme.palette.primary[600] : theme.palette.secondary[100]
                                    }}>
                                    <ListItemText primary={p.name}/>
                                    <IconButton onClick={() => deletePolicy(p.id, i)} size="small">
                                        <Delete/>
                                    </IconButton>
                                    </ListItemButton>
                                ))}
                            </List>
                            <Button color="secondary" variant="outlined" onClick={() => createNewPolicy()}>Create New Policy</Button>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 md={9}>
                {currentPolicy != null && (
                    <SecurityPolicyCard id={currentPolicy} onChange={() => refetch()}/>
                )}
            </Grid2>
        </Grid2>
    </Box>
  )
}

export default SecurityPolices