import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Markdown from 'react-markdown';
import {Box, Fade, Skeleton, Typography} from '@mui/material';
import remarkGfm from 'remark-gfm';
import {Settings, Visibility} from "@mui/icons-material";

const DisplayDocs = () => {
    const [url, setUrl] = useState('/');
    const [page, setPage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { pathname } = useLocation();

    const fetchData = async (url) => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + `/docs?url=${url}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include"});

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            const parsedPage = data.page.replaceAll('\\n', '\n');
            setPage(parsedPage);
        } catch (error) {
            console.error('Fetch error:', error);
            setPage(null);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        setPage(null);
        const url = pathname.split('/docs')[1];
        setUrl(url);
        fetchData(url);
    }, [pathname]);





    // Renderers



    return (
        <>
            {page ? (
                <Fade in={true}>
                    <Box>
                        <Markdown remarkPlugins={[remarkGfm]}>{page}</Markdown>
                    </Box>
                </Fade>
            ) : (
                <>
                    {isLoading ? (
                        <Skeleton variant={'rectangular'} height={200} />
                    ) : (
                        <Box>
                            <Typography variant={"h3"}>Oops!</Typography>
                            <Typography>
                                There was an error loading the page. This page might not exist yet.
                                If you have any questions please contact support at support@ctickets.net

                            </Typography>
                        </Box>
                    )}
                </>
            )}
        </>
    );
};

export default DisplayDocs;
