import { Card, CardContent, Skeleton, Typography, Divider, CardActions, Button, Box } from '@mui/material';
import DataField from 'components/data/DataField';
import React from 'react'
import { useGetUserAssignedStudentQuery, LazyNavigation, useGetUserAssignedDeviceQuery } from 'state/api';

const UserAssignedDevice = ({id}) => {
    const {data, isLoading, error} = useGetUserAssignedDeviceQuery(id);

    if(isLoading || data == null || data == undefined) {
        <Card>
            <CardContent>
                <Skeleton height="20vh"/>
            </CardContent>
        </Card>
    }

    if(error) {
        return (        
        <Card>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Assigned Devce Information</Typography>
                <Divider sx={{mb: '1rem'}}/>
            </CardContent>
        </Card>)
    }
    return (
        <Card sx={{height: '100%'}}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Assigned Devce Information</Typography>
                <Divider sx={{mb: '1rem'}}/>

                {data == null ? (
                    <Box>

                    </Box>
                ): (                    
                    <Box>

                        <DataField compact title="Serial Number" data={data.serialNumber} width={"100%"} />
                        <DataField compact title="Model" data={data.model} width={"100%"} />
                        <DataField compact title="Asset Id" data={data.annotatedAssetId} width={"100%"} />
                        <DataField compact title="Annotated User" data={data.annotatedUser} width={"100%"} />
                    </Box>

                )}

                
            </CardContent>
            <CardActions sx={{mt: 'auto'}}>
                {data != null && (

                    <Button color="secondary" variant="outlined" onClick={() => LazyNavigation("/org/devices/" + data.serialNumber)}>View Device</Button>
                )}
            </CardActions>
        </Card>
    )
}

export default UserAssignedDevice