import { Close } from '@mui/icons-material';
import { AppBar, Box, Button, Card, CardContent, Chip, Dialog, DialogContent, Divider, IconButton, Skeleton, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Loading from 'components/Loading';
import DataField from 'components/data/DataField';
import DataList from 'components/data/DataList';
import ImageCard from 'components/data/ImageCard';
import TwoSide from 'components/data/TwoSide';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setForcedMode } from 'state';
import { useGetMessagesFromTicketQuery, useGetImagesFromTicketQuery, useGetAllTicketsQuery, useGetLayoutQuery } from 'state/api';
import './ExportTicketPdf.styles.css';
import TicketStatusComponent from 'components/data/TicketStatus';

interface TicketPrintable {
  ticket: any;
  layout: any[];
}
const TicketPrintable: React.FC<TicketPrintable> = ({ ticket, layout }) => {

  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const [gridItems, setGridItems] = useState([]);
  const [headerItems, setHeaderItems] = useState([]);
  const theme = useTheme();

  const dispatch = useDispatch();
  const darkMode = localStorage.getItem("mode") == 'dark'

  useEffect(() => {
    var ticketData = JSON.parse(ticket.data);

    var items = layout.map((i) => ({
      ...i,
      uid: Math.random()
    }));

    for (var i = 0; i < items.length; i++) {
      if (items[i].name == "Student Information") {
        items[i].value = ticket.studentName;
      } else {
        items[i].value = ticketData[items[i].name]
      }
    }

    var headerItems = items.filter((i) => i.type == 'header').map((i) => ({ ...i, uid: Math.random() }))

    setHeaderItems([...headerItems])
    setGridItems([...items])
  }, [ticket])


  useEffect(() => {
    if (darkMode) {
      dispatch(setForcedMode("light"))
    }
  }, [])

  const getTicketStatusToString = (data) => {
    switch (data) {
      case 0:
        return "Waiting for Repair"
        break;
      case 1:
        return "Repaired, Waiting for Return"
        break;
      case 2:
        return "In Office"
        break;
      case 3:
        return "Completed"
        break;
      case 4:
        return "Other"
        break;

    }
  }

  return (
    <Box sx={{
      //@ts-ignore
      width: '100%',
      position: 'relative',
    }} className="page print-no-cutoff" >
      <Box className="header">
        <TwoSide>
          <Box>
            <Stack direction={"row"} spacing={1} divider={<Divider flexItem orientation='vertical' />}>
              <Box>
                <img src={"https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=" + window.location.protocol + "//" + window.location.host + "/org/ticket/" + ticket.id} alt="QR CODE" />
              </Box>
              <Box>
                <Typography variant="h1">Ticket #{ticket.id.slice(-7)}</Typography>
                <Typography variant='subtitle2'>Author: {ticket.author}</Typography>
              </Box>
            </Stack>
          </Box>
          <Stack spacing={0}>
            <Typography>Created At: {new Date(ticket.createdAt).toLocaleTimeString() + " " + new Date(ticket.createdAt).toLocaleDateString()}</Typography>
            <Stack direction="row" alignItems={'center'}>
              <Typography>Status: </Typography>
              <TicketStatusComponent status={ticket.status} editable={false} onChange={() => {}}/>

            </Stack>
            <Box sx={{
              display: 'flex',
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: 'center',
              maxWidth: '20vw',
            }}>
              <Typography sx={{ mr: '0.2rem' }}>Assignees: </Typography>
              <Stack direction={"row"} flexWrap={"wrap"} spacing={1}>
                {ticket.assignees.map((a) => (<Chip size="small" key={a} label={a.split("@")[0]} />))}
              </Stack>
            </Box>
            {headerItems.map((i, index) => (
              <Typography key={i.uid}>{i.name}: {i.value}</Typography>
            ))}
          </Stack>
        </TwoSide>
      </Box>
      <Divider />
      <Grid2 container spacing={1}>
        {gridItems.map((item, index) => {
          if (item.type == "title") {
            return (
              <Grid2 key={item.uid} xs={12}>
                <Typography variant='h2'>{item.name}</Typography>
              </Grid2>
            )
          }
          if (item.type == "divider") {
            return (
              <Grid2 key={item.uid} xs={12}>
                <Divider sx={{ margin: '.2rem 0' }} />
              </Grid2>
            )
          }
          if (item.type == "field") {
            return (
              <Grid2 key={item.uid} xs={item.xs || 6}>
                <Typography variant="h5">
                  {item.name}
                </Typography>
                <Divider />
                <Box className="print-dark-background" sx={{
                  //@ts-ignore
                  margin: '5px 0',
                  padding: '.3rem 2rem .3rem .5rem',
                  //@ts-ignore
                  backgroundColor: theme.palette.neutral[700],
                  background: 'rgba(0,0,0,.07)'

                }}>
                  <Typography variant="body1" color={theme.palette.primary.contrastText}>{item.value ?? "None"}</Typography>
                </Box>
              </Grid2>
            )
          }
          return <></>
        })}
      </Grid2>

    </Box>
  )
}


interface ExportTicketPdf {
  isOpen: boolean;
  setOpen: (boolean) => void;

}


const ExportTicketPdf: React.FC<ExportTicketPdf> = ({ isOpen, setOpen }) => {
  const { data: tickets, isLoading, error } = useGetAllTicketsQuery("");
  const dispatch = useDispatch();
  const darkMode = localStorage.getItem("mode") == 'dark'
  const { data: layout, isLoading: layoutLoading, error: layoutError } = useGetLayoutQuery("");

  useEffect(() => {
    if (darkMode && isOpen) {
      dispatch(setForcedMode("light"))
    }
  }, [isOpen])


  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      fullScreen
    >
      <AppBar sx={{ position: 'relative', displayPrint: "none" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              if (darkMode) {
                dispatch(setForcedMode("dark"))
              }

              setOpen(false)
            }}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid2 container spacing={1}>
          <Grid2 xs={12} md={2} className='print-hidden'>
            <Card className="sidebar">
              <CardContent>
                <Button onClick={() => window.print()} color="secondary" fullWidth variant="contained">
                  Print Tickets
                </Button>
              </CardContent>
            </Card>
          </Grid2>
          <Grid2 sx={{m: '0'}} xs={12} md={10}>
            {isLoading || layoutLoading ? (
              <Loading />
            ) : (
              <Stack>
                {tickets.map((t) =>
                  <TicketPrintable ticket={t} layout={layout.layout} />
                )}
              </Stack>
            )}
          </Grid2>
        </Grid2>
      </DialogContent>
    </Dialog>
  )
}

export default ExportTicketPdf