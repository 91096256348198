import React from 'react';
import {Divider, Skeleton, Typography, useTheme} from "@mui/material";
import HoverPaper from "../HoverPaper";
import {ResponsiveSankey} from "@nivo/sankey";
import {convertMuiThemeToNivoTheme} from "./PieChart";
import {useGetInventoryDeviceTransactionsQuery} from "../../state/api";

const DeviceMovementSankeyChart = ({id}) => {
    const [graphData, setGraphData] = React.useState(null)
    const {data, refetch} = useGetInventoryDeviceTransactionsQuery(id ?? "")
    const theme = useTheme();
    React.useEffect(() => {
        refetch()
    }, [id])

    React.useEffect(() => {
        if (data) {
            // If array is empty, set at least one item so the graph will render
            if (data.length === 0) {
                setGraphData({
                    nodes: [{ id: "checkout" }, { id: "checkin" }],
                    links: []
                });
                return;
            }

            // Get nodes by getting all unique sources and destinations
            var nIds = [];
            var nNames = [];
            var links = [];
            

            data.forEach((item) => {
                // Convert readonly object to mutable object
                item = JSON.parse(JSON.stringify(item));

                // Ensure source and destination are defined
                item.source = item.source || "checkin";
                item.sourceName = item.sourceName || "Checkin";

                item.destination = item.destination || "checkout";
                item.destinationName = item.destinationName || "Checkout";



                if (!nIds.includes(item.sourceName)) {
                    nIds.push(item.sourceName);
                    nNames.push(item.sourceName);
                }

                if (!nIds.includes(item.destinationName)) {
                    nIds.push(item.destinationName);
                    nNames.push(item.destinationName);
                }

                // If the source to destination link already exists, increment the value
                var found = false;
                links.forEach((link) => {
                    if (link.source === item.sourceName && link.target === item.destinationName) {
                        link.value += 1;
                        found = true;
                    }


                });

                // Add link without making it cyclic

                // If the link doesn't exist and doesn't create a cycle, add it
                if (!found && item.sourceName !== item.destinationName) {
                    // Check if adding this link would create a cycle
                    const isCyclic = checkCyclic(links, item.destinationName, item.sourceName);

                    if (!isCyclic) {
                        links.push({ source: item.sourceName, target: item.destinationName, value: 1 });
                    } else {
                        // increment the value of the link that would create a cycle
                        links.forEach((link) => {
                            if (link.source === item.destinationName && link.target === item.sourceName) {
                                link.value += 1;
                            }
                        });
                    }
                }
            });

            var n = nIds.map((item, index) => ({ id: nNames[index] }));

            setGraphData({ nodes: n, links: links });
        }
    }, [data]);

    function moveObjectToMiddle(arr, objectId) {
        const indexToMove = arr.findIndex(obj => obj.id === objectId);

        if (indexToMove !== -1) {
            // Remove the object from its current position
            const objectToMove = arr.splice(indexToMove, 1)[0];

            // Calculate the middle index
            const middleIndex = Math.floor(arr.length / 2);

            // Insert the object at the middle index
            arr.splice(middleIndex, 0, objectToMove);
        }

        return arr;
    }

    // Function to check if adding a link would create a cycle
    function checkCyclic(existingLinks, currentSource, currentDestination) {
        const visitedNodes = new Set();
        const stack = [currentSource];

        while (stack.length > 0) {
            const currentNode = stack.pop();
            visitedNodes.add(currentNode);

            const outgoingLinks = existingLinks.filter(link => link.source === currentNode);

            for (const link of outgoingLinks) {
                const nextNode = link.target;

                if (nextNode === currentDestination) {
                    // Adding this link would create a cycle
                    return true;
                }

                if (!visitedNodes.has(nextNode)) {
                    stack.push(nextNode);
                }
            }
        }

        // No cycle found
        return false;
    }

    return (
        <HoverPaper elevation={4} sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "400px",
        }}>
            <Typography variant={"h3"}>Inventory Relations</Typography>
            <Divider/>

            {graphData == null || graphData.nodes.length == 0 ? (
                <Skeleton variant={"rectangular"} height={"100%"} width={"100%"}/>
            ) : (
                <ResponsiveSankey
                    data={graphData}
                    margin={{top: 40, right: 160, bottom: 40, left: 50}}
                    align="justify"
                    colors={{scheme: 'category10'}}
                    nodeOpacity={1}
                    nodeHoverOthersOpacity={0.35}
                    nodeThickness={18}
                    nodeSpacing={24}
                    nodeBorderWidth={0}
                    nodeBorderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                0.8
                            ]
                        ]
                    }}
                    theme={convertMuiThemeToNivoTheme(theme)}
                    nodeBorderRadius={3}
                    linkOpacity={0.5}
                    linkHoverOthersOpacity={0.1}
                    linkContract={3}
                    enableLinkGradient={true}
                    labelPosition="outside"
                    labelOrientation="vertical"
                    labelPadding={16}
                    labelTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1
                            ]
                        ]
                    }}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            translateX: 130,
                            itemWidth: 100,
                            itemHeight: 14,
                            itemDirection: 'right-to-left',
                            itemsSpacing: 2,
                            itemTextColor: '#999',
                            symbolSize: 14,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000'
                                    }
                                }
                            ]
                        }
                    ]}
                />

            )}

        </HoverPaper>
    );
};

export default DeviceMovementSankeyChart;