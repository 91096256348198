import React, { useState } from 'react'
import {Formik} from "formik";
import * as yup from "yup";
import { Backdrop, Box, Button, CircularProgress, MenuItem, TextField } from '@mui/material';


const registerSchema = yup.object().shape({
    email: yup.string().email("Needs to be a valid email").required(),
    role: yup.number().required(),
    isBanned: yup.number().required()
})

//@ts-ignore
function AddUserForm({preSelectedUser}) {

    const initialValues = {
        email: preSelectedUser.email ?? "",
        role: preSelectedUser.role ?? 3,
        isBanned: preSelectedUser.isBanned ? 1 : 0
    }

    const [open, setOpen] = useState(false);
    const setBackdropOpen = () => {
        setOpen(true);
    }

    const handleFormSubmit = (values: any, s: any) => {

        setOpen(true); 
        values.isBanned = values.isBanned == 0 ? false : true;

        fetch(process.env.REACT_APP_BASE_URL + '/org/users', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include"
            }).then(async function(response) {
                //TODO: Set domain and user perms
                    setOpen(false);    
                    
                }).catch((reason) => {
                    setOpen(false);
                })
    
    
    }

  return (
    <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={registerSchema}
        isInitialValid={preSelectedUser.email != null}
    >
        {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
            isValid,
        }) => (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                
            }}>
                <form onSubmit={handleSubmit}>
                    <TextField 
                        label="Email"
                        color="secondary"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        name="email"
                        type="email"
                        error={Boolean(touched.email) && Boolean(errors.email)}
                        fullWidth
                        sx={{mt: '1rem'}}
                    />

                    <TextField 
                        disabled={values.role == 0}
                        label="Role"
                        color="secondary"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.role}
                        name="role"
                        select
                        fullWidth
                        error={Boolean(touched.role) && Boolean(errors.role)}
                        sx={{mt: '1rem'}}
                    >
                        <MenuItem value={0} disabled={true}>Creator</MenuItem>
                        <MenuItem value={1}>Admin</MenuItem>
                        <MenuItem value={3}>Student</MenuItem>
                        <MenuItem value={4}>None (Don't allow login)</MenuItem>
                    </TextField>


                    <TextField 
                        disabled={values.role == 0}
                        label="Is Banned?"
                        color="secondary"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.isBanned}
                        name="isBanned"
                        select
                        fullWidth
                        error={Boolean(touched.isBanned) && Boolean(errors.isBanned)}
                        sx={{mt: '1rem'}}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>

                    <Button sx={{mt:'1rem'}}type="submit" variant={"contained"} color={"secondary"} disabled={!isValid || preSelectedUser.role == 0}>Submit</Button>
                </form>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        )}


    </Formik>
  )
}

export default AddUserForm