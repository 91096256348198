import {Box, Button, Chip, Dialog, DialogContent, Divider, Typography} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2';
import Loading from 'components/Loading';
import DataField from 'components/data/DataField';
import React, { useEffect, useState } from 'react'


interface AuditCard {
    open,
    setOpen
}
const createCsvFromString = (inputString): any => {
    const sections = inputString.split('\n\n');
    const csvContent: string[] = [];

    for (const section of sections) {
        const lines = section.split('\n').map(line => line.replace(/^\s*\|\s*|\s*\|\s*$/g, '').trim());
        if (lines.length < 3) {
            // Include text outside of the table as a single-row CSV
            csvContent.push(section.trim().replace(/\n/g, ','));
        } else {
            // Process table sections dynamically
            const headers = lines[1].split('|').map(header => header.trim());
            const data = lines.slice(3, -1).map(line => line.split('|').map(item => item.trim()));

            const sectionCsv = [headers.join(',')];
            sectionCsv.push(...data.map(row => row.join(',')));
            sectionCsv.push(''); // Add an empty line between sections
            csvContent.push(sectionCsv.join('\n'));
        }
    }

    return csvContent
};
const AuditData = ({id, load}) => {
    const [auditData, setAuditData] = useState(null);
    useEffect(() => {
        const loadData = async () => {
            var response = await fetch(process.env.REACT_APP_BASE_URL + '/org/admin/audits/' + id, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include",
            });

            if(response.status == 200) {
                var jsonData = await  response.json()
                setAuditData(jsonData);
            }
        }
        if(load) {
            loadData();
        } else {
            setAuditData(null)
        }
    }, [load])

    enum AuditType {
        TicketAdded,
        TicketUpdated,
        TicketDeleted,
      
        TicketStatusChanged,
      
        TicketMessageAdded,
        TicketMessageDeleted,
        TicketMessageUpdated,
      
        TicketPictureAdded,
        TicketPictureUpdated,
        TicketPictureRemoved,
      
        StudentsUpdated,
        StudentsAdded,
      
        ReturnDocumentUpdated,
        ReturnDocumentAdded,
        ReturnDocumentRemoved,
      
        ReturnDocumentStatusChanged,
      
        ServerError,
        ServerWarning,
        ServerInfo,
        ServerDebug,
      
        StudentLink,
        StudentLinkFailed,
      }
      
      function getAuditTypeString(enumValue: number) {
        const enumKeys = Object.keys(AuditType).filter(
          (key) => typeof AuditType[key] === "number"
        );
      
        for (const key of enumKeys) {
          if (AuditType[key] === enumValue) {
            return <Chip color={enumValue == AuditType.StudentLinkFailed ? "error" : "success"} size="small" variant="outlined" label={key.replace(/([A-Z])/g, " $1").trim()}/>;
          }
        }
      
        return "";
      }

      const downloadAsCSV = () => {
        const csvContent = createCsvFromString(auditData.message);
        const csv = csvContent.join('\n');
        const blob = new Blob([csv], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `audit-${id}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

    return (
        <Box>
            {auditData == null ? (
                <Loading/>
            ) : (
                <Box>
                    <Typography variant="h3">Audit #{id}</Typography>

                    <Grid2 container spacing={2}>
                        <Grid2 xs={12} md={6}>
                            <DataField title="User" compact data={auditData.user} width={"100%"}/>
                        </Grid2>
                        <Grid2 xs={12} md={6}>
                            <DataField title="Type" compact data={getAuditTypeString(auditData.type)} width={"100%"}/>
                        </Grid2>
                        <Grid2 xs={12}>
                            <Button color={"success"} variant={"contained"} onClick={downloadAsCSV}>Download as CSV</Button>
                        </Grid2>
                    </Grid2>

                    <Divider sx={{m: '1rem 0 2rem 0'}}/>
                    <Typography variant="body1" sx={{ fontFamily: 'monospace', whiteSpace: 'pre' }}>
                        {auditData.message}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}

const AuditCard = ({open, setOpen, id}) => {
  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogContent>
            <AuditData id={id} load={open} />
        </DialogContent>
    </Dialog>
  )
}

export default AuditCard