import React from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    Divider,
    IconButton,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import BatchCheckinDevicesDialog from "./BatchCheckinDevicesDialog";
import {useParams} from "react-router-dom";
import {Delete, QrCodeScanner, SearchRounded} from "@mui/icons-material";
import SnackbarHandler from "../../../components/SnackbarHandler";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../components/CustomNoRowsOverlay";
import DeviceAutocomplete from "../../../components/Autocomplete/DeviceAutocomplete";
import HandheldQRScanner from "../../../components/HandheldQRScanner";

interface CheckinProps {
    onAdded: (device) => void;
}




const CheckinSearch: React.FC<CheckinProps> = ({onAdded}) => {
    return (
        <>
            <Typography variant={"h5"}>Search for your Device</Typography>
            <Typography variant={"subtitle1"}>
                Note: Device does not have to exist to add it. Press enter to add the device.
            </Typography>

            <DeviceAutocomplete onValueChange={(d) => onAdded(d?.serialNumber ?? d)}
                                label={"Add Device"}
                                value={""}
                                freeSolo/>
        </>
    )
}

const CheckinScan: React.FC<CheckinProps> = ({onAdded}) => {
    return (
        <>
            <Typography variant={"h5"}>Scan</Typography>
            <Typography variant={"subtitle1"}>
                Scan the barcode on the device to add it.
                Make sure it is the serial number
            </Typography>

            <HandheldQRScanner onValidInput={(d) => onAdded(d?.serialNumber ?? d)} onLoading={() => {
            }} onInvalidInput={() => {
            }} freeSolo displayInfo/>
        </>
    )

}


const checkinModes = [
    {
        name: "Search",
        description: "Search for devices by SKU",
        icon: <SearchRounded/>,
    },
    {
        name: "Scan",
        description: "Scan devices using a barcode scanner",
        icon: <QrCodeScanner/>,
    }
]


interface BatchMoveDevicesDialogProps {
    open: boolean;
    setOpen: (boolean) => void;
    onClose: () => void;
}


const BatchMoveDevicesDialog: React.FC<BatchMoveDevicesDialogProps> = ({open, setOpen, onClose}) => {
    const {id} = useParams();
    const [snackbarData, setSnackbarData] = React.useState({open: false, message: "", severity: "success"})
    const [addedDevices, setAddedDevices] = React.useState([]);
    const [mode, setMode] = React.useState(checkinModes[0])

    const colDefs: GridColDef[] = [
        {
            field: 'sku',
            headerName: 'Device',
            flex: 1,
            editable: false,
        },
        {
            field: 'sourceName',
            headerName: 'Source',
            flex: 1,
            editable: false,
        },
        {
            field: 'destinationName',
            headerName: 'Destination',
            flex: 1,
            editable: false,
        },
        {
            field: 'author',
            headerName: 'Author',
            flex: 1,
            editable: false,
        },
        {
            field: 'time',
            headerName: 'Time',
            flex: 1,
            editable: false,
            type: "date",
            valueGetter: (params) => {
                // Convert the UTC Timestamp to a date string in the local timezone
                const date = new Date(params.row.time);
                return date;
            }
        },
    ]


    const onDeviceAdded = async (device) => {
        setSnackbarData({...snackbarData, open: false})
        // Make sure the device is not already in the list
        if (addedDevices.includes(device)) {
            return;
        }

        // Make request to add device to the list
        var response = await fetch(process.env.REACT_APP_BASE_URL + `/org/inventory/devices/${device}/move?location=${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        });

        // If the request was successful, add the device to the list
        if (response.status === 200) {
            //Display success
            setSnackbarData({open: true, message: device + " moved successfully", severity: "success"})
            setAddedDevices([...addedDevices, await response.json()])
        } else {
            // Display error
            setSnackbarData({open: true, message: "Error moving " + device + ". Make sure device is already checked in", severity: "error"})
        }

    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false)
                onClose()
            }}
            fullWidth
            maxWidth={"md"}
        >
            <DialogContent>
                <SnackbarHandler open={snackbarData.open} onClose={() =>
                    setSnackbarData({...snackbarData, open: false})
                } message={snackbarData.message} severity={snackbarData.severity}/>

                <Typography variant={"h4"}>Batch Move</Typography>
                <Typography variant={"subtitle1"}>
                    Note: Devices must exist and be in checked in to be moved.
                </Typography>
                <Divider/>

                <ToggleButtonGroup
                    value={mode}
                    exclusive
                    onChange={(e, value) => setMode(value)}
                    sx={{m: '1rem 0'}}
                >
                    {checkinModes.map((mode) => (
                        <ToggleButton value={mode}>
                            {mode.icon}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>

                {mode?.name === "Search" && (
                    <CheckinSearch onAdded={onDeviceAdded}/>
                )}

                {mode?.name === "Scan" && (
                    <CheckinScan onAdded={onDeviceAdded}/>
                )}


                <Divider sx={{mt: '1rem'}}/>

                <DataGrid
                    sx={{minHeight: '300px', mt: '1rem'}}
                    getRowId={(row) => row.sku}
                    columns={colDefs}
                    rows={addedDevices}
                    slots={{noRowsOverlay: CustomNoRowsOverlay}}
                />

            </DialogContent>
        </Dialog>
    );
};

interface BatchMoveDevicesDialogWrapperProps {
    onClose: () => void;
}
export const BatchMoveDevicesDialogWrapper:React.FC<BatchMoveDevicesDialogWrapperProps> = ({onClose}) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Button variant={"outlined"} color={"warning"} onClick={() => setOpen(true)}>Batch Move</Button>
            <BatchMoveDevicesDialog onClose={onClose} open={open} setOpen={setOpen}/>
        </>
    )
}


export default BatchMoveDevicesDialog;