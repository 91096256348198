import React, {useEffect} from 'react';
import {AppBar, Box, Button, Link, Toolbar, Typography, useTheme} from '@mui/material';
import DocsSidebar from './DocsSidebar';
import {Outlet} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setForcedMode} from '../state';
import FlexBetween from '../components/FlexBetween';
import {LazyNavigation} from '../state/api';

function Navbar() {
    const theme = useTheme();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setForcedMode('light'));
    }, []);

    return (
        <AppBar sx={{position: 'fixed', width: '100%', zIndex: (theme) => theme.zIndex.drawer + 1}}>
            <Toolbar>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                        width: '100%',
                    }}
                >
                    <Box color={theme.palette.secondary.main} display="flex" alignItems="center" gap="0.5rem">
                        <Link color={"secondary"} href={"/"} underline="none">
                            <Typography variant="h2" fontWeight="bold" >
                                CTickets
                            </Typography>
                        </Link>

                    </Box>

                    <FlexBetween>
                        <Button color="secondary" variant="contained" onClick={() => LazyNavigation('/login')}>
                            Login/Register
                        </Button>
                    </FlexBetween>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

const DocsHome = () => {
    return (
        <Box sx={{display: 'flex', height: '100%', width: "100%"}}>
            <DocsSidebar/>

            <Box flexGrow={1}>
                <Navbar/>
                <Box component={"main"} sx={{p: '1rem'}}>
                    <Toolbar/>
                    <Outlet/>
                </Box>
            </Box>
        </Box>
    );
};

export default DocsHome;
