// Import all Material icons you want to use

import React, { useEffect, useState } from 'react';
import { Select, MenuItem, IconButton, Box, Typography, Divider, Chip, TextField, Card, CardContent, Checkbox, FormControlLabel, useTheme, Button, LinearProgress, ListItemButton, ListItemText, List, Skeleton } from '@mui/material';
import * as iconName from "@mui/icons-material"
import Grid2 from '@mui/material/Unstable_Grid2';
import { useGetTicketStatusQuery, useGetTicketStatusesQuery } from 'state/api';

function IconSelector({ selectedIcon, onIconChange }) {
  const handleIconChange = (event) => {
    onIconChange(event.target.value);
  };

  const availableIcons = [
    "PendingOutlined",
    "KeyboardReturnOutlined",
    "HourglassBottomOutlined",
    "CheckOutlined",
    "QuestionMarkOutlined",
    "AddShoppingCartOutlined",
    "AssignmentOutlined",
    "Build",
    "Report",
    "PriorityHigh",
    "FeedbackOutlined",
    "TimerOutlined"]

  return (
    <Select value={selectedIcon} onChange={handleIconChange} fullWidth>
      {availableIcons.map((icon) => (
        <MenuItem key={icon} value={icon}>
          <IconButton>
            {React.createElement(iconName[icon])}
          </IconButton>
        </MenuItem>
      ))}
    </Select>
  );
}

interface TicketStatusEditor {
  id: string;
  onUpdate: () => void;
}
const TicketStatusEditor: React.FC<TicketStatusEditor> = ({ id, onUpdate }) => {

  const { data, isLoading, error, refetch } = useGetTicketStatusQuery(id);
  const [currentStatus, setCurrentStatus] = useState(null);

  useEffect(() => {
    refetch()
  }, [id])



  useEffect(() => {
    if (!isLoading && data != null) {
      setCurrentStatus({ ...data })
    } else {
      setCurrentStatus(null)
    }
  }, [isLoading, data])


  const UpdateStatus = async (status) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/tickets/status/' + status.id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: "include",
      body: JSON.stringify(status)
    })
    setCurrentStatus({ ...status })
    onUpdate();
    refetch();
  }

  if (isLoading || currentStatus == null) {
    return (
      <Skeleton variant="rectangular" height={600} />
    )
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h3">Icon Preview</Typography>

        <Divider sx={{ m: '1rem 0 2rem 0' }} />

        {/**@ts-ignore */}
        <Chip icon={React.createElement(iconName[currentStatus.iconUrl])} size="large" label={currentStatus.title} variant="outlined" color={currentStatus.color} />

        <Divider sx={{ m: '2rem 0 1rem 0' }} />

        <Typography variant="h3">Icon Editor: </Typography>

        <Divider sx={{ m: '0rem 0 2rem 0' }} />

        <Grid2 container spacing={1} rowSpacing={5}>
          <Grid2 xs={12}>
            <IconSelector onIconChange={(i) => {
              const updateData = { ...currentStatus, iconUrl: i };
              UpdateStatus(updateData);
            }} selectedIcon={currentStatus.iconUrl} />
          </Grid2>
          <Grid2 xs={12}>
            <TextField
              label="Title..."
              value={currentStatus.title}
              onChange={(v) => {
                const updateData = { ...currentStatus, title: v.target.value };
                setCurrentStatus({ ...updateData })
              }}
              onBlur={(v) => {
                const updateData = { ...currentStatus, title: v.target.value };
                UpdateStatus(updateData);
              }}
              color='secondary'
              fullWidth
            />
          </Grid2>

          <Grid2 xs={12}>
            <TextField
              label="Color"
              value={currentStatus.color}
              onChange={(v) => {
                const updateData = { ...currentStatus, color: v.target.value };
                UpdateStatus(updateData);
              }}
              color='secondary'
              select
              fullWidth
            >
              <MenuItem value={"success"}>Green</MenuItem>
              <MenuItem value={"warning"}>Yellow</MenuItem>
              <MenuItem value={"error"}>Red</MenuItem>
              <MenuItem value={"default"}>Grey</MenuItem>
              <MenuItem value={"info"}>Blue</MenuItem>
            </TextField>
          </Grid2>
          <Grid2 xs={12}>
            <FormControlLabel control={<Checkbox checked={currentStatus.consideredComplete} value={currentStatus.consideredComplete} onChange={(e, c) => {
              const updateData = { ...currentStatus, consideredComplete: c };
              UpdateStatus(updateData);
            }} color='secondary' />} label="Consider ticket completed if this is the status" />
          </Grid2>
          <Grid2 xs={12}>
            <FormControlLabel control={<Checkbox checked={currentStatus.isDefaultStatus} value={currentStatus.isDefaultStatus} onChange={(e, c) => {
              const updateData = { ...currentStatus, isDefaultStatus: c };
              UpdateStatus(updateData);
            }} color='secondary' />} label="Set this status to be the Default Status when creating a ticket?" />
          </Grid2>
        </Grid2>
      </CardContent>


    </Card>
  )
}

const TicketStatusSettings = () => {
  const { data, isLoading, error, refetch } = useGetTicketStatusesQuery("");
  const theme = useTheme();
  const [currentStatus, setCurrentStatus] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(-1)

  useEffect(() => {
    refetch()
  }, [])

  const AddStatus = async () => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/tickets/status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: "include",
      body: JSON.stringify({
        id: "",
        title: "New Status",
        color: "success",
        consideredComplete: false,
        iconUrl: "PendingOutlined",
        isDefaultStatus: false
      })
    })

    refetch();
  };

  const DeleteStatus = async (id) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + `/org/tickets/status/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: "include",
    })

    refetch();
  };



  return (
    <Box sx={{ mt: '1rem' }}>
      <Typography variant="h3">Ticket Status Settings</Typography>
      <Typography variant='subtitle2'>Customize your ticket statuses</Typography>
      <Divider sx={{ mt: '1rem', mb: '2rem' }} />
      {(isLoading || data == null) && (
        <LinearProgress />
      )}
      <Grid2 container spacing={5}>
        <Grid2 xs={12} md={3}>
          <Card>
            <CardContent>
              <List>
                {data == null ? (
                  <>
                    <Skeleton variant="rectangular" height={50} sx={{ m: '1rem 0' }} />
                    <Skeleton variant="rectangular" height={50} sx={{ m: '1rem 0' }} />
                    <Skeleton variant="rectangular" height={50} sx={{ m: '1rem 0' }} />
                  </>
                ) : (
                  <>
                    {data.map((p, i) => (
                      <ListItemButton key={p.id} onClick={() => {
                        setCurrentIndex(i)
                        setCurrentStatus(p.id)
                        console.log(p)
                      }} sx={{
                        //@ts-ignore
                        backgroundColor: currentIndex === i ? theme.palette.secondary[300] : "transparent",

                        //@ts-ignore
                        color: currentIndex === i ? theme.palette.primary[600] : theme.palette.secondary[100],

                        m: '.4rem 0'
                      }}>
                        <ListItemText primary={p.title} />
                        <IconButton onClick={() => DeleteStatus(p.id)} size="small">
                          <iconName.Delete />
                        </IconButton>
                      </ListItemButton>
                    ))}
                  </>
                )}
              </List>

              <Button color="secondary" variant="outlined" fullWidth onClick={() => AddStatus()}>Add Status</Button>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 xs={12} md={9}>
          {currentStatus != null && (
            <TicketStatusEditor id={currentStatus} onUpdate={() => { refetch() }} />
          )}
        </Grid2>
      </Grid2>
    </Box>
  )
}

export default TicketStatusSettings