import { Box, Typography, Divider, useTheme, IconButton, SpeedDial, SpeedDialAction, SpeedDialIcon, TextField, MenuItem, ButtonGroup, Stack, Slider, Icon, ToggleButtonGroup, ToggleButton, Checkbox, FormControlLabel, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Toolbar, AppBar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useGetFormQuery, useGetLayoutQuery } from 'state/api'
import { SavableField } from '../TicketFormSettings';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import LayoutItems from './LayoutItems';
import { GridCell } from '@mui/x-data-grid';
import DataField from 'components/data/DataField';
import TwoSide from 'components/data/TwoSide';
import { Add, Close, Delete, HorizontalRule, Preview, SafetyDivider, Title, ViewStream, WidthFull, WidthNormal } from '@mui/icons-material';
import DataEditable from 'components/data/DataEditable';
import CustomTicketLayout from 'components/CustomTicketLayout';
import Loading from 'components/Loading';
import DataOutlined from 'components/data/DataOutlined';

interface GridItem {
    name: string;
    index: number;
    type: "title" | 'divider' | 'field'
    uid: number;
    editable?: boolean;
    xs?: number;
}

function TicketLayoutSettings() {
    const [formItems, setFormItems] = useState([])
    const [gridItems, setGridItems] = React.useState<GridItem[]>([]);
    const [headerItems, setHeaderItems] = React.useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const {data: layout, isLoading: layoutLoading} = useGetLayoutQuery("");
    const theme = useTheme();
    const {data, isLoading} = useGetFormQuery("");

    useEffect(() => {
        if(!isLoading) {
            console.log(data)
            var fields = data.fields as SavableField[];

            var fieldNames = fields.map((field) => {
                return field.label;
            })

            setFormItems(fieldNames)
        }
    }, [data])

    

    useEffect(() => {

        if(!layoutLoading && layout != null) {
            var items = layout.layout.filter((l) => l.type != "header").map((l) => ({...l, uid: Math.random()}))
            var headerItems= layout.layout.filter((l) => l.type == 'header' ).map((i) => ({...i, uid: Math.random()}))
            setGridItems(items)
            setHeaderItems(headerItems)
        } else {
            setGridItems([])
        }
        
    }, [layout])

    useEffect(() => {
        if(gridItems.length == 0) return;
        upload();
    }, [gridItems, headerItems])

    const handleClose = () => {
        setDialogOpen(false)
    }

    const addField = () => {
        gridItems.push({
            name: formItems[0],
            xs: 6,
            index: gridItems.length + 1,
            type: 'field',
            uid: Math.random(),
            editable: true
        })

        setGridItems([...gridItems])
    }

    const addTitle = () => {
        gridItems.push({
            name: "Title",
            xs: 12,
            uid: Math.random(),
            index: gridItems.length + 1,
            type: 'title'
        })

        setGridItems([...gridItems])
    }

    const addDivider = () => {
        gridItems.push({
            name: "divider",
            xs: 12,
            index: gridItems.length + 1,
            type: 'divider',
            uid: Math.random(),
        })

        setGridItems([...gridItems])
    }

    const upload = () => {
        if(data == null) return;
        var fields = data.fields;
        var items = gridItems.map((item) => {
            var field = fields.find((i) => i.label == item.name);
            if(field == null) {
                console.log("Field not found for " + item.name)
                return item;
            }
            return {...item, getter: field.getter}
        })

        items.push(...headerItems)
        console.log(items)
        fetch(process.env.REACT_APP_BASE_URL + "/org/tickets/layout", {
            method: "PATCH",
            body: JSON.stringify(items),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include"
            }).then(async function(response) {
            }).catch( (reason) => {
            })
    }

    const addHeaderItem = () => {
        headerItems.push({
            name: formItems[0],
            xs: 12,
            index: headerItems.length + 1,
            type: 'header',
            uid: Math.random(),
        })
        setHeaderItems([...headerItems])
    }
    if(layoutLoading) return (<Loading/>)
    return (
        <Box sx={{mt: '1rem'}}>
            <Typography variant="h3">Ticket Layout Settings</Typography>
            <Typography variant='subtitle2'>Customize how you view your tickets</Typography>
            <Divider sx={{mt: '1rem'}}/>
            <Grid2 container spacing={2}>

                <Grid2 xs>
                    <Box sx={{
                      mt: '1rem',
                      //@ts-ignore
                      backgroundColor:theme.palette.background.alt,
                      borderRadius:"0.55rem",
                      padding: '1rem',
                      mb: '1rem',
                      height: '100%'
                    }}
                    >
                        <TwoSide>
                            <Box>
                                <Typography variant="h1">Ticket #0000000000</Typography>
                                <Typography variant='subtitle2'>Author: You</Typography>
                            </Box>
                            <Box>
                                <Typography>Created At: {new Date().toDateString()}</Typography>
                                <Typography>Status: Active</Typography>
                                <Typography>Assignees: None</Typography>
                                {headerItems.map((i, index) => 
                                    <Stack sx={{
                                        //@ts-ignore
                                        backgroundColor:theme.palette.background.paper,
                                        borderRadius:"0.55rem",
                                        padding: '.5rem .3rem',
                                        cursor: 'grab',
                                        margin: '1rem 0'
                                    }} key={i.uid} direction="row" alignItems={'center'}>
                                        <TextField
                                            sx={{width: '100%'}}
                                            label="Field"
                                            id='item-field'
                                            select
                                            size="small"
                                            variant="standard"
                                            defaultValue={i.name}
                                            onChange={(e) => {
                                                headerItems[index].name = e.target.value;
                                                setHeaderItems([...headerItems])
                                            }}
                                        >
                                            {formItems.map((i) => <MenuItem key={i} value={i}>{i}</MenuItem>)}
                                        </TextField>
                                        <IconButton onClick={() => {
                                            headerItems.splice(index, 1);
                                            upload()
                                            setHeaderItems([...headerItems])
                                        }} size="small">
                                            <Delete />
                                        </IconButton>
                                    </Stack>
                                )}
                                <Button size="small" onClick={(e) => addHeaderItem()} variant="contained">Add Header Item</Button>
                            </Box>
                        </TwoSide>
                        <Divider sx={{mt: '1rem'}}/>
                        <Grid2 container spacing={1}>
                            {gridItems.map((item, index) => (
                                <>
                                    {item.type == 'field' && (
                                        <Grid2 key={item.uid} xs={item.xs}>
                                            {/**Be able to drop it here */}
                                            <Box sx={{
                                                mt: '1rem',
                                                //@ts-ignore
                                                backgroundColor:theme.palette.background.paper,
                                                borderRadius:"0.55rem",
                                                padding: '1rem',
                                                mb: '1rem',
                                                cursor: 'grab'
                                            }}>
                                                <Stack spacing={{ xs: 2}} divider={<Divider orientation="vertical" flexItem />} direction="row">
                                                    <TextField
                                                        sx={{width: '100%'}}
                                                        label="Field"
                                                        id='item-field'
                                                        select
                                                        defaultValue={item.name}
                                                        onChange={(e) => {
                                                            gridItems[index].name = e.target.value;
                                                            setGridItems([...gridItems])
                                                        }}
                                                    >
                                                        {formItems.map((i) => <MenuItem key={i} value={i}>{i}</MenuItem>)}
                                                    </TextField>
                                                    <Box sx={{width: '100%', ml: '1rem'}}>
                                                        <Typography variant="h6">Actions</Typography>
                                                        <Stack direction="row">
                                                            <FormControlLabel control={<Checkbox checked={item.xs == 12} onChange={(v, check) => {
                                                                gridItems[index].xs = check ? 12 : 6;
                                                                setGridItems([...gridItems])
                                                            }} color='secondary'/>} label="Full Width?" />
                                                            <FormControlLabel control={<Checkbox checked={item.editable} onChange={(v, check) => {
                                                                gridItems[index].editable = check;
                                                                setGridItems([...gridItems])
                                                            }} color='secondary'/>} label="Editable?" />
                                                            <IconButton onClick={() => {
                                                                setGridItems([...gridItems.filter((i, ind) => ind != index)])
                                                            }} color="warning">
                                                                <Delete/>
                                                            </IconButton>
                                                        </Stack>
                                                    </Box>
                                                </Stack>
        
                                            </Box>            
                                        </Grid2>
                                    )}

                                    {item.type == 'title' && (
                                        <Grid2 key={item.uid} xs={12}>
                                            <Box sx={{width: '100%'}}>
                                                <DataEditable width="100%" variant='h2' data={item.name} callback={function (value: string): void {
                                                    gridItems[index].name = value
                                                    setGridItems([...gridItems])
                                                } } onDelete={() => {
                                                    gridItems.splice(index, 1);
                                                    setGridItems([...gridItems])
                                                }} ></DataEditable>

                                            </Box>
                                        </Grid2>
                                    )}

                                    {item.type == 'divider' && (
                                        <Grid2 key={item.uid} xs={12}>
                                            <Divider/>
                                            <IconButton onClick={() => {gridItems.splice(index, 1); setGridItems([...gridItems])}} color="warning">
                                                <Delete />
                                            </IconButton>
                                        </Grid2>
                                    )}
                                </>
                            ))}
                        </Grid2>
                        <Box>
                                <SpeedDial ariaLabel={'SpeedDial'} color="secondary" icon={<SpeedDialIcon />} direction='right'>
                                    <SpeedDialAction key={123} onClick={addField} icon={<ViewStream/>} tooltipTitle={"Add Field"}/>
                                    <SpeedDialAction key={123} onClick={addTitle} icon={<Title/>} tooltipTitle={"Add Title"}/>
                                    <SpeedDialAction key={123} onClick={addDivider} icon={<HorizontalRule/>} tooltipTitle={"Add Divider"}/>
                                    <SpeedDialAction key={123} onClick={() => setDialogOpen(true)} icon={<Preview/>} tooltipTitle={"Preview"}/>
                                </SpeedDial>
                        </Box>
                    </Box>
                </Grid2>
            </Grid2>

            <Dialog
              open={dialogOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullScreen
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    
                    <CustomTicketLayout refetch={() => {}} layout={
                        //@ts-ignore
                        gridItems.map((i) =>({...i, value: "Bla Bla Bla"}) )} 
                        ticketInformation={
                        {id: "00000000000", author:"You", status:"", createdAt:"Today", studentName: "Unknown"}} />
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default TicketLayoutSettings