import { SearchOutlined } from '@mui/icons-material'
import { Box, Dialog, DialogContent, IconButton } from '@mui/material'
import QueryAllAutocomplete from 'components/Autocomplete/QueryAllAutocomplete';
import Search from 'components/Search'
import React, { useState } from 'react'

function DialogSearch() {
  const [open, setOpen] = useState(false);

  const GoTo = (option) => {
    if(typeof option === "string" && isUrl(option)) {
      document.location.href = option
    }

    lazyNavigation(option.link);

  }

  const isUrl = (data: string) => {
    return data.split('/').length > 0
  }


  const lazyNavigation = (route) => {
    const host = document.location.origin;
    document.location.href = host + route
  }  
  return (
    <Box>

      <IconButton onClick={() => setOpen(true)}>
        <SearchOutlined/>
      </IconButton>

      <Dialog
        maxWidth="lg"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogContent sx={{width: '80vw'}}>
          <QueryAllAutocomplete 
            label="Search..."
            fullWidth
            onValueChange={(val) => {
              GoTo(val)
            }}
            value=""
            freeSolo
          />
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default DialogSearch