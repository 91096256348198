import React, { useEffect, useState } from 'react'
import { useGetLayoutQuery } from 'state/api';
import Loading from './Loading';
import { isAllOf } from '@reduxjs/toolkit';
import CustomTicketLayout from './CustomTicketLayout';

interface CustomTicket {
    ticketInformation: TicketInformation;
    data: object;
    printMode?: boolean;
    onFieldUpdate?: (field: any) => void;
    onStudentUpdate?: (student: any, fields: any) => void;
    isNonMedium?: boolean;
    refetch: () => void;
}

interface TicketInformation {
    id: string;
    author: string;
    status: string;
    createdAt: string;
    studentName: string
}

interface GridItem {
    name: string;
    value: string;
    index: number;
    type: "title" | 'divider' | 'field'
    uid: number;
    editable?: boolean;
    xs?: number;
}
const CustomTicket: React.FC<CustomTicket> = ({ticketInformation, data, printMode, onFieldUpdate, refetch, isNonMedium, onStudentUpdate}) => {
    const {data: layout, isLoading, error} = useGetLayoutQuery("");
    const [gridItems, setGridItems] = useState([]);
    useEffect(() => {
        if(!isLoading) {
            //Map both the layout and data together
            var items = layout.layout.map((l) => {
                return {
                    ...l, 
                    value: data[l.name]
                }
            })

            setGridItems([...items])
        }
    }, [layout])


    if(isLoading) return <Loading/>
  return (
    <CustomTicketLayout onFieldUpdated={onFieldUpdate} onStudentUpdate={onStudentUpdate} refetch={refetch}
    //@ts-ignore
    ticketInformation={ticketInformation} layout={gridItems} printMode={printMode}/>
  )
}

export default CustomTicket