//@ts-nocheck
import { Box, Typography, useTheme } from '@mui/material'
import Loading from 'components/Loading';
import NotFound from 'components/NotFound';
import StripeCheckout from 'components/stripe/StripeCheckout'
import React from 'react'
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { useGetPaymentSessionQuery } from 'state/api';

function PaymentPage() {
    const theme = useTheme();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
  
    // Access the value of the "session" parameter
    const session = searchParams.get('session');
    console.log(session)
    const {data, isLoading, error} = useGetPaymentSessionQuery(session);

    if(isLoading) {
        return (<Loading/>)
    }

    if(error) {
        return (<NotFound/>)
    }
  return (
    <Box>

        <Box width="100%" backgroundColor={theme.palette.primary.main[500]} p="1rem 6%" textAlign="center">
            <Typography 
            variant="h4" 
            fontWeight="bold" 
            sx={{color: theme.palette.primary.contrastText}}
            >
            CTickets
            </Typography>
        </Box>
        <Box sx={{
            "display":"flex",
            "flexDirection":"column",
            "justifyContent":"flex-start",
            "p":"1.25rem 1rem",
            "flex":"1 1 100%",
            //@ts-ignore
            "backgroundColor":theme.palette.background.alt,
            "borderRadius":"0.55rem",
            "width": "auto",
            "height": "100vh"
        }}>

            <StripeCheckout/>

            <Typography variant='subtitle1' sx={{marginTop: '1rem', textAlign: 'center'}}>Please note: This product is currently in the beta phase. Please contact us for a beta access token to recieve this for free if you already havent.</Typography>
        </Box>
    </Box>
  )
}

export default PaymentPage