
import {
    Backdrop,
    Box,
    Breadcrumbs,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    InputAdornment,
    Link,
    MenuItem,
    Paper,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material'
import { useGoogleLogin } from '@react-oauth/google';
import Error from 'components/Error';
import Header from 'components/Header'
import Loading from 'components/Loading';
import ReauthenticateToken from 'components/ReauthenticateToken';
import SnackbarHandler from 'components/SnackbarHandler';
import DataEditable from 'components/data/DataEditable';
import DataField from 'components/data/DataField';
import DataOutlined from 'components/data/DataOutlined';
import EditableList from 'components/data/EditableList';
import TwoSide from 'components/data/TwoSide';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useGetLinkProviderSettingsQuery } from 'state/api';
import ReconfigureProvider from './ReconfigureProvider/ReconfigureProvider';
import OUEditableList from 'components/data/OUEditableList';
import Grid2 from '@mui/material/Unstable_Grid2';

function LinkProviderSettings() {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const {data, error, isLoading, refetch} = useGetLinkProviderSettingsQuery("");
    const [ignoreOus, setIgnoreOus] = useState([])
    const [loanerOus, setLoanerOus] = useState([])
    const [decomissionedOu, setDecomissionedOu] = useState("")
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [open, setDialogOpen] = useState(false);
    const [refreshTime, setRefreshTime] = useState(30);


    //Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarError, setSnackbarError] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const [reconfigureOpen, setReconfigureOpen] = useState(false)


    //@ts-ignore
    const state = useSelector(sel => sel.global);
    useEffect(() => {
        if(!isLoading) {
            setIgnoreOus(data.ignoreOus || [])
            setLoanerOus(data.loanerOus || [])
            setRefreshTime(data.refreshTime)
            setDecomissionedOu(data.decommissionOu);
        }
    }, [data])


    const scopes = [
        "https://www.googleapis.com/auth/admin.directory.device.chromeos.readonly",
        "https://www.googleapis.com/auth/admin.directory.domain.readonly",
        "https://www.googleapis.com/auth/admin.directory.user.readonly",
        "https://www.googleapis.com/auth/admin.directory.orgunit.readonly"
    ]

    const googleRegister = useGoogleLogin({
        scope: scopes.join(' '),
        state: "offline",
        hosted_domain: state.domain,
        //@ts-ignore
        access_type: "offline",
        responseType: "code",
        prompt: "consent",
        flow: 'auth-code',
        onSuccess: (codeResponse) => setNewToken(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
      });
    if(isLoading) return (<Loading/>);

    if(error != null) return (<Error Message={"There was a error getting the settings!"}/>)


    
    const setNewToken = (tokenResponse: any) => {
        setBackdropOpen(true);
        fetch(process.env.REACT_APP_BASE_URL + '/org/provider/data/credentials/update?relink=false', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                code: tokenResponse.code,
                type:"google",
                redirectURI: document.location.origin,
                scope: tokenResponse.scope
            })
            }).then(async (response) => {
                    setBackdropOpen(false);
                    if(response.status == 200) {
                        setSnackbarError(false)
                        setSnackbarMessage("Success! Your token has been updated. It may take a few minutes for the changes to be applied")
                        setSnackbarOpen(true)
                    } else {
                        var res = await response.json()
                        setSnackbarError(true) 
                        setSnackbarMessage("Error! There was a problem setting your token: " + res["error"])
                        setSnackbarOpen(true)
                    }
                    refetch()
                }).catch((reason) => {
                    setSnackbarError(true) 
                    setSnackbarMessage("Error! There was a problem setting your token: " + reason)
                    setSnackbarOpen(true)
                    setBackdropOpen(false);
                })
    }

    const handleUpdate = (body) => {
        setBackdropOpen(true)
        fetch(process.env.REACT_APP_BASE_URL + "/org/provider/settings", {
            method: "PATCH",
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include"
            }).then(async function(response) {
                setBackdropOpen(false);
            }).catch( (reason) => {
                setBackdropOpen(false);
            })
    }

    const restartProvider = () => {
        setBackdropOpen(true);
        fetch(process.env.REACT_APP_BASE_URL + "/org/provider/restart", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include"
            }).then(async function(response) {
                setBackdropOpen(false);
            }).catch( (reason) => {
                setBackdropOpen(false);
            })
    }

    const handleClose = () => {
        setDialogOpen(false);
  
    };  

    const navigate = (link) => {
        const host = document.location.origin;
        document.location.href = host  + link;
    }
    return (
      <Box sx={{
        mt: '1rem'
      }}>
        <SnackbarHandler 
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarError ? "error" : "success" }
            message={snackbarMessage}
        />
        <Typography variant='h3'>Your Link Provider</Typography>
        <Typography variant='subtitle2'>View and customize how we link data</Typography>
        <Divider sx={{mt: '1rem'}}/>

        <Paper elevation={16} sx={{
            mt: '1rem',
            padding: '1rem',
            mb: '1rem'
        }}>
            <Box            
                mt="20px"
                display="flex"
                flexDirection="column"
                flexWrap="nowrap"
                justifyContent="flex-start"
                flex='1'
                
            >
                <TwoSide
                >
                    <Box
                        gridColumn='span 1'
                    >
                        <Typography variant="h6">Link Authority</Typography>
                        <Typography variant="body1">This determines whether to link the Device to a User, or link a User to a Device</Typography>
                    </Box>

                    <Box
                        gridColumn='span 2'
                    >
                        <DataOutlined data={data.authority}/>
                    </Box>
                </TwoSide>

                <Divider/>  

                <TwoSide>
                    <Box
                        gridColumn='span 1'
                    >
                        <Typography variant="h6">Authority Field</Typography>
                        <Typography variant="body1">This tells the provider which field we should look for to find a match</Typography>
                    </Box>

                    <Box
                        gridColumn='span 2'
                    >
                        <DataOutlined data={data.authorityField}/>

                    </Box>
                </TwoSide>

                <Divider/>

                <TwoSide>
                    <Box
                        gridColumn='span 1'
                    >
                        <Typography variant="h6">Reciever Field</Typography>
                        <Typography variant="body1">This tells the provider which field we should attempt to match with the data from the authority field</Typography>
                    </Box>

                    <Box
                        gridColumn='span 2'
                    >
                        <DataOutlined data={data.receiverField}/>

                    </Box>
                </TwoSide>

                <Divider/>

                <TwoSide>
                    <Box>
                        <Typography variant="h6">Additional Options</Typography>
                        <Typography variant="body1">Some additional options to customize your link provider</Typography>
                    </Box>

                    <Box sx={{
                        display:"flex",
                        flexDirection:"column",
                        flexWrap: 'wrap'
                    }}>
                        <TextField
                            id="update-time"
                            label="Update Time"
                            value={refreshTime}
                            helperText="Determines how often to check for updates (in minutes)"
                            sx={{m: '1rem 0'}}
                            color="secondary"
                            variant="standard"
                            onBlur={(e) => {
                                handleUpdate({refreshTime: refreshTime})

                            }}
                            onChange={(e) => {
                                var val = Number(e.target.value);
                                setRefreshTime(val)

                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                            }}
                        >
                        </TextField>

                        <TextField
                            id="mock-data"
                            label="Use Mock Data"
                            value={data.isMockData ? "true" : 'false'}
                            helperText="Determines if we should use mock data or real data"
                            sx={{m: '1rem 0'}}
                            select
                            color="secondary"
                            variant="standard"
                            type="number"
                        >
                            <MenuItem value={"true"} key={"yes"}>Yes</MenuItem>
                            <MenuItem value={"false"} key={"no"}>No</MenuItem>
                        </TextField>

                        <Button variant="outlined" color="warning" sx={{m: "1rem 0"}} onClick={() => restartProvider()}>Restart Link Provider</Button>

                        <Button variant="outlined" color="warning" onClick={() => {navigate("/org/setup")}}>Create New Link Provider</Button>

                        {state.experimentalOptions.includes("ReconfigureProvider") && (
                            <Box sx={{width: '100%'}}>
                                <ReconfigureProvider open={reconfigureOpen} setOpen={setReconfigureOpen} />
                                <Button fullWidth sx={{mt: '1rem'}} variant="outlined" color="error" onClick={() => {setReconfigureOpen(true)}}>Re-Configure Provider</Button>
                            </Box>
                        )}
                    </Box>
                </TwoSide>

            </Box>
        </Paper>
        <Paper elevation={16} sx={{
            mt: '1rem',

            padding: '1rem',
            mb: '1rem'
        }}

        >
            <Typography variant="h3">Data Provider Settings</Typography>
            <Typography variant="subtitle2">Update your Data Provider.</Typography>
            <Divider/>

            <TwoSide>
                <Box>
                    <Typography variant="h6">Provider Name</Typography>
                    <Typography variant="body1">The name of the data provider</Typography>
                </Box>
                <Box>
                    <DataOutlined data={"Google Provider"}/>
                </Box>
            </TwoSide>
            <Divider/>
            <TwoSide>
                <Box>
                    <Typography variant="h6">Create new Refresh Token</Typography>
                    <Typography variant="body1">Update the refresh token for the data provider.</Typography>
                </Box>
                <Box>
                    <ReauthenticateToken />
                </Box>
            </TwoSide>
            <Divider/>
            <TwoSide>
                <Box>
                    <Typography variant="h6">Decommissioned OU Path</Typography>
                    <Typography variant="body1">
                        The path that chromebooks will go to when decomissioned/deprovisioned. <br/>
                        If expermiental settings are on, users with the permissions can decomission a user/device, which will <br/>
                        move the device into the org unit path and can also deprovision the device if needed.<br/>
                    </Typography>
                </Box>
                <Box>
                    <DataEditable data={decomissionedOu} callback={(v) => {handleUpdate({decommissionOu: v})}}/>
                </Box>
            </TwoSide>
            <Divider/>
            <Grid2 container sx={{mt:'1rem'}} spacing={2}>
                <Grid2 md={6}>
                    <Box>
                        <Typography variant="h6">List of OU's to ignore</Typography>
                        <Typography variant="body1">A list of organizational units to ignore in the linking process. Must start with a / or *</Typography>
                    </Box>
                </Grid2>
                <Grid2 md={6}>
                    <Box sx={{display: 'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'flex-end', maxWidth: "100%"}}>
                        <Box width="80%">
                            <OUEditableList initialItems={ignoreOus} onUpdate={(v) => handleUpdate({ignoreOus: v})} validator={(val) => {return val.includes('/') || val.includes("*")}}/>
                        </Box>
                    </Box>
                </Grid2>
                <Grid2 md={12}>
                    <Divider/>
                </Grid2>
                <Grid2 md={6}>
                    <Box>
                        <Typography variant="h6">Loaner Directory</Typography>
                        <Typography variant="body1">The path of loaner devices OU. Can be empty</Typography>
                    </Box>
                </Grid2>
                <Grid2 md={6}>
                    <Box sx={{display: 'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'flex-end', maxWidth: "100%"}}>
                        <Box sx={{width:"80%"}}>
                            <OUEditableList initialItems={loanerOus} onUpdate={(v) => handleUpdate({loanerOus: v})} validator={(val) => {return val.includes('/') || val.includes("*")}}/>
                        </Box>
                    </Box>

                </Grid2>
            </Grid2>

        </Paper>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
        >
            <Loading/>
        </Backdrop>
      </Box>
    )
}

export default LinkProviderSettings