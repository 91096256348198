import React from 'react';
import {IconButton, Tooltip} from "@mui/material";
import {HelpOutlineOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

const HelpTooltip = () => {
    const {pathname} = window.location;
    //TODO: This page should request to see which page should be displayed, but for now it's hardcoded
    const navTo = () => {
        // Open the docs page in a new tab
        window.open("/docs", "_blank");
    }
    return (
        <Tooltip title={"View Docs for this Page"}>
            <IconButton onClick={() => navTo()}>
                <HelpOutlineOutlined sx={{ fontSize: "25px" }} />
            </IconButton>
        </Tooltip>
    );
};

export default HelpTooltip;