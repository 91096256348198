import { useTheme } from '@emotion/react';
import { Alert, Backdrop, Box, Button, CircularProgress, Divider, FormControlLabel, Snackbar, Switch, TextField, Typography, useMediaQuery } from '@mui/material'
import Header from 'components/Header'
import Device from 'components/data/Device';
import React, { useEffect, useState } from 'react'
import {Formik} from "formik";
import * as yup from "yup";
import { useGetSelfQuery } from 'state/api';
import Loading from 'components/Loading';
import state from 'state';
import { error } from 'console';
import Transition from 'components/Transition';
import { Html5QrcodeScanner } from 'html5-qrcode';
import Html5QrcodePlugin from 'components/Html5QrcodePlugin';




const returnSchema = yup.object().shape({
    returnedDevice: yup.boolean().required("required"),
    returnedCharger: yup.boolean().required("required"),
    returnedCase: yup.boolean().required("required"),
    returnNotes: yup.string().notRequired(),
    inGoodCond: yup.boolean().required("required"),
    author: yup.string().required("required")
})


function CheckIn() {

    interface values {
        returnedDevice: boolean,

        returnedCharger: boolean,
        returnedCase: boolean,
        returnNotes: string,
        inGoodCond: boolean,

        author: string

    }
    //@ts-ignore
    let initialValues: values = {}

    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const [data, setData] = useState('');
    const {data: me, isLoading} = useGetSelfQuery("");
    const [qrReaderOpen, setQrReaderOpen] = useState(false);
    const [device, setDevice] = useState({
        device: null,
        returnDocument: null,
        student: null,
    })

    const [loadingOpen, setIsLoadingOpen] = useState(false);
    const [snackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("Updated Student");
    const [snackbarIsError, setIsSnackbarError] = useState(false);


    const theme = useTheme();
    useEffect(() => {
        if(data == '') return
        //@ts-ignore
        setDevice({});
        fetch(process.env.REACT_APP_BASE_URL + '/org/devices/returned/query?query=' + data, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include"
            }).then(async function(response) {
                    //window.navigator.vibrate(300);
                    var result = await response.json()

                    setDevice(result);
                    if(response.status == 200) {
                        setIsSnackbarError(false);
                        setSnackbarMessage("Device found!");
                        setIsSnackbarOpen(true);
                        initialValues = {
                            //@ts-ignore
                            returnedDevice: device.returnDocument.returnedDevice,
                    
                            //@ts-ignore
                            returnedCharger: device.returnDocument.returnedCharger,
                            //@ts-ignore
                            returnedCase: device.returnDocument.returnedCase,
                            //@ts-ignore
                            returnNotes: device.returnDocument.returnNotes || "",
                            //@ts-ignore
                            inGoodCond: device.returnDocument.inGoodCondition,
                    
                            author: me.user.firstName + " " + me.user.lastName
                        } 
                    } else {

                        setIsSnackbarError(true);
                        setSnackbarMessage("Could not find device: " + data);
                        setIsSnackbarOpen(true);
                    }

                }).catch((reason) => {
                })

    }, [data])

    if(isLoading) {return (<Loading/>)}


    if(device.device != null) {
        initialValues = {
            //@ts-ignore
            returnedDevice: device.returnDocument.returnedDevice,
    
            //@ts-ignore
            returnedCharger: device.returnDocument.returnedCharger,
            //@ts-ignore
            returnedCase: device.returnDocument.returnedCase,
            //@ts-ignore
            returnNotes: device.returnDocument.returnNotes || "",
            //@ts-ignore
            inGoodCond: device.returnDocument.inGoodCondition,
    
            author: me.user.firstName + " " + me.user.lastName
        }
    }

    const handleFormSubmit = (values: any) => {
        setIsLoadingOpen(true);
        let doc = device.returnDocument;
        if(doc == null) return;
        //@ts-ignore
        doc.returnedDevice = values.returnedDevice;
        //@ts-ignore
        doc.returnedCharger = values.returnedCharger;
        //@ts-ignore
        doc.returnedCase = values.returnedCase;
        //@ts-ignore
        doc.returnNotes = values.returnNotes;
        //@ts-ignore
        doc.inGoodCondition = values.inGoodCond;
        //@ts-ignore
        doc.returner = values.author;



        //@ts-ignore
        fetch(process.env.REACT_APP_BASE_URL + `/org/devices/returned/${device.returnDocument.id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(doc),
            credentials: "include"
            }).then(async function(response) {
                    setIsLoadingOpen(false);
                    setSnackbarMessage("Secussfully updated!");
                    setIsSnackbarOpen(true);
                    //@ts-ignore
                    initialValues = {};
                    
                }).catch((reason) => {
                    setSnackbarMessage("There was a error updating the document!")
                    setIsLoadingOpen(false);
                    setIsSnackbarOpen(true);
                })
    }

    return (
      <Box m="1.5rem 2.5rem">
          <Header title={"Check in devices"} subtitle={"Check in chromebooks and keep track of them"}/>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap:'wrap',
                justifyContent: 'flex-start'
            }}>

                <Button sx={{m: '1rem'}} variant="contained" color='secondary' onClick={() => setQrReaderOpen(!qrReaderOpen)}>{qrReaderOpen ? "Close Camera" : "Open Camera"}</Button>

                {qrReaderOpen ? (
                    <Html5QrcodePlugin
                        fps={10}
                        qrbox={250}
                        disableFlip={false}
                        qrCodeSuccessCallback={(text: string, result:string) =>{
                            console.log(text);
                            setData(text);
                            setQrReaderOpen(false);
                        }}
                    />
                ) : (
                <>
                    <Typography> Or </Typography>
                    <TextField
                        sx={{m:'1rem'}}
                        id="outlined-required"
                        label="input"
                        defaultValue="Device"
                        onChange={(e) => setData(e.target.value)}
                        helperText={"Enter device to search"}
                    />
                </>
                )}

                {device.device != null ? (
                    <Box sx={{
                        p:"1.25rem 1rem",
                        flex:"1 1 100%",
                        //@ts-ignore
                        backgroundColor:theme.palette.background.alt,
                        borderRadius:"0.55rem",
                        mb: '5rem'
                    }}>

                        <Device header={"Device for return"} data={device.device}/>

                        <Divider/>

                        <Box>
                            <Typography variant='h3'>Retrurn data</Typography>
                            <Typography variant='h4'>Assigned student: {
                                //@ts-ignore
                                device.student && device.student.name
                            }</Typography>

                            <Box>
                                <Formik
                                
                                    onSubmit={handleFormSubmit}
                                    initialValues={initialValues}
                                    validationSchema={returnSchema}
                                    isInitialValid={true}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                        isValid,

                                    }) => (
                                        <form onSubmit={async (e) =>  { await handleSubmit(e);}}>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                justifyContent: 'space-between'
                                            }}>

                                                <FormControlLabel
                                                  control={
                                                    <Switch color="secondary" checked={values.returnedDevice} onChange={handleChange} name="returnedDevice" />
                                                  }
                                                  label="Returned Device?"
                                                />
                                                <FormControlLabel
                                                  control={
                                                    <Switch color="secondary" checked={values.returnedCase} onChange={handleChange} name="returnedCase" />
                                                  }
                                                  label="Returned Case?"
                                                />
                                                <FormControlLabel
                                                  control={
                                                    <Switch color="secondary" checked={values.returnedCharger} onChange={handleChange} name="returnedCharger" />
                                                  }
                                                  label="Returned Charger?"
                                                />
                                                <FormControlLabel
                                                  control={
                                                    <Switch color="secondary" checked={values.inGoodCond} onChange={handleChange} name="inGoodCond" />
                                                  }
                                                  label="Is in Good Condition?"
                                                />                                                
                                                <TextField 
                                                    label="Notes"
                                                    color="secondary"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.returnNotes}
                                                    name="returnNotes"
                                                    error={Boolean(touched.returnNotes) && Boolean(errors.returnNotes)}
                                                    //@ts-ignore
                                                    helperText={touched.returnNotes && errors.returnNotes}
                                                    multiline
                                                    sx={{m: isNonMediumScreens ? "0" : '1rem 0' }}
                                                />
                                                <TextField 
                                                    label="Author"
                                                    color="secondary"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.author}
                                                    name="author"
                                                    error={Boolean(touched.author) && Boolean(errors.author)}
                                                    //@ts-ignore
                                                    helperText={touched.author && errors.author}
                                                    sx={{m: isNonMediumScreens ? "0" : '1rem 0' }}
                                                />

                                                <Button sx={{m: isNonMediumScreens ? "0" : '1rem 0' }} variant='contained' color="secondary" type='submit'>Submit</Button>
                                            </Box>
                                        </form> 

                                    )}
                                </Formik>
                            </Box>    

                        </Box>
                    </Box>
                ) : (<></>)}


                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loadingOpen}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar anchorOrigin={{horizontal: 'center', vertical: 'bottom'}} TransitionComponent={Transition} open={snackbarOpen} autoHideDuration={6000} onClose={() => setIsSnackbarOpen(false)}>
                    <Alert onClose={() => setIsSnackbarOpen(false)} severity={snackbarIsError ? "error" : "success"} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
      </Box>
    )
}

export default CheckIn