import { Box } from '@mui/material'
import React from 'react'

function TwoSide({children}) {
  return (
    <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        m="1rem 0"
    
    >
        {children}
    </Box>
  )
}

export default TwoSide