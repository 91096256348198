import { Add } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { TextField } from 'formik-material-ui';
import React, { useEffect, useState } from 'react'
import DataEditable from './DataEditable';
import OUPathAutocomplete from 'components/Autocomplete/OUPathAutocomplete';
import Error from 'components/Error';

interface StringListProps {
    initialItems: string[];
    validator?: (input: string) => boolean;
    onUpdate?: (values: string[]) => void;
}
const OUEditableList: React.FC<StringListProps> = ({initialItems, validator, onUpdate}) => {
    const [list, setList] = useState(initialItems);
    const [newItem, setNewItem] = useState('');
    const [snackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    useEffect(() => {
        setList(initialItems)
    }, [initialItems])

    const handleAddItem = () => {
        if(!validator || validator(newItem))
        {
            setList([...list, newItem]);
            setNewItem('');
            if(onUpdate) onUpdate([...list, newItem]);

        } else {
            setSnackbarMessage("The input is not a valid input!");
            setIsSnackbarOpen(true);
        }
    }

    const handleUpdateItem = (value, index) => {
        const newList = [...list];
        newList[index] = value;
        setList(newList);
        if(onUpdate) onUpdate(newList);
    }

    const handleDeleteItem = (index) => {
        const newList = [...list];
        newList.splice(index, 1);
        setList(newList);
        if(onUpdate) onUpdate(newList);

    }

    return (
        <Box sx={{width: "100%"}}>
            {list.map((item, index) => (
                <DataEditable
                    width='100%'
                    key={item}
                    data={item}
                    callback={(value) => handleUpdateItem(value, index)}
                    onDelete={() => handleDeleteItem(index)}
                />
            ))}
            <Box
                display="flex"
                justifyContent={"space-between"}
                alignContent={"center"}
            >
                <OUPathAutocomplete 
                    label='OU Path...'
                    fullWidth
                    onValueChange={(v) => {
                        setNewItem(v);
                    }}
                />
                <IconButton onClick={handleAddItem}>
                    <Add />
                </IconButton>

            </Box>
            {snackbarOpen && <Error Message={snackbarMessage}/>}
        </Box>
    )
}

export default OUEditableList