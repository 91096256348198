import { Box, Dialog, DialogContent, Divider, Skeleton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useGetGenerateLoginCodeQuery } from 'state/api';

interface QrCodeLogin {
    open: boolean;
    setOpen: (mode: boolean) => void;
}
const QrCodeLogin:React.FC<QrCodeLogin> = ({open, setOpen}) => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState("/org/dashboard")
    var {data, isLoading, error, refetch } = useGetGenerateLoginCodeQuery(currentPath)
    const [code, setCode] = useState(null);

    useEffect(() => {
        if(data != null) {
            setCode(data.key);
        }
    }, [data])

    useEffect(() => {
        setCurrentPath(location.pathname);
        console.log(location.pathname)
        refetch()
    }, [location])

    useEffect(() => {
        if(open) {
            setCode(null)
            refetch()
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogContent>
                <Typography variant="h3">Login Using QR Code</Typography>
                <Divider sx={{m: '1rem 0 2rem 0'}}/>
                <Box sx={{display: 'flex', alignContent: "center", flexDirection: 'column'}}>

                    {code != null && (
                        <img src={"https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=" + window.location.protocol + "//" + window.location.host + "/login/qr?code=" + code}/>
                    )}
                </Box>
                            
                {code == null && (
                    <Skeleton/>
                )}
                <Divider sx={{m: '1rem 0'}} />
                <Typography>Note: This QR code will expire in 1 minute after opening</Typography>
            </DialogContent>   
        </Dialog>
    )
}

export default QrCodeLogin