import { Alert, Snackbar } from '@mui/material'
import React, { useState } from 'react'

//@ts-ignore
function Error({Message}) {

    const [open, setIsOpen] = useState(true);
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >

            <Alert onClose={() => setIsOpen(false)} severity="error" sx={{ width: '100%' }}>
                {Message}
            </Alert>

        </Snackbar>
  )
}

export default Error