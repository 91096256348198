import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import Loading from 'components/Loading';
import SnackbarHandler from 'components/SnackbarHandler';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { LazyNavigation, useGetExperimentalScopesQuery } from 'state/api';

interface EnableExperimentalSettings {
    open: boolean;
    setOpen: (boolean) => void;
}

const Authenticate = ({scopes, setOpen}) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarError, setSnackbarError] = useState(false)
    const [backdropOpen, setBackdropOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    
    //@ts-ignore
    const global = useSelector(s => s.global);

    const googleActivateExperimentalSettings = useGoogleLogin({
        scope: scopes,
        state: "offline",
        //@ts-ignore
        access_type: "offline",
        responseType: "code",
        hosted_domain: global.domain,
        prompt: "consent",
        flow: 'auth-code',
        onSuccess: (codeResponse) => uploadCredentials(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    const uploadCredentials = async (tokenResponse) => {
        setBackdropOpen(true)
        var response = await fetch(process.env.REACT_APP_BASE_URL + '/org/provider/data/credentials/update?relink=false&experimental=true', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                code: tokenResponse.code,
                type:"google",
                redirectURI: document.location.origin,
                scope: tokenResponse.scope
            })
        })
        setBackdropOpen(false)
        if(response.status == 200) {
            setSnackbarError(false)
            setSnackbarMessage("Success! The token has been updated and experimental settings have been enabled.")
            setSnackbarOpen(true)
            LazyNavigation("/org/settings/experimental")
        } else {
            setSnackbarError(true)
            setSnackbarMessage("There was a problem setting the token. Please try again later")
            setSnackbarOpen(true)
        }
    }

    return (
        <Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <Loading/>
            </Backdrop>
            <SnackbarHandler open={snackbarOpen} onClose={() => setSnackbarOpen(false)} message={snackbarMessage} severity={snackbarError ? "error" : "success"}/>
            <Button color="secondary" variant="contained" onClick={() => googleActivateExperimentalSettings()}>I Understand</Button>
        </Box>
    )
}
const EnableExperimentalSettings: React.FC<EnableExperimentalSettings> = ({open ,setOpen}) => {

    const {data, isLoading, error} = useGetExperimentalScopesQuery("");

  return (
    <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
    >
        <DialogTitle>
            <Typography variant="h2">Enable Experimental Settings</Typography>
        </DialogTitle>
        <DialogContent>
            <Typography>Warning! These features are experimental and require scopes that are not already required for the base program to work. These scopes are: </Typography>
            <Stack ml="1rem">
                
                <li>Read/Write to devices. Used to update OU and other fields</li>
                <li>Read/Write Org Units. Used to create/update org units to move devices and users into</li>
                <li>Read/Write User. Used to update info for things like creating students or updating assigned device</li>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button sx={{mr: '1rem'}} color="error" variant="contained" onClick={() => setOpen(false)}>Cancel</Button>

            {data == null ? (
                <Box sx={{ m: 1, position: 'relative' }}>
                    <Button disabled>I Understand</Button>
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                </Box>
            ) : (
                <Authenticate scopes={data.scopes} setOpen={setOpen} />
            )}
        </DialogActions>
    </Dialog>
  )
}

export default EnableExperimentalSettings