import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function NotFound() {
    const navigate = useNavigate();
    const lazyNavigation = () => {
      const host = document.location.origin;
      document.location.href = host + "/org/dashboard"
  }   

  return (
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%'}}>
        <Typography variant="h1">Oops! This page doesnt exist yet.</Typography>
        <Typography variant="h6" sx={{m:"1rem 0"}}>Hopefully it will be finished soon. But for now please navigate <Button color="secondary" variant="contained" onClick={lazyNavigation}>here</Button></Typography>
    </Box>

  )
}

export default NotFound