import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Fab,
    IconButton,
    TextField,
    Typography
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Header from 'components/Header';
import Loading from 'components/Loading';
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom'
import {HasMinimumScopeWithArray, LazyNavigation, useGetStudentGroupQuery} from 'state/api';
import GroupMembersTable from './GroupMembersTable';
import {Close, Settings} from '@mui/icons-material';
import {Formik} from "formik";
import * as yup from "yup";
import UserAutocomplete from 'components/Autocomplete/UserAutocomplete';
import SnackbarHandler from 'components/SnackbarHandler';
import RequiresScope from "../../components/RequiresScope";
import HoverPaper from "../../components/HoverPaper";


interface StudentGroupSettingsDialog {
    group: any;
    onChange: () => void;
    setOpen: (open: boolean) => void;
    isOpen: boolean;
}

const StudentGroupSettingsDialog: React.FC<StudentGroupSettingsDialog> = ({group, onChange, setOpen, isOpen}) => {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({open: false, severity: "success", message: ""});
    const [initialValues, setInitialValues] = useState({
        name: "",
        description: "",
        owner: ""
    })

    useEffect(() => {
        setInitialValues({
            name: group.name,
            description: group.description,
            owner: group.owner ?? ""
        })
    }, [group])


    const validationSchema = yup.object({
        name: yup.string().min(5, "Name must be longer than 5 characters.").max(50, "Cannot exceed 50 characters.").required("A Group Name is required."),
        description: yup.string().max(200, "Cannot exceed 200 characters."),
        owner: yup.string(),
    })

    const onFormSubmit = async (values) => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/students/groups/' + group.id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                name: values.name,
                owner: values.owner,
                description: values.description
            })
        })
        if (response.status == 200) {
            setSnackbar({
                open: true,
                message: `Success! Student Group Updated!`,
                severity: 'success'
            })
            onChange()

        } else if (response.status == 404) {
            setSnackbar({
                open: true,
                message: `There was a problem updating this group.`,
                severity: 'error'
            })
        } else {
            setSnackbar({
                open: true,
                message: `There was a problem updating this group.`,
                severity: 'error'
            })
        }

    }
    return (
        <Dialog
            open={isOpen}
            onClose={() => setOpen(false)}
            maxWidth="md"
            fullWidth
        >
            {loading && (<Loading backdrop/>)}
            {/**@ts-ignore */}
            <SnackbarHandler open={snackbar.open}
                             onClose={() => setSnackbar({open: false, severity: "success", message: ""})}
                             severity={snackbar.severity} message={snackbar.message}/>
            <DialogTitle>
                <Typography variant="h2">Edit Group {group.name}</Typography>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close/>
            </IconButton>
            <Divider/>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onFormSubmit}
                >
                    {({
                          values,
                          errors,
                          touched,
                          getFieldHelpers,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                          resetForm,
                          isValid,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="flex !important"
                                gap="30px"
                                m="1rem 0"
                                padding="1rem"
                                borderRadius="1.5rem"
                                flexDirection="column"
                                justifyContent="flex-start"
                            >
                                <TextField
                                    fullWidth
                                    id="name"
                                    name="name"
                                    label="Group Name"
                                    color="secondary"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.name && Boolean(errors.name)}
                                    //@ts-ignore
                                    helperText={touched.name && errors.name}
                                />

                                <TextField
                                    label="Description"
                                    color="secondary"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.description}
                                    name="description"
                                    multiline
                                    rows={4}
                                    error={Boolean(touched.description) && Boolean(errors.description)}
                                    //@ts-ignore
                                    helperText={touched.description && errors.description}
                                />

                                <UserAutocomplete onValueChange={(v) => {
                                    setFieldValue("owner", v.primaryEmail)
                                }}
                                                  label={"Group Owner"}
                                                  value={values.owner}
                                                  freeSolo
                                />
                                <Button color="secondary" variant="contained" type="submit"
                                        disabled={!isValid}>Save</Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}


const StudentGroupOverview = () => {
    const {id} = useParams();
    const [canEdit, setCanEdit] = useState(false)
    const {data, isLoading, refetch, error} = useGetStudentGroupQuery(id)
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    //@ts-ignore
    const global = useSelector(d => d.global)

    useEffect(() => {
        refetch()
    }, [id])

    useEffect(() => {
        if (global.scopes) {
            setCanEdit(HasMinimumScopeWithArray(global.scopes, "write:student:groups"))
        }
    }, [global])


    return (
        <Box m="1.5rem 2.5rem">
            <Header title={"Student Groups"} subtitle={"Assign students to groups for easier tracking"}/>
            <Divider/>
            {data ? (
                <>
                    <HoverPaper elevation={4} sx={{mb: '3rem', padding: '1rem'}}>
                        <Grid2 container spacing={1}>
                            <Grid2 xs={12} md={6}>

                                <Typography variant="h2">{data.name}</Typography>
                                <Typography variant="subtitle1">{data.description}</Typography>
                                <Typography variant="h4" sx={{mt: '1rem'}}>Owner: {data.owner}</Typography>

                            </Grid2>
                        </Grid2>

                        <RequiresScope scope={"read:device:checkin"}>
                            <Button color={"secondary"} variant={"contained"} sx={{mt: '1rem'}} onClick={() => {
                                LazyNavigation(`/org/devices/returned?type=0&group=${data.id}`)
                            }}>View Checkin Data For This Group</Button>
                        </RequiresScope>
                        <Divider sx={{m: '1rem 0 1rem 0'}}/>

                    </HoverPaper>
                    <GroupMembersTable group={data} isEditable/>
                    {canEdit && (
                        <>
                            <Fab size="small" sx={{
                                position: 'absolute',
                                right: 15,
                                top: 150,
                                color: 'grey',
                                zIndex: '1000'
                            }}
                                 onClick={() => {
                                     setEditDialogOpen(true)
                                 }}
                            >
                                <Settings/>
                            </Fab>
                            <StudentGroupSettingsDialog group={data} isOpen={editDialogOpen} setOpen={setEditDialogOpen}
                                                        onChange={() => {
                                                            refetch();
                                                            setEditDialogOpen(false)
                                                        }}/>
                        </>
                    )}
                </>
            ) : (
                <Loading/>
            )}

        </Box>
    )
}

export default StudentGroupOverview