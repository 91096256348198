//@ts-nocheck
import React from "react";
import {Paper, Typography, useTheme} from "@mui/material";
import FlexBetween from "./FlexBetween";
import GlassPaper from "./GlassPaper";
import HoverPaper from "./HoverPaper";


const StatBox = ({title, value, increase, icon, description}) => {
    const theme = useTheme();
    return (
        <HoverPaper elevation={4} sx={{
            gridColumn: "span 2",
            gridRow: "span 1",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "1.25rem 1rem",
            flex: "1 1 100%",
        }}

        >
            <FlexBetween>
                <Typography variant="h6" sx={{color: theme.palette.secondary[100]}}>
                    {title}
                </Typography>
                {icon}
            </FlexBetween>

            <Typography
                variant="h3"
                fontWeight="600"
                sx={{color: theme.palette.secondary[200]}}
            >
                {value}
            </Typography>
            <FlexBetween gap="1rem">
                <Typography
                    variant="h5"
                    fontStyle="italic"
                    sx={{color: theme.palette.secondary.light}}
                >
                    {increase}
                </Typography>
                <Typography>{description}</Typography>
            </FlexBetween>
        </HoverPaper>
    );
};

export default StatBox;