import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  Grow,
  Paper,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import SnackbarHandler from 'components/SnackbarHandler';
import React, {useState} from 'react'
import {useParams} from 'react-router-dom';
import {LazyNavigation} from 'state/api';

interface DeleteTicket {
    id: string;
    open: boolean;
    setOpen: (b: boolean) => void;
    onDelete: () => void;
}

const DeleteTicket: React.FC<DeleteTicket> = ({id, open, setOpen, onDelete}) => {
    const [checked, setChecked] = useState(false)

    const [snackbar, setSnackbar] = useState({open: false, message: "", severity: "error"})

    const deleteTicket = async () => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/tickets/' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        })

        if (response.status == 200) {
            setOpen(false)
            setSnackbar({open: true, message: "Success! This ticket has been deleted", severity: "success"})
            onDelete()
        } else {
            setSnackbar({
                open: true,
                message: "Uh Oh! There was a problem deleteing your ticket, please contact support",
                severity: "error"
            })
        }
    }
    return (
        <>
            {/**@ts-ignore */}
            <SnackbarHandler open={snackbar.open} message={snackbar.message} severity={snackbar.severity}
                             onClose={() => setSnackbar({open: false, message: "", severity: "error"})}/>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth
                onClose={() => setOpen(false)}
            >
                <DialogContent>

                    <Typography variant="h3">Delete ticket?</Typography>
                    <Divider sx={{mb: '1rem'}}/>
                    <Typography>Warning! You are about to delete a ticket. It is recommended that you set tickets to
                        complete upon finishing them instead of deleting them.</Typography>

                    <Box sx={{mt: '1rem'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={(e, v) => setChecked(v)}
                                    color="secondary"
                                />
                            }
                            label="I understand that once I click delete that I will no longer be able to access this ticket"
                        />
                    </Box>

                    <Button variant="contained" color="error" disabled={!checked} onClick={deleteTicket}>Delete
                        Ticket</Button>
                </DialogContent>

            </Dialog>
        </>

    )
}

function TicketActions() {
    const theme = useTheme()
    const {id} = useParams();

    const [open, setOpen] = useState(false)
    return (
        <Grow in={true}>
            <Paper elevation={16} sx={{
                m: '1rem auto',
                padding: '1rem',
                width: '80%'
            }}
            >
                <Typography variant="h3">Actions</Typography>
                <Divider sx={{mt: '1rem'}}/>

                <Stack spacing={1}>
                    <Button variant="contained" color='error' onClick={() => setOpen(true)}>Delete</Button>
                </Stack>

                <DeleteTicket open={open} setOpen={setOpen} onDelete={() => LazyNavigation("/org/ticket")} id={id}/>
            </Paper>
        </Grow>

    )
}

export default TicketActions