import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Typography, useTheme } from '@mui/material'
import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react'
import state from 'state';
import SnackbarHandler from './SnackbarHandler';
import Loading from './Loading';
import { useGetRequiredScopesQuery } from 'state/api';
import { useSelector } from 'react-redux';

const Authenticator = ({scopes, domain}) => {
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarError, setSnackbarError] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const theme = useTheme();
    
    const googleRegister = useGoogleLogin({
        scope: scopes,
        state: "offline",
        hosted_domain: domain,
        //@ts-ignore
        access_type: "offline",
        responseType: "code",
        prompt: "consent",
        flow: 'auth-code',
        onSuccess: (codeResponse) => setNewToken(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });


    const setNewToken = (tokenResponse) => {
        setBackdropOpen(true);
        fetch(process.env.REACT_APP_BASE_URL + '/org/provider/data/credentials/update?relink=false', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                code: tokenResponse.code,
                type:"google",
                redirectURI: document.location.origin,
                scope: tokenResponse.scope
            })
            }).then(async (response) => {
                    setBackdropOpen(false);
                    if(response.status == 200) {
                        setSnackbarError(false)
                        setSnackbarMessage("Success! Your token has been updated. It may take a few minutes for the changes to be applied")
                        setSnackbarOpen(true)
                        setDialogOpen(false)

                    } else {
                        var res = await response.json()
                        setSnackbarError(true) 
                        setSnackbarMessage("Error! There was a problem setting your token: " + res["error"])
                        setSnackbarOpen(true)
                        setDialogOpen(false)

                    }
                }).catch((reason) => {
                    setSnackbarError(true) 
                    setSnackbarMessage("Error! There was a problem setting your token: " + reason)
                    setSnackbarOpen(true)
                    setBackdropOpen(false);
                    setDialogOpen(false)

                })
    }
    return (
        <Box>
            <SnackbarHandler open={snackbarOpen} onClose={() => setSnackbarOpen(false)} message={snackbarMessage} severity={snackbarError ? "error" : "success"} />
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
            >
                <Loading/>
            </Backdrop>
            <Button color={"warning"} variant="outlined" onClick={() => setDialogOpen(true)}>Re-Authenticate Token</Button>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogContent>
                    <Typography variant="h2">Update Token</Typography>
                    <Divider sx={{mb: '1rem'}} />
                        Please note, for CTickets to be able to create the link between a Google User and a device to manage
                        tickets, CTickets needs read-only access to Google Admin.
                        <br/>
                        We will only ask for scopes related to users and chromebooks. If you wish to read more about API access, you can 
                        do so <a href="https://developers.google.com/identity/protocols/oauth2/scopes#admin-directory" style={{color: theme.palette.primary.contrastText}}>here</a>
                        
                        <br/>
                        <br/>
                        Note: you can revoke CTickets access anytime you wish by following <a href="https://myaccount.google.com/u/0/permissions" style={{color: theme.palette.primary.contrastText}}>this link</a> and removing CTickets
                </DialogContent>
                <DialogActions>
                    <Button color="error" variant="outlined" onClick={() => setDialogOpen(false)}>Cancel</Button>
                    <Button color="success" variant="outlined" onClick={() => googleRegister()}>Update Token</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

const ReauthenticateToken = () => {
    const {data, isLoading, error} = useGetRequiredScopesQuery("");
    const [scopes, setScopes] = useState(null);
    //@ts-ignore
    const state = useSelector(s => s.global);
    useEffect(() => {
        if(data != null) {
            setScopes(data.scopes);
        }
    }, [data])

    return (
      <Box>
        {scopes == null ? (
            <Button disabled={scopes == null} color="warning">
                Re-Authenticate Token
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
            </Button>
        ) : (<Authenticator scopes={scopes} domain={state.domain} />)}
      </Box>
    )
}

export default ReauthenticateToken