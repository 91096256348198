import { Box, Skeleton } from '@mui/material'
import React from 'react'

function SkeletonLoading() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', flexDirection: "column"}}>
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="rectangular" width={210} height={60} />
        <Skeleton variant="rounded" width={210} height={60} />
    </Box>
  )
}

export default SkeletonLoading