import { Box, Breadcrumbs, Chip, Divider, Link, Typography, useTheme } from '@mui/material'
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Header from 'components/Header'
import Loading from 'components/Loading';
import TwoSide from 'components/data/TwoSide';
import React, { useState } from 'react'
import AuditCard from 'scenes/data/audit/AuditCard';
import { useGetServerAuditsQuery } from 'state/api';

function AdminAudits() {
  const {data, isLoading, error} = useGetServerAuditsQuery("");
  const [auditOpen, setAuditOpen] = useState(false);
  const [audit, setAudit] = useState(null);
  const theme = useTheme();
  if(isLoading) return <Loading/>

  const columns: GridColDef[] = [
    { field: 'type', headerName: 'Type',flex: 0.5, renderCell: (params) => {
      return getAuditTypeString(params.row.type)
    } },
    { field: 'user', headerName: 'User',flex: 0.5 },
    { field: 'message', headerName: 'Message', flex: 1 },
    { field: 'time', headerName: 'Time',type: 'dateTime', flex: 0.7, valueGetter: (params) => {
      return new Date(params.row.time);
    }},
  ];



  enum AuditType {
    TicketAdded,
    TicketUpdated,
    TicketDeleted,
  
    TicketStatusChanged,
  
    TicketMessageAdded,
    TicketMessageDeleted,
    TicketMessageUpdated,
  
    TicketPictureAdded,
    TicketPictureUpdated,
    TicketPictureRemoved,
  
    StudentsUpdated,
    StudentsAdded,
  
    ReturnDocumentUpdated,
    ReturnDocumentAdded,
    ReturnDocumentRemoved,
  
    ReturnDocumentStatusChanged,
  
    ServerError,
    ServerWarning,
    ServerInfo,
    ServerDebug,
  
    StudentLink,
    StudentLinkFailed,

    DeviceUpdated,
    UserUpdated,
    
    ActionFired,
    ActionFailed
  }
  
  function getAuditTypeString(enumValue: number) {
    const enumKeys = Object.keys(AuditType).filter(
      (key) => typeof AuditType[key] === "number"
    );
  
    for (const key of enumKeys) {
      if (AuditType[key] === enumValue) {
        return <Chip color={enumValue == AuditType.StudentLinkFailed ? "error" : "success"} size="small" variant="outlined" label={key.replace(/([A-Z])/g, " $1").trim()}/>;
      }
    }
  
    return "";
  }

  const reversedData = [...data || []].reverse();
  return (
    <Box mt="1rem">
      <TwoSide>
        <Box>
          <Typography variant="h3">Server Audits</Typography>
          <Typography variant='subtitle2'>View your server audits</Typography>
        </Box>
        <Typography sx={{mt: 'auto'}} variant='subtitle2'>*Hint: Click on a row to view the whole audit</Typography>
      </TwoSide>
      <Divider sx={{mt: '1rem'}}/>
      {audit != null && (
        <AuditCard id={audit} open={auditOpen} setOpen={(b) => {setAudit(null); setAuditOpen(b)}}/>
        
      )}
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            cursor: 'grab',

          },
          "& .MuiDataGrid-columnHeaders": {
            //@ts-ignore
            backgroundColor: theme.palette.background.alt,
            //@ts-ignore
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            //@ts-ignore
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            //@ts-ignore
            backgroundColor: theme.palette.background.alt,
            //@ts-ignore
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            //@ts-ignore
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          slots={{ toolbar: GridToolbar }}
          loading={isLoading || !data}
          getRowId={(row) => row.id}
          rows={reversedData}
          columns={columns}
          onRowClick={(params) => {
            setAudit(params.row.id)
            setAuditOpen(true)
          }}
        />
      </Box>
    
    </Box>
  )
}

export default AdminAudits