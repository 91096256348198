import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Divider,
    FormControlLabel,
    Skeleton,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {useGetDeviceQuery} from "../../../state/api";
import UserAutocomplete from "../../../components/Autocomplete/UserAutocomplete";
import Grid2 from "@mui/material/Unstable_Grid2";
import OUPathAutocomplete from "../../../components/Autocomplete/OUPathAutocomplete";
import {LoadingButton} from "@mui/lab";
import {SnackbarHandlerWrapper} from "../../../components/SnackbarHandler";

interface AssignDeviceToStudentDialogParams {
    id: string;
    setOpen: (open: boolean) => void;
    open: boolean;
}

const AssignDeviceToStudentDialog: React.FC<AssignDeviceToStudentDialogParams> =
    ({id, open, setOpen}) => {
        const [isUpdating, setIsUpdating] = React.useState(false);
        const [updateDevice, setUpdateDevice] = React.useState(false);
        const [providerDevice, setProviderDevice] = React.useState(null);
        const [deviceAssignedTo, setDeviecAssignedTo] = React.useState(null);
        const [studentsCurrentDevice, setStudentsCurrentDevice] = React.useState(null);
        const [assignTo, setAssignTo] = React.useState(null);
        const {data, isLoading, error} = useGetDeviceQuery(id);
        const {data: inventoryData, isLoading: inventoryIsLoading, error: inventoryError} = useGetDeviceQuery(id);

        const [snackbarData, setSnackbarData] = React.useState({open: false, message: "", severity: "success"});

        React.useEffect(() => {
            if (data != null) {
                setProviderDevice(data.device);

                getAssignedStudent(data.device.serialNumber).then((res) => {
                    if (res != null) {
                        setDeviecAssignedTo(res);
                    }
                })
            }
        }, [data])

        React.useEffect(() => {
            setStudentsCurrentDevice(null)
            if (assignTo) {
                getAssignedStudent(assignTo.primaryEmail).then((res) => {
                    if (res != null) {
                        setStudentsCurrentDevice(res);
                    }
                })
            }
        }, [assignTo])

        const getAssignedStudent = async (serial) => {
            var res = await fetch(process.env.REACT_APP_BASE_URL + `/org/students/query/any?q=${serial}`, {
                credentials: 'include',
                method: "GET",
            })

            if (res.status === 200) {
                return await res.json();
            }
        }

        const getDevice = async (serial) => {
            var res = await fetch(process.env.REACT_APP_BASE_URL + `org/provider/data/devices/${serial}`, {
                credentials: 'include',
                method: "GET",
            })



            if (res.status === 200) {
                return await res.json();
            }
        }


        const assignAsPrimary = async () => {
            setIsUpdating(true)

            var res = await fetch(process.env.REACT_APP_BASE_URL + `/org/inventory/devices/${id}/assign/primary?accountId=${assignTo.id}&update=${updateDevice}`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(providerDevice)
            })

            setIsUpdating(false)

            if (res.status === 200) {
                setSnackbarData({open: true, message: "Device Assigned. This page will refresh", severity: "success"})
                setTimeout(() => {
                    window.location.reload();
                }, 3000)
            } else {
                setSnackbarData({open: true, message: "Error assigning Device", severity: "error"})
            }
        }

        const assignAsSecondary = async () => {
            setIsUpdating(true)
            var res = await fetch(process.env.REACT_APP_BASE_URL + `/org/inventory/devices/${id}/assign/secondary?accountId=${assignTo.id}&update=${updateDevice}`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(providerDevice)
            })

            setIsUpdating(false)

            if (res.status === 200) {
                setSnackbarData({open: true, message: "Device Assigned. This page will refresh", severity: "success"})
                setTimeout(() => {
                    window.location.reload();
                }, 3000)
            } else {
                setSnackbarData({open: true, message: "Error assigning Device", severity: "error"})
            }
        }


        return (
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogContent>
                    <SnackbarHandlerWrapper snackbarData={snackbarData} setSnackbarData={setSnackbarData}/>

                    <Typography>Assign {id} to Student</Typography>
                    <Divider sx={{m: '.5rem 0 1rem 0'}}/>
                    {isLoading || inventoryIsLoading ? (
                        <Skeleton variant={"rectangular"} height={200}/>
                    ) : (
                        <>

                            <FormControlLabel
                                control={<Switch value={updateDevice}  color={"secondary"} onChange={(e, c) => setUpdateDevice(c)}/>}
                                label="Update Device?"/>
                            {(providerDevice != null && updateDevice) && (
                                <Box sx={{m: '2rem 0'}}>
                                    <Typography>Edit device details. Note: They may be overwritten depending on your
                                        setup</Typography>
                                    <Divider sx={{mb: '1rem'}}/>
                                    <Grid2 container spacing={2}>
                                        <Grid2 xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                color={"secondary"}
                                                variant={"filled"}
                                                label={"Asset ID"}
                                                onChange={(e) => setProviderDevice({
                                                    ...providerDevice,
                                                    annotatedAssetId: e.target.value
                                                })}
                                                value={providerDevice.annotatedAssetId}
                                            />
                                        </Grid2>
                                        <Grid2 xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                variant={"filled"}
                                                color={"secondary"}
                                                label={"Notes"}
                                                onChange={(e) => setProviderDevice({
                                                    ...providerDevice,
                                                    notes: e.target.value
                                                })}
                                                value={providerDevice.notes}
                                            />
                                        </Grid2>
                                        <Grid2 xs={12} md={6}>
                                            <OUPathAutocomplete fullWidth label={"Device OU"} onValueChange={(v) => {
                                                setProviderDevice({...providerDevice, orgUnitPath: v})
                                            }} initialValue={providerDevice.orgUnitPath}/>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{mt: '1rem'}}/>

                                </Box>
                            )}


                            <UserAutocomplete onValueChange={(s) => setAssignTo(s)} label={"Assign To?"} value={""}/>

                            {studentsCurrentDevice != null && (
                                <Box sx={{m: '1rem 0'}}>
                                    <Typography>Warning! Student currently has a primary assigned device.</Typography>
                                </Box>
                            )}


                            <Stack sx={{mt: '1rem'}} direction={"row"} spacing={1}>
                                {studentsCurrentDevice != null ? (
                                    <LoadingButton disabled={assignTo == null} loading={isUpdating} variant={"outlined"} color={"success"}>Assign as Primary Device and ReAssign
                                        old device.</LoadingButton>
                                ) : (
                                    <LoadingButton disabled={assignTo == null} loading={isUpdating} variant={"outlined"} color={"success"} onClick={assignAsPrimary}>Assign as
                                        Primary Device</LoadingButton>
                                )}
                                <LoadingButton disabled={assignTo == null} loading={isUpdating} variant={"outlined"} color={"secondary"} onClick={assignAsSecondary}>Assign as
                                    Secondary Device</LoadingButton>
                            </Stack>
                        </>
                    )}


                </DialogContent>

            </Dialog>
        );
    };


export const AssignDeviceToStudentDialogWrapper = ({id}) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Button onClick={() => setOpen(true)} variant="outlined" color="warning">Assign To Student</Button>
            {open && (
                <AssignDeviceToStudentDialog id={id} open={open} setOpen={setOpen}/>
            )}
        </>
    )
}
export default AssignDeviceToStudentDialog;