import { Add } from '@mui/icons-material';
import { Alert, Autocomplete, Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Snackbar, TextField } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { GridToolbarContainer } from '@mui/x-data-grid';
import UserAutocomplete from 'components/Autocomplete/UserAutocomplete';
import React, { useEffect, useState } from 'react'
import {useGetDataProviderUsersQuery } from 'state/api';

interface AddUserToGroup {
    id: string;
    isOpen: boolean;
    onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
const AddUserToGroup: React.FC<AddUserToGroup> = ({id, isOpen, onClose}) =>  {
    const {data, isLoading} = useGetDataProviderUsersQuery("");
    const [options, setOptions] = useState([])
    const [user, setUser] = useState({})
    const [openSnackbar, setSnackbarOpen] = useState(false);
    const [snackbarIsError, setSnackbarError] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const handleClick = () => {
      fetch(process.env.REACT_APP_BASE_URL + `/org/groups/${id}/members`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: "include",

        //@ts-ignore
        body: JSON.stringify(user.primaryEmail == null ? user : user.primaryEmail)
        }).then(async (res) => {
          if(res.status == 200) {

            //@ts-ignore
            setSnackbarMessage("User " + (user.primaryEmail == null ? user : user.primaryEmail) + " was added successfully!")
            setSnackbarError(false);
            setSnackbarOpen(true);
            setUser({});
            onClose()
          } else {
            var response = await res.json();

            //@ts-ignore
            setSnackbarMessage("Error during request: " + response.error +"!")
            setSnackbarError(true);
            setSnackbarOpen(true);
          }
        })
    };
  
    useEffect(() => {
        if(!isLoading) {
            var d = data.map((d) => {
                return {
                    label: d.primaryEmail,
                    ...d
                }
            })

            setOptions([...d])
        }
    }, [data])
    return (
      <Box>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarIsError ? "error" : "success"} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
          <Dialog
          open={isOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => onClose()}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Add user to group?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              This action will add a user to the group, meaning that they will have all permissions that are within this group, and they will
              be removed from any other groups that they may be in. 
              <br/>
              Note: You can add a email address that doesnt exist yet as long as it matches the domain, but note that the user will pop up as unknown.
              <br/>
            </DialogContentText>
            <UserAutocomplete 
              label='User'
              freeSolo
              showIcon
              onValueChange={(v) => setUser(v)}
              value=''
            />

          </DialogContent>
          <DialogActions>
            <Button color="warning" onClick={() => onClose()}>Cancel</Button>
            <Button color="success" disabled={user == ""} onClick={() => handleClick()}>Add</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
}

export default AddUserToGroup