import {AddOutlined, Close, Delete, RemoveRedEye} from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    Skeleton,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import {DataGrid, GridActionsCellItem, GridColDef, GridToolbar, GridValueGetterParams} from '@mui/x-data-grid';
import QueryDataProviderAutocomplete from 'components/Autocomplete/QueryDataProviderAutocomplete';
import SnackbarHandler from 'components/SnackbarHandler';
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import {HasMinimumScopeWithArray, LazyNavigation, useGetStudentGroupMembersQuery} from 'state/api';
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import HoverPaper from "../../components/HoverPaper";


interface AddMemberDialog {
    group: any;
    open: boolean;
    sticky?: boolean;
    setOpen: (open: boolean) => void;
    onUpdate: () => void;
}

const AddMemberDialog: React.FC<AddMemberDialog> = ({group, open, setOpen, onUpdate, sticky = false}) => {
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success"
    })
    const [value, setValue] = useState("")
    const [addFull, setAddFull] = useState(true);
    const addMember = async (member) => {

        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/students/groups/' + group.id + `/members?type=${getType(member)}&member=${getMemberDataHeader(member)}&addFullStudent=${addFull}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        })

        if (response.status == 200) {
            setSnackbar({
                open: true,
                message: `Success! Member ${getMemberDataHeader(member)} was added`,
                severity: 'success'
            })
        } else if (response.status == 404) {
            setSnackbar({
                open: true,
                message: `The member ${getMemberDataHeader(member)} already exists within the group ${group.name}`,
                severity: 'error'
            })
        } else {
            setSnackbar({
                open: true,
                message: `There was a problem adding ${getMemberDataHeader(member)}`,
                severity: 'error'
            })
        }

        onUpdate();

        if (!sticky)
            setOpen(false)
        else {
            setValue("")
        }


    }

    const getType = (member) => {
        switch (member.type) {
            case "Devices":
                return "device";
            case "Users":
                return "user";
            case "Students":
                return "student";
        }
    }

    const getMemberDataHeader = (member) => {
        switch (member.type) {
            case "Devices":
                return member.serialNumber;
            case "Users":
                return member.primaryEmail;
            case "Students":
                return member.email;
        }
    }

    return (
        <Box>
            <SnackbarHandler onClose={() => setSnackbar(
                {
                    open: false,
                    message: "",
                    severity: "success"
                    /**@ts-ignore */

                })} open={snackbar.open} message={snackbar.message} severity={snackbar.severity}/>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth="md"
                fullWidth
            >

                <DialogTitle>
                    <Typography variant="h2">Add Member</Typography>

                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>

                <DialogContent>
                    <Typography variant="subtitle1">Add a Student, Device, or User to {group.name}</Typography>

                    <Divider sx={{m: '0 0 1rem 0'}}/>

                    <FormControlLabel
                        control={
                            <Checkbox
                                color="secondary"
                                checked={addFull}
                                onChange={(e, checked) => setAddFull(checked)}
                            />
                        }
                        label={
                            <Tooltip
                                title="Adding a full student will also add the associated device as well as the associated user account to this group">
                                <span>Add Full Student?</span>
                            </Tooltip>
                        }
                    />

                    <QueryDataProviderAutocomplete fullWidth onValueChange={(v) => {
                        setValue(v)
                        addMember(v)
                    }} label={'Member to Add'} value={value}/>
                </DialogContent>

            </Dialog>
        </Box>
    )
}


interface GroupMembersTable {
    group: any;
    isEditable?: boolean;
    compact?: boolean;
}

const GroupMembersTable: React.FC<GroupMembersTable> = ({group, isEditable, compact = false}) => {
    const theme = useTheme();
    const [addMemberOpen, setAddMemberOpen] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canViewStudents, setCanViewStudents] = useState(false);
    const [canViewUsers, setCanViewUsers] = useState(false);
    const [canViewDevices, setCanViewDevices] = useState(false);
    const [canRemove, setCanRemove] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success"
    })
    const [data, setData] = useState(null);
    //@ts-ignore
    const global = useSelector(d => d.global);
    const {data: members, isLoading, error, refetch} = useGetStudentGroupMembersQuery(group.id);

    useEffect(() => {
        if (global.scopes != null) {
            setCanEdit(HasMinimumScopeWithArray(global.scopes, "write:student:groups:members:add"))
            setCanRemove(HasMinimumScopeWithArray(global.scopes, "write:student:groups:members:remove"))
            setCanViewStudents(HasMinimumScopeWithArray(global.scopes, "read:student"))
            setCanViewUsers(HasMinimumScopeWithArray(global.scopes, "read:user"))
            setCanViewDevices(HasMinimumScopeWithArray(global.scopes, "read:device"))
        }
    }, [global])

    useEffect(() => {
        if (members != null) {
            var students = members.students.filter(m => m != null);
            var devices = members.devices.filter(m => m != null);
            var users = members.users.filter(m => m != null);

            setData({
                students: students,
                devices: devices,
                users: users
            })
        }
    }, [members]);

    const removeMember = async (member, type) => {
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/org/students/groups/' + group.id + `/members?type=${type}&member=${member}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        })

        if (response.status == 200) {
            setSnackbar({
                open: true,
                message: `Success! Member ${member} was removed`,
                severity: 'success'
            })
        } else if (response.status == 404) {
            setSnackbar({
                open: true,
                message: `The member ${member} does not exist within the group ${group.name}`,
                severity: 'error'
            })
        } else {
            setSnackbar({
                open: true,
                message: `There was a problem removing ${member}`,
                severity: 'error'
            })
        }

        refetch()
    }

    const studentsGridColDef: GridColDef[] = [
        {field: 'name', headerName: 'Name', flex: 0.7},
        {field: 'email', headerName: 'Email', flex: 1},
        {
            field: 'serialNumber',
            headerName: 'Device Serial Number',
            type: 'string',
            flex: 1,
        },
        {
            field: 'annotatedAssetId',
            headerName: 'Device Asset Number',
            type: 'string',
            flex: 1,
        },
        {
            field: 'tickets',
            headerName: 'Number of Tickets',
            flex: .5,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.tickets != null ? params.row.tickets.length : '0'}`,
        },

    ];

    const devicesGridColDef: GridColDef[] = [
        {
            field: 'serialNumber',
            headerName: 'Device Serial Number',
            type: 'string',
            flex: 1,
        },
        {
            field: 'annotatedAssetId',
            headerName: 'Device Asset Number',
            type: 'string',
            flex: 1,
        },
        {
            field: 'tickets',
            headerName: 'Number of Tickets',
            flex: .5,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.tickets != null ? params.row.tickets.length : '0'}`,
        },

    ];

    const usersGridColDef: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            type: 'string',
            flex: 1,
        },
        {
            field: 'primaryEmail',
            headerName: 'Primary Email',
            type: 'string',
            flex: 1,
        },

    ];

    if (isEditable) {
        usersGridColDef.push({
            field: 'actions',
            type: 'actions',
            headerName: "Actions",
            getActions: (params) => {
                var options = []

                if (canViewUsers) {
                    options.push(...
                        [
                            <GridActionsCellItem
                                size='large'

                                icon={<RemoveRedEye/>}
                                label="View?"
                                onClick={() => LazyNavigation("/org/users/" + params.row.primaryEmail)}
                            />
                        ]
                    )
                }

                if (canEdit) {
                    options.push(...
                        [
                            <GridActionsCellItem
                                size='large'
                                icon={<Delete color="warning"/>}
                                label="Delete?"
                                onClick={() => removeMember(params.row.primaryEmail, "user")}
                            />,
                        ]
                    )
                }


                return (options)
            }
        })

        devicesGridColDef.push({
            field: 'actions',
            type: 'actions',
            headerName: "Actions",
            getActions: (params) => {
                var options = []
                if (canViewDevices) {
                    options.push(...
                        [
                            <GridActionsCellItem
                                size='large'

                                icon={<RemoveRedEye/>}
                                label="View?"
                                onClick={() => LazyNavigation("/org/devices/" + params.row.serialNumber)}
                            />
                        ]
                    )
                }

                if (canEdit) {
                    options.push(...
                        [
                            <GridActionsCellItem
                                size='large'
                                icon={<Delete color="warning"/>}
                                label="Delete?"
                                onClick={() => removeMember(params.row.serialNumber, "device")}
                            />,
                        ]
                    )
                }


                return (options)
            }
        })


        studentsGridColDef.push({
            field: 'actions',
            type: 'actions',
            headerName: "Actions",
            getActions: (params) => {
                var options = []

                if (canViewStudents) {
                    options.push(...
                        [
                            <GridActionsCellItem
                                size='large'

                                icon={<RemoveRedEye/>}
                                label="View?"
                                onClick={() => LazyNavigation("/org/students/" + params.row.email)}
                            />
                        ]
                    )
                }
                if (canEdit) {
                    options.push(...
                        [
                            <GridActionsCellItem
                                size='large'
                                icon={<Delete color="warning"/>}
                                label="Delete?"
                                onClick={() => removeMember(params.row.email, "student")}
                            />,
                        ]
                    )
                }


                return (options)
            }
        })
    }

    return (
        <HoverPaper elevation={4} sx={{padding: "1rem"}}>
            {(isEditable && canEdit) && (

                <Button onClick={() => setAddMemberOpen(true)} variant='outlined' color='secondary'
                        endIcon={<AddOutlined/>}>
                    Add Member
                </Button>
            )}
            <SnackbarHandler onClose={() => setSnackbar(
                {
                    open: false,
                    message: "",
                    severity: "success"
                    /**@ts-ignore */

                })} open={snackbar.open} message={snackbar.message} severity={snackbar.severity}/>
            <AddMemberDialog
                open={addMemberOpen}
                setOpen={setAddMemberOpen}
                onUpdate={() => refetch()}
                group={group}
                sticky
            />

            {data == null && (
                <Box>
                    <Skeleton variant={"rectangular"} height={50}/>
                    <Skeleton variant={"rectangular"} height={50}/>
                    <Skeleton variant={"rectangular"} height={50}/>
                </Box>
            )}
            {data != null && (

                <Box
                    width="100%"
                    mt="40px"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },

                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${theme.palette.secondary[200]} !important`,
                        },
                    }}
                >
                    <Typography variant='h3'>Students</Typography>
                    <DataGrid
                        slots={{toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay}}
                        loading={isLoading}
                        getRowId={(row) => row.id}
                        autoHeight
                        rows={data.students || []}
                        columns={studentsGridColDef}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                    />

                    <Divider sx={{m: '1rem 0'}}/>

                    <Typography variant='h3'>Devices</Typography>
                    <DataGrid
                        slots={{toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay}}
                        loading={false}
                        getRowId={(row) => row.serialNumber}
                        autoHeight
                        rows={data.devices || []}
                        columns={devicesGridColDef}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}

                    />

                    <Divider sx={{m: '1rem 0'}}/>

                    <Typography variant='h3'>User Accounts</Typography>
                    <DataGrid
                        slots={{toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay}}
                        loading={false}
                        getRowId={(row) => row.id}
                        autoHeight
                        rows={data.users || []}
                        columns={usersGridColDef}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}

                    />

                    <Divider sx={{m: '1rem 0'}}/>
                </Box>
            )}
        </HoverPaper>
    )
}

export default GroupMembersTable