import { Add, Delete } from '@mui/icons-material';
import { Box, Typography, Divider, Stack, IconButton, TextField, MenuItem, useTheme } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useGridInitialization } from '@mui/x-data-grid/internals';
import Loading from 'components/Loading';
import React, { useEffect, useState } from 'react'
import { useGetFormQuery, useGetTableQuery } from 'state/api';

interface TableField {
    field: string;
    headerName: string;
    flex: number;
}
function TicketTableSettings() {
    //List of all possible columns
    const [items, setItems] = React.useState<TableField[]>([]);

    //List of columns that we are currently using
    const [columns, setColumns] = useState([]);
    const [exampleItems, setExampleItems] = useState([]);
    const {data, isLoading} = useGetFormQuery("");
    const {data: tableItems, isLoading: tableIsLoading} = useGetTableQuery("");
    const theme = useTheme()



    useEffect(() => {
        if(!isLoading) {
          var fields = data.fields || [];

          fields = fields.map((f) => {
            return {
              field: f.label,
              headerName: f.label,
              flex: 1
            }
          })



          fields.push({
            field: 'status',
            headerName: "Status",
            flex: 1
          })

          fields.push({
            field: 'createdAt',
            headerName: "Created At",
            flex: 1
          })
          
          setItems(fields)
          
        }
    }, [data])

    useEffect(() => {
      //Load from existing table
      if(!tableIsLoading) {
        setColumns([...tableItems] || [])
      }
    }, [tableItems])

    const update = () => {
      console.log(columns)
      // var d = items.map((i) => {
      //   if(i.field == 'Created At') {
      //     return {
      //       field: "createdAt",
      //       headerName: "Created At",
      //       flex: i.flex
      //     }
      //   } else if(i.field == "Status") {
      //     return {
      //       field: "status",
      //       headerName: "Status",
      //       flex: i.flex
      //     }
      //   } else return i;
      // })

      fetch(process.env.REACT_APP_BASE_URL + "/org/tickets/table", {
        method: "PATCH",
        body: JSON.stringify(columns),
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: "include"
        }).then(async function(response) {
        }).catch( (reason) => {
        })
    }

  


    function generateRandomString(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
      
        for (var i = 0; i < length; i++) {
          var randomIndex = Math.floor(Math.random() * charactersLength);
          result += characters.charAt(randomIndex);
        }
      
        return result;
    }

    function generateRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
      
      

    function arrayToObject(arr, value) {
        var result = {};
        for (var i = 0; i < arr.length; i++) {
          result[arr[i]] = value;
        }
        return result;
      }
      

    const add = () => {

      var newItem = items.filter((i) => columns.findIndex((v) => v.field == i.field) == -1)

      if(newItem.length == 0) return;

      var item = {
        field: newItem[0].field,
        headerName: newItem[0].headerName,
        flex: 1
      }

      columns.push(item)

      setColumns([...columns])


      update()

    }


    function arrayDifferences(arr1, arr2) {
        var differences = [];
      
        for (var i = 0; i < arr1.length; i++) {
          if (!arr2.includes(arr1[i])) {
            differences.push(arr1[i]);
          }
        }
      
        for (var j = 0; j < arr2.length; j++) {
          if (!arr1.includes(arr2[j])) {
            differences.push(arr2[j]);
          }
        }
      
        return differences;
      }
      

    if(isLoading) return (<Loading/>)
    return (
    <Box sx={{mt: '1rem'}}>
        <Typography variant="h3">Ticket Table Settings</Typography>
        <Typography variant='subtitle2'>Customize how tickets show up on tables</Typography>
        <Divider sx={{mt: '1rem'}}/>
        
        <Box sx={{
            //@ts-ignore
            backgroundColor:theme.palette.background.alt,
            borderRadius:"0.55rem",
            padding: '.5rem',
            m: '1rem 0'
        }}>
            <Typography variant="h4">Items</Typography>
            <Divider/>
            <Stack direction={'row'} flexWrap={'wrap'} divider={<Divider sx={{m: '1rem 0'}} orientation='vertical' flexItem/>}>
              {columns.map((c, i) => (
                  <Box key={c.field} sx={{
                    //@ts-ignore
                    backgroundColor: 'rgba(0,0,0,0.1)',
                    borderRadius:"0.55rem",
                    margin: '1rem',
                    padding: '.5rem'
                }}>
                  <Stack direction="row" divider={<Divider orientation='vertical' flexItem/>} spacing={1}>
                    <TextField
                      label="Field"
                      value={c.headerName}
                      select
                      onChange={(e) => {
                        //Update columns with the item
                        var index = items.findIndex((v) => v.headerName == e.target.value);
                        if(index == -1) return;
                        columns[i] = items[index];

                        setColumns([...columns])
                        update();

                      }}
                      color="secondary"
                      >
                        {items.map((i) => {
                          if(i.headerName != c.headerName && columns.findIndex((v) => v.field == i.field) != -1) {
                            return;
                          } else {
                            return <MenuItem key={i.headerName} value={i.headerName}>{i.headerName}</MenuItem>
                          }
                        })}
                    </TextField>
                    <TextField
                      label="Size"
                      color="secondary"
                      value={c.flex}
                      select
                      onChange={(e) => {
                        columns[i].flex = e.target.value;
                        setColumns([...columns])
                        update();
                      }}
                    >
                        <MenuItem key={.5} value={.5}>sm</MenuItem>
                        <MenuItem key={1} value={1}>md</MenuItem>
                        <MenuItem key={1.5} value={1.5}>lg</MenuItem>
                    </TextField>

                    <IconButton color='warning' onClick={() => {
                        columns.splice(i, 1)
                        update()
                        setColumns([...columns])
                    }}>
                        <Delete/>
                    </IconButton>
                  </Stack>
                </Box>
              ))}
              <IconButton onClick={add}>
                  <Add/>
              </IconButton>
            </Stack>
        </Box>
        <Box sx={{
            //@ts-ignore
            backgroundColor:theme.palette.background.alt,
            borderRadius:"0.55rem",
            padding: '.5rem',
            m: '1rem 0'
        }}>
            <Typography variant="h4">Example Table</Typography>

            <Box
              mt="40px"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  //@ts-ignore
                  backgroundColor: theme.palette.background.alt,
                  //@ts-ignore
                  color: theme.palette.secondary[100],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: theme.palette.primary.light,
                },
                "& .MuiDataGrid-footerContainer": {
                  //@ts-ignore
                  backgroundColor: theme.palette.background.alt,
                  //@ts-ignore
                  color: theme.palette.secondary[100],
                  borderTop: "none",
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  //@ts-ignore
                  color: `${theme.palette.secondary[200]} !important`,
                },
              }}
            >
              <DataGrid
                loading={isLoading}
                getRowId={(row) => row.id}
                rows={exampleItems || []}
                columns={columns}
              />
            </Box>
        </Box>

    </Box>
  )
}

export default TicketTableSettings