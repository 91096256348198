import React from 'react';
import {LazyNavigation, useGetStudentDevicesQuery} from "../../../state/api";
import {Box, Button, ButtonGroup, Card, CardContent, Divider, Skeleton, Typography, useTheme} from "@mui/material";
import {DataGrid, GridCellParams, GridToolbar} from "@mui/x-data-grid";
import {AddDeviceToStudentDialog} from "./StudentAssignedDevicesDialog";
import HoverPaper from "../../../components/HoverPaper";
import {DeviceCheckinDialogWrapper} from "../../inventory/devices/DeviceCheckinDialog";

interface StudentAssignedDevicesProps {
    id: string
}

const StudentAssignedDevices: React.FC<StudentAssignedDevicesProps> = ({id}) => {
    const {data, isLoading, error, refetch} = useGetStudentDevicesQuery(id);
    const theme = useTheme()

    const removeDevice = (sku) => {
        fetch(process.env.REACT_APP_BASE_URL + `/org/students/${id}/devices/${sku}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        }).then(() => {
            refetch()
        })
    }

    if (isLoading) {
        return (
            <HoverPaper sx={{height: '100%'}}>
                <Skeleton height="20vh"/>
                <Skeleton height="5vh"/>
                <Skeleton height="5vh"/>
            </HoverPaper>
        )
    }

    const columns = [
        {
            field: "sku",
            headerName: "SKU",
            flex: 1,
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 1
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: .5,
            renderCell: (params: GridCellParams) => (
                <ButtonGroup>
                    <Button onClick={() => LazyNavigation("/org/inventory/devices/" + params.row.sku)} color="secondary"
                            variant="outlined">View</Button>
                    <DeviceCheckinDialogWrapper id={params.row.sku} />
                </ButtonGroup>
            )
        }
    ]

    return (
        <HoverPaper elevation={4} sx={{height: '100%'}}>
            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Student Assigned
                Devices</Typography>
            <Divider sx={{mb: '1rem'}}/>

            <AddDeviceToStudentDialog id={id} onChange={() => {
                refetch()
            }}/>
            {data != null && (
                <Box
                    width="100%"
                    mt="40px"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            cursor: 'grab',
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${theme.palette.secondary[200]} !important`,
                        },
                    }}
                >
                    <DataGrid
                        sx={{minHeight: '200px', mt: '1rem'}}
                        slots={{toolbar: GridToolbar}}
                        getRowId={(row) => row.id}
                        autoHeight
                        columns={columns}
                        rows={data || []}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                    />
                </Box>
            )}
        </HoverPaper>

    )
}

export default StudentAssignedDevices;