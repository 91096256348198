import { Autocomplete, Backdrop, Box, Button, Checkbox, Grow, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, TextField, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2';
import DeviceAutocomplete from 'components/Autocomplete/DeviceAutocomplete';
import UserAutocomplete from 'components/Autocomplete/UserAutocomplete';
import Loading from 'components/Loading';
import React, { useEffect, useState } from 'react'
import { useGetAllDataFromProviderQuery } from 'state/api';

interface Setup {
    OnSetupComplete: () => void;
}
const SetupLink: React.FC<Setup> = ({OnSetupComplete}) => {
    const {data, isLoading, error} = useGetAllDataFromProviderQuery("");
    const [users, setUsers] = useState([])
    const [devices, setDevices] = useState([])
    const [backdropOpen, setBackdropOpen] = useState(false);

    const [device, setDevice] = useState({} as any)
    const [user, setUser] = useState({} as any)

    useEffect(() => {
        if(!isLoading) {
            setUsers(data.users)
            setDevices(data.devices)
        }
    }, [data])

    const [deviceField, setDeviceField] = useState("")
    const [userField, setUserField] = useState("")

    const [linkPercentage, setLinkPercentage] = useState(0);
    const [linkExample, setLinkExample] = useState(null as any);


    const FindLink = async() => {
        setBackdropOpen(true)
        var response = await fetch(process.env.REACT_APP_BASE_URL + `/org/provider/configuration/link?deviceId=${device.serialNumber}&userId=${user.id}&deviceField=${deviceField}&userField=${userField}`, {credentials: "include"})

        if(response.status == 200) {
            var items = await response.json();
            var linkPercentage = items[0].matchPercentage;

            setLinkPercentage(linkPercentage)
        }

        setBackdropOpen(false)
    }

    const TestLink = async () => {
        setBackdropOpen(true)
        var response = await fetch(process.env.REACT_APP_BASE_URL + `/org/provider/configuration/test?deviceField=${deviceField}&userField=${userField}`, {credentials: "include"})
        
        if(response.status == 200) {
            var items = await response.json()
            console.log(items)
            setLinkExample(items)
        }
        setBackdropOpen(false)
    }

    const SaveConfiguration = async () => {
        setBackdropOpen(true)
        var response = await fetch(process.env.REACT_APP_BASE_URL + `/org/provider/configuration`, 
            {
                method:"PATCH", 
                credentials: "include", 
                body: JSON.stringify({deviceField: deviceField, userField: userField}),
                headers: {
                    'Content-Type': 'application/json'
                },
            })

        setBackdropOpen(false)
        if(response.status == 200) {
            OnSetupComplete();
        }

    }
  return (
    <Box>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
        >
            <Loading/>
        </Backdrop>

        <Grow in={true}>
            <Box>
                <Typography variant="body1">Now that we are able to pull data, lets work on getting it linked together.</Typography>
                <Typography variant="body1">Select a User and Device that you know should be linked together.</Typography>

                <Box mt="1rem">
                    <Grid2 container spacing={5}>
                        <Grid2 md={6}>
                            <DeviceAutocomplete 
                                label="Search Devices"
                                onValueChange={(v) => {
                                    delete v.label;
                                    setDevice({...v})
                                }}
                                value=""
                            />

                        </Grid2>
                        <Grid2 md={6}>
                            <UserAutocomplete
                                label="Search User"
                                onValueChange={(v) => {
                                    delete v.label
                                    setUser({ ...v })
                                }} 
                                value={''}                                                            
                            />                        
                        </Grid2>
                        <Grid2 md={6}>
                            {Object.keys(device).length > 0 && (<Typography>Now select one field to match with a user</Typography>)}
                            <List>

                                {Object.keys(device).map((key) => (
                                    <ListItem key={key} disablePadding>
                                        <ListItemButton>
                                            <ListItemIcon>
                                              <Checkbox
                                                checked={deviceField == key}
                                                edge="start"
                                                tabIndex={-1}
                                                disableRipple
                                                color="secondary"
                                                onClick={(e) => setDeviceField(key)}
                                              />
                                            </ListItemIcon>
                                        </ListItemButton>
                                        <TextField 
                                            fullWidth
                                            label={key}
                                            value={device[key]}
                                            variant="standard"
                                            color="secondary"
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid2>
                        <Grid2 md={6}>
                            {Object.keys(user).length > 0 && (<Typography>Now select one field to match with the device</Typography>)}
                            <List>
                                {Object.keys(user).map((key) => (
                                    <ListItem key={key} disablePadding>
                                        <ListItemButton>
                                            <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                tabIndex={-1}
                                                checked={userField == key} 

                                                disableRipple
                                                color="secondary"
                                                onClick={(e) => setUserField(key)}
                                            />
                                            </ListItemIcon>
                                        </ListItemButton>
                                        <TextField 
                                            fullWidth
                                            label={key}
                                            value={user[key]}
                                            variant="standard"
                                            color="secondary"
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid2>
                    </Grid2>

                    <Typography variant="subtitle1">Match {deviceField} with {userField}</Typography>

                    {deviceField != "" && userField != "" && (
                        <Grow in={deviceField != "" && userField != ""}>

                            <Box sx={{m: "1rem 0"}}>
                                <Typography>Now that we have our fields, lets test it out.</Typography>
                                <Typography variant='subtitle1'>
                                    Note: If you dont have the fields in your data provider (Google Admin, Microsoft Azure, ect) set up yet,
                                    and want to do it manually, click <i>I will do it myself</i>
                                </Typography>
                                <Stack sx={{m: '1rem 0'}} direction="row" justifyContent={'center'} spacing={1}>
                                    <Button variant="outlined" onClick={FindLink} color="secondary">Find Link</Button>
                                    <Button variant="outlined" color="warning" onClick={() => SaveConfiguration()}>I will do it myself</Button>
                                </Stack>

                                <Grow  in={linkPercentage > 0}>
                                    <Box sx={{mt: '1rem'}}>

                                        <Typography>We were able to find a link! Confidence: ~{linkPercentage * 100}%</Typography>

                                        <Button onClick={TestLink} color="secondary" variant="outlined">Test Link</Button>

                                        {linkExample != null && (
                                            <Grow in={linkExample != null}>
                                                <Box>
                                                    <Typography>Success!</Typography>

                                                    <Typography sx={{mt: '1rem'}}>Here is a example student</Typography>
                                                    <Grid2 container spacing={1}>
                                                        <Grid2 md={6}>
                                                            <Typography variant="h4">Device</Typography>
                                                            <List>
                                                                {Object.keys(linkExample.example.device).map((key) => (
                                                                    <ListItem key={key} disablePadding>
                                                                        <TextField 
                                                                            sx={{width:'80%'}}
                                                                            label={key}
                                                                            value={linkExample.example.device[key]}
                                                                            variant="standard"
                                                                            color="secondary"
                                                                        />
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                        </Grid2>
                                                        <Grid2 md={6}>
                                                            <Typography variant="h4">User</Typography>

                                                            <List>
                                                                {Object.keys(linkExample.example.user).map((key) => (
                                                                    <ListItem key={key} disablePadding>
                                                                        <TextField 
                                                                            sx={{width:'80%'}}

                                                                            label={key}
                                                                            value={linkExample.example.user[key]}
                                                                            variant="standard"
                                                                            color="secondary"
                                                                        />
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                        </Grid2>
                                                    </Grid2>
                                                    <Box mt="1rem">
                                                        <Typography>If everything is good, click here to save</Typography>
                                                        <Button onClick={SaveConfiguration} variant="contained" color="success">Save</Button>
                                                        <Typography variant="subtitle1">
                                                            Please note: after saving, the organization might not work as expected. This is because it is creating
                                                            all the links for the students for the first time, it should start working as expected after a few minutes
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grow>
                                        )}
                                    </Box>
                                </Grow>
                            </Box>
                        </Grow>
                    )}
                </Box>
            </Box>
        </Grow>
    </Box>
  )
}

export default SetupLink