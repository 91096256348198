import {Box, Button, Divider, Grow, Typography} from '@mui/material';
import Loading from 'components/Loading';
import React, {useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom';
import {useGetStudentQuery} from 'state/api';
import Error from 'components/Error';
import {Article, Person} from '@mui/icons-material';
import Grid2 from '@mui/material/Unstable_Grid2';
import DataField from 'components/data/DataField';
import StudentDeviceInfoCard from './StudentDeviceInfoCard';
import DeprovisionStudent from './DeprovisionStudent';
import StudentUserAccountInfoCard from './StudentUserAccountInfoCard';
import StudentTickets from './StudentTickets';
import {useSelector} from 'react-redux';
import NotesCard from '../NotesCard';
import StudentGroupsCard from '../other/StudentGroupsCard';
import DeviceCheckinHistory from '../device/DeviceCheckinHistory';
import StudentAssignedDevices from "./StudentAssignedDevices";
import HoverPaper from "../../../components/HoverPaper";

const option = [
    {
        name: 'Information',
        link: 'information',
        icon: <Person/>
    },
    {
        name: 'Tickets',
        link: 'tickets',
        icon: <Article/>
    }
]

function Student() {
    const getEndpoint = (): string => {
        var points = window.location.pathname.split('/');
        return points[points.length - 1];
    }
    const [active, setActive] = useState(getEndpoint())
    const [dialogOpen, setDialogOpen] = useState(false);
    //@ts-ignore
    const state = useSelector(s => s.global);
    const {id} = useParams();
    const {data, isLoading, error} = useGetStudentQuery(id)
    const navigate = useNavigate();

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setActive(newValue);
        navigate(newValue);
    };
    if (isLoading) return (<Loading/>)
    if (error || data == null || data.length == 0) return <Error Message={"Could not find student " + id}/>
    return (
        <Box m="1rem">
            <Typography variant='h2'>Student Information: {data.name}</Typography>
            <Divider sx={{mb: '1rem'}}/>

            <Grid2 container spacing={3}>
                <Grid2 xs={12} md={6}>
                    <Grow in={true}>

                        <HoverPaper elevation={4} sx={{height: '100%'}}>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Student
                                Information</Typography>
                            <Divider/>

                            <DataField compact title="Name" data={data.name} width={"100%"}/>
                            <DataField compact title="Email" data={data.email} width={"100%"}/>
                            <DataField compact title="Device" data={data.serialNumber} width={"100%"}/>


                            <Divider sx={{m: '1rem 0 .2rem 0'}}/>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Current Assigned
                                Loaner</Typography>
                            <DataField compact title="Loaner" data={data.loaner} width="100%"/>
                            <DataField compact title="Loaner Asset Id" data={data.loanerAssetId} width="100%"/>

                            {state.experimentalOptions.includes("DeprovisionDevice") && (
                                <Box>
                                    <Button color="warning" variant="outlined" onClick={() => setDialogOpen(true)}>Deprovision
                                        Device/Student</Button>
                                    <DeprovisionStudent open={dialogOpen} setOpen={setDialogOpen}
                                                        studentInformation={data}/>
                                </Box>
                            )}
                        </HoverPaper>

                    </Grow>
                </Grid2>
                <Grid2 xs={12} md={6}>
                    <Grow in={true}>
                        <Box>
                            <StudentDeviceInfoCard id={data.serialNumber}/>
                        </Box>
                    </Grow>
                </Grid2>
                <Grid2 xs={12} md={4}>
                    <Grow in={true}>
                        <Box sx={{height: "100%"}}>
                            <StudentUserAccountInfoCard id={data.accountId}/>
                        </Box>
                    </Grow>
                </Grid2>
                <Grid2 xs={12} md={8}>
                    <Grow in={true}>
                        <Box>
                            <StudentTickets/>
                        </Box>
                    </Grow>
                </Grid2>
                <Grid2 xs={12}>
                    <Grow in={true}>
                        <Box>
                            <NotesCard id={data.id} label={"Student's Notes"}/>
                        </Box>
                    </Grow>
                </Grid2>

                <Grid2 xs={12} md={12}>
                    <Grow in={true}>
                        <Box sx={{height: '100%'}}>
                            <StudentAssignedDevices id={data.id}/>
                        </Box>
                    </Grow>
                </Grid2>

                <Grid2 xs={12} md={6}>
                    <Grow in={true}>
                        <Box>
                            <StudentGroupsCard id={data.email} xs={12} md={12}/>
                        </Box>
                    </Grow>
                </Grid2>


                <Grid2 xs={12}>
                    <Grow in={true}>
                        <Box>
                            <DeviceCheckinHistory id={data.id} idIsStudent typeHidden='student'/>
                        </Box>
                    </Grow>
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default Student