import {Box, Button, Divider, Skeleton, Stack, Tooltip, Typography} from '@mui/material';
import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
    useGetDeviceQuery,
    useGetInventoryDeviceLocationQuery,
    useGetInventoryDeviceQuery,
    useGetInventoryDeviceTransactionsQuery, useGetStudentsForDeviceQuery
} from "../../../state/api";
import HoverPaper from "../../../components/HoverPaper";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../components/CustomNoRowsOverlay";
import DataField from "../../../components/data/DataField";
import ViewInProviderButton from "../../../components/ViewInProviderButton";
import DeviceMovementSankeyChart from "../../../components/Charts/DeviceMovementSankeyChart";
import {MoveDeviceDialogWrapper} from "./MoveDeviceDialog";
import SnackbarHandler from "../../../components/SnackbarHandler";
import DeviceCheckoutDialogWrapper from "./DeviceCheckoutDialogWrapper";
import GenerateTag from "../../../components/GenerateTag";
import {AssignDeviceToStudentDialogWrapper} from "./AssignDeviceToStudentDialog";
import {DeviceCheckinDialogWrapper} from "./DeviceCheckinDialog";


const InventoryDeviceTransactions = () => {
    const {id} = useParams();
    const {data, isLoading, refetch} = useGetInventoryDeviceTransactionsQuery(id);

    React.useEffect(() => {
        refetch()
    }, [id])

    const colDefs: GridColDef[] = [
        {
            field: 'sku',
            headerName: 'Device',
            flex: 1,
            editable: false,
        },
        {
            field: 'sourceName',
            headerName: 'Source',
            flex: 1,
            editable: false,
        },
        {
            field: 'destinationName',
            headerName: 'Destination',
            flex: 1,
            editable: false,
        },
        {
            field: 'author',
            headerName: 'Author',
            flex: 1,
            editable: false,
        },
        {
            field: 'time',
            headerName: 'Time',
            flex: 1,
            editable: false,
            type: "date",
            valueGetter: (params) => {
                const timestamp = params.row.time;

                const date = new Date(timestamp);
                return date;
            }
        },
    ]


    return (
        <HoverPaper elevation={4} sx={{height: '100%', minHeight: "400px"}}>
            <Typography variant={"h4"}>Device Transactions</Typography>
            <Divider/>
            {data ? (
                <DataGrid
                    sx={{height: '90%', mt: '1rem'}}
                    columns={colDefs}
                    rows={data ?? []}
                    getRowId={(row) => row.id}
                    slots={{noRowsOverlay: CustomNoRowsOverlay}}

                    //Default sort by time
                    sortModel={[
                        {
                            field: 'time',
                            sort: 'desc',
                        },
                    ]}
                />
            ) : (
                <Skeleton variant={"rectangular"} height={"90%"}/>
            )}
        </HoverPaper>

    )
}

const InventoryDeviceDetails = () => {
    const {id} = useParams();
    const {
        data: inventoryDevice,
        isLoading: inventoryDeviceLoading,
        refetch: inventoryDeviceRefetch
    } = useGetInventoryDeviceQuery(id);
    const {data, isLoading, refetch} = useGetDeviceQuery(id);

    React.useEffect(() => {
        refetch()
        inventoryDeviceRefetch()
    }, [id])

    const navigate = useNavigate();

    return (
        <HoverPaper elevation={4} sx={{height: '100%'}}>
            <Typography variant={"h4"}>Device Details</Typography>
            <Divider sx={{mb: '1rem'}}/>
            {inventoryDeviceLoading ? (
                <Skeleton variant={"rectangular"} height={"30%"}/>
            ) : (
                <>
                    <DataField compact title="SKU" data={inventoryDevice?.sku} width={"100%"}/>
                    <DataField compact title="Type" data={inventoryDevice?.type} width={"100%"}/>
                    <DataField compact title="Location" data={inventoryDevice?.locationName} width={"100%"}/>
                    <DataField compact title="Status" data={inventoryDevice?.isInInventory ? "In Inventory" : "Checked Out"} width={"100%"}/>
                </>
            )}
            {isLoading ? (
                <Skeleton variant={"rectangular"} height={"30%"}/>
            ) : (
                <>
                    {data ? (
                        <>
                            <DataField compact title="Serial Number" data={data.device.serialNumber} width="100%"/>
                            <DataField compact title="Asset Id" data={data.device.annotatedAssetId} width="100%"/>
                            <DataField compact title="Annotated User" data={data.device.annotatedUser} width="100%"/>
                            <DataField compact title="Annotated Location" data={data.device.annotatedLocation}
                                       width="100%"/>
                            <DataField compact title="Notes" data={data.device.notes} width="100%"/>
                            <DataField compact title="OU" data={data.device.orgUnitPath} width="100%"/>
                            {data.device.activeTimeRanges != null && (<DataField compact title="Last Login Time"
                                                                                 data={new Date(data.device.activeTimeRanges.at(-1)).toDateString()}
                                                                                 width="100%"/>)}

                            <ViewInProviderButton type="device" id={data.device.deviceId}/>
                            <Button fullWidth sx={{mt: '10px'}} variant={"outlined"} color={"success"}
                                    onClick={() => navigate("/org/devices/" + data.device.serialNumber)}>View
                                Device</Button>
                        </>
                    ) : (
                        <Typography>Device does not exist in provider. Additional data could not be
                            provided</Typography>
                    )}
                </>
            )}
        </HoverPaper>
    )
}

const InventoryDeviceLocation = () => {
    const {id} = useParams();
    const {data, isLoading, refetch} = useGetInventoryDeviceLocationQuery(id);

    const navigate = useNavigate();

    React.useEffect(() => {
        refetch()
    }, [id])

    return (
        <HoverPaper elevation={4} sx={{height: '100%'}}>
            <Typography variant={"h4"}>Location</Typography>
            <Divider sx={{mb: '1rem'}}/>
            {isLoading ? (
                <Skeleton variant={"rectangular"} height={"30%"}/>
            ) : (
                <>
                    {data ? (
                        <>

                            <DataField compact title="Location Name" data={data.name} width="100%"/>
                            <DataField compact title="Description" data={data.description} width="100%"/>
                            <Button fullWidth sx={{mt: '10px'}} variant={"outlined"} color={"success"}
                                    onClick={() => navigate("/org/inventory/locations/" + data?.id)}>View
                                Location</Button>
                        </>
                    ) : (
                        <Typography>Device is currently not assigned to a location.</Typography>
                    )}
                </>
            )}
        </HoverPaper>
    )
}

const DeviceActions = () => {
    const {id} = useParams();
    const {data, refetch} = useGetInventoryDeviceQuery(id);
    const [snackbarData, setSnackbarData] = React.useState({open: false, message: "", severity: "success"})
    const navigate = useNavigate()

    React.useEffect(() => {
        refetch()
    }, [id])

    const DeleteDevice = async (sku) => {
        var response = await fetch(process.env.REACT_APP_BASE_URL + `/org/inventory/devices/${sku}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        });

        // If the request was successful, add the device to the list
        if (response.status === 200) {
            //Display success
            setSnackbarData({open: true, message: "Device removed successfully. This page will relocate in 5 seconds", severity: "success"})

            // Navigate back to inventory in 5 seconds
            setTimeout(() => {
                navigate("/org/inventory/devices")
            }, 5000)

        } else {
            // Display error
            setSnackbarData({open: true, message: "Error removing device", severity: "error"})
        }

    }

    return (
        <HoverPaper elevation={4} sx={{height: '100%'}}>
            <Typography variant={"h4"}>Actions</Typography>
            <Divider sx={{mb: '1rem'}}/>
            <SnackbarHandler open={snackbarData.open} onClose={() =>
                setSnackbarData({...snackbarData, open: false})
            } message={snackbarData.message} severity={snackbarData.severity}/>

            <Stack spacing={1}>
                <MoveDeviceDialogWrapper device={id}/>
                {data?.isInInventory ? (
                    <>
                        <AssignDeviceToStudentDialogWrapper id={id}/>
                    </>
                ) : (
                    <>
                        <DeviceCheckinDialogWrapper id={id}/>
                    </>
                )}
                <Button onClick={() => DeleteDevice(id)} color={"error"} variant={"outlined"}>
                    Delete Device
                </Button>
            </Stack>
        </HoverPaper>
    )
}

const AssignedStudents = () => {
    const {id} = useParams();
    const {data, isLoading, refetch} = useGetStudentsForDeviceQuery(id);

    React.useEffect(() => {
        refetch()
    }, [id])

    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            type: 'string',
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            type: 'string',
            flex: 1,
        }
    ];

    return (
        <HoverPaper elevation={4} sx={{height: '100%', minHeight: '200px'}}>
            <Typography variant={"h4"}>Assigned Students</Typography>
            <Divider sx={{mb: '1rem'}}/>
            {isLoading ? (
                <Skeleton variant={"rectangular"} height={"30%"}/>
            ) : (
                <>
                    {data?.length == 0 ? (
                        <Typography>Device is not assigned to any students</Typography>
                    ) : (
                        <Box>
                            <DataGrid
                                autoHeight
                                columns={columns}
                                rows={data ?? []}
                                getRowId={(row) => row.id}
                                slots={{noRowsOverlay: CustomNoRowsOverlay}}
                                onRowClick={(params) => navigate("/org/students/" + params.row.id)}
                            />
                        </Box>

                    )}
                </>
            )}
        </HoverPaper>
    )

}


const InventoryDevice = () => {
    const {id} = useParams();
    return (
        <Box sx={{padding: '1rem'}}>
            <Typography variant={"h5"}>{id}</Typography>
            <Divider/>

            <Grid2 spacing={2} container sx={{mt: '1rem'}}>
                <Grid2 xs={12} md={8}>
                    <InventoryDeviceTransactions/>
                </Grid2>

                <Grid2 xs={12} md={4}>
                    <InventoryDeviceDetails/>
                </Grid2>

                <Grid2 xs={12} md={6}>
                    <InventoryDeviceLocation/>
                </Grid2>

                <Grid2 xs={12} md={6}>
                    <DeviceActions/>
                </Grid2>

                <Grid2 xs={12} md={12}>
                    <AssignedStudents/>
                </Grid2>

                <Grid2 xs={12} md={12}>
                    <DeviceMovementSankeyChart id={id}/>
                </Grid2>

            </Grid2>
        </Box>
    );
};

export default InventoryDevice;