
import {AddOutlined } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grow, useTheme } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Header from 'components/Header';
import React, { useState } from 'react'
import {useGetUsersQuery } from 'state/api';
import AddUserForm from './AddUserForm';
import { useSelector } from 'react-redux';
function Users() {
  //@ts-ignore
    const canAdd = useSelector(s => s.global).userRole == "Creator"
    const theme = useTheme();
    const {data, isLoading} = useGetUsersQuery("");
    const [selectedUser, setSelectedUser] = useState({});
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'firstName', headerName: 'First Name',flex: 1 },
        { field: 'lastName', headerName: 'Last Name',flex: 1 },
        {
          field: 'email',
          headerName: 'Email',
          type: 'string',
          flex: 1,
        },
        { field: 'occupation', headerName: 'Occupation',flex: 1 },
        { field: 'role', headerName: 'Role',flex: 1, valueGetter(params) {
            return roleToString(params.row['role'])
        }, },
        { field: 'isBanned', headerName: 'Banned?',flex: 1, type: 'boolean' },
        
      ];



      const openUserDialog = (params: GridCellParams) => {
        setSelectedUser(params.row);
        handleClickOpen();
      }
    

      const [open, setOpen] = React.useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
        setSelectedUser({});
      };

      const roleToString = (role: number) => {
        switch(role) {
          case 0: return "Creator";
          case 1: return "Admin";
          case 3: return "Student";
          default: return "None";
        }
      }
    
    return (
      <Box m="1.5rem 2.5rem" maxWidth={'83vw'}>
      <Header title="USERS" subtitle="List of all users" />
      <Grow in={true}>

        <Box
          height="75vh"
          maxWidth={"85vw"}
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
                          //@ts-ignore
              backgroundColor: theme.palette.background.alt,
                          //@ts-ignore
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
                          //@ts-ignore
              backgroundColor: theme.palette.background.alt,
                          //@ts-ignore
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              //@ts-ignore
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            slots={{ toolbar: GridToolbar }}
            loading={isLoading || !data}
            getRowId={(row) => row.id}
            rows={data || []}
            columns={columns}
            onCellClick={(data) => {
              if(canAdd) {
                openUserDialog(data)
              }
            }}
          />
        </Box>
      </Grow>


      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add or Update User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add or Update a user with a specified role.
            <br/>
            NOTE: If you are only setting the user to the default role of the server it is not needed as they are set with the default role upon login
          </DialogContentText>
          <AddUserForm preSelectedUser={selectedUser}/>
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
    )
}

export default Users