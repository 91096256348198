import { Box, Tab, Tabs, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

function TicketSettings() {

    const defaultSettings = [
        {
            name: 'Form',
            link: 'form'
        },
        {
            name: 'Layout',
            link: 'layout'
        },
        {
            name: "Table",
            link: 'table'
        },
        {
            name: "Status",
            link: "status"
        }
    ]

    const navigate = useNavigate();
    const getEndpoint = (): string => {
        var points = window.location.pathname.split('/');
        return points[points.length - 1];
    }
    const [active, setActive] = useState(getEndpoint())
    const theme = useTheme();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setActive(newValue);
        navigate(newValue);
    };
    return (
        <Box>
        <Tabs 
            value={active} 
            onChange={handleChange} 
            textColor="secondary"
            indicatorColor="secondary"
            
            sx={{
                mt: '2rem',
                //@ts-ignore
                //backgroundColor:theme.palette.background.alt
            }}
        >
            {defaultSettings.map(item => {
                return (
                    <Tab value={item.link} label={item.name}/>
                )
            })}
        </Tabs>
        <Outlet/>
    </Box>
    )
}

export default TicketSettings