import { Clear, DoNotStepSharp } from '@mui/icons-material';
import { Box, IconButton, MenuItem, Slide, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'

export interface SelectProps {
    id: number;
    label: string;
    steps: string[];
    activeStep: number;
    basedOnAnswer: boolean;
    initialIndex?: number;
    onDelete: (id: number) => void;
    onUpdate: (id: number, label: string, offset: number) => void;
}
const SelectOption: React.FC<SelectProps> = ({id, label, initialIndex, activeStep, steps, basedOnAnswer,onDelete, onUpdate}) => {
    const [transition, setTransition] = useState(true)
    const [value, setValue] = useState(label);
    const [step, setStep] = useState(0)
    const [stepIndexList, setSteps] = useState([])
    const [offset, setOffset] = useState(0);


    useEffect(() => {
        if(initialIndex != undefined) {
            setStep(initialIndex)
        } else {
            setStep(0)
        }

        var stepMap = steps.map((s, index) => {
            return {offset: index - activeStep - 1, label: s}
        }).filter((step) => step.offset > 0)

        setSteps([...stepMap])

    }, [])


    useEffect(() => {
        onUpdate(id, label, step)
    }, [step])

    const deleteSelf = () => {
        const timer = setTimeout(() => {
            
            onDelete(id);
        }, 1000);

        setTransition(false);
    
        // cleanup function
        return () => {
          clearTimeout(timer);
        };
    }  
  return (
    <Box>
        <Slide direction='right' in={transition} mountOnEnter unmountOnExit>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end'
            }}>
                <TextField
                    onChange={(e) => {setValue(e.target.value); onUpdate(id, e.target.value, offset)}}
                    variant="standard"
                    color="secondary"
                    value={value}
                >
                    
                </TextField>
                <IconButton onClick={() => deleteSelf()}>
                    <Clear/>
                </IconButton>
                {basedOnAnswer && (
                    <TextField 
                        sx={{ml: '3rem', width: '10rem'}}
                        variant="standard"
                        defaultValue={0}
                        value={step}
                        onChange={(e) => setStep(Number(e.target.value))}
                        select
                    >
                        <MenuItem value={0}>Next Step</MenuItem>
                        {stepIndexList.map(step => (<MenuItem value={step.offset}>{step.label}</MenuItem>))}
                        <MenuItem value={Number.MAX_VALUE}>Submit Form</MenuItem>

                    </TextField>
                )}
            </Box>
        </Slide>
    </Box>
  )
}

export default SelectOption