import { QrCode2, QrCodeScanner } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react'
import {QrReader} from "react-qr-reader"
const SearchByQrCode = () => {
    const [open, setOpen] = useState(false);
    const handleOnValueChange = async (value: string) => {
        //Test if it is a url
        const ticketRegex = /\/ticket\/([a-f0-9]{24})/;
        var match = value.match(ticketRegex);

        if(match && match[1]) {
            navigate('/org/ticket/' + match[1])
        }

        //Attempt to query database for search
        var response = await fetch(process.env.REACT_APP_BASE_URL + '/org/search/query' + `?q=${value}`, {
            credentials: 'include',
            method: "GET"
        })

        if(response.status == 200) {
            var data = await response.json();

            if(data.users?.length > 0) {
                navigate('/org/users/' + data.users[0].id)
            }

            if(data.devices?.length > 0) {
                navigate('/org/devices/' + data.devices[0].serialNumber)
            }

            if(data.students?.length > 0) {
                navigate('/org/students/' + data.students[0].email)
            }

            if(data.tickets?.length > 0) {
                navigate('/org/tickets/' + data.tickets[0].id)
            }
        }
    }

    const navigate = (route) => {
        const host = document.location.origin;
        document.location.href = host + route
    }
    return (
        <Box>
            <IconButton onClick={() => setOpen(true)}>
                <QrCodeScanner/>
            </IconButton>

            <Dialog 
                open={open}
                onClose={() => setOpen(false)}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>
                    Scan a Code
                </DialogTitle>
                <DialogContent sx={{width: '100%'}}>
                    <QrReader
                      onResult={(result, error) => {
                        if (!!result) {
                          handleOnValueChange(result.getText())
                        }
                    
                        if (!!error) {
                          console.info(error);
                        }
                      }}
                      scanDelay={100}
                      //@ts-ignore
                      style={{ width: '100%' }}
                      constraints={{facingMode: "environment"}}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default SearchByQrCode