import {Box, Divider, Grow, Typography, useTheme} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import Loading from 'components/Loading'
import React from 'react'
import {useParams} from 'react-router-dom'
import {useGetDeviceQuery} from 'state/api'
import Error from "../../../components/Error"
import DeviceInfoCard from './DeviceInfoCard'
import RecentUsersCard from './RecentUsersCard'
import DeviceDebugCard from './DeviceDebugCard'
import DeviceAssignedStudent from './DeviceAssignedStudent'
import DeviceTicketsCard from './DeviceTicketsCard'
import NotesCard from '../NotesCard'
import StudentGroupsCard from '../other/StudentGroupsCard'
import DeviceCheckinHistory from './DeviceCheckinHistory'
import DeviceInventoryCard from "./DeviceInventoryCard";

function Device() {
    const theme = useTheme();
    const {id} = useParams();
    const {data, isLoading, error} = useGetDeviceQuery(id);
    if (isLoading) return <Loading/>
    if (error) return <Error Message={"Could not find device " + id}/>
    const getLink = (endpoint: string) => {
        const host = document.location.origin;
        return host + endpoint;
    }

    const convertToDate = (date) => {
        return new Date(date).toLocaleDateString() + " " + new Date(date).toLocaleTimeString()
    }

    return (
        <Box m="1rem">
            <Typography variant='h2'>Device Information: {data.device.serialNumber}</Typography>
            <Divider sx={{mb: '1rem'}}/>
            <Grid2 container spacing={3}>
                <Grid2 xs={12} md={4}>
                    <Grow in={true}>
                        <Box height={"100%"}>

                            <DeviceInfoCard device={data.device} isLoading={isLoading}/>
                        </Box>
                    </Grow>
                </Grid2>
                <Grid2 xs={12} md={8}>
                    <Grow in={true}>
                        <Box height={"100%"}>

                            <RecentUsersCard id={data.device.serialNumber}/>
                        </Box>
                    </Grow>
                </Grid2>
                <Grid2 xs={12} md={6}>
                    <Grow in={true}>
                        <Box height={"100%"}>

                            <DeviceDebugCard device={data.device} isLoading={isLoading}/>
                        </Box>
                    </Grow>
                </Grid2>
                <Grid2 xs={12} md={6}>
                    <Grow in={true}>
                        <Box height={"100%"}>

                            <DeviceAssignedStudent id={data.device.serialNumber}/>
                        </Box>
                    </Grow>
                </Grid2>
                <Grid2 xs={12} md={12}>
                    <Grow in={true}>
                        <Box height={"100%"}>
                            <DeviceTicketsCard tickets={data.tickets} isLoading={isLoading}/>
                        </Box>
                    </Grow>
                </Grid2>
                <Grid2 xs={12}>
                    <Grow in={true}>
                        <Box>
                            <NotesCard id={data.device.serialNumber} label={"Device's Notes"}/>
                        </Box>
                    </Grow>
                </Grid2>

                <Grid2 xs={12} md={6}>
                    <Grow in={true}>
                        <Box>
                            <StudentGroupsCard id={data.device.serialNumber} xs={12} md={12}/>
                        </Box>
                    </Grow>
                </Grid2>


                <Grid2 xs={12} md={6}>
                    <Grow in={true}>
                        <Box>
                            <DeviceInventoryCard device={data.device}/>
                        </Box>
                    </Grow>
                </Grid2>

                <Grid2 xs={12} md={12}>
                    <Grow in={true}>
                        <Box sx={{height: '100%'}}>
                            <DeviceCheckinHistory id={data.device.serialNumber}/>
                        </Box>
                    </Grow>
                </Grid2>

            </Grid2>
        </Box>
    )
}

export default Device